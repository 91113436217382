import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const generatePdf = () => {
  const documentDefinition = {
    content: [
      { text: 'Hello, this is a PDF document generated using pdfmake!' },
      // Add more content as needed
    ],
  };

  return documentDefinition;
};

export default generatePdf