import React, { useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { updateEmployeePassword } from "Apis/employeeapi";
import { useToast } from "@/components/ui/use-toast";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"

const UpdateEmpPassword = (props) => {
    const { toast } = useToast();

    const [open, setOpen] = useState(false);

    const initialValues = {
        password: "",
        confirmPassword: ""
    };

    const validationSchema = Yup.object().shape({
        password: Yup.string()
            .min(6, "Must be at least 6 characters")
            .required(" Password is required"),
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .required('Confirm Password is required'),
    });

    const onSubmit = async (values, { resetForm }) => {
        // Handle form submission

        const sendRequest = await updateEmployeePassword(values);

        resetForm({ values: "" });
        setOpen(false)
        if (sendRequest.success == true) {
            toast({
                title: "Success",
                description: sendRequest.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendRequest.message,
            });
        }
    };

    return (

        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Change Password</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Change Password</DialogTitle>
                    <DialogDescription>
                        Fill the Form and Submit
                    </DialogDescription>
                </DialogHeader>

                <div
                    className={
                        "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                    }
                >
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={onSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                        }) => (
                            <Form>
                                <div className="grid grid-cols-1 md:grid-cols-1 pt-3 pb-16 px-3 gap-2 gap-y-4 ">
                                    <div>
                                        <Label htmlFor="password"> New Password:</Label>
                                        <Input
                                            className="mt-3"
                                            type="text"
                                            id="password"
                                            name="password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.password}
                                        />
                                        <ErrorMessage
                                            name="password"
                                            className="text-red-500 text-xs  my-2"
                                            component="div"
                                        />
                                    </div>

                                    <div>
                                        <Label htmlFor="confirmPassword"> Confirm Password:</Label>
                                        <Input
                                            className="mt-3"
                                            type="text"
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.confirmPassword}
                                        />
                                        <ErrorMessage
                                            name="confirmPassword"
                                            className="text-red-500 text-xs  my-2"
                                            component="div"
                                        />
                                    </div>
                                </div>

                                <div className="mx-3">
                                    <Button type="submit">Submit</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            </DialogContent>
        </Dialog>

    );
};

export default UpdateEmpPassword;
