import React from 'react';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import Breadcrumb from 'LoadingComponents/Breadcrumb';
import { Percent, SquareStack } from 'lucide-react';
import Funds from './Funds';
import InternalFundTransfer from './InternalFundTransfer';
const FundsHome = () => {

    const BreadcrumbArray = [{ name: 'Payout', icon: <SquareStack /> }, { name: 'Reports', icon: <Percent /> }]
    return (
        <div>
            <Breadcrumb data={BreadcrumbArray} />
            <Tabs defaultValue="addfunds" className="w-full">
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="addfunds">Add Funds</TabsTrigger>
                    <TabsTrigger value="internaltransfer">Internal Fund Transfer</TabsTrigger>

                </TabsList>
                <TabsContent value="addfunds">
                    <Funds />
                </TabsContent>
                <TabsContent value="internaltransfer">
                    <InternalFundTransfer />
                </TabsContent>

            </Tabs>
        </div>
    );
};

export default FundsHome;