import React from 'react';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import MerchantChargeDetails from './MerchantChargeDetails';
import Breadcrumb from 'LoadingComponents/Breadcrumb';
import { Percent, SquareStack } from 'lucide-react';
import MerchantGateway from './MerchantGateway/MerchantGateway'
import VendorConfiguration from './VendorConfiguration/VendorConfiguration';
import VendorCharges from './VendorCharges/VendorCharges';
const MerchantSettings = () => {

    const BreadcrumbArray = [{ name: 'Technical', icon: <SquareStack /> }, { name: 'Merchant Settings', icon: <Percent /> }]
    return (
        <div>
            <Breadcrumb data={BreadcrumbArray} />
            <Tabs defaultValue="charge_details" className="w-full">
                <TabsList className="grid w-full grid-cols-4">
                    <TabsTrigger value="charge_details">Merchant Charge Details</TabsTrigger>
                    <TabsTrigger value="gateway_routes">Merchant Gateway Routes</TabsTrigger>
                    <TabsTrigger value="vendor_config">Vendor Configuration</TabsTrigger>
                    <TabsTrigger value="vendor_charges">Vendor Charges</TabsTrigger>
                </TabsList>
                <TabsContent value="charge_details">
                    <MerchantChargeDetails />
                </TabsContent>
                <TabsContent value="gateway_routes">
                    <MerchantGateway />
                </TabsContent>
                <TabsContent value="vendor_config">
                    <VendorConfiguration />
                </TabsContent>
                <TabsContent value="vendor_charges">
                    <VendorCharges />
                </TabsContent>
            </Tabs>
        </div>
    );
};

export default MerchantSettings;