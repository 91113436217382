import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast"

const ResellerMiddleware = ({ children }) => {
    const { toast } = useToast()
    let navigate = useNavigate();
    const [jwt, setJwt] = useState(localStorage.getItem("reseller"));

    


    return jwt ? <Outlet /> : <Navigate to="/reseller/login" />

};

export default ResellerMiddleware;