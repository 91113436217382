import React, { useState, useEffect } from 'react';


const MerchantPayoutTransactionDialog = (props) => {

    const [transactionInfo, setTransactioninfo] = useState(null);

    useEffect(() => {
        setTransactioninfo(props.payoutTransactions)
    }, [props])



    return (

        transactionInfo != null &&
        <div >
            <div className="grid grid-cols-8 gap-1 py-4">
                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Reference Id</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.reference_id}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Transfer Id</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.transfer_id}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Transfer Mode</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.transfer_mode}</h5>
                </div>
            </div>


            <hr />

            <h5 className='font-bold'>Beneficiary Detail</h5>
            <div className="grid grid-cols-8 gap-1 py-4">
                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Name</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.ben_name}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Phone</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.ben_phone}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Email</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.ben_email}</h5>
                </div>


                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Card </h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.ben_card_no}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Ifsc</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.ben_ifsc}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Bank Account</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.ben_bank_acc}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Upi</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.ben_upi}</h5>
                </div>
            </div>


            <hr />
            <h5 className='font-bold'>Transfer Detail</h5>
            <div className="grid grid-cols-8 gap-1 py-4">
                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Transfer Type</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.transfer_type}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Status</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.status}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Transfer Description</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.transfer_desc}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Amount</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.amount}</h5>
                </div>


                <div className="my-2 col-span-2">
                    <h5 className="text-xs">Vendor Charges </h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.vendor_charges}</h5>
                </div>

                <div className="my-2 col-span-2">
                    <h5 className="text-xs">GST</h5>
                    <h5 className="text-sm font-medium break-words">{transactionInfo.goods_service_tax}</h5>
                </div>


            </div>
        </div>


    );
};

export default MerchantPayoutTransactionDialog;