import React, { useState, useEffect } from "react";
import MerchantPayments from "./Payment/MerchantPayment";
import MerchantRefund from "./Refund/MerchantRefund";
import MerchantOrder from "./Order/MerchantOrder";
import TestPayments from "./Payment/TestPayment";
import TestOrder from "./Order/TestOrder";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useOutletContext } from "react-router-dom";

const MerchantTransactions = () => {

  const testMode = useOutletContext();

  const [activeTab, setActiveTab] = useState('payments');

  useEffect(() => {
    console.log(testMode)
    if (testMode) {
      setActiveTab('testpayments')
    }
    else {
      setActiveTab('payments')
    }
  }, [testMode])

  console.log(activeTab)

  const onTabChange = (value) => {
    setActiveTab(value);
  }

  return (
    <div className="mt-6 px-6">
      {
        testMode ? (<Tabs value={activeTab} onValueChange={onTabChange} className="w-full">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="testpayments">Payments</TabsTrigger>

            <TabsTrigger value="testorders">Orders</TabsTrigger>
          </TabsList>
          <TabsContent value="testpayments">
            <TestPayments />
          </TabsContent>

          <TabsContent value="testorders">
            <TestOrder />
          </TabsContent>
        </Tabs>
        ) : (<Tabs value={activeTab} onValueChange={onTabChange} className="w-full">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="payments">Payments</TabsTrigger>
            <TabsTrigger value="refunds">Refunds</TabsTrigger>
            <TabsTrigger value="orders">Orders</TabsTrigger>
          </TabsList>
          <TabsContent value="payments">
            <MerchantPayments />
          </TabsContent>
          <TabsContent value="refunds">
            <MerchantRefund />
          </TabsContent>
          <TabsContent value="orders">
            <MerchantOrder />
          </TabsContent>
        </Tabs>)
      }



    </div>

  );
};

export default MerchantTransactions;
