import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { getPayoutApiKeys, generatePayoutApiKeys } from "Apis/merchantapis";
import moment from "moment";
import ViewApiKeys from "Merchant/Pages/Settings/ViewApiKeys";

const PayoutApiKeys = () => {
    const [apiKeys, setapiKeys] = useState([]);


    const getData = async () => {
        const fetchData = await getPayoutApiKeys();
        setapiKeys(fetchData.data);
    };

    const regenerateApikeysClick = async () => {
        const regenerate = await generatePayoutApiKeys();
        getData();
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="rounded-md border">
            {apiKeys.length === 0 && <div className=" px-3 py-3 ">
                <Button
                    onClick={() => {
                        regenerateApikeysClick();
                    }}
                >
                    Generate Api Keys
                </Button>
            </div>}


            {apiKeys.length > 0 && <Table>

                <TableHeader>
                    <TableRow>
                        <TableHead>Sno.</TableHead>
                        <TableHead>Api Key Id</TableHead>
                        <TableHead>Created At</TableHead>
                        <TableHead>Action</TableHead>
                        <TableHead>View </TableHead>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {apiKeys.length > 0 &&
                        apiKeys.map((item, index) => (
                            <TableRow key={item._id}>
                                <TableCell className="font-medium">{index + 1}</TableCell>
                                <TableCell>{item.api_key}</TableCell>
                                <TableCell>
                                    {moment(item.created_date).format("YYYY-MM-DD")}
                                </TableCell>
                                <TableCell>
                                    <Button
                                        onClick={() => {
                                            regenerateApikeysClick();
                                        }}
                                    >
                                        Regerate
                                    </Button>
                                </TableCell>
                                <TableCell>
                                    <ViewApiKeys apiKeys={item} />
                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>}

        </div>
    );
};

export default PayoutApiKeys;
