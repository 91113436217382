import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { Button } from '@/components/ui/button';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"

const MerchantDocuments = (props) => {
    const [documents, setDocuments] = useState("");
    const [backendUrl, setBackendUrl] = useState("");

    console.log(documents, backendUrl)
    useEffect(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}`;
        setBackendUrl(url)
        setDocuments(props.documents)
    }, [props])

    return (
        <div>
            <Card>
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-lg font-medium">
                        Merchant Documents
                    </CardTitle>

                </CardHeader>
                <CardContent>
                    <Table>
                        <TableCaption>A list of merchant uploaded Documents.</TableCaption>
                        <TableHeader>
                            <TableRow>
                                <TableHead className="">File Name</TableHead>
                                <TableHead>File</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {documents.auth_pan && (
                                <TableRow key={document._id}>
                                    <TableCell>Authorized Signatory Pan Card</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.auth_pan}`}
                                                                alt="png"
                                                                width="50%"
                                                                partnership_deed
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.auth_aadhar && (
                                <TableRow key={document.auth_aadhar}>
                                    <TableCell>Authorized Signatory Aadhar Card</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.auth_aadhar}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="20%"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.company_pan && (
                                <TableRow key={documents.company_pan}>
                                    <TableCell>Company Pan Card</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.company_pan}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="200"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.gstin && (
                                <TableRow key={documents.gstin}>
                                    <TableCell>Company GST</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.gstin}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="200"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.reg_certificate && (
                                <TableRow key={documents.reg_certificate}>
                                    <TableCell>Registration Certificate</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.reg_certificate}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="200"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.cancel_cheque && (
                                <TableRow key={documents.cancel_cheque}>
                                    <TableCell>Cancelled Cheque</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.cancel_cheque}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="200"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.cin && (
                                <TableRow key={documents.cin}>
                                    <TableCell>CIN</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.cin}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="200"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.llpin && (
                                <TableRow key={documents.llpin}>
                                    <TableCell>LLPIN</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.llpin}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="200"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.inc_llp && (
                                <TableRow key={documents.inc_llp}>
                                    <TableCell>Certificate of INC LLP</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.inc_llp}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="200"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.partnership_deed && (
                                <TableRow key={documents.partnership_deed}>
                                    <TableCell>Partnership Deed</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.partnership_deed}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="200"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.moaaoa && (
                                <TableRow key={documents.moaaoa}>
                                    <TableCell>Moa/Aoa</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.moaaoa}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="200"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}

                            {documents.trust_deed && (
                                <TableRow key={documents.trust_deed}>
                                    <TableCell>Trust Deed</TableCell>
                                    <TableCell>
                                        <Dialog>
                                            <DialogTrigger><Button variant="outline">Open</Button></DialogTrigger>
                                            <DialogContent className="max-w-full">
                                                <DialogHeader>
                                                    <DialogTitle></DialogTitle>
                                                    <DialogDescription>
                                                        <div className='flex justify-center'>
                                                            <img
                                                                src={`${backendUrl}/${documents.trust_deed}`}
                                                                alt="png"
                                                                width="50%"
                                                                height="200"
                                                            />
                                                        </div>
                                                    </DialogDescription>
                                                </DialogHeader>
                                            </DialogContent>
                                        </Dialog>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>

                    </Table>
                </CardContent>
            </Card>

        </div>
    );
};

export default MerchantDocuments;