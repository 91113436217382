import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { createReseller } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";

export default function AddReseller(props) {
    const { toast } = useToast();
    const [open, setOpen] = useState(false);

    const [date, setDate] = useState();






    const initialValues =
    {
        name: "",
        email: "",
        mobile: "",
        password: ""
    };


    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email address').required('Email is required'),
        mobile: Yup.string().required('Mobile is required'),
        password: Yup.string().required('Password is required'),
    });



    const submitResellerForm = async (values) => {
        console.log(values)
        const sendData = await createReseller(values)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        setOpen(false)
        props.refreshFunction();
    }





    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button className="w-32" variant="outline">Add</Button>
            </DialogTrigger>
            <DialogContent className={
                "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Chargeback Form</DialogTitle>
                    <DialogDescription>
                        Fill the Form & Click submmit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">






                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={submitResellerForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values } = formik;

                            return (
                                <div className=" mt-1  p-5 ">
                                    <Form>
                                        <div className="grid grid-cols-3 gap-4 my-6">


                                            <div>
                                                <Label htmlFor="cdr_desc"> Name:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="name"
                                                    name="name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.name}
                                                />
                                                {errors.name && touched.name && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.name}
                                                    </span>
                                                )}
                                            </div>

                                            <div>
                                                <Label htmlFor="transaction_gid"> Email:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="email"
                                                    name="email"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.email}
                                                />
                                                {errors.email && touched.email && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.email}
                                                    </span>
                                                )}
                                            </div>

                                            <div>
                                                <Label htmlFor="adjustment_trans_id">Mobile:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="mobile"
                                                    name="mobile"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.mobile}
                                                />
                                                {errors.mobile && touched.mobile && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.mobile}
                                                    </span>
                                                )}
                                            </div>



                                            <div>
                                                <Label htmlFor="total_amount"> Password:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="password"
                                                    name="password"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.password}
                                                />
                                                {errors.password && touched.password && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.password}
                                                    </span>
                                                )}
                                            </div>



                                        </div>


                                        <div className='flex justify-center'>
                                            <Button
                                                type="submit"
                                                className="btn mt-5 w-96  font-medium  "
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>



                </div>

            </DialogContent>
        </Dialog>
    )
}
