import React from 'react';
import { useOutletContext } from "react-router-dom";
import TestMerchantDashboard from './TestMerchantDashboard';
import MerchantDashboard from './MerchantDashboard';

const DashboardHome = () => {
    const testMode = useOutletContext();
    return (
        <div>
            {
                testMode ? <TestMerchantDashboard /> : <MerchantDashboard />
            }
        </div>
    );
};

export default DashboardHome;