import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { Percent, SquareStack } from 'lucide-react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import DateTimeRangeInput from "ReusableComponents/DateTimeRangeInput";
import { addDays, format } from "date-fns";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { getMerchantBalanceSummary, getMerchantList } from "Apis/employeeapi";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import TableLoading from "LoadingComponents/TableLoading";
import Breadcrumb from "LoadingComponents/Breadcrumb";
import MerchantCard from "ReusableComponents/MerchantCard";
import AddFund from "./AddFund";
const Funds = () => {
    const [open, setOpen] = useState(false);
    const [percent, setPercent] = useState('');
    const [transactionInfo, setTransactionInfo] = useState('');
    const [merchants, setMerchants] = useState("");
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });

    const [paymentIdQ, setpaymentIdQ] = useState("");
    const [statusQ, setstatusQ] = useState("");
    const [selectedMerchant, setSelectedMerchant] = useState("");

    const [viewTable, setViewTable] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [stats, setStats] = useState([]);

    const getDateRange = (dateRangeObject) => {
        console.log(dateRangeObject, 'parent');
        setDate(dateRangeObject)

    };

    const getData = async (skip, limit, dates, merchantId) => {
        setTableLoading(true)

        if (dates != undefined) {
            setSkip(0)
            setLimit(10)
            const fetchTransactions = await getMerchantBalanceSummary(
                merchantId,
                skip,
                limit,
                dates
            );
            setTransactions(fetchTransactions.data);
            setStats(fetchTransactions.totalStats);
            setCount(fetchTransactions.count);
            setTableLoading(false)
        }
    };

    const reloadFunds = () => {
        getData(skip, limit, date, selectedMerchant)
    }

    const fetchMerchantsList = async () => {
        const fetchList = await getMerchantList();
        setMerchants(fetchList.data)
    }

    useEffect(() => {
        fetchMerchantsList();
    }, []);

    const fetchdata = (limit, skip) => {
        console.log(limit, skip);
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, date, paymentIdQ, statusQ);
    };

    const onClickView = () => {
        if (viewTable === true) {
            setViewTable(false)
        }
        else {
            setViewTable(true)
        }
    }



    useEffect(() => {
        if (viewTable === true) {
            getData(skip, limit, date, selectedMerchant);
        }
    }, [date, limit, selectedMerchant, skip, viewTable]);

    const BreadcrumbArray = [{ name: 'Payout', icon: <SquareStack /> }, { name: 'Funds', icon: <Percent /> }]
    return (
        <div className="rounded-md border">
            <Breadcrumb data={BreadcrumbArray} />
            <div className="grid grid-cols-1 md:grid-cols-4 pt-3 pb-8 px-3 gap-8">
                <DateTimeRangeInput getFunction={getDateRange} />
                <div>
                    <div>
                        <Select
                            onValueChange={(field) => {
                                setSelectedMerchant(field)
                            }}
                            defaultValue={""}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select a Status" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Merchants</SelectLabel>
                                    <SelectItem value="">All</SelectItem>
                                    {merchants.length > 0 && merchants.map((merchant) => (
                                        <SelectItem key={merchant._id} value={merchant._id}>{merchant.name}</SelectItem>
                                    ))}

                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div>
                    <Button className="mx-2" onClick={() => { onClickView() }}>View</Button>
                </div>
            </div>

            <div className=" pb-3  px-3 gap-8">
                <AddFund refreshFunction={reloadFunds} />
            </div>





            {viewTable === true && <div>
                <Table>
                    <TableCaption>A list of your recent payments.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead >Sno.</TableHead>
                            <TableHead>Merchant</TableHead>
                            <TableHead>Fund Added</TableHead>
                            <TableHead>Added On</TableHead>
                            <TableHead >Closing Balance</TableHead>
                            <TableHead>Remark</TableHead>

                        </TableRow>
                    </TableHeader>

                    {tableLoading === false ?
                        <TableBody>
                            {transactions.length > 0 &&
                                transactions.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>< MerchantCard merchant={item.merchant_id} /></TableCell>
                                        <TableCell className={item.is_debit ? 'text-red-400 font-bold' : 'text-green-400 font-bold'}> {item.is_debit ? '-' : '+'} {item.fund_added}</TableCell>
                                        <TableCell>{moment(item.add_date).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                        <TableCell>{item.closing_bal}</TableCell>
                                        <TableCell>{item.remark}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                        : <TableLoading count={9} />

                    }
                </Table>

                <div className="flex justify-center my-3">
                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div></div>}


            <Dialog open={open} onOpenChange={setOpen} disableOutsidePointerEvents >

                <DialogContent
                    className={
                        "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                    }
                >
                    <DialogHeader>
                        <DialogTitle>Exporting Transactions</DialogTitle>
                        <DialogDescription>

                        </DialogDescription>
                    </DialogHeader>
                    <h5 className="text-lg font-bold">{percent} %</h5>
                    <Progress value={percent} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Funds;
