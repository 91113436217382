import React, { useState, useEffect } from "react";
import { getAllItems } from "Apis/merchantapis";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table"
import { Label } from "@/components/ui/label";
import { addItem } from "Apis/merchantapis";
import { useToast } from "@/components/ui/use-toast";

const ItemCart = (props) => {

  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [cart, setCart] = useState([]);

  const getData = async () => {
    const fetchItems = await getAllItems();
    setItems(fetchItems.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const initialValues = {
    item: "",
    item_amount: "",
    item_quantity: "",
    total_amount: "",
  };

  const validationSchema = Yup.object().shape({
    item: Yup.string().required("Item Name is required"),
    item_amount: Yup.number().required("Item Price is required"),
    item_quantity: Yup.number()
      .required('Item Quantity is required')
      .min(1, 'Item Quantity must be greater than 0'),
    total_amount: Yup.number()
      .required('Total Amount is required')
      .min(1, 'Total Amount must be greater than 0')
  });

  const onSubmit = async (values) => {
    // Handle form submission
    setOpen(false);
    const filteredItems = items.filter(item => values.item.includes(item._id));
    values.item_name = filteredItems[0].item_name
    setCart(current => [...current, values]);
  };

  const deleteItem = (indexToDelete) => {
    // Copy the state array
    const updatedItems = cart;

    // Modify the copy: Remove the element at indexToDelete
    updatedItems.splice(indexToDelete, 1);

    // Update the state with the modified copy
    setCart(updatedItems);
  };

  useEffect(() => {
    props.sendCart(cart)
  }, [cart, props])





  return (
    <div className="mx-4"> 
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          <Button variant="outline">Add Item</Button>
        </DialogTrigger>
        <DialogContent
          className={
            " overflow-y-scroll sm:max-w-[425px] hide-scrollbar"
          }
        >
          <DialogHeader>
            <DialogTitle>Add Item</DialogTitle>
            <DialogDescription>Add Items to Cart .</DialogDescription>
          </DialogHeader>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            onSubmit={onSubmit}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
              setFieldValue
            }) => (



              <Form>

                <div className="grid grid-cols-1 md:grid-cols-1 pt-3 pb-16  gap-2 gap-y-4 ">
                  <div>
                    <Label htmlFor="item">Item Name:</Label>
                    <div className="mt-3">
                      <Select
                        onValueChange={(field) => {
                          setFieldValue('item', field);
                          const filteredItems = items.filter(item => field.includes(item._id));
                          setFieldValue('item_amount', parseInt(filteredItems[0].item_amount));
                        }}
                      >
                        <SelectTrigger className="w-full">
                          <SelectValue placeholder="Select Item" />
                        </SelectTrigger>
                        <SelectContent
                          className={
                            "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                          }>
                          {items.length > 0 &&
                            items.map((item) => (
                              <SelectItem key={item._id} value={item._id}>
                                {item.item_name}
                              </SelectItem>
                            ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <ErrorMessage
                      name="item_name"
                      className="text-red-500 text-xs  my-2"
                      component="div"
                    />
                  </div>

                  <div>
                    <Label htmlFor="item_amount">Item Price:</Label>
                    <Input
                      className="mt-3"
                      type="number"
                      id="item_amount"
                      readOnly
                      name="item_amount"
                      onBlur={handleBlur}
                      value={values.item_amount}
                    />
                    <ErrorMessage
                      name="item_amount"
                      className="text-red-500 text-xs my-2"
                      component="div"
                    />
                  </div>

                  <div>
                    <Label htmlFor="item_quantity">Item Quantity:</Label>
                    <Input
                      className="mt-3"
                      type="number"
                      id="item_quantity"
                      name="item_quantity"
                      onChange={(e) => {
                        // Update field1 value
                        setFieldValue('item_quantity', e.target.value);


                        // Update field2 based on field1 value
                        setFieldValue('total_amount', parseInt(e.target.value) * parseInt(values.item_amount));
                      }}
                      onBlur={handleBlur}
                      value={values.item_quantity}
                    />
                    <ErrorMessage
                      name="item_quantity"
                      className="text-red-500 text-xs my-2"
                      component="div"
                    />
                  </div>

                  <div>
                    <Label htmlFor="total_amount">Item Total:</Label>
                    <Input
                      className="mt-3"
                      type="text"
                      id="total_amount"
                      name="total_amount"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.total_amount}
                    />
                    {/* Add validation error display */}
                    <ErrorMessage
                      name="total_amount"
                      className="text-red-500 text-xs my-2"
                      component="div"
                    />
                  </div>
                </div>

                <div></div>

                <DialogFooter>
                  <Button type="submit">Submit</Button>
                </DialogFooter>
              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
      <div className="my-4">
        <div className="">
          <Table>
            <TableCaption></TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead >Item Name</TableHead>
                <TableHead>Item Price</TableHead>
                <TableHead>Item Quantity</TableHead>
                <TableHead >Item Total</TableHead>
                <TableHead >Action</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {cart.map((item, index) => (
                <TableRow key={index}>
                  <TableCell className="font-medium">{item.item_name}</TableCell>
                  <TableCell>{item.item_amount}</TableCell>
                  <TableCell>{item.item_quantity}</TableCell>
                  <TableCell >{item.total_amount}</TableCell>
                  <TableCell ><Button type="reset" onClick={(index) => { deleteItem(index) }}>Delete</Button></TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>

    </div>
  );
};

export default ItemCart;
