import React, { useState, useEffect, useRef } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import moment from "moment";
import PaginatedItems from "Pagination/Pagination";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Download } from "lucide-react";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { getMerchantTotalAmountofTransactions } from "Apis/employeeapi";
import TableLoading from "LoadingComponents/TableLoading";
import { DownloadTableExcel } from 'react-export-table-to-excel';

const MerchantAmountofTransactions = () => {
    const today = new Date();
    const [date, setDate] = useState({
        from: new Date(today.getFullYear(), today.getMonth(), 1),
        to: new Date(today.getFullYear(), today.getMonth() + 1, 0),
    });


    const [numberOfTransactions, setNumberOfTransactions] = useState('');

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");


    const [loading, setLoading] = useState(false);

    const getData = async (dates, skip, limit) => {
        setLoading(true)
        const dateConverter = (date) => {
            const currentDate = new Date(date);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            const dateString = `${year}-${month}-${day}`;
            return dateString;
        };

        if (dates !== undefined) {
            const fromDateString = dateConverter(dates.from);
            const toDateString = dateConverter(dates.to);

            const dateRangeObject = {
                from: fromDateString,
                to: toDateString,
            };

            const fetchData = await getMerchantTotalAmountofTransactions(
                dateRangeObject, skip, limit
            );

            setNumberOfTransactions(fetchData.data)
            setCount(fetchData.count)
            setLoading(false)
        }
    };

    const tableRef = useRef(null);



    const fetchdata = (limit, skip) => {
        setSkip(skip);
        setLimit(limit);
        getData(date, skip, limit);
    };




    useEffect(() => {
        getData(date, skip, limit);
    }, [date]);



    return (
        <div className="rounded-md border mt-4">
            <div className="grid grid-cols-1 md:grid-cols-4 pt-3 pb-8 px-3 gap-8">
                <div className="">

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                id="date"
                                variant={"outline"}
                                className={cn(
                                    "w-full justify-start text-left font-normal",
                                    !date && "text-muted-foreground"
                                )}
                            >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {date?.from ? (
                                    date.to ? (
                                        <>
                                            {format(date.from, "LLL dd, y")} -{" "}
                                            {format(date.to, "LLL dd, y")}
                                        </>
                                    ) : (
                                        format(date.from, "LLL dd, y")
                                    )
                                ) : (
                                    <span>Pick a date</span>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                initialFocus
                                mode="range"
                                defaultMonth={date?.from}
                                selected={date}
                                onSelect={setDate}
                                numberOfMonths={2}
                            />
                        </PopoverContent>
                    </Popover>
                </div>

                <DownloadTableExcel
                    filename={`total transaction amount ${moment(date.from).format('DD-MM-YYYY')}-${moment(date.to).format('DD-MM-YYYY')}`}
                    sheet="amount"
                    currentTableRef={tableRef.current}
                >
                    <Button> <Download className="mr-1 w-4" /> Download </Button>
                </DownloadTableExcel>
            </div>





            <Table ref={tableRef}>
                <TableCaption></TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead>Sno.</TableHead>
                        <TableHead>Merchant Name</TableHead>
                        <TableHead>Merchant Id</TableHead>
                        <TableHead>Amount of Successfull Transactions</TableHead>
                        <TableHead>Amount of Failed Transactions</TableHead>
                        <TableHead>Amount. of Authorized Transactions</TableHead>
                        <TableHead>Total Amount of  Transactions</TableHead>
                        <TableHead>Total Gst</TableHead>
                        <TableHead>Total Net Settlement</TableHead>
                        <TableHead>Total Charges</TableHead>
                    </TableRow>
                </TableHeader>

                {loading === false ?
                    <TableBody>
                        {numberOfTransactions.length > 0 &&
                            numberOfTransactions.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className="font-medium">{index + 1}</TableCell>
                                    <TableCell>{item.merchantName}</TableCell>
                                    <TableCell>{item.merchantGid}</TableCell>
                                    <TableCell>{item.successAmount}</TableCell>
                                    <TableCell>{item.failedAmount}</TableCell>
                                    <TableCell>{item.authorizedAmount}</TableCell>
                                    <TableCell>{item.totalAmount}</TableCell>
                                    <TableCell>{item.gst_amount}</TableCell>
                                    <TableCell>{item.net_settlement}</TableCell>
                                    <TableCell>{item.total_charges}</TableCell>
                                </TableRow>
                            ))}
                    </TableBody> : <TableLoading count={6} />}
            </Table>

            <div className="flex justify-center my-3">
                <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
            </div>
        </div >
    );
};

export default MerchantAmountofTransactions;
