import React, { useState, useEffect,useRef } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import moment from "moment";
import { Download } from "lucide-react";
import { DownloadTableExcel } from 'react-export-table-to-excel';
import PaginatedItems from "Pagination/Pagination";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { getMerchantTotalNumberofTransactions } from "Apis/employeeapi";
import TableLoading from "LoadingComponents/TableLoading";


const MerchantNumberofTransactions = () => {
    const today = new Date();
    const [date, setDate] = useState({
        from: new Date(today.getFullYear(), today.getMonth(), 1),
        to: new Date(today.getFullYear(), today.getMonth() + 1, 0),
    });

    const [numberOfTransactions, setNumberOfTransactions] = useState('');
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [loading, setLoading] = useState(false);
    const tableRef = useRef(null);

    const getData = async (dates, skip, limit) => {
        setLoading(true)
        const dateConverter = (date) => {
            const currentDate = new Date(date);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            const dateString = `${year}-${month}-${day}`;
            return dateString;
        };

        if (dates !== undefined) {
            const fromDateString = dateConverter(dates.from);
            const toDateString = dateConverter(dates.to);

            const dateRangeObject = {
                from: fromDateString,
                to: toDateString,
            };

            const fetchData = await getMerchantTotalNumberofTransactions(
                dateRangeObject, skip, limit
            );

            setNumberOfTransactions(fetchData.data)
            setCount(fetchData.count)
            setLoading(false)
        }
    };

    const fetchdata = (limit, skip) => {
        setSkip(skip);
        setLimit(limit);
        getData(date, skip, limit);
    };

    useEffect(() => {
        getData(date, skip, limit);
    }, [date]);



    return (
        <div className="rounded-md border">
            <div className="grid grid-cols-1 md:grid-cols-4 pt-3 pb-8 px-3 gap-8">
                <div className="">

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                id="date"
                                variant={"outline"}
                                className={cn(
                                    "w-full justify-start text-left font-normal",
                                    !date && "text-muted-foreground"
                                )}
                            >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {date?.from ? (
                                    date.to ? (
                                        <>
                                            {format(date.from, "LLL dd, y")} -{" "}
                                            {format(date.to, "LLL dd, y")}
                                        </>
                                    ) : (
                                        format(date.from, "LLL dd, y")
                                    )
                                ) : (
                                    <span>Pick a date</span>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                initialFocus
                                mode="range"
                                defaultMonth={date?.from}
                                selected={date}
                                onSelect={setDate}
                                numberOfMonths={2}
                            />
                        </PopoverContent>
                    </Popover>
                </div>

                <DownloadTableExcel
                    filename={`Number of Transactions ${moment(date.from).format('DD-MM-YYYY')}-${moment(date.to).format('DD-MM-YYYY')}`}
                    sheet="Transactions"
                    currentTableRef={tableRef.current}
                >
                    <Button> <Download className="mr-1 w-4" /> Download </Button>
                </DownloadTableExcel>

            </div>

            <Table ref={tableRef}>
                <TableCaption></TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead>Sno.</TableHead>
                        <TableHead>Merchant Name</TableHead>
                        <TableHead>Merchant Id</TableHead>
                        <TableHead>No. of Successfull Transactions</TableHead>
                        <TableHead>No. of Failed Transactions</TableHead>
                        <TableHead>No. of Authorized Transactions</TableHead>
                        <TableHead>Total No. of  Transactions</TableHead>
                        <TableHead>Success Ratio</TableHead>
                    </TableRow>
                </TableHeader>

                {loading === false ?

                    <TableBody>
                        {numberOfTransactions.length > 0 &&
                            numberOfTransactions.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell className="font-medium">{index + 1}</TableCell>
                                    <TableCell>{item.merchantName}</TableCell>
                                    <TableCell>{item.merchantGid}</TableCell>
                                    <TableCell>{item.successCount}</TableCell>
                                    <TableCell>{item.failedCount}</TableCell>
                                    <TableCell>{item.authorizedCount}</TableCell>
                                    <TableCell>{item.totalTransactions}</TableCell>
                                    <TableCell>{item.successRatio}</TableCell>

                                </TableRow>
                            ))}
                    </TableBody>
                    : <TableLoading count={6} />}

            </Table>

            <div className="flex justify-center my-3">
                <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
            </div>
        </div >
    );
};

export default MerchantNumberofTransactions;
