import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { getMerchantList, editPayoutRoute, getPayoutVendorBankList } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";

export default function EditPayoutGateway(props) {
    const { toast } = useToast();
    const [merchants, setMerchants] = useState();
    const [vendorBanks, setVendorBanks] = useState();
    const [merchantGatewayDetails, setMerchantGatewayDetails] = useState(null);
    const [open, setOpen] = useState(false);



    const getData = async () => {
        const fetchMerchants = await getMerchantList();
        setMerchants(fetchMerchants.data)
        const fetchBanks = await getPayoutVendorBankList();
        setVendorBanks(fetchBanks.data)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setMerchantGatewayDetails(props.merchantGateway);
    }, [props])


    let initialChargeDetailsValues;

    if (merchantGatewayDetails === null) {
        initialChargeDetailsValues =
        {
            merchantId: "",
            imps: "",
            neft: "",
            rtgs: "",
            upi: ""
        }
            ;
    }
    else {
        initialChargeDetailsValues =
        {
            merchantId: merchantGatewayDetails.merchant_id._id,
            imps: merchantGatewayDetails.imps,
            neft: merchantGatewayDetails.neft,
            rtgs: merchantGatewayDetails.rtgs,
            upi: merchantGatewayDetails.upi
        }
    }




    const chargeDetailSchema = Yup.object().shape({
        merchantId: Yup.string().required('This Field is required')
        ,
        imps: Yup.string().required('This Field is required'),
        neft: Yup.string().required('This Field is required'),

        rtgs: Yup.string().required('This Field is required'),
        upi: Yup.string().required('This Field is required'),
    });

    const fieldArray1 = [
        "imps",
        "neft",
        "rtgs",
        "upi"
    ];



    function transformString(inputString) {
        // Split the input string by underscores
        const parts = inputString.split('_');

        // Capitalize the first letter of each part and join them with a space
        const transformedString = parts.map(part => {
            return part.charAt(0).toUpperCase() + part.slice(1);
        }).join(' ');

        return transformedString;
    }

    const submitChargeDetailsForm = async (values) => {

        const sendData = await editPayoutRoute(values)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        setOpen(false)
         props.refreshFunction();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Edit</Button>
            </DialogTrigger>
            <DialogContent className={
                "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Edit Payout Gateway Route Form</DialogTitle>
                    <DialogDescription>
                        Fill the Form & Click submit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        enableReinitialize
                        initialValues={initialChargeDetailsValues}
                        validationSchema={chargeDetailSchema}
                        onSubmit={submitChargeDetailsForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values, setFieldValue } = formik;

                            return (
                                <div className=" mt-1  p-5 ">
                                    <div>
                                        <h5>Merchant Details</h5>
                                        <div className='flex gap-7 my-3'>
                                            <div>
                                                <h5 className='text-xs'>Name</h5>
                                                <h5 className='text-md font-bold'>{merchantGatewayDetails.merchant_id.name}</h5>
                                            </div>
                                            <div>
                                                <h5 className='text-xs'>Merchant Id</h5>
                                                <h5 className='text-md font-bold'>{merchantGatewayDetails.merchant_id.merchant_gid}</h5>
                                            </div>
                                            <div>
                                                <h5 className='text-xs'>Email</h5>
                                                <h5 className='text-md font-bold'>{merchantGatewayDetails.merchant_id.email}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='my-5'>
                                        <hr />
                                    </div>

                                    <Form>
                                        <div className="grid grid-cols-3 gap-4">


                                            {fieldArray1.map((fieldName, index) => (
                                                <div key={index}>
                                                    <Label htmlFor={fieldName}>{transformString(fieldName)}:</Label>
                                                    <div className="mt-3">
                                                        <Select

                                                            onValueChange={(field) => {
                                                                setFieldValue(fieldName, field)
                                                            }}
                                                            defaultValue={values[fieldName]}
                                                        >
                                                            <SelectTrigger>
                                                                <SelectValue placeholder="Select a Vendor" />
                                                            </SelectTrigger>
                                                            <SelectContent className={
                                                                "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                            }>
                                                                <SelectGroup>
                                                                    <SelectLabel>Vendor Banks</SelectLabel>
                                                                    <SelectItem value="">Select Vendor Banks</SelectItem>
                                                                    {vendorBanks.map((vendor) => (
                                                                        <SelectItem value={vendor._id} key={vendor._id}>{vendor.bank_name}</SelectItem>
                                                                    ))}

                                                                </SelectGroup>
                                                            </SelectContent>
                                                        </Select>
                                                    </div>

                                                    {errors[fieldName] && touched[fieldName] && (
                                                        <span className="text-xs text-red-400">
                                                            {errors[fieldName]}
                                                        </span>
                                                    )}
                                                </div>
                                            ))}




                                        </div>




                                        <div className='flex justify-center'>
                                            <Button
                                                type="submit"
                                                className="btn mt-5 w-96  font-medium  "
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                </div>

            </DialogContent>
        </Dialog>
    )
}
