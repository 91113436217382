import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@//components/ui/scroll-area";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { LogOut, Users, IndianRupee, BadgeInfo, Gauge, User2, Settings, ShieldAlert, File, SquareStack, Vibrate, Settings2, Webhook } from "lucide-react";
import SmoothCollapse from "react-smooth-collapse";

export default function ResellerSidebar() {
  const location = useLocation();
  const { pathname } = location;
  const [merchantMenuExpanded, setMerchantMenuExpanded] = useState(false);
  const [technicalMenuExpanded, setTechnicalMenuExpanded] = useState(false);
  const [riskMenuExpanded, setRiskMenuExpanded] = useState(false);
  const [payoutExpanded, setPayoutExpanded] = useState(false);

  const onClickMerchantMenu = () => {
    if (merchantMenuExpanded) {
      setMerchantMenuExpanded(false)
    }
    else {
      setMerchantMenuExpanded(true)
    }
  }

  const onClickTechnicalMenu = () => {
    if (technicalMenuExpanded) {
      setTechnicalMenuExpanded(false)
    }
    else {
      setTechnicalMenuExpanded(true)
    }
  }

  const onClickRiskMenu = () => {
    if (riskMenuExpanded) {
      setRiskMenuExpanded(false)
    }
    else {
      setRiskMenuExpanded(true)
    }
  }

  const onClickPayoutMenu = () => {
    if (payoutExpanded) {
      setPayoutExpanded(false)
    }
    else {
      setPayoutExpanded(true)
    }
  }


  return (
    <div className={cn("pb-12")}>
      <div className="space-y-4 py-4">
        <div className="px-3 py-2">
          <div className="space-y-1">




            <Button
              variant={"ghost"}
              className="my-1 w-full flex  justify-between"
              onClick={onClickMerchantMenu}
            >
              <div className="flex">
                <Users className="mr-2 h-4 w-4" />
                Payin
              </div>
              <div className="col-span-2">
                <svg xmlns="http://www.w3.org/2000/svg" className={`${merchantMenuExpanded && 'rotate-90'} `} width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="m9 18 6-6-6-6" /></svg>
              </div>
            </Button>
            <SmoothCollapse expanded={merchantMenuExpanded} >
              {merchantMenuExpanded &&
                <div>
                  <Link to="/reseller/payin/dashboard">
                    <Button
                      variant={pathname === "/reseller/payin/dashboard" ? "secondary" : "ghost"}
                      className="my-1 w-full justify-start"
                    >
                      <IndianRupee className="mr-2 h-4 w-4" />
                      Dashboard
                    </Button>
                  </Link>

                  <Link to="/reseller/payin/transactions">
                    <Button
                      variant={pathname === "/reseller/payin/transactions" ? "secondary" : "ghost"}
                      className="my-1 w-full justify-start"
                    >
                      <BadgeInfo className="mr-2 h-4 w-4" />
                      Transactions
                    </Button>
                  </Link>


                </div>
              }
            </SmoothCollapse>




            <Button
              variant={"ghost"}
              className="my-1 w-full flex  justify-between"
              onClick={onClickPayoutMenu}
            >
              <div className="flex">
                <SquareStack className="mr-2 h-4 w-4" />
                Payout
              </div>
              <div className="col-span-2">
                <svg xmlns="http://www.w3.org/2000/svg" className={`${payoutExpanded && 'rotate-90'} `} width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="m9 18 6-6-6-6" /></svg>
              </div>
            </Button>
            <SmoothCollapse expanded={payoutExpanded} >
              {payoutExpanded && <div>
                <Link to="/reseller/payout/dashboard">
                  <Button
                    variant={pathname === "/reseller/payout/dashboard" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <Settings2 className="mr-2 h-4 w-4" />
                    Dashboard
                  </Button>
                </Link>

                <Link to="/reseller/payout/transactions">
                  <Button
                    variant={pathname === "/reseller/payout/transactions" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <IndianRupee className="mr-2 h-4 w-4" />
                    Transactions
                  </Button>
                </Link>

                <Link to="/reseller/payout/statements">
                  <Button
                    variant={pathname === "/reseller/payout/statements" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <IndianRupee className="mr-2 h-4 w-4" />
                    Statement
                  </Button>
                </Link>


               
              </div>}
            </SmoothCollapse>


          



          



            <Link to="/reseller/logout">
              <Button
                variant={pathname === "/employee_logout" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <LogOut className="mr-2 h-4 w-4" />
                Logout
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
