import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { getPayoutWebhook, addPayoutWebhook } from "Apis/merchantapis";
import moment from "moment";
import PayoutAddWebhook from "./PayoutAddWebhook";

const PayoutWebhooks = () => {
    const [webhooks, setWebhooks] = useState([]);
    const { toast } = useToast();

    const getData = async () => {
        const fetchData = await getPayoutWebhook();
        setWebhooks(fetchData.data);
    };

    const submitAndRefresh = async (values) => {
        const sendRequest = await addPayoutWebhook(values)
        if (sendRequest.success === true) {
            toast({
                title: "Success",
                description: sendRequest.message
            })
        }
        else {
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description: sendRequest.message
            })
        }
        getData()
    }



    useEffect(() => {

        getData();
    }, []);

    return (
        <div className="rounded-md border">
            <div className="px-3 mt-3">
                <PayoutAddWebhook refreshWebhooks={submitAndRefresh} />
            </div>

            <div className="px-3"></div>
            <Table>

                <TableHeader>
                    <TableRow>
                        <TableHead>Sno.</TableHead>
                        <TableHead>Webhook Url</TableHead>
                        <TableHead>Notify Email</TableHead>
                        <TableHead> Date </TableHead>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {webhooks.length > 0 &&
                        webhooks.map((item, index) => (
                            <TableRow key={item._id}>
                                <TableCell className="font-medium">{index + 1}</TableCell>
                                <TableCell>{item.webhook_url}</TableCell>
                                <TableCell>{item.notify_email}</TableCell>
                                <TableCell>
                                    {moment(item.created_date).format("YYYY-MM-DD")}
                                </TableCell>


                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </div>
    );
};

export default PayoutWebhooks;
