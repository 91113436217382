/* eslint-disable jsx-a11y/heading-has-content */
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import { Building2, Factory, BookOpenIcon, FolderUp, User, CheckCircle2, AlertCircle } from "lucide-react";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/components/ui/alert"
import { getStates, getBusinessTypes, getBusinessCategories, getBusinessExpenditures, getMerchantPersonalDetails, getMerchantBusinessDetails, addUpdateUserBusinessValidation, updateMerchantPersonalDetails, addUpdateUserBusiness, uploadDocuments } from "Apis/merchantapis";



const MerchantActivation = (props) => {
    let navigate = useNavigate();

    const [states, setStates] = useState("");
    const [initalStateValue, setinitalState] = useState("");
    const [businessType, setBusinessType] = useState("");
    const [initialbusinessType, setinitialBusinessType] = useState("");
    const [businessCategory, setBusinessCategory] = useState("");
    const [initialbusinessCategory, setinitialBusinessCategory] = useState("");

    const [businessExpenditure, setbusinessExpenditure] = useState("");
    const [initialbusinessExpenditure, setinitialbusinessExpenditure] =
        useState("");


    const [tabScreen, setTabScreen] = useState(true);
    const [PersonalDetailsform, setPersonalDetailsform] = useState(false);
    const [CompanyInfoForm, setCompanyInfoForm] = useState(false);
    const [BusinessInfoform, setBusinessInfoform] = useState(false);
    const [BusinessCardform, setBusinessCardform] = useState(false);
    const [FilesUploadform, setFilesUploadform] = useState(false);

    const [PersonalDetailsformCheck, setPersonalDetailsformCheck] = useState(false);
    const [CompanyInfoFormCheck, setCompanyInfoFormCheck] = useState(false);
    const [BusinessInfoformCheck, setBusinessInfoformCheck] = useState(false);
    const [BusinessCardformCheck, setBusinessCardformCheck] = useState(false);
    const [FilesUploadformCheck, setFilesUploadformCheck] = useState(false);


    const [userName, setuserName] = useState("");
    const [userBusinessData, setuserBusinessData] = useState("");

    //file fields status
    const [auth_pan, setauth_pan] = useState(false);
    const [auth_aadhar, setauth_aadhar] = useState(false);
    const [company_pan, setcompany_pan] = useState(false);
    const [gstin, setgstin] = useState(false);
    const [reg_certificate, setreg_certificate] = useState(false);
    const [cancel_cheque, setcancel_cheque] = useState(false);
    const [cin, setcin] = useState(false);
    const [llpin, setllpin] = useState(false);
    const [inc_llp, setinc_llp] = useState(false);
    const [partnership_deed, setpartnership_deed] = useState(false);
    const [moa_aoa, setmoa_aoa] = useState(false);
    const [trust_deed, settrust_deed] = useState(false);
    const [selectedBusinessType, setselectedBusinessType] = useState({});
    const [selectedBusinessTypeText, setselectedBusinessTypeText] = useState("");
    //end file fields

    const getData = async () => {
        //userdata
        const userDataResponse = await getMerchantPersonalDetails();
        const userBusinessDataResponse = await getMerchantBusinessDetails();

        checkWhichFormsFilled()

        setuserName(userDataResponse.data);
        setuserBusinessData(userBusinessDataResponse.data);

        const StateResponse = await getStates();

        setStates(StateResponse.data);
        if (userBusinessDataResponse.data.state === undefined) {
            setinitalState(StateResponse.data[0]._id);
        }
        else {
            setinitalState(userBusinessDataResponse.data.state._id);
        }



        const BusinessTypeResponse = await getBusinessTypes();

        setBusinessType(BusinessTypeResponse.busniess_types);
        setinitialBusinessType(BusinessTypeResponse.busniess_types[0]._id);

        if (userBusinessDataResponse.data.business_type_id === undefined) {
            setinitialBusinessType(BusinessTypeResponse.busniess_types[0]._id);
        }
        else {
            setinitialBusinessType(userBusinessDataResponse.data.business_type_id._id);
        }

        const BusinessCategoryResponse = await getBusinessCategories();

        setBusinessCategory(BusinessCategoryResponse.busniess_categories);

        if (userBusinessDataResponse.data.business_category_id === undefined) {
            setinitialBusinessCategory(
                BusinessCategoryResponse.busniess_categories[0]._id
            );
        }
        else {
            setinitialBusinessCategory(userBusinessDataResponse.data.business_category_id._id);
        }

        const BusinessExpendituresResponse = await getBusinessExpenditures();

        setbusinessExpenditure(BusinessExpendituresResponse.busniess_expenditures);
        if (userBusinessDataResponse.data.business_expenditure === undefined) {
            setinitialbusinessExpenditure(
                BusinessExpendituresResponse.busniess_expenditures[0]._id
            );

        }
        else {

            setinitialbusinessExpenditure(
                userBusinessDataResponse.data.business_expenditure._id
            );
        }


        if (userBusinessDataResponse.data.business_type_id) {
            setselectedBusinessTypeText(
                userBusinessDataResponse.data.business_type_id.type_name
            );



        }


        props.refreshFunction();

    };


    const checkWhichFormsFilled = () => {

        if (userName.hasOwnProperty('name') && userName.name !== '' &&
            userName.hasOwnProperty('email') && userName.email !== '' &&
            userName.hasOwnProperty('mobile_no') && userName.mobile_no !== '') {
            setPersonalDetailsformCheck(true)
        }

    }

    useEffect(() => {
        checkWhichFormsFilled();

        if (selectedBusinessTypeText === "Private Limited") {
            setauth_pan(true);
            setauth_aadhar(true);
            setcompany_pan(true);
            setgstin(true);
            setreg_certificate(false);
            setcancel_cheque(true);
            setcin(true);
            setllpin(false);
            setinc_llp(false);
            setpartnership_deed(false);
            setmoa_aoa(true);
            settrust_deed(false);


        } else if (selectedBusinessTypeText === "Proprietorship") {
            setauth_pan(true);
            setauth_aadhar(true);
            setcompany_pan(false);
            setgstin(true);
            setreg_certificate(true);
            setcancel_cheque(true);
            setcin(true);
            setllpin(false);
            setinc_llp(false);
            setpartnership_deed(false);
            setmoa_aoa(true);
            settrust_deed(false);
        } else if (selectedBusinessTypeText === "Partnership") {
            setauth_pan(true);
            setauth_aadhar(true);
            setcompany_pan(true);
            setgstin(true);
            setreg_certificate(false);
            setcancel_cheque(true);
            setcin(false);
            setllpin(false);
            setinc_llp(false);
            setpartnership_deed(true);
            setmoa_aoa(true);
            settrust_deed(false);
        } else if (selectedBusinessTypeText === "Public Limited") {
            setauth_pan(true);
            setauth_aadhar(true);
            setcompany_pan(true);
            setgstin(true);
            setreg_certificate(false);
            setcancel_cheque(true);
            setcin(true);
            setllpin(false);
            setinc_llp(false);
            setpartnership_deed(false);
            setmoa_aoa(true);
            settrust_deed(false);
        } else if (selectedBusinessTypeText === "LLP") {
            setauth_pan(true);
            setauth_aadhar(true);
            setcompany_pan(true);
            setgstin(true);
            setreg_certificate(false);
            setcancel_cheque(true);
            setcin(false);
            setllpin(true);
            setinc_llp(true);
            setpartnership_deed(false);
            setmoa_aoa(false);
            settrust_deed(false);
        } else if (
            selectedBusinessTypeText === "Trust" ||
            selectedBusinessTypeText === "Society" ||
            selectedBusinessTypeText === "NGO"
        ) {
            setauth_pan(true);
            setauth_aadhar(true);
            setcompany_pan(true);
            setgstin(true);
            setreg_certificate(true);
            setcancel_cheque(true);
            setcin(false);
            setllpin(false);
            setinc_llp(false);
            setpartnership_deed(false);
            setmoa_aoa(false);
            settrust_deed(true);
        } else if (selectedBusinessTypeText === "Not Registered") {
            setauth_pan(true);
            setauth_aadhar(true);
            setcompany_pan(true);
            setgstin(true);
            setreg_certificate(true);
            setcancel_cheque(true);
            setcin(false);
            setllpin(false);
            setinc_llp(false);
            setpartnership_deed(false);
            setmoa_aoa(false);
            settrust_deed(false);
        }
    }, [userName, userBusinessData, selectedBusinessTypeText])


    useEffect(() => {
        getData();
    }, []);

    //pdformcontents
    const initialPersonalDetailsValues = {
        name: userName.name,
        email: userName.email,
        mobile_no: userName.mobile_no,
    };

    const personalDetailSchema = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        mobile_no: Yup.string()
            .min(10, "Please enter a 10 digit Mobile Number")
            .max(10, "Please enter a 10 digit Mobile Number"),
    });


    const submitPersonalDetailsForm = async (values, { resetForm }) => {
        updateMerchantPersonalDetails(values);
        onClickBack()
    };

    //endpdformcontents

    //companyformcontents
    const initialCompanyValues = {
        monthly_expenditure: initialbusinessExpenditure,
        company_name: userBusinessData.business_name,
        company_address: userBusinessData.address,
        pincode: userBusinessData.pincode,
        city: userBusinessData.city,
        state: initalStateValue,
        country: "india",
        form: 'company_details'
    };

    const companySchema = Yup.object().shape({
        company_name: Yup.string().required("Company Name is required"),
        company_address: Yup.string().required("Company Address is required"),
        pincode: Yup.string()
            .matches(/^[1-9][0-9]{5}$/, "Pin Code is not valid")
            .required("Pin Code is required"),
        city: Yup.string().required("City is required"),
    });

    const submitCompanyForm = async (values, { resetForm }) => {
        setinitalState(values.state);
        setinitialbusinessExpenditure(values.monthly_expenditure);
        await addUpdateUserBusiness(values);
        setPersonalDetailsform(false);
        setCompanyInfoForm(false);
        setBusinessInfoform(true);
        setBusinessCardform(false);

        getData();
        onClickBack()
    };



    //endcompanyformcontents

    //businessformcontents
    const initialbusinessValues = {
        business_type: initialbusinessType,
        business_category: initialbusinessCategory,
        webapp_url: userBusinessData.webapp_url,
        bank_name: userBusinessData.bank_name,
        bank_acc_no: userBusinessData.bank_acc_no,
        bank_ifsc_code: userBusinessData.bank_ifsc_code,
        form: 'business_bank_details'
    };

    const businessSchema = Yup.object().shape({
        bank_name: Yup.string().required("Bank Name is required"),
        bank_acc_no: Yup.string().required("Bank Account Number is required").test('checkDuplication', 'Bank Account Number Already Registered', async value => {
            const response = await addUpdateUserBusinessValidation('bank_acc_no', value);
            console.log(response)
            return response; // return false if email exists
        }),
        bank_ifsc_code: Yup.string()
            .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Ifsc Code is not valid")
            .required("Ifsc Code is required"),
    });

    useEffect(() => {
        if (businessType.length > 0) {
            const findSelectedType = businessType.filter(function (item) {
                return item._id === initialbusinessType;
            });

        }

    }, [businessType, initialbusinessType])


    const submitbusinessForm = async (values, { resetForm }) => {
        setselectedBusinessType(values.business_type)

        await addUpdateUserBusiness(values);
        setPersonalDetailsform(false);
        setCompanyInfoForm(false);
        setBusinessInfoform(false);
        setBusinessCardform(true);

        getData();
        onClickBack()
    };



    //endbusinessformcontents

    //businesscardformcontents
    const initialbusinesscardValues = {
        company_pan: userBusinessData.comp_pan_number,
        company_gst: userBusinessData.comp_gst,
        auth_sign_pan_no: userBusinessData.mer_pan_number,
        auth_sign_aadhar_no: userBusinessData.mer_aadhar_number,
        auth_sign_name: userBusinessData.mer_name,
        form: 'business_docs_details'
    };

    const businesscardSchema = Yup.object().shape({
        company_pan: Yup.string()
            .matches(
                /^([A-Z]){3}C([A-Z])([0-9]){4}([A-Z]){1}?$/,
                "Company pan no is not valid"
            )
            .required("Company pan no is required").test('checkDuplication', 'Company Pan No is already registered', async value => {
                const response = await addUpdateUserBusinessValidation('comp_pan_number', value);
                console.log(response)
                return response; // return false if email exists
            }),
        company_gst: Yup.string()
            .matches(
                /[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/,
                "Company Gstin is not valid"
            )
            .required("Company Gstin is required").test('checkDuplication', 'Company Gstin is Already Registered', async value => {
                const response = await addUpdateUserBusinessValidation('comp_gst', value);
                console.log(response)
                return response; // return false if email exists
            }),
        auth_sign_pan_no: Yup.string()
            .matches(
                /^([A-Z]){3}P([A-Z])([0-9]){4}([A-Z]){1}?$/,
                "Signatory pan no is not valid"
            )
            .required("Signatory pan no is required").test('checkDuplication', 'Signatory Pan No is Already Registered', async value => {
                const response = await addUpdateUserBusinessValidation('mer_pan_number', value);
                console.log(response)
                return response; // return false if email exists
            }),
        auth_sign_aadhar_no: Yup.string()
            .min(12, "Please enter a 12 digit Mobile Number")
            .max(12, "Please enter a 12 digit Mobile Number")
            .required("Signatory Aadhar Number is required").test('checkDuplication', 'Signatory Aadhar Number is Already Registered', async value => {
                const response = await addUpdateUserBusinessValidation('mer_aadhar_number', value);
                console.log(response)
                return response; // return false if email exists
            }),
        auth_sign_name: Yup.string().required("Signatory Name is required"),
    });

    const submitbusinesscardForm = async (values, { resetForm }) => {

        await addUpdateUserBusiness(values);
        setBusinessCardform(false);
        setFilesUploadform(true);

        getData();
        onClickBack()
    };



    //endbusinesscardformcontents


    //upload files form
    const initialFilesValues = {
        auth_pan: "",
        auth_aadhar: "",
        company_pan: "",
        gstin: "",
        reg_certificate: "",
        cancel_cheque: "",
        cin: "",
        llpin: "",
        inc_llp: "",
        partnership_deed: "",
        moa_aoa: "",
        trust_deed: "",
    };

    const maxFileSize = 5 * 1024 * 1024; // 5 MB in bytes
    const allowedImageMimeTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/bmp",
        "image/tiff",
        "image/webp",
        "image/svg+xml",
        "image/x-icon",
        "image/vnd.microsoft.icon",
        "application/pdf",
        // Add more mime types for other image formats or document types
    ];

    const createFileTests = (fieldName) => {
        return Yup.mixed()
            .required(`${fieldName} Required`)
            .test(
                "fileSize",
                "File size is too large",
                (value) => !value || value.size <= maxFileSize
            )
            .test(
                "fileType",
                "Invalid file type. Only images are allowed",
                (value) => !value || allowedImageMimeTypes.includes(value.type)
            );
    };

    let schema = {};

    if (selectedBusinessTypeText === "Private Limited") {
        schema = {
            auth_pan: createFileTests("Signatory Pan Card"),
            auth_aadhar: createFileTests("Signatory Aadhar Card Required"),
            company_pan: createFileTests("Company Pan Card Required"),
            cancel_cheque: createFileTests("Cancel Cheque Required"),
            cin: createFileTests("Cin Required"),
        }
    }
    else if (selectedBusinessTypeText === "Proprietorship") {
        schema = {
            auth_pan: createFileTests("Signatory Pan Card"),
            auth_aadhar: createFileTests("Signatory Aadhar Card"),
            company_pan: createFileTests("Company Pan Card"),
            reg_certificate: createFileTests("Registration Certificate is"),
            cin: createFileTests("Cin")
        }
    }
    else if (selectedBusinessTypeText === "Partnership") {
        schema = {
            auth_pan: createFileTests("Signatory Pan Card Required"),
            auth_aadhar: createFileTests("Signatory Aadhar Card Required"),
            company_pan: createFileTests("Company Pan Card Required"),
            cancel_cheque: createFileTests("Cancel Cheque Required"),
            partnership_deed: createFileTests("Partnership Deed is Required"),
        }
    } else if (selectedBusinessTypeText === "Public Limited") {
        schema = {
            auth_pan: createFileTests("Signatory Pan Card Required"),
            auth_aadhar: createFileTests("Signatory Aadhar Card Required"),
            company_pan: createFileTests("Company Pan Card Required"),
            cancel_cheque: createFileTests("Cancel Cheque Required"),
            cin: createFileTests("Cin is Required"),
        }
    } else if (selectedBusinessTypeText === "LLP") {
        schema = {
            auth_pan: createFileTests("Signatory Pan Card Required"),
            auth_aadhar: createFileTests("Signatory Aadhar Card Required"),
            company_pan: createFileTests("Company Pan Card Required"),
            cancel_cheque: createFileTests("Cancel Cheque Required"),
            llpin: createFileTests("LLpin is Required"),
            inc_llp: createFileTests("Inc Llp /Aoa Required")
        }
    } else if (
        selectedBusinessTypeText === "Trust" ||
        selectedBusinessTypeText === "Society" ||
        selectedBusinessTypeText === "NGO"
    ) {
        schema = {
            auth_pan: createFileTests("Signatory Pan Card Required"),
            auth_aadhar: createFileTests("Signatory Aadhar Card Required"),
            company_pan: createFileTests("Company Pan Card Required"),
            cancel_cheque: createFileTests("Cancel Cheque Required"),
            reg_certificate: createFileTests("Registration Certificate is Required"),
            trust_deed: createFileTests("Trust Deed Required")
        }
    } else if (selectedBusinessTypeText === "Not Registered") {
        schema = {
            auth_pan: createFileTests("Signatory Pan Card Required"),
            auth_aadhar: createFileTests("Signatory Aadhar Card Required"),
            company_pan: createFileTests("Company Pan Card Required"),
            cancel_cheque: createFileTests("Cancel Cheque Required"),
            reg_certificate: createFileTests("Registration Certificate is Required"),
        }
    }


    const FilesSchema = Yup.object().shape(
        schema
    );

    const submitFilesForm = async (values, { resetForm }) => {
        let formData = new FormData();

        // Update the formData object
        formData.append("auth_pan", values.auth_pan);
        formData.append("auth_aadhar", values.auth_aadhar);
        formData.append("company_pan", values.company_pan);
        formData.append("gstin", values.gstin);
        formData.append("reg_certificate", values.reg_certificate);
        formData.append("cancel_cheque", values.cancel_cheque);
        formData.append("cin", values.cin);
        formData.append("llpin", values.llpin);
        formData.append("incllp", values.inc_llp);
        formData.append("partnership_deed", values.partnership_deed);
        formData.append("moaaoa", values.moa_aoa);
        formData.append("trust_deed", values.trust_deed);

        await uploadDocuments(formData);


        getData();
        onClickBack()
    };


    //endupload files form


    const onClickPersonalDetails = () => {
        setTabScreen(false);
        setPersonalDetailsform(true);
        setCompanyInfoForm(false);
        setBusinessInfoform(false);
        setBusinessCardform(false);
        setFilesUploadform(false);
    }

    const onClickCompanyDetails = () => {
        setTabScreen(false);
        setPersonalDetailsform(false);
        setCompanyInfoForm(true);
        setBusinessInfoform(false);
        setBusinessCardform(false);
        setFilesUploadform(false);
    }


    const onClickBusinessInfoDetails = () => {
        setTabScreen(false);
        setPersonalDetailsform(false);
        setCompanyInfoForm(false);
        setBusinessInfoform(true);
        setBusinessCardform(false);
        setFilesUploadform(false);
    }


    const onClickBusinessDetails = () => {
        setTabScreen(false);
        setPersonalDetailsform(false);
        setCompanyInfoForm(false);
        setBusinessInfoform(false);
        setBusinessCardform(true);
        setFilesUploadform(false);
    }


    const onClickUploadDocument = () => {
        setTabScreen(false);
        setPersonalDetailsform(false);
        setCompanyInfoForm(false);
        setBusinessInfoform(false);
        setBusinessCardform(false);
        setFilesUploadform(true);
    }


    const onClickBack = () => {
        setTabScreen(true);

        setPersonalDetailsform(false);
        setCompanyInfoForm(false);
        setBusinessInfoform(false);
        setBusinessCardform(false);
        setFilesUploadform(false);
    }



    return (
        <div className="mb-16">
            {tabScreen === true &&
                <div>
                    <h1 className="text-lg font-bold">Complete your KYC</h1>
                    <h5 className="text-xs font-normal">Please Complete the process . You can use Test Mode without kyc .</h5>
                    <div className="grid grid-cols-3 gap-6 my-12">
                        <Button className="py-8 text-lg" onClick={(() => { onClickPersonalDetails() })} >Personal Details {PersonalDetailsformCheck ? <CheckCircle2 className="ml-4 text-green-400" /> : <User className="ml-4" />}   </Button>

                        <Button className="py-8 text-lg" onClick={(() => { onClickCompanyDetails() })} >Company Details  {userName.company_details_form ? <CheckCircle2 className="ml-4 text-green-400" /> : <Building2 className="ml-4" />}</Button>

                        <Button className="py-8 text-lg" onClick={(() => { onClickBusinessInfoDetails() })} >Business and Bank Details {userName.business_bank_details_form ? <CheckCircle2 className="ml-4 text-green-400" /> : <Factory className="ml-4" />}</Button>

                        <Button className="py-8 text-lg" onClick={(() => { onClickBusinessDetails() })} >Business Docs Details  {userName.business_docs_details ? <CheckCircle2 className="ml-4 text-green-400" /> : <BookOpenIcon className="ml-4" />}</Button>

                        <Button className="py-8 text-lg" onClick={(() => { onClickUploadDocument() })} >Upload Documents  {userName.documents_upload ? <CheckCircle2 className="ml-4 text-green-400" /> : <FolderUp className="ml-4" />}</Button>
                    </div>
                </div>
            }


            {PersonalDetailsform === true && (
                <div className="flex    items-center justify-center mt-3 ">

                    <div className=" ">
                        <div className="text-center text-3xl font-bold">Personal Details</div>
                        <div className="text-center text-sm font-bold">Please enter following personal details </div>
                        <div className="grid grid-cols-2">
                            <img src="/activation/personaldata.svg" alt="SVG" />

                            <main className="flex w-full flex-col items-center  lg:max-w-md ">
                                <div className="w-full max-w-lg p-4 sm:px-5">
                                    <div className="text-center">

                                        <div className="mt-4">
                                            <h2 className="text-2xl font-semibold "></h2>
                                            <p className="text-2xl font-bold">
                                            </p>
                                        </div>
                                    </div>
                                    <Formik
                                        enableReinitialize
                                        initialValues={initialPersonalDetailsValues}
                                        validationSchema={personalDetailSchema}
                                        onSubmit={submitPersonalDetailsForm}
                                    >
                                        {(formik) => {
                                            const { errors, touched, isValid, dirty } = formik;

                                            return (
                                                <div className="card mt-5 rounded-lg p-5 lg:p-7">
                                                    <Form>
                                                        <label className="block">

                                                            <span >Name <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                            <Field
                                                                type="text"
                                                                name="name"
                                                                id="name"
                                                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                placeholder=""
                                                            />
                                                        </label>
                                                        {errors.name && touched.name && (
                                                            <span className="text-red-600 text-xs">
                                                                {errors.name}
                                                            </span>
                                                        )}

                                                        <label className="block mt-3">
                                                            <span>Email <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                            <Field
                                                                type="email"
                                                                name="email"
                                                                id="email"
                                                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                placeholder=""
                                                            />
                                                        </label>
                                                        {errors.email && touched.email && (
                                                            <span className="text-red-600 text-xs">
                                                                {errors.email}
                                                            </span>
                                                        )}

                                                        <label className="block mt-3">
                                                            <span>Mobile <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                            <Field
                                                                type="number"
                                                                name="mobile_no"
                                                                id="mobile_no"
                                                                className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                placeholder=""
                                                            />
                                                        </label>
                                                        {errors.mobile_no && touched.mobile_no && (
                                                            <span className="text-red-600 text-xs">
                                                                {errors.mobile_no}
                                                            </span>
                                                        )}

                                                        <Button
                                                            type="submit"
                                                            className="btn mt-5 w-full  font-medium  "
                                                        >
                                                            Submit
                                                        </Button>
                                                        <Button variant="outline" className=" mt-5 w-full  font-medium " onClick={(() => { onClickBack() })} >Back</Button>
                                                    </Form>
                                                </div>
                                            );
                                        }}
                                    </Formik>
                                </div>
                            </main>
                        </div>
                    </div>

                </div>
            )}

            {CompanyInfoForm === true && (
                <div className="flex mt-3 items-center justify-center">

                    <div className="">
                        <div className="text-center text-3xl font-bold">Company Details</div>
                        <div className="text-center text-sm font-bold">Please enter following company details </div>
                        <div className="grid grid-cols-2">


                            <img src="/activation/businessdetails.svg" alt="SVG" />

                            <div className="">
                                <main className="flex w-full flex-col items-center  lg:max-w-md">
                                    <div className="w-full max-w-lg p-4 sm:px-5">
                                        <div className="text-center">


                                        </div>
                                        <Formik
                                            enableReinitialize
                                            initialValues={initialCompanyValues}
                                            validationSchema={companySchema}
                                            onSubmit={submitCompanyForm}
                                        >
                                            {(formik) => {
                                                const { errors, touched, isValid, dirty, setFieldValue } = formik;

                                                return (
                                                    <div className="card  rounded-lg p-5 lg:p-7">
                                                        <Form>
                                                            <label className="block">
                                                                <span>Monthly Expenditure <p style={{ display: 'inline', color: 'red' }}>*</p></span>


                                                                <Select name="monthly_expenditure"
                                                                    id="monthly_expenditure"
                                                                    defaultValue={initialbusinessExpenditure}
                                                                    onValueChange={(field) => {
                                                                        setFieldValue('monthly_expenditure', field);
                                                                    }}>
                                                                    <SelectTrigger className="w-full">
                                                                        <SelectValue />
                                                                    </SelectTrigger>
                                                                    <SelectContent>
                                                                        <SelectGroup>
                                                                            {businessExpenditure.map((value) => (

                                                                                <SelectItem key={value._id} value={value._id}>{value.expenditure}</SelectItem>
                                                                            ))}

                                                                        </SelectGroup>
                                                                    </SelectContent>
                                                                </Select>
                                                            </label>

                                                            <label className="block mt-3">
                                                                <span>Company Name <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="company_name"
                                                                    id="company_name"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.company_name && touched.company_name && (
                                                                <span className="text-red-600 text-xs">
                                                                    {errors.company_name}
                                                                </span>
                                                            )}

                                                            <label className="block mt-3">
                                                                <span>Company Address <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="company_address"
                                                                    id="company_address"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.company_address && touched.company_address && (
                                                                <span className="text-red-600 text-xs">
                                                                    {errors.company_address}
                                                                </span>
                                                            )}

                                                            <label className="block mt-3">
                                                                <span>Pincode <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="number"
                                                                    name="pincode"
                                                                    id="pincode"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.pincode && touched.pincode && (
                                                                <span className="text-red-600 text-xs">
                                                                    {errors.pincode}
                                                                </span>
                                                            )}

                                                            <label className="block mt-3">
                                                                <span>City <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="city"
                                                                    id="city"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                ></Field>
                                                            </label>
                                                            {errors.city && touched.city && (
                                                                <span className="text-red-600 text-xs">
                                                                    {errors.city}
                                                                </span>
                                                            )}

                                                            <label className="block mt-3">
                                                                <span>State <p style={{ display: 'inline', color: 'red' }}>*</p></span>


                                                                <Select name="state"
                                                                    id="state"
                                                                    defaultValue={initalStateValue}
                                                                    onValueChange={(field) => {
                                                                        setFieldValue('state', field);
                                                                    }}>
                                                                    <SelectTrigger className="w-full ">
                                                                        <SelectValue />
                                                                    </SelectTrigger>
                                                                    <SelectContent className={
                                                                        "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                                    }>
                                                                        <SelectGroup >
                                                                            {states.map((value) => (

                                                                                <SelectItem key={value._id} value={value._id}>{value.state_name}</SelectItem>
                                                                            ))}

                                                                        </SelectGroup>
                                                                    </SelectContent>
                                                                </Select>
                                                            </label>

                                                            <label className="block mt-3">
                                                                <span>Country <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    as="select"
                                                                    name="country"
                                                                    id="country"
                                                                    className="mt-1.5 w-full rounded-lg border  px-3 py-2"
                                                                >
                                                                    <option value="India">India</option>
                                                                </Field>
                                                            </label>

                                                            <Button
                                                                type="submit"
                                                                className="btn mt-5 w-full  font-medium "
                                                            >
                                                                Submit
                                                            </Button>

                                                            <Button variant="outline" className=" mt-5 w-full  font-medium " onClick={(() => { onClickBack() })} >Back</Button>
                                                        </Form>
                                                    </div>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>




                </div>

            )}

            {BusinessInfoform === true && (
                <div className="flex mt-3 items-center justify-center">

                    <div className="">
                        <div className="text-center text-3xl font-bold">Business Info Details</div>
                        <div className="text-center text-sm font-bold">Please enter following Business details </div>
                        <div className="grid grid-cols-2">


                            <img src="/activation/businessinfo.svg" alt="SVG" />

                            <div className="">
                                <main className="flex w-full flex-col items-center lg:max-w-md">
                                    <div className="w-full max-w-lg p-4 sm:px-5">

                                        <Formik
                                            enableReinitialize
                                            initialValues={initialbusinessValues}
                                            validationSchema={businessSchema}
                                            onSubmit={submitbusinessForm}
                                        >
                                            {(formik) => {
                                                const { errors, touched, isValid, dirty, setFieldValue } = formik;

                                                return (
                                                    <div className="card mt-5 rounded-lg p-5 lg:p-7">
                                                        <Form>
                                                            <label className="block">
                                                                <span>Business Type <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                {/* <Field
                                                        as="select"
                                                        name="business_type"
                                                        id="business_type"
                                                        className=" mt-1.5 w-full rounded-lg border  px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                                    
                                                    >
                                                        {businessType.length > 0 &&
                                                            businessType.map((value) => (
                                                                <option key={value._id} value={value._id}>
                                                                    {value.type_name}
                                                                </option>
                                                            ))}
                                                    </Field> */}


                                                                <Select name="business_type"
                                                                    id="business_type"
                                                                    defaultValue={initialbusinessType}
                                                                    onValueChange={(field) => {
                                                                        setFieldValue('business_type', field);
                                                                    }}
                                                                >
                                                                    <SelectTrigger className="w-full ">
                                                                        <SelectValue />
                                                                    </SelectTrigger>
                                                                    <SelectContent className={
                                                                        "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                                    }>
                                                                        <SelectGroup >
                                                                            {businessType.length > 0 && businessType.map((value) => (

                                                                                <SelectItem key={value._id} value={value._id}>{value.type_name}</SelectItem>
                                                                            ))}

                                                                        </SelectGroup>
                                                                    </SelectContent>
                                                                </Select>
                                                            </label>

                                                            <label className="block">
                                                                <span>Business Category <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                {/* <Field
                                                        as="select"
                                                        name="business_category"
                                                        id="business_category"
                                                        className=" mt-1.5 w-full rounded-lg border  px-3 py-2 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:bg-navy-700 dark:hover:border-navy-400 dark:focus:border-accent"
                                                    >
                                                        {businessCategory.length > 0 &&
                                                            businessCategory.map((value) => (
                                                                <option key={value._id} value={value._id}>
                                                                    {value.category_name}
                                                                </option>
                                                            ))}
                                                    </Field> */}


                                                                <Select name="business_category"
                                                                    id="business_category"
                                                                    defaultValue={initialbusinessCategory}
                                                                    onValueChange={(field) => {
                                                                        setFieldValue('business_category', field);
                                                                    }}>
                                                                    <SelectTrigger className="w-full ">
                                                                        <SelectValue />
                                                                    </SelectTrigger>
                                                                    <SelectContent className={
                                                                        "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                                    }>
                                                                        <SelectGroup >
                                                                            {businessCategory.length > 0 && businessCategory.map((value) => (

                                                                                <SelectItem key={value._id} value={value._id}>{value.category_name}</SelectItem>
                                                                            ))}

                                                                        </SelectGroup>
                                                                    </SelectContent>
                                                                </Select>
                                                            </label>

                                                            <label className="block mt-3">
                                                                <span>Web App/Url (optional)</span>
                                                                <Field
                                                                    type="text"
                                                                    name="webapp_url"
                                                                    id="mobile"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>

                                                            <label className="block mt-3">
                                                                <span>Bank Name <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="bank_name"
                                                                    id="bank_name"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.bank_name && touched.bank_name && (
                                                                <span className="text-red-600 text-xs">
                                                                    {errors.bank_name}
                                                                </span>
                                                            )}

                                                            <label className="block mt-3">
                                                                <span>Bank Account <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="bank_acc_no"
                                                                    id="bank_acc_no"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.bank_acc_no && touched.bank_acc_no && (
                                                                <span className="text-red-600 text-xs">
                                                                    {errors.bank_acc_no}
                                                                </span>
                                                            )}

                                                            <label className="block mt-3">
                                                                <span>Ifsc Code <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="bank_ifsc_code"
                                                                    id="bank_ifsc_code"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.bank_ifsc_code && touched.bank_ifsc_code && (
                                                                <span className="text-red-600 text-xs">
                                                                    {errors.bank_ifsc_code}
                                                                </span>
                                                            )}

                                                            <Button
                                                                type="submit"
                                                                className=" mt-5 w-full font-medium"
                                                            >
                                                                Submit
                                                            </Button>

                                                            <Button variant="outline" className=" mt-5 w-full  font-medium " onClick={(() => { onClickBack() })} >Back</Button>
                                                        </Form>
                                                    </div>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>



                </div>
            )}

            {BusinessCardform === true && (
                <div className="flex mt-3 items-center justify-center">
                    <div className="">
                        <div className="text-center text-3xl font-bold">Business Details</div>
                        <div className="text-center text-sm font-bold">Please enter following business details </div>
                        <div className="grid grid-cols-2">
                            <img src="/activation/companydetails.svg" alt="SVG" />
                            <div className=" ">
                                <main className="flex w-full flex-col items-center  lg:max-w-md">
                                    <div className="w-full max-w-lg p-4 sm:px-5">
                                        <div className="text-center">
                                            <div className="mt-4">
                                                <h2 className="text-2xl font-semibold "></h2>
                                                <p className="text-xl font-bold">

                                                </p>
                                            </div>
                                        </div>
                                        <Formik
                                            enableReinitialize
                                            initialValues={initialbusinesscardValues}
                                            validationSchema={businesscardSchema}
                                            onSubmit={submitbusinesscardForm}
                                        >
                                            {(formik) => {
                                                const { errors, touched, isValid, dirty, setFieldValue } = formik;

                                                return (
                                                    <div className="card mt-5 rounded-lg p-5 lg:p-7">
                                                        <Form>
                                                            <label className="block mt-3">
                                                                <span>Company PAN No. <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="company_pan"
                                                                    id="company_pan"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.company_pan && touched.company_pan && (
                                                                <span className="text-red-600 text-xs">
                                                                    {errors.company_pan}
                                                                </span>
                                                            )}

                                                            <label className="block mt-3">
                                                                <span>Company GST <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="company_gst"
                                                                    id="company_gst"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.company_gst && touched.company_gst && (
                                                                <span className="text-red-600 text-xs">
                                                                    {errors.company_gst}
                                                                </span>
                                                            )}

                                                            <label className="block mt-3">
                                                                <span>Authorized Signatory PAN No <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="auth_sign_pan_no"
                                                                    id="auth_sign_pan_no"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.auth_sign_pan_no &&
                                                                touched.auth_sign_pan_no && (
                                                                    <span className="text-red-600 text-xs">
                                                                        {errors.auth_sign_pan_no}
                                                                    </span>
                                                                )}

                                                            <label className="block mt-3">
                                                                <span>Authorized Signatory Aadhar No <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="auth_sign_aadhar_no"
                                                                    id="auth_sign_aadhar_no"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.auth_sign_aadhar_no &&
                                                                touched.auth_sign_aadhar_no && (
                                                                    <span className="text-red-600 text-xs">
                                                                        {errors.auth_sign_aadhar_no}
                                                                    </span>
                                                                )}

                                                            <label className="block mt-3">
                                                                <span>Authorized Signatory Name <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                <Field
                                                                    type="text"
                                                                    name="auth_sign_name"
                                                                    id="auth_sign_name"
                                                                    className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                    placeholder=""
                                                                />
                                                            </label>
                                                            {errors.auth_sign_name && touched.auth_sign_name && (
                                                                <span className="text-red-600 text-xs">
                                                                    {errors.auth_sign_name}
                                                                </span>
                                                            )}

                                                            <Button
                                                                type="submit"
                                                                className="mt-5 w-full  font-medium"
                                                            >
                                                                Submit
                                                            </Button>

                                                            <Button variant="outline" className=" mt-5 w-full  font-medium " onClick={(() => { onClickBack() })} >Back</Button>
                                                        </Form>
                                                    </div>
                                                );
                                            }}
                                        </Formik>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </div>




                </div>

            )}

            {FilesUploadform === true && (

                <div className="flex mt-3 items-center justify-center ">

                    <div className="w-full">
                        <div className="text-center text-3xl font-bold">Upload Documents</div>
                        <div className="text-center text-sm font-bold">Please upload your documents here.</div>
                        {selectedBusinessTypeText === "" ? <div className="my-12"> <Alert>
                            <AlertCircle className="h-4 w-4" />
                            <AlertTitle>Heads up!</AlertTitle>
                            <AlertDescription>
                                Please Fill the Business and Bank Details Form First..
                            </AlertDescription>
                        </Alert>
                            <Button variant="outline" className=" mt-5 w-full  font-medium " onClick={(() => { onClickBack() })} >Back</Button>
                        </div> :
                            <div className="grid grid-cols-2">
                                <img src="/activation/docsupload.svg" alt="SVG" />
                                <div className="">

                                    <main className="flex w-full flex-col items-centerlg:max-w-md ">
                                        <div className="w-full max-w-lg p-4 sm:px-5">

                                            <Formik
                                                enableReinitialize
                                                initialValues={initialFilesValues}
                                                validationSchema={FilesSchema}
                                                onSubmit={submitFilesForm}
                                            >
                                                {(formik) => {
                                                    const { errors, touched, isValid, dirty, setFieldValue } =
                                                        formik;

                                                    console.log(errors);

                                                    return (
                                                        <div className="card mt-5 rounded-lg p-5 lg:p-7">
                                                            <Form>
                                                                {auth_pan == true && (
                                                                    <label className="block">
                                                                        <span>Auth Signatory Pan Card <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                        <input
                                                                            type="file"
                                                                            id="auth_pan"
                                                                            onChange={(event) => {
                                                                                setFieldValue(
                                                                                    "auth_pan",
                                                                                    event.target.files[0]
                                                                                );
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />
                                                                        {errors.auth_pan && touched.auth_pan && (
                                                                            <span className="text-red-600 text-xs">
                                                                                {errors.auth_pan}
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                )}

                                                                {auth_aadhar == true && (
                                                                    <label className="block">
                                                                        <span>Auth Signatory Aadhar Card <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                        <input
                                                                            type="file"
                                                                            id="auth_aadhar"
                                                                            onChange={(event) => {
                                                                                setFieldValue(
                                                                                    "auth_aadhar",
                                                                                    event.target.files[0]
                                                                                );
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />
                                                                        {errors.auth_aadhar && touched.auth_aadhar && (
                                                                            <span className="text-red-600 text-xs">
                                                                                {errors.auth_aadhar}
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                )}

                                                                {company_pan == true && (
                                                                    <label className="block">
                                                                        <span>Company Pan Card <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                        <input
                                                                            type="file"
                                                                            id="company_pan"
                                                                            onChange={(event) => {
                                                                                setFieldValue(
                                                                                    "company_pan",
                                                                                    event.target.files[0]
                                                                                );
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />
                                                                        {errors.company_pan && touched.company_pan && (
                                                                            <span className="text-red-600 text-xs">
                                                                                {errors.company_pan}
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                )}

                                                                {gstin == true && (
                                                                    <label className="block">
                                                                        <span>Gstin (optional)</span>
                                                                        <input
                                                                            type="file"
                                                                            id="gstin"
                                                                            onChange={(event) => {
                                                                                setFieldValue("gstin", event.target.files[0]);
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />{" "}
                                                                        {errors.gstin && touched.gstin && (
                                                                            <span className="text-red-600 text-xs">
                                                                                {errors.gstin}
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                )}

                                                                {reg_certificate == true && (
                                                                    <label className="block">
                                                                        <span>Registration Certificate <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                        <input
                                                                            type="file"
                                                                            id="reg_certificate"
                                                                            onChange={(event) => {
                                                                                setFieldValue(
                                                                                    "reg_certificate",
                                                                                    event.target.files[0]
                                                                                );
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />{" "}
                                                                        {errors.reg_certificate &&
                                                                            touched.reg_certificate && (
                                                                                <span className="text-red-600 text-xs">
                                                                                    {errors.reg_certificate}
                                                                                </span>
                                                                            )}
                                                                    </label>
                                                                )}

                                                                {cancel_cheque == true && (
                                                                    <label className="block">
                                                                        <span>Cancelled Cheque <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                        <input
                                                                            type="file"
                                                                            id="cancel_cheque"
                                                                            onChange={(event) => {
                                                                                setFieldValue(
                                                                                    "cancel_cheque",
                                                                                    event.target.files[0]
                                                                                );
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />
                                                                        {errors.cancel_cheque && touched.cancel_cheque && (
                                                                            <span className="text-red-600 text-xs">
                                                                                {errors.cancel_cheque}
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                )}

                                                                {cin == true && (
                                                                    <label className="block">
                                                                        <span>CIN <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                        <input
                                                                            type="file"
                                                                            id="cin"
                                                                            onChange={(event) => {
                                                                                setFieldValue("cin", event.target.files[0]);
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />
                                                                        {errors.cin && touched.cin && (
                                                                            <span className="text-red-600 text-xs">
                                                                                {errors.cin}
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                )}

                                                                {llpin == true && (
                                                                    <label className="block">
                                                                        <span>LLPIN <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                        <input
                                                                            type="file"
                                                                            id="llpin"
                                                                            onChange={(event) => {
                                                                                setFieldValue("llpin", event.target.files[0]);
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />
                                                                        {errors.llpin && touched.llpin && (
                                                                            <span className="text-red-600 text-xs">
                                                                                {errors.llpin}
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                )}

                                                                {inc_llp == true && (
                                                                    <label className="block">
                                                                        <span>Certificate of INC LLP <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                        <input
                                                                            type="file"
                                                                            id="inc_llp"
                                                                            onChange={(event) => {
                                                                                setFieldValue("inc_llp", event.target.files[0]);
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />
                                                                        {errors.inc_llp && touched.inc_llp && (
                                                                            <span className="text-red-600 text-xs">
                                                                                {errors.inc_llp}
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                )}

                                                                {partnership_deed == true && (
                                                                    <label className="block">
                                                                        <span>Partnership Deed <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                        <input
                                                                            type="file"
                                                                            name="partnership_deed"
                                                                            onChange={(event) => {
                                                                                setFieldValue(
                                                                                    "partnership_deed",
                                                                                    event.target.files[0]
                                                                                );
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />{" "}
                                                                        {errors.partnership_deed &&
                                                                            touched.partnership_deed && (
                                                                                <span className="text-red-600 text-xs">
                                                                                    {errors.partnership_deed}
                                                                                </span>
                                                                            )}
                                                                    </label>
                                                                )}

                                                                {moa_aoa == true && (
                                                                    <label className="block">
                                                                        <span>Moa/Aoa (optional)</span>
                                                                        <input
                                                                            type="file"
                                                                            id="moa_aoa"
                                                                            onChange={(event) => {
                                                                                setFieldValue("moa_aoa", event.target.files[0]);
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />
                                                                        {errors.moa_aoa && touched.moa_aoa && (
                                                                            <span className="text-red-600 text-xs">
                                                                                {errors.moa_aoa}
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                )}

                                                                {trust_deed == true && (
                                                                    <label className="block">
                                                                        <span>Trust Deed <p style={{ display: 'inline', color: 'red' }}>*</p></span>
                                                                        <input
                                                                            type="file"
                                                                            id="trust_deed"
                                                                            onChange={(event) => {
                                                                                setFieldValue(
                                                                                    "trust_deed",
                                                                                    event.target.files[0]
                                                                                );
                                                                            }}
                                                                            className="form-input mt-1.5 w-full rounded-lg border border-slate-300 bg-transparent px-3 py-2 placeholder:text-slate-400/70 hover:border-slate-400 focus:border-primary dark:border-navy-450 dark:hover:border-navy-400 dark:focus:border-accent"
                                                                            placeholder=""
                                                                        />
                                                                        {errors.trust_deed && touched.trust_deed && (
                                                                            <span className="text-red-600 text-xs">
                                                                                {errors.trust_deed}
                                                                            </span>
                                                                        )}
                                                                    </label>
                                                                )}

                                                                <Button
                                                                    type="submit"
                                                                    className="mt-5 w-full  font-medium"
                                                                >
                                                                    Submit
                                                                </Button>
                                                                <Button variant="outline" className=" mt-5 w-full  font-medium " onClick={(() => { onClickBack() })} >Back</Button>
                                                            </Form>
                                                        </div>
                                                    );
                                                }}
                                            </Formik>
                                        </div>
                                    </main>
                                </div>
                            </div>}
                    </div>



                </div>

            )}
        </div>
    );
};

export default MerchantActivation;
