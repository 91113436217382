import React, { useState, useEffect } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { deleteItem } from "Apis/merchantapis";
import { useToast } from "@/components/ui/use-toast";

const DeleteItem = (prop) => {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState(false);
  const [itemName, setItemName] = useState(false);

  const onSubmit = async () => {
    // Handle form submission
    setOpen(false);
    const sendRequest = await deleteItem(itemId);
    if (sendRequest.success == true) {
      toast({
        title: "Success",
        description: sendRequest.message,
      });
    } else {
      toast({
        variant: "destructive",
        title: "Error",
        description: sendRequest.message,
      });
    }
    prop.getFunction();
  };

  const CloseDialog = () => {
    setOpen(false);
  };

  useEffect(() => {
    setItemId(prop.item_id);
    setItemName(prop.item_name);
  }, [prop]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Delete</Button>
      </DialogTrigger>
      <DialogContent className={" overflow-y-scroll w-[400px] hide-scrollbar"}>
        <DialogHeader>
          <DialogTitle>Are you Sure ?</DialogTitle>
          <DialogDescription>{itemName} will be deleted .</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="destructive"
            onClick={() => {
              onSubmit();
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => {
              CloseDialog();
            }}
          >
            No
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteItem;
