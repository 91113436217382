import React, { useState, useEffect } from 'react';
import { Calendar } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, subDays, format } from "date-fns";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import { useToast } from "@/components/ui/use-toast";

const DateTimeRangeInput = (prop) => {
    const { toast } = useToast();
    const [openPopOver, setOpenPopOver] = useState(false);

    const [date, setDate] = useState({
        from: subDays(new Date(), 1), // Set to yesterday
        to: new Date(), // Set to today
    });

    const [timeRangeInput, setTimeRangeInput] = useState({
        from: "00:00:00",
        to: "23:59:59"
    });

    const [DateRangeInput, setDateRangeInput] = useState({
        from: new Date(subDays(new Date(), 1).setHours(timeRangeInput.from.split(":")[0], timeRangeInput.from.split(":")[1])),
        to: new Date(new Date().setHours(timeRangeInput.to.split(":")[0], timeRangeInput.to.split(":")[1])),
    });


    const handleTimeInputChange = (field, value) => {
        setTimeRangeInput((prev) => ({ ...prev, [field]: value }));
    };

    const handleTimeRangeSubmit = () => {
        setOpenPopOver(false)
        const fromTimeParts = timeRangeInput.from.split(":");
        const toTimeParts = timeRangeInput.to.split(":");

        const fromHours = parseInt(fromTimeParts[0], 10) || 0;
        const fromMinutes = parseInt(fromTimeParts[1], 10) || 0;
        const fromSeconds = parseInt(fromTimeParts[2], 10) || 0;

        const toHours = parseInt(toTimeParts[0], 10) || 0;
        const toMinutes = parseInt(toTimeParts[1], 10) || 0;
        const toSeconds = parseInt(toTimeParts[2], 10) || 0;

        const fromDate = new Date(date.from);
        fromDate.setHours(fromHours, fromMinutes, fromSeconds);

        const toDate = new Date(date.to);
        toDate.setHours(toHours, toMinutes, toSeconds);


        const dayDifference = Math.abs((toDate - fromDate) / (24 * 60 * 60 * 1000));

        if (dayDifference > 10) {
            toast({
                variant: "destructive",
                title: "Cannot Select More Than 10 Days",
                description: "Please select a date range within 10 days.",
            });
            return;
        }

        const updatedDateTimeRange = {
            from: fromDate,
            to: toDate,
        };



        setDateRangeInput(updatedDateTimeRange);

        const dateConverter = (date) => {
            const currentDate = new Date(date);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            const hours = String(currentDate.getHours()).padStart(2, "0");
            const minutes = String(currentDate.getMinutes()).padStart(2, "0");
            const seconds = String(currentDate.getSeconds()).padStart(2, "0");

            const dateString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            return dateString;
        };

        const fromDateString = dateConverter(updatedDateTimeRange.from);
        const toDateString = dateConverter(updatedDateTimeRange.to);

        const dateRangeObject = {
            from: fromDateString,
            to: toDateString,
        };


        prop.getFunction(dateRangeObject)
    };


    useEffect(() => {
        handleTimeRangeSubmit()
    }, [])



    return (
        <div className="">

            <Popover onOpenChange={setOpenPopOver} open={openPopOver}>
                <PopoverTrigger asChild >
                    <Button
                        id="date"
                        variant={"outline"}
                        className={cn(
                            "w-full justify-start text-left font-normal",
                            !date && "text-muted-foreground"
                        )}
                    >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date?.from ? (
                            date.to ? (
                                <>
                                    {format(date.from, "LLL dd, y")} -{" "}
                                    {format(date.to, "LLL dd, y")}
                                </>
                            ) : (
                                format(date.from, "LLL dd, y")
                            )
                        ) : (
                            <span>Pick a date</span>
                        )}
                    </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                    <Calendar
                        initialFocus
                        mode="range"
                        defaultMonth={date?.from}
                        selected={date}
                        onSelect={setDate}
                        numberOfMonths={2}
                    />

                    <div className="grid grid-cols-3 mb-3">
                        <div className="text-center mx-12">
                            <label htmlFor="fromTime"> </label>
                            <Input
                                type="time"
                                step="1"
                                id="fromTime"
                                value={timeRangeInput.from}
                                onChange={(e) => handleTimeInputChange("from", e.target.value)}
                            />
                        </div>
                        <div className="text-center mx-12">
                            <label htmlFor="toTime"> </label>
                            <Input
                                type="time"
                                step="1"
                                id="toTime"
                                value={timeRangeInput.to}
                                onChange={(e) => handleTimeInputChange("to", e.target.value)}
                            />
                        </div>

                        <Button className="mx-12" onClick={handleTimeRangeSubmit}>Submit</Button>
                    </div>

                </PopoverContent>
            </Popover>
        </div >
    );
};

export default DateTimeRangeInput;