import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const EmployeeLogout = () => {
    let navigate = useNavigate();
    localStorage.removeItem("employee");

    useEffect(() => {
        navigate("/manage/login");
    }, []);

    return <div></div>;
};

export default EmployeeLogout;