import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { motion } from "framer-motion"
import { getMerchantBusinessDetails } from 'Apis/merchantapis';
import { Building2, WalletCards, Landmark } from 'lucide-react';

const BusinessDetails = () => {

    const [businessDetails, setbusinessDetails] = useState([]);

    const fetchData = async () => {
        const apiResponse = await getMerchantBusinessDetails();
        setbusinessDetails(apiResponse.data)
        
    }

    useEffect(() => {
        fetchData()
        console.log(businessDetails)
    }, [])


    return (
        <div>
            {businessDetails.business_type_id  &&
                <div>
                    <div className="grid sm:grid-cols-4 grid-cols-1 gap-8">
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ rotate: 0, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20
                            }}
                        >
                            <Card>
                                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                    <CardTitle className="text-lg font-medium">
                                        Business Info
                                    </CardTitle>
                                    <Building2 />
                                </CardHeader>
                                <CardContent>
                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold'>Business Type:</h5>
                                        <h5 className='text-md font-bold'> {businessDetails.business_type_id.type_name}</h5>
                                    </div>

                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold' >Business Category:</h5>
                                        <h5 className='text-md font-bold'>{businessDetails.business_category_id.category_name}</h5>
                                    </div>

                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold'>WebApp/Url:</h5>
                                        <h5 className='text-md font-bold'>{businessDetails.webapp_url}</h5>
                                    </div>

                                </CardContent>
                            </Card>
                        </motion.div>
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ rotate: 0, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20
                            }}
                        >
                            <Card>
                                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                    <CardTitle className="text-lg font-medium">
                                        Bank Account Details
                                    </CardTitle>
                                    <Landmark />
                                </CardHeader>
                                <CardContent>

                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold'>Bank Name:</h5>
                                        <h5 className='text-md font-bold'> {businessDetails.bank_name}</h5>
                                    </div>

                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold' >Account Number:</h5>
                                        <h5 className='text-md font-bold'>{businessDetails.bank_acc_no}</h5>
                                    </div>

                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold'>Ifsc Code :</h5>
                                        <h5 className='text-md font-bold'>{businessDetails.bank_ifsc_code}</h5>
                                    </div>

                                </CardContent>
                            </Card>
                        </motion.div>


                       
                    </div>
                    <div className='grid grid-cols-4 my-2'>



                    <motion.div
                            className="sm:col-span-2 col-span-4"
                            initial={{ scale: 0 }}
                            animate={{ rotate: 0, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20
                            }}
                        >
                            <Card >
                                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                    <CardTitle className="text-lg font-medium">
                                        Business Detail Info
                                    </CardTitle>
                                    <WalletCards />
                                </CardHeader>
                                <CardContent>
                                    <div className="grid sm:grid-cols-2 grid-cols-1">
                                        <div>
                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Business Name:</h5>
                                                <h5 className='text-md font-bold'> {businessDetails.business_name}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold' >Company PAN Number:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.comp_pan_number}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Company GST No:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.comp_gst}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Authorized Signatory PAN No:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.mer_pan_number}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Authorized Signatory Aadhar No:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.mer_aadhar_number}</h5>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Authorized Signatory Name:</h5>
                                                <h5 className='text-md font-bold'> {businessDetails.mer_name}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold' >Address:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.address}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Pincode:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.pincode}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>City:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.city}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>State</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.state.state_name}</h5>
                                            </div>
                                        </div>
                                    </div>


                                </CardContent>
                            </Card>
                        </motion.div>



                    </div>
                </div>}

        </div >
    );
};

export default BusinessDetails;