import React from 'react';
import { useOutletContext } from "react-router-dom";
import MerchantPayoutStatement from './PayoutStatement';
import MerchantTestPayoutStatement from './TestPayoutStatement';

const PayoutStatementHome = () => {
    const testMode = useOutletContext();

    return (
        <div>
            {testMode ? <MerchantTestPayoutStatement /> : <MerchantPayoutStatement />}
        </div>
    );
};

export default PayoutStatementHome;