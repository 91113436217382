import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { getStates, getBusinessCategories, getBusinessTypes, getBusinessExpenditures, UpdateMerchantBusiness } from 'Apis/employeeapi';
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { useToast } from "@/components/ui/use-toast";

export default function EditBusinessDetails(props) {
    const { toast } = useToast();
    const [businessDetails, setBusinessDetails] = useState(props.businessdetails);
    const [merchantId, setMerchantId] = useState(props.businessdetails.created_merchant);
    const [open, setOpen] = useState(false);

    const [states, setStates] = useState([]);
    const [businessTypes, setBusinessTypes] = useState([]);
    const [businessCategories, setBusinessCategories] = useState([]);
    const [businessExpenditures, setBusinessExpenditures] = useState([]);

   

    const fetchData = async () => {
        const fetchStates = await getStates()
        const fetchBusniessTypes = await getBusinessTypes()
        const fetchBusinessCategories = await getBusinessCategories()
        const fetchBusinessExpenditures = await getBusinessExpenditures()

        

        setStates(fetchStates.data)
        setBusinessTypes(fetchBusniessTypes.busniess_types)
        setBusinessCategories(fetchBusinessCategories.busniess_categories)
        setBusinessExpenditures(fetchBusinessExpenditures.busniess_expenditures)
    }

    useEffect(() => {
        fetchData()
        setBusinessDetails(props.businessdetails)
        setMerchantId(props.businessdetails.created_merchant)
    }, [props])

    const initialBusinessDetailsValues = {
        monthly_expenditure: businessDetails.business_expenditure._id,
        company_name: businessDetails.business_name,
        company_address: businessDetails.address,
        pincode: businessDetails.pincode,
        city: businessDetails.city,
        state: businessDetails.state._id,
        country: "india",
        business_type: businessDetails.business_type_id._id,
        business_category: businessDetails.business_category_id._id,
        webapp_url: businessDetails.webapp_url,
        bank_name: businessDetails.bank_name,
        bank_acc_no: businessDetails.bank_acc_no,
        bank_ifsc_code: businessDetails.bank_ifsc_code,
        company_pan: businessDetails.comp_pan_number,
        company_gst: businessDetails.comp_gst,
        auth_sign_pan_no: businessDetails.mer_pan_number,
        auth_sign_aadhar_no: businessDetails.mer_aadhar_number,
        auth_sign_name: businessDetails.mer_name
    };


    const personalDetailSchema = Yup.object().shape({
        company_name: Yup.string().required("Company Name is required"),
        company_address: Yup.string().required("Company Address is required"),
        pincode: Yup.string()
            .matches(/^[1-9][0-9]{5}$/, "Pin Code is not valid")
            .required("Pin Code is required"),
        city: Yup.string().required("City is required"),
        bank_name: Yup.string().required("Bank Name is required"),
        bank_acc_no: Yup.string().required("Bank Account Number is required"),
        bank_ifsc_code: Yup.string()
            .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, "Ifsc Code is not valid")
            .required("Ifsc Code is required"),
        company_pan: Yup.string()
            .matches(
                /^([A-Z]){3}C([A-Z])([0-9]){4}([A-Z]){1}?$/,
                "Company pan no is not valid"
            )
            .required("Company pan no is required"),
        company_gst: Yup.string()
            .matches(
                /[0-9]{2}[A-Z]{3}[ABCFGHLJPTF]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}/,
                "Company Gstin is not valid"
            )
            .required("Company Gstin is required"),
        auth_sign_pan_no: Yup.string()
            .matches(
                /^([A-Z]){3}P([A-Z])([0-9]){4}([A-Z]){1}?$/,
                "Signatory pan no is not valid"
            )
            .required("Signatory pan no is required"),
        auth_sign_aadhar_no: Yup.string()
            .min(12, "Please enter a 12 digit Mobile Number")
            .max(12, "Please enter a 12 digit Mobile Number")
            .required("Signatory Aadhar Number is required"),
        auth_sign_name: Yup.string().required("Signatory Name is required"),
    });

    const submitPersonalDetailsForm = async (values) => {
        const update = await UpdateMerchantBusiness(merchantId, values)
        if (update.success === true) {
            toast({
                title: "Success",
                description: update.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: update.message,
            });
        }
        setOpen(false)
        props.refreshData();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Edit</Button>
            </DialogTrigger>
            <DialogContent className={
                "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Edit Personal Details</DialogTitle>
                    <DialogDescription>
                        Make changes to your profile here. Click edit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        enableReinitialize
                        initialValues={initialBusinessDetailsValues}
                        validationSchema={personalDetailSchema}
                        onSubmit={submitPersonalDetailsForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange, setFieldValue,
                                handleBlur, values } = formik;

                            return (
                                <div >
                                    <Form >
                                        <div className="grid grid-cols-3 mt-1 p-5 gap-4">
                                            

                                            <div>
                                                <Label htmlFor="monthly_expenditure">Monthly Expenditure:</Label>
                                                <Select name="monthly_expenditure"
                                                    id="monthly_expenditure"
                                                    defaultValue={values.monthly_expenditure}
                                                    onValueChange={(field) => {
                                                        setFieldValue('monthly_expenditure', field);
                                                    }}>
                                                    <SelectTrigger className="w-full">
                                                        <SelectValue />
                                                    </SelectTrigger>
                                                    <SelectContent>
                                                        <SelectGroup>
                                                            {businessExpenditures.map((value) => (

                                                                <SelectItem key={value._id} value={value._id}>{value.expenditure}</SelectItem>
                                                            ))}

                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                                {errors.monthly_expenditure && touched.monthly_expenditure && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.monthly_expenditure}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="company_name">Company Name:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="company_name"
                                                    name="company_name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.company_name}
                                                />
                                                {errors.company_name && touched.company_name && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.company_name}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="company_address">Company Address:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="company_address"
                                                    name="company_address"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.company_address}
                                                />
                                                {errors.company_address && touched.company_address && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.company_address}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="pincode">Pincode:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="pincode"
                                                    name="pincode"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.pincode}
                                                />
                                                {errors.pincode && touched.pincode && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.pincode}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="city">City:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="city"
                                                    name="city"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.city}
                                                />
                                                {errors.city && touched.city && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.city}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="state">State:</Label>
                                                <Select name="state"
                                                    id="state"
                                                    defaultValue={values.state}
                                                    onValueChange={(field) => {
                                                        setFieldValue('state', field)
                                                    }}>
                                                    <SelectTrigger className="w-full ">
                                                        <SelectValue />
                                                    </SelectTrigger>
                                                    <SelectContent className={
                                                        "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                    }>
                                                        <SelectGroup >
                                                            {states.map((value) => (

                                                                <SelectItem key={value._id} value={value._id}>{value.state_name}</SelectItem>
                                                            ))}

                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                                {errors.state && touched.state && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.state}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="country">Country:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="country"
                                                    name="country"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.country}
                                                />
                                                {errors.country && touched.country && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.country}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="business_type">Business Type:</Label>
                                                <Select name="business_type"
                                                    id="business_type"
                                                    defaultValue={values.business_type}
                                                    onValueChange={(field) => {
                                                       
                                                        setFieldValue('business_type', field);
                                                    }}
                                                >
                                                    <SelectTrigger className="w-full ">
                                                        <SelectValue />
                                                    </SelectTrigger>
                                                    <SelectContent className={
                                                        "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                    }>
                                                        <SelectGroup >
                                                            {businessTypes.length > 0 && businessTypes.map((value) => (

                                                                <SelectItem key={value._id} value={value._id}>{value.type_name}</SelectItem>
                                                            ))}

                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                                {errors.business_type && touched.business_type && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.business_type}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="business_category">Business Category:</Label>
                                                <Select name="business_category"
                                                    id="business_category"
                                                    defaultValue={values.business_category}
                                                    onValueChange={(field) => {
                                                        setFieldValue('business_category', field);
                                                    }}>
                                                    <SelectTrigger className="w-full ">
                                                        <SelectValue />
                                                    </SelectTrigger>
                                                    <SelectContent className={
                                                        "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                    }>
                                                        <SelectGroup >
                                                            {businessCategories.length > 0 && businessCategories.map((value) => (

                                                                <SelectItem key={value._id} value={value._id}>{value.category_name}</SelectItem>
                                                            ))}

                                                        </SelectGroup>
                                                    </SelectContent>
                                                </Select>
                                                {errors.business_category && touched.business_category && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.business_category}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="webapp_url">Webapp URL:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="webapp_url"
                                                    name="webapp_url"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.webapp_url}
                                                />
                                                {errors.webapp_url && touched.webapp_url && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.webapp_url}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="bank_name">Bank Name:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="bank_name"
                                                    name="bank_name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.bank_name}
                                                />
                                                {errors.bank_name && touched.bank_name && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.bank_name}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="bank_acc_no">Bank Account Number:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="bank_acc_no"
                                                    name="bank_acc_no"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.bank_acc_no}
                                                />
                                                {errors.bank_acc_no && touched.bank_acc_no && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.bank_acc_no}
                                                    </span>
                                                )}
                                            </div>

                                            <div>
                                                <Label htmlFor="bank_ifsc_code">Bank IFSC Code:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="bank_ifsc_code"
                                                    name="bank_ifsc_code"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.bank_ifsc_code}
                                                />
                                                {errors.bank_ifsc_code && touched.bank_ifsc_code && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.bank_ifsc_code}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="company_pan">Company PAN Number:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="company_pan"
                                                    name="company_pan"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.company_pan}
                                                />
                                                {errors.company_pan && touched.company_pan && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.company_pan}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="company_gst">Company GST Number:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="company_gst"
                                                    name="company_gst"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.company_gst}
                                                />
                                                {errors.company_gst && touched.company_gst && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.company_gst}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="auth_sign_pan_no">Authorized Signatory PAN Number:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="auth_sign_pan_no"
                                                    name="auth_sign_pan_no"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.auth_sign_pan_no}
                                                />
                                                {errors.auth_sign_pan_no && touched.auth_sign_pan_no && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.auth_sign_pan_no}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="auth_sign_aadhar_no">Authorized Signatory Aadhar Number:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="auth_sign_aadhar_no"
                                                    name="auth_sign_aadhar_no"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.auth_sign_aadhar_no}
                                                />
                                                {errors.auth_sign_aadhar_no && touched.auth_sign_aadhar_no && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.auth_sign_aadhar_no}
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <Label htmlFor="auth_sign_name">Authorized Signatory Name:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="auth_sign_name"
                                                    name="auth_sign_name"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.auth_sign_name}
                                                />
                                                {errors.auth_sign_name && touched.auth_sign_name && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.auth_sign_name}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <div className='text-center'>
                                            <Button
                                                type="submit"
                                                className=" "
                                            >
                                                Edit
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                </div>

            </DialogContent>
        </Dialog>
    )
}
