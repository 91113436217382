import React from 'react';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import Breadcrumb from 'LoadingComponents/Breadcrumb';
import { Percent, SquareStack } from 'lucide-react';
import PayoutTransactionReport from './PayoutTransactionReport/PayoutTransactionReport';
import PayoutStatementReport from './PayoutStatementReport/PayoutStatementReport';
const AdminPayoutReports = () => {

    const BreadcrumbArray = [{ name: 'Payout', icon: <SquareStack /> }, { name: 'Reports', icon: <Percent /> }]
    return (
        <div>
            <Breadcrumb data={BreadcrumbArray} />
            <Tabs defaultValue="payouttransactionreport" className="w-full">
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="payouttransactionreport">Payout Transaction Report</TabsTrigger>
                    <TabsTrigger value="payoutstatementreport">Payout Statement Report</TabsTrigger>

                </TabsList>
                <TabsContent value="payouttransactionreport">
                    <PayoutTransactionReport/>
                </TabsContent>
                <TabsContent value="payoutstatementreport">
                   <PayoutStatementReport />
                </TabsContent>

            </Tabs>
        </div>
    );
};

export default AdminPayoutReports;