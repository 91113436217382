import React, { useState, useEffect, lazy, Suspense } from "react";
import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel"
import Autoplay from "embla-carousel-autoplay"
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { LoginRequest, saveMerchantLoginActivity } from "../../Apis/merchantapis";
import { AspectRatio } from "@/components/ui/aspect-ratio"
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast"
import TextTransition, { presets } from 'react-text-transition';


const Spline = lazy(() => import("@splinetool/react-spline"));


const TEXTS = ['Customizable Payment Gateway Solution For Your Business', 'Easy-To-Use APIs That Require Minimal Setup', 'Simple And Easy-To-Use Dashboard', 'Fast And Simple Onboarding Process', 'Improved Success Rates', 'A Secure And Reliable Platform', '24x7 Assistance And Technical Support'];

export default function Login() {
  let navigate = useNavigate();
  const { toast } = useToast();


  const [index, setIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      2000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });


  // Handle form submission
  const handleSubmit = async (values, { setSubmitting }) => {
    const returnData = await LoginRequest(values);
    console.log(returnData);
    if (returnData.success === true) {
      localStorage.setItem("user", JSON.stringify(returnData.data));

      saveMerchantLoginActivity()

      navigate("/dashboard");
    } else if (returnData.success === false) {
      console.log(returnData.message);
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: returnData.message
      })
    }





  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 ">
      <div className="mx-12 mt-1  ">
        <AspectRatio ratio={11 / 9} className="mt-16">

          <Suspense fallback={<div></div>}>
            <Spline scene="https://prod.spline.design/3Aig-YZK0fYNIayR/scene.splinecode" />
          </Suspense>
        </AspectRatio>


      </div>
      <div className="flex justify-center">
        <div className=" px-2 mt-12 w-2/4">

          <h1 className="scroll-m-20 text-center text-5xl font-extrabold tracking-tight md:text-5xl  my-6">
            Payripe
          </h1>
          <h1 className="scroll-m-20 text-center text-xl font-bold tracking-tight md:text-xl  my-6">
            Merchant Login
          </h1>
          <Card>
            <CardHeader className="space-y-1">
              <CardTitle className="text-2xl">Sign In</CardTitle>
              <CardDescription>
                Enter your email below to sign in to your account
              </CardDescription>
            </CardHeader>
            <Formik
              initialValues={{ email: "", password: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                isSubmitting,
              }) => (
                <Form>
                  <CardContent className="grid gap-4">
                    <div className="grid gap-2">
                      <Label htmlFor="email">Email</Label>
                      <Input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="email"
                        className="text-red-500"
                        component="div"
                      />
                    </div>
                    <div className="grid gap-2">
                      <Label htmlFor="password">Password</Label>
                      <Input
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      <ErrorMessage
                        name="password"
                        className="text-red-500"
                        component="div"
                      />
                    </div>
                  </CardContent>
                  <CardFooter>
                    <Button className="w-full" type="submit">
                      Login
                    </Button>
                  </CardFooter>
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      </div>
    </div>
  );
}
