
import { CardTitle, CardDescription, CardHeader, CardContent, Card } from "@/components/ui/card"
import { Link } from "react-router-dom"
import { Button } from "@/components/ui/button"
import { Badge } from "@/components/ui/badge"
import { RocketIcon } from "@radix-ui/react-icons"

import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/components/ui/alert"

export default function Home() {
    return (
        <main className="flex flex-col items-center justify-center   py-1 sm:py-2">
            <div className="p-4 sm:p-6">
                <div className="max-w-md mx-auto">
                    <Card className="mx-auto max-w-sm">
                        <CardHeader className="space-y-1">
                            <CardTitle className="text-2xl font-bold">Payripe Login</CardTitle>
                            <CardDescription>Select your account type to proceed</CardDescription>
                        </CardHeader>
                        <CardContent>
                            <div className="space-y-4">

                                <Link className="" to="/login">
                                    <Button className="w-full my-3" variant="outline">
                                        Merchant Login
                                    </Button>
                                </Link>

                                <Link className="" to="/manage/login">
                                    <Button className="w-full my-3" variant="outline">
                                        Employee Login
                                    </Button>
                                </Link>

                                <Link className="" to="/overview_api_documentation">
                                <Button className="w-full my-3" variant="outline">
                                        Api Documentation
                                </Button>
                                </Link>

                            </div>
                            <div className="mt-4 text-center text-sm">
                                <Badge color="" variant="destructive">
                                    Website is under progress
                                </Badge>
                            </div>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </main>
    )
}

