import React from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { updateMerchantPassword } from "Apis/merchantapis";
import { useToast } from "@/components/ui/use-toast";

const ChangePassword = (prop) => {
  const { toast } = useToast();

  const initialValues = {
    password: "",
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Must be at least 6 characters")
      .required(" Password is required"),
  });

  const onSubmit = async (values, { resetForm }) => {
    // Handle form submission

    console.log(values);
    const sendRequest = await updateMerchantPassword(values);

    resetForm({ values: "" });
    if (sendRequest.success == true) {
      toast({
        title: "Success",
        description: sendRequest.message,
      });
    } else {
      toast({
        variant: "destructive",
        title: "Error",
        description: sendRequest.message,
      });
    }
  };

  return (
    <div
      className={
        "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
      }
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
        }) => (
          <Form>
            <div className="grid grid-cols-1 md:grid-cols-1 pt-3 pb-16 px-3 gap-2 gap-y-4 ">
              <div>
                <Label htmlFor="password"> New Password:</Label>
                <Input
                  className="mt-3"
                  type="text"
                  id="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <ErrorMessage
                  name="password"
                  className="text-red-500 text-xs  my-2"
                  component="div"
                />
              </div>
            </div>

            <div className="mx-3">
              <Button type="submit">Submit</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChangePassword;
