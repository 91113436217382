import React, { useState, useEffect } from 'react';
import {
    Card,
    CardContent
} from "@/components/ui/card"

const Breadcrumb = (props) => {
    const [breadcrumbData, setBreadcrumb] = useState('')

    

    useEffect(() => {
        setBreadcrumb(props.data)
    }, [props])

    return (
        <div>
            <Card className=" m-2">
                <CardContent className="p-2">
                    <div className="flex">
                        {breadcrumbData.length > 0 && breadcrumbData.map((item, index) => (
                            <div className="flex" key={index}>
                                <h5 className='text-xs'>{item.icon} </h5>
                                <h5  className='text-sm ml-2 jetbrains'>{item.name} {index !== breadcrumbData.length - 1 && '->'}</h5>
                            </div>


                        ))}
                    </div>
                </CardContent >
            </Card>
        </div>
    );
};

export default Breadcrumb;