import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog";
import { getTransactionInfoViaId } from "Apis/merchantapis";
import { Progress } from "@/components/ui/progress"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import DateTimeRangeInput from "ReusableComponents/DateTimeRangeInput";
import download from 'downloadjs';
import { addDays } from "date-fns";
import { Button } from "@/components/ui/button";
import { getMerchantPayoutStatement, exportMerchantPayoutStatement } from "Apis/merchantapis";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import { io } from "socket.io-client";
import TableLoading from "LoadingComponents/TableLoading";

const MerchantPayoutStatement = () => {
    const socket = io(`${process.env.REACT_APP_PAYMENT_URL}`);
    const [open, setOpen] = useState(false);
    const [percent, setPercent] = useState('');
    const [transactionInfo, setTransactionInfo] = useState('');
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });

    const [paymentIdQ, setpaymentIdQ] = useState("");
    const [statusQ, setstatusQ] = useState("");

    const [viewTable, setViewTable] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [transactions, setTransactions] = useState([]);

    const getDateRange = (dateRangeObject) => {
        console.log(dateRangeObject, 'parent');
        setDate(dateRangeObject)

    };

    const getData = async (skip, limit, dates, paymentId, status) => {
        setTableLoading(true)

        if (dates !== undefined) {
            const fetchTransactions = await getMerchantPayoutStatement(
                skip,
                limit,
                dates,
                statusQ
            );
            setTransactions(fetchTransactions.data);
            setCount(fetchTransactions.count);
            setTableLoading(false)
        }
    };

    const fetchdata = (limit, skip) => {
        console.log(limit, skip);
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, date, paymentIdQ, statusQ);
    };

    const onClickView = () => {
        if (viewTable === true) {
            setViewTable(false)
        }
        else {
            setViewTable(true)
        }
    }



    const onClickTransactionId = async (id) => {
        console.log(id)
        const fetchdata = await getTransactionInfoViaId(id);
        setTransactionInfo(fetchdata.data)
        console.log(fetchdata)

    }



    useEffect(() => {
        const timer = setInterval(() => {
            const userId = JSON.parse(localStorage.getItem("user")).userId;
            socket.on(userId, function (msg) {
                console.log(msg);
                setPercent(msg);

            });
        }, 200);

        return () => {
            clearInterval(timer);
        };
    }, []);


    const onClickDownload = async () => {
        console.log('download clicked')

        setPercent('Fetching Data')

        const exportCall = await exportMerchantPayoutStatement(
            date, statusQ
        );

        setPercent('Downloading File')
        const blob = await exportCall.blob();

        download(blob, `${date.from}-${date.to}.csv`, 'text/csv');

        setOpen(false)
        setPercent('')
    }

    useEffect(() => {
        if (viewTable === true) {
            getData(skip, limit, date, paymentIdQ, statusQ);
        }
    }, [date, paymentIdQ, statusQ, viewTable]);

    return (
        <div className="rounded-md border">
            <div className="grid grid-cols-1 md:grid-cols-4 pt-3 pb-16 px-3 gap-8">
                <DateTimeRangeInput getFunction={getDateRange} />
                <div>
                    <Select
                        onValueChange={(field) => {
                            setstatusQ(field);
                        }}
                        defaultValue={""}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Select a Status" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Status</SelectLabel>
                                <SelectItem value="">All</SelectItem>
                                <SelectItem value="fund credit">Fund Credit</SelectItem>
                                <SelectItem value="fund debit">Fund Debit</SelectItem>
                                <SelectItem value="consolidate">Consolidate</SelectItem>
                                <SelectItem value="payout">Payout</SelectItem>
                                <SelectItem value="refund">Refund</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <div>
                    <Button className="mx-2" onClick={() => { onClickView() }}>View</Button>
                    <Button className="mx-2" disabled={percent !== ''} variant="outline" onClick={() => { onClickDownload() }}>{percent !== '' ? percent : 'Download'}</Button>
                </div>
            </div>





            {viewTable === true && <div>

                <Table>
                    <TableCaption>A list of your recent payments.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead >Sno.</TableHead>
                            <TableHead>Created Date</TableHead>
                            <TableHead>Debit</TableHead>
                            <TableHead>Credit</TableHead>
                            <TableHead >Type</TableHead>
                            <TableHead>Closing Balance</TableHead>
                            <TableHead>Txn Id</TableHead>
                            <TableHead>Remark</TableHead>
                        </TableRow>
                    </TableHeader>

                    {tableLoading === false ?
                        <TableBody>
                            {transactions.length > 0 &&
                                transactions.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>  {moment(item.createdDate).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                        <TableCell>{item.debit}</TableCell>
                                        <TableCell>{item.credit}</TableCell>
                                        <TableCell>{item.type}</TableCell>
                                        <TableCell>{item.closingBalance}</TableCell>
                                        <TableCell>{item.txnRefId}</TableCell>
                                        <TableCell>{item.remark}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                        : <TableLoading count={9} />

                    }
                </Table>

                <div className="flex justify-center my-3">
                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div></div>}


            <Dialog open={open} onOpenChange={setOpen} disableOutsidePointerEvents >

                <DialogContent
                    className={
                        "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                    }
                >
                    <DialogHeader>
                        <DialogTitle>Exporting Transactions</DialogTitle>
                        <DialogDescription>

                        </DialogDescription>
                    </DialogHeader>
                    <h5 className="text-lg font-bold">{percent} %</h5>
                    <Progress value={percent} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default MerchantPayoutStatement;
