import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@//components/ui/scroll-area";
import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { LogOut, Link2, Mailbox, UserSquare2, FileText, Workflow, IndianRupee, UserCheck } from "lucide-react";
import SmoothCollapse from "react-smooth-collapse";

export default function MerchantSidebar(props) {
  const location = useLocation();
  const { pathname } = location;

  const [payoutMenuExpanded, setPayoutMenuExpanded] = useState(false);

  const [testMode, setTestMode] = useState(false);

  const onClickPayoutMenu = () => {
    if (payoutMenuExpanded) {
      setPayoutMenuExpanded(false)
    }
    else {
      setPayoutMenuExpanded(true)
    }
  }

  useEffect(() => {
    setTestMode(props.testMode)
  }, [props])

  return (
    <div className={cn("pb-12")}>
      <div className="space-y-4 py-4">
        <div className="px-3 py-2">
          <div className="space-y-1">
            <Link to="dashboard">
              <Button
                variant={pathname === "/dashboard" ? "secondary" : "ghost"}
                className=" my-1 w-full justify-start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mr-2 h-4 w-4"
                >
                  <circle cx="12" cy="12" r="10" />
                  <polygon points="10 8 16 12 10 16 10 8" />
                </svg>
                Dashboard
              </Button>
            </Link>
            <Link to="transactions">
              <Button
                variant={pathname === "/transactions" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mr-2 h-4 w-4"
                >
                  <rect width="7" height="7" x="3" y="3" rx="1" />
                  <rect width="7" height="7" x="14" y="3" rx="1" />
                  <rect width="7" height="7" x="14" y="14" rx="1" />
                  <rect width="7" height="7" x="3" y="14" rx="1" />
                </svg>
                Transactions
              </Button>
            </Link>
            <Link to="paylinks">
              <Button
                variant={pathname === "/paylinks" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <Link2 className="mr-2 h-4 w-4" />
                Paylinks
              </Button>
            </Link>

            {!testMode && <Link to="invoices">
              <Button
                variant={pathname === "/invoices" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <FileText className="mr-2 h-4 w-4" />
                Invoices
              </Button>
            </Link>}


            {/* <Link to="settlements">
              <Button
                variant={pathname === "/settlements" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mr-2 h-4 w-4"
                >
                  <path d="M4.9 19.1C1 15.2 1 8.8 4.9 4.9" />
                  <path d="M7.8 16.2c-2.3-2.3-2.3-6.1 0-8.5" />
                  <circle cx="12" cy="12" r="2" />
                  <path d="M16.2 7.8c2.3 2.3 2.3 6.1 0 8.5" />
                  <path d="M19.1 4.9C23 8.8 23 15.1 19.1 19" />
                </svg>
                Settlements
              </Button>
            </Link> */}

            <Button
              variant={"ghost"}
              className="my-1 w-full flex  justify-between"
              onClick={onClickPayoutMenu}
            >
              <div className="flex">
                <IndianRupee className="mr-2 h-4 w-4" />
                Payout
              </div>
              <div className="col-span-2">
                <svg xmlns="http://www.w3.org/2000/svg" className={`${payoutMenuExpanded && 'rotate-90'} `} width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="m9 18 6-6-6-6" /></svg>
              </div>
            </Button>

            <SmoothCollapse expanded={payoutMenuExpanded} >
              {payoutMenuExpanded && <div>
                <Link to="/payout_dashboard">
                  <Button
                    variant={pathname === "/payout_dashboard" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <UserCheck className="mr-2 h-4 w-4" />
                    Dashboard
                  </Button>
                </Link>
                <Link to="/beneficiaries">
                  <Button
                    variant={pathname === "/beneficiaries" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <UserCheck className="mr-2 h-4 w-4" />
                    Beneficiaries
                  </Button>
                </Link>


                <Link to="/payout_payment_report">
                  <Button
                    variant={pathname === "/payout_payment_report" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <UserCheck className="mr-2 h-4 w-4" />
                    Payout Report
                  </Button>
                </Link>

                <Link to="/payout_settings">
                  <Button
                    variant={pathname === "/payout_settings" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <UserCheck className="mr-2 h-4 w-4" />
                    Payout Settings
                  </Button>
                </Link>

                <Link to="/payout_statement_report">
                  <Button
                    variant={pathname === "/payout_statement_report" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <UserCheck className="mr-2 h-4 w-4" />
                    Payout Statement
                  </Button>
                </Link>





              </div>}
            </SmoothCollapse>

            <Link to="settings">
              <Button
                variant={pathname === "/settings" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <UserSquare2 className="mr-2 h-4 w-4" />
                Settings
              </Button>
            </Link>

            <Link to="api_documentation">
              <Button
                variant={pathname === "/api_documentation" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <Workflow className="mr-2 h-4 w-4" />
                Api
              </Button>
            </Link>

            <Link to="feedback">
              <Button
                variant={pathname === "/feedback" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <Mailbox className="mr-2 h-4 w-4" />
                Feedback
              </Button>
            </Link>

            <Link to="merchant_account">
              <Button
                variant={pathname === "/merchant_account" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <UserSquare2 className="mr-2 h-4 w-4" />
                Account
              </Button>
            </Link>

            <Link to="logout">
              <Button
                variant={pathname === "/logout" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <LogOut className="mr-2 h-4 w-4" />
                Logout
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
