import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { motion } from "framer-motion"
import { ArrowRightLeft, IndianRupeeIcon } from "lucide-react";
import CardLoading from "LoadingComponents/CardLoading";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { getTransactionInfoViaId } from "Apis/merchantapis";
import { Progress } from "@/components/ui/progress"
import DateTimeRangeInput from "ReusableComponents/DateTimeRangeInput";
import download from 'downloadjs';
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";

import { Input } from "@/components/ui/input";
import { getTestPayoutLast10DaysDate, getTestPayoutDashboardDataByDate } from "Apis/merchantapis";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import { Skeleton } from "@/components/ui/skeleton";
import TableLoading from "LoadingComponents/TableLoading";

const TestDashboard = () => {
    const [open, setOpen] = useState(false);
    const [percent, setPercent] = useState('');
    const [transactionInfo, setTransactionInfo] = useState('');
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });

    const [dateStats, setDateStats] = useState([]);
    const [dateSuccessStats, setDateSuccessStats] = useState([]);
    const [dateFailedStats, setdateFailedStats] = useState([]);

    const [stats, setStats] = useState([]);
    const [successStats, setSuccessStats] = useState([]);
    const [failureStats, setFailureStats] = useState([]);

    const getDateRange = (dateRangeObject) => {
        console.log(dateRangeObject, 'parent');
        setDate(dateRangeObject)
    };

    const getData = async () => {
        const fetchTransactions = await getTestPayoutLast10DaysDate();

        if (fetchTransactions.result.length > 0) {
            setStats(fetchTransactions.result[0]);
            const successData = await fetchTransactions.result[0].statuses.filter(data => data.status === 'Success');
            const failureData = await fetchTransactions.result[0].statuses.filter(data => data.status === 'Failed');

            if (successData.length > 0) {
                setSuccessStats(successData[0].totalTransactions)
            }
            else {
                setSuccessStats(0)
            }

            if (failureData.length > 0) {
                setFailureStats(failureData[0].totalTransactions)
            } else {
                setFailureStats(0)
            }

        } else {
            setStats({ totalAmountAll: 0, totalTransactionsAll: 0 })
            setSuccessStats(0)
            setFailureStats(0)
        }

    };

    const getDataByDate = async (dates) => {
        if (dates !== undefined) {
            const fetchDateStats = await getTestPayoutDashboardDataByDate(dates);
            console.log(fetchDateStats)

            if (fetchDateStats.result.length > 0) {
                setDateStats(fetchDateStats.result[0]);

                const successData = await fetchDateStats.result[0].statuses.filter(data => data.status === 'Success');

                const failureData = await fetchDateStats.result[0].statuses.filter(data => data.status === 'Failed');

                if (successData.length > 0) {
                    setDateSuccessStats(successData[0].totalTransactions)
                }
                else {
                    setDateSuccessStats(0)
                }

                if (failureData.length > 0) {
                    setdateFailedStats(failureData[0].totalTransactions)
                } else {
                    setdateFailedStats(0)
                }

            } else {
                setDateStats({ totalAmountAll: 0, totalTransactionsAll: 0 })
                setDateSuccessStats(0)
                setdateFailedStats(0)
            }

        }
    }


    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        getDataByDate(date);
    }, [date]);

    return (
        <div className="rounded-md border">
            <div className="mx-3 my-3 grid grid-cols-4 gap-4 ">
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Last 10 days Transfer Amount
                            </CardTitle>
                            <ArrowRightLeft />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold"> ₹ {stats.totalAmountAll}</div>

                        </CardContent>
                    </Card>
                </motion.div>

                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <Card className="h-full">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Last 10 days total Transaction
                            </CardTitle>
                            <ArrowRightLeft />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{stats.totalTransactionsAll}</div>

                        </CardContent>
                    </Card>
                </motion.div>

                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <Card className="h-full">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Last 10 days Success Transaction
                            </CardTitle>
                            <ArrowRightLeft />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold"> {successStats}</div>

                        </CardContent>
                    </Card>
                </motion.div>
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <Card className="h-full">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Last 10 days Failed Transaction
                            </CardTitle>
                            <ArrowRightLeft />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{failureStats}</div>

                        </CardContent>
                    </Card>
                </motion.div>
            </div>

            <hr className="mx-2 my-3" />

            <div className=" pt-3 pb-16 px-3  gap-8">
                <div className="my-4 text-lg font-bold" >
                    <h5>Date Wise Stats</h5>
                </div>
                <div className="w-72">
                    <DateTimeRangeInput className getFunction={getDateRange} />
                </div>


                {dateStats && <div className=" my-3 grid grid-cols-4 gap-4 ">
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card>
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Last 10 days Transfer Amount
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold"> ₹ {dateStats.totalAmountAll}</div>

                            </CardContent>
                        </Card>
                    </motion.div>

                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card className="h-full">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Last 10 days total Transaction
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold">{dateStats.totalTransactionsAll}</div>

                            </CardContent>
                        </Card>
                    </motion.div>

                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card className="h-full">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Last 10 days Success Transaction
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold"> {dateSuccessStats}</div>

                            </CardContent>
                        </Card>
                    </motion.div>
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card className="h-full">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Last 10 days Failed Transaction
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold">{dateFailedStats}</div>

                            </CardContent>
                        </Card>
                    </motion.div>
                </div>}

            </div>



        </div>
    );
};

export default TestDashboard;
