import React, { useEffect, useState } from 'react';
import { getMerchantDocuments, uploadDocuments } from 'Apis/merchantapis';
import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { useToast } from "@/components/ui/use-toast";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"


const Documents = () => {
    const { toast } = useToast();
    const [imageBlob, setImageBlob] = useState(null);
    const [backendUrl, setBackendUrl] = useState("");
    const [merchantDocs, setMerchantDocs] = useState([]);
    const [imageWidth, setImageWidth] = useState(null);

    const getData = async () => {

        const fetchMerchants = await getMerchantDocuments();
        setMerchantDocs(fetchMerchants.documents);
    };

    useEffect(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}`;
        setBackendUrl(url)
        getData()
    }, [])


    const fetchImage = async (imageUrl) => {
        try {
            const response = await fetch(imageUrl);
            const blob = await response.blob();
            setImageBlob(blob);
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    }


    const submitFilesForm = async (field, file) => {
        let formData = new FormData();

        if (field === 'auth_pan') {
            formData.append("auth_pan", file);
        }
        else if (field === 'auth_aadhar') {
            formData.append("auth_aadhar", file);
        }
        else if (field === 'company_pan') {
            formData.append("company_pan", file);
        }
        else if (field === 'gstin') {
            formData.append("gstin", file);
        }
        else if (field === 'reg_certificate') {
            formData.append("reg_certificate", file);
        }
        else if (field === 'cancel_cheque') {
            formData.append("cancel_cheque", file);
        }
        else if (field === 'cin') {
            formData.append("cin", file);
        }
        else if (field === 'llpin') {
            formData.append("llpin", file);
        }
        else if (field === 'incllp') {
            formData.append("incllp", file);
        }
        else if (field === 'partnership_deed') {
            formData.append("partnership_deed", file);
        }
        else if (field === 'moaaoa') {
            formData.append("moaaoa", file);
        }
        else if (field === 'trust_deed') {
            formData.append("trust_deed", file);
        }

        const uploadRequest = await uploadDocuments(formData);
        if (uploadRequest.success === true) {
            toast({
                title: "Success",
                description: uploadRequest.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: uploadRequest.message,
            });
        }



        getData();

    };

    const handleImageLoad = (event) => {
        // Access the width of the image through the 'naturalWidth' property
        const width = event.target.naturalWidth;
        console.log(width);
        setImageWidth(width);
    };

    return (
        <div>
            <div className="grid grid-cols-4 gap-4">


                {merchantDocs.length > 0 && merchantDocs.map((doc) => (
                    <Card className="">
                        <CardHeader>
                            <CardTitle>{doc.doc_label}</CardTitle>
                            <CardDescription></CardDescription>
                        </CardHeader>
                        <CardContent>

                            <Dialog>
                                <DialogTrigger ><Button variant="outline">Open</Button></DialogTrigger>
                                <DialogContent className="max-w-full">
                                    <DialogHeader>
                                        <DialogTitle></DialogTitle>
                                        <DialogDescription>
                                            <div className='flex justify-center'>
                                                <img
                                                    src={`${backendUrl}/${doc.file_name}`}
                                                    alt="png"
                                                    width="600px"
                                                    height="200px"
                                                    onLoad={handleImageLoad}
                                                />
                                            </div>

                                        </DialogDescription>
                                    </DialogHeader>
                                </DialogContent>
                            </Dialog>

                            <hr className='my-2' />
                            <h5 className='text-xs font-bold mb-2'>Update Document</h5>
                            <Input type="file" onChange={(event) => {

                                submitFilesForm(doc.doc_name, event.target.files[0])
                            }} />

                        </CardContent>

                        <CardFooter className="">


                        </CardFooter>
                    </Card>
                ))}

            </div>
        </div>
    );
};

export default Documents;