import React from 'react';
import Profile from './Profile';
import { User2 } from 'lucide-react';
import Breadcrumb from 'LoadingComponents/Breadcrumb';
import LoginActivity from './LoginActivity';

const ProfilePage = () => {
    const BreadcrumbArray = [{ name: 'Profile', icon: <User2 /> }]
    return (
        <div className="rounded-md border mt-4">
            <Breadcrumb data={BreadcrumbArray} />
            <Profile/>
            <LoginActivity/>
        </div>
    );
};

export default ProfilePage;