import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Button } from "@/components/ui/button";
const ViewApiKeys = (prop) => {
  const [apikeys, setApiKeys] = useState({});

  useEffect(() => {
    setApiKeys(prop.apiKeys);
  }, [prop]);

  
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">View</Button>
      </DialogTrigger>
      <DialogContent
        className={
          "lg:max-w-screen-sm overflow-y-scroll max-h-screen hide-scrollbar"
        }
      >
        <DialogHeader>
          <DialogTitle>Api Keys</DialogTitle>
          <DialogDescription>
            Generated Api Keys for accepting payments .
          </DialogDescription>
        </DialogHeader>
        <div className="my-3">
          <div className="grid grid-cols-4  gap-4">
            <Label htmlFor="name" className="col-span-1 mt-3 text-center ">
              Key Id
            </Label>
            <Input
              id="name"
              value={apikeys.api_key}
              readOnly
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 mt-4  gap-4">
            <Label htmlFor="name" className="col-span-1 mt-3 text-center">
              Secret Key
            </Label>
            <Input
              id="name"
              value={apikeys.api_secret}
              readOnly
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 mt-4  gap-4">
            <Label htmlFor="name" className="col-span-1 mt-3 text-center">
              Request Hash Key
            </Label>
            <Input
              id="name"
              value={apikeys.request_hashkey}
              readOnly
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 mt-4  gap-4">
            <Label htmlFor="name" className="col-span-1 mt-3 text-center">
              Request Salt Key
            </Label>
            <Input
              id="name"
              value={apikeys.request_salt_key}
              readOnly
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 mt-4  gap-4">
            <Label htmlFor="name" className="col-span-1 mt-3 text-center">
              Response Hash Key
            </Label>
            <Input
              id="name"
              value={apikeys.response_hashkey}
              readOnly
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 mt-4  gap-4">
            <Label htmlFor="name" className="col-span-1 mt-3 text-center">
              Response Salt Key
            </Label>
            <Input
              id="name"
              value={apikeys.response_salt_key}
              readOnly
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 mt-4  gap-4">
            <Label htmlFor="name" className="col-span-1 mt-3 text-center">
              Aes Request Key
            </Label>
            <Input
              id="name"
              value={apikeys.encryption_request_key}
              readOnly
              className="col-span-3"
            />
          </div>

          <div className="grid grid-cols-4 mt-4  gap-4">
            <Label htmlFor="name" className="col-span-1 mt-3 text-center">
              Aes Response Key
            </Label>
            <Input
              id="name"
              value={apikeys.encryption_response_key}
              readOnly
              className="col-span-3"
            />
          </div>
        </div>
        <DialogFooter></DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ViewApiKeys;
