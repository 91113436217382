import React from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import Items from "./Items/Items";
import Customers from "./Customers/Customers";
import InvoicesModule from "./Invoices/Invoices";

const Invoices = () => {
  return (
    <div className="mt-6 px-6">
    <Tabs defaultValue="invoices" className="w-full">
      <TabsList className="grid w-full grid-cols-3">
        <TabsTrigger value="invoices">Invoices</TabsTrigger>
        <TabsTrigger value="items">Items</TabsTrigger>
        <TabsTrigger value="customers">Customers</TabsTrigger>
      </TabsList>
      <TabsContent value="invoices">
       <InvoicesModule/>
      </TabsContent>
      <TabsContent value="items">
       <Items/>
      </TabsContent>
      <TabsContent value="customers">
       <Customers/>
       </TabsContent>
      
    </Tabs>
  </div>
  );
};

export default Invoices;
