import React from 'react';
import { useParams } from 'react-router-dom';
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import MerchantPersoanlAndBusinessDetails from './Tabs/MerchantDetails/MerchantPersoanlAndBusinessDetails';
import UpdatePassword from './Tabs/UpdatePassword';
import MerchantWebhooks from './Tabs/Webhook/MerchantWebhooks';
import ApiKeys from './Tabs/ApiKeys/Apikeys';
import MerchantLoginActivity from './Tabs/MerchantLoginActivity';
import Breadcrumb from 'LoadingComponents/Breadcrumb';
import { Users, BadgeInfo } from "lucide-react";

const MerchantDetailsTabs = () => {
    const BreadcrumbArray = [{ name: 'Merchant', icon: <Users /> }, { name: 'Details', icon: <BadgeInfo /> }, { name: 'Merchant Details And Info', icon: '' }]
    const { id } = useParams();
    return (
        <div>
            <Breadcrumb data={BreadcrumbArray} />
            <div className='py-2'>
                <Link to="/merchant/merchants">   <Button>Back</Button></Link>
            </div>
            <Tabs defaultValue="details" className="w-full ">
                <TabsList className="grid overflow-x: auto grid-cols-5">
                    <TabsTrigger value="details">Merchant Details</TabsTrigger>
                    <TabsTrigger value="password">Merchant Change Password</TabsTrigger>
                    <TabsTrigger value="activity">Merchant Login Activity</TabsTrigger>
                    <TabsTrigger value="webhooks">Merhant Webhooks</TabsTrigger>
                    <TabsTrigger value="api">Merchant Api Keys</TabsTrigger>
                </TabsList>
                <TabsContent value="details">
                    <MerchantPersoanlAndBusinessDetails merchantId={id} />
                </TabsContent>
                <TabsContent value="password">
                    <UpdatePassword merchantId={id} />
                </TabsContent>
                <TabsContent value="activity">
                    <MerchantLoginActivity merchantId={id} />
                </TabsContent>
                <TabsContent value="webhooks">
                    <MerchantWebhooks merchantId={id} />
                </TabsContent>
                <TabsContent value="api">
                    <ApiKeys merchantId={id} />
                </TabsContent>
            </Tabs>
        </div>
    );
};

export default MerchantDetailsTabs;