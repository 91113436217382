import React from "react";
import PayoutApiKeys from "./ApiKeys/PayoutApiKeys";
import PayoutWebhooks from "./PayoutWebhook/PayoutWebhook";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import IpWhitelist from "./IpWhitelist/IpWhitelist";

const PayoutSettings = () => {
    return (
        <div className="mt-6 px-6">
            <Tabs defaultValue="apikeys" className="w-full">
                <TabsList className="grid w-full grid-cols-3">
                    <TabsTrigger value="apikeys">Api Keys</TabsTrigger>
                    <TabsTrigger value="webhook">Webhook</TabsTrigger>
                    <TabsTrigger value="ipwhitelist">Ip Whitelist</TabsTrigger>
                </TabsList>
                <TabsContent value="apikeys">
                    <  PayoutApiKeys />
                </TabsContent>
                <TabsContent value="webhook">
                    <PayoutWebhooks />
                </TabsContent>
                <TabsContent value="ipwhitelist">
                    <IpWhitelist />
                </TabsContent>

            </Tabs>
        </div>
    );
};

export default PayoutSettings;
