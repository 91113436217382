import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { ScrollArea } from "@//components/ui/scroll-area";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { LogOut, Users, IndianRupee, BadgeInfo, Gauge, User2, Settings, ShieldAlert, File, SquareStack, Vibrate, Settings2, Webhook } from "lucide-react";
import SmoothCollapse from "react-smooth-collapse";

export default function EmployeeSidebar() {
  const location = useLocation();
  const { pathname } = location;
  const [merchantMenuExpanded, setMerchantMenuExpanded] = useState(false);
  const [technicalMenuExpanded, setTechnicalMenuExpanded] = useState(false);
  const [riskMenuExpanded, setRiskMenuExpanded] = useState(false);
  const [payoutExpanded, setPayoutExpanded] = useState(false);

  const onClickMerchantMenu = () => {
    if (merchantMenuExpanded) {
      setMerchantMenuExpanded(false)
    }
    else {
      setMerchantMenuExpanded(true)
    }
  }

  const onClickTechnicalMenu = () => {
    if (technicalMenuExpanded) {
      setTechnicalMenuExpanded(false)
    }
    else {
      setTechnicalMenuExpanded(true)
    }
  }

  const onClickRiskMenu = () => {
    if (riskMenuExpanded) {
      setRiskMenuExpanded(false)
    }
    else {
      setRiskMenuExpanded(true)
    }
  }

  const onClickPayoutMenu = () => {
    if (payoutExpanded) {
      setPayoutExpanded(false)
    }
    else {
      setPayoutExpanded(true)
    }
  }


  return (
    <div className={cn("pb-12")}>
      <div className="space-y-4 py-4">
        <div className="px-3 py-2">
          <div className="space-y-1">
            <Link to="employee_dashboard">
              <Button
                variant={pathname === "/employee_dashboard" ? "secondary" : "ghost"}
                className=" my-1 w-full justify-start"
              >


                <Gauge className="mr-2 h-4 w-4" />
                Dashboard
              </Button>
            </Link>


            <Link to="chargeback">
              <Button
                variant={pathname === "/chargeback" ? "secondary" : "ghost"}
                className=" my-1 w-full justify-start"
              >
                <IndianRupee className="mr-2 h-4 w-4" />
                ChargeBack
              </Button>
            </Link>

            <Link to="reseller">
              <Button
                variant={pathname === "/reseller" ? "secondary" : "ghost"}
                className=" my-1 w-full justify-start"
              >
                <User2 className="mr-2 h-4 w-4" />
                Reseller
              </Button>
            </Link>

            <Button
              variant={"ghost"}
              className="my-1 w-full flex  justify-between"
              onClick={onClickMerchantMenu}
            >
              <div className="flex">
                <Users className="mr-2 h-4 w-4" />
                Merchants
              </div>
              <div className="col-span-2">
                <svg xmlns="http://www.w3.org/2000/svg" className={`${merchantMenuExpanded && 'rotate-90'} `} width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="m9 18 6-6-6-6" /></svg>
              </div>
            </Button>
            <SmoothCollapse expanded={merchantMenuExpanded} >
              {merchantMenuExpanded &&
                <div>
                  <Link to="/merchant/merchant_transactions">
                    <Button
                      variant={pathname === "/merchant/merchant_transactions" ? "secondary" : "ghost"}
                      className="my-1 w-full justify-start"
                    >
                      <IndianRupee className="mr-2 h-4 w-4" />
                      Transactions Info
                    </Button>
                  </Link>

                  <Link to="/merchant/merchants">
                    <Button
                      variant={pathname === "/merchant/merchants" ? "secondary" : "ghost"}
                      className="my-1 w-full justify-start"
                    >
                      <BadgeInfo className="mr-2 h-4 w-4" />
                      Details
                    </Button>
                  </Link>

                  <Link to="/merchant/notifications">
                    <Button
                      variant={pathname === "/merchant/notifications" ? "secondary" : "ghost"}
                      className="my-1 w-full justify-start"
                    >
                      <Vibrate className="mr-2 h-4 w-4" />
                      Notifications
                    </Button>
                  </Link>
                </div>
              }
            </SmoothCollapse>

            <Button
              variant={"ghost"}
              className="my-1 w-full flex  justify-between"
              onClick={onClickTechnicalMenu}
            >
              <div className="flex">
                <SquareStack className="mr-2 h-4 w-4" />
                Technical
              </div>
              <div className="col-span-2">
                <svg xmlns="http://www.w3.org/2000/svg" className={`${technicalMenuExpanded && 'rotate-90'} `} width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="m9 18 6-6-6-6" /></svg>
              </div>




            </Button>
            <SmoothCollapse expanded={technicalMenuExpanded} >
              {technicalMenuExpanded && <div>
                <Link to="/merchant/charge_details">
                  <Button
                    variant={pathname === "/merchant/charge_details" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <Settings2 className="mr-2 h-4 w-4" />
                    Merchant Settings
                  </Button>
                </Link>


                <Link to="/technical/transactions">
                  <Button
                    variant={pathname === "/technical/transactions" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <IndianRupee className="mr-2 h-4 w-4" />
                    Transactions
                  </Button>
                </Link>

                <Link to="/technical/webhook_responses">
                  <Button
                    variant={pathname === "/technical/webhook_responses" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <Webhook className="mr-2 h-4 w-4" />
                    Webhooks
                  </Button>
                </Link>

              </div>}
            </SmoothCollapse>


            <Button
              variant={"ghost"}
              className="my-1 w-full flex  justify-between"
              onClick={onClickPayoutMenu}
            >
              <div className="flex">
                <SquareStack className="mr-2 h-4 w-4" />
                Payout
              </div>
              <div className="col-span-2">
                <svg xmlns="http://www.w3.org/2000/svg" className={`${payoutExpanded && 'rotate-90'} `} width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="m9 18 6-6-6-6" /></svg>
              </div>
            </Button>
            <SmoothCollapse expanded={payoutExpanded} >
              {payoutExpanded && <div>
                <Link to="/payout/settings">
                  <Button
                    variant={pathname === "/payout/settings" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <Settings2 className="mr-2 h-4 w-4" />
                    Payout Settings
                  </Button>
                </Link>

                <Link to="/payout/balance">
                  <Button
                    variant={pathname === "/payout/balance" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <IndianRupee className="mr-2 h-4 w-4" />
                    Balance
                  </Button>
                </Link>

                <Link to="/payout/funds">
                  <Button
                    variant={pathname === "/payout/funds" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <IndianRupee className="mr-2 h-4 w-4" />
                    Funds
                  </Button>
                </Link>


                <Link to="/payout/reports">
                  <Button
                    variant={pathname === "/payout/reports" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <IndianRupee className="mr-2 h-4 w-4" />
                    Reports
                  </Button>
                </Link>



              </div>}
            </SmoothCollapse>


            <Button
              variant={"ghost"}
              className="my-1 w-full flex  justify-between"
              onClick={onClickRiskMenu}
            >
              <div className="flex">
                <ShieldAlert className="mr-2 h-4 w-4" />
                R & C
              </div>


              <div className="">
                <svg xmlns="http://www.w3.org/2000/svg" className={`${riskMenuExpanded && 'rotate-90'} `} width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><path d="m9 18 6-6-6-6" /></svg>
              </div>




            </Button>
            <SmoothCollapse expanded={riskMenuExpanded} >
              {riskMenuExpanded && <div>
                <Link to="/riskcompliance/documentverification">
                  <Button
                    variant={pathname === "/riskcompliance/documentverification" ? "secondary" : "ghost"}
                    className="my-1 w-full justify-start"
                  >
                    <File className="mr-2 h-4 w-4" />
                    Doc Verification
                  </Button>
                </Link>



              </div>}
            </SmoothCollapse>

            <Link to="/app_variables">
              <Button
                variant={pathname === "/app_variables" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >



                <Settings className="mr-2 h-4 w-4" />
                Settings
              </Button>
            </Link>


            <Link to="/profile">
              <Button
                variant={pathname === "/profile" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >



                <User2 className="mr-2 h-4 w-4" />
                Profile
              </Button>
            </Link>



            <Link to="employee_logout">
              <Button
                variant={pathname === "/employee_logout" ? "secondary" : "ghost"}
                className="my-1 w-full justify-start"
              >
                <LogOut className="mr-2 h-4 w-4" />
                Logout
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
