import React from 'react';
import RootLayout from 'Layout';
import { Toaster } from "@/components/ui/toaster";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ResellerLoginPage from 'Reseller/Auth/ResellerLogin';
import ResellerMiddleware from 'Reseller/Auth/Middleware/ResellerMiddleware';
import ResellerLayout from 'Reseller/Layout/ResellerLayout';
import ResellerPayinDashboard from 'Reseller/Pages/Payin/ResellerPayinDashboard';
import ResellerPayinTransactions from 'Reseller/Pages/Payin/ResellerPayinTransactions';
import ResellerPayoutDashboard from 'Reseller/Pages/Payout/ResellerPayoutDashboard';
import ResellerPayoutTransactions from 'Reseller/Pages/Payout/ResellerPayoutTransactions';
import ResellerPayoutStatement from 'Reseller/Pages/Payout/ResellerPayoutStatement';
import ResellerLogout from 'Reseller/Auth/ResellerLogout';

const ResellerRoutes = () => {
    return (

        <Routes>
            {/* Admin routes */}
            <Route element={<RootLayout />}>
                <Route path="/reseller/login" element={<ResellerLoginPage />} />
            </Route>

            <Route element={<ResellerMiddleware children={undefined} />}>



                <Route element={<ResellerLayout />}>

                    <Route path="/reseller/payin/dashboard" element={<ResellerPayinDashboard />} />
                    <Route path="/reseller/payin/transactions" element={<ResellerPayinTransactions />} />


                    <Route path="/reseller/payout/dashboard" element={<ResellerPayoutDashboard />} />
                    <Route path="/reseller/payout/transactions" element={<ResellerPayoutTransactions />} />
                    <Route path="/reseller/payout/statements" element={<ResellerPayoutStatement />} />




                    <Route path="/reseller/logout" element={<ResellerLogout />} />


                </Route>
            </Route>
        </Routes>

    );
};

export default ResellerRoutes;