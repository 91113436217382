import React from "react";
import PersonalDetails from "./PersonalDetails/PersonalDetails";
import ChangePassword from "./ChangePassword/ChangePassword";
import MerchantNotifications from "./Notifications/Notifications";
import Documents from "./Documents/Documents";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Button } from "@/components/ui/button";

const MerchantAccount = () => {
  return (
    <div className="mt-6 px-6">
      <Tabs defaultValue="personaldetails" className="w-full">
        <TabsList className="grid w-full grid-cols-4">
          <TabsTrigger value="personaldetails">Personal Details</TabsTrigger>
          <TabsTrigger value="documents">Documents</TabsTrigger>
        <TabsTrigger value="changepassword">Change Password</TabsTrigger>
        <TabsTrigger value="merchantnotifications">Notifications</TabsTrigger>
      </TabsList>
      <TabsContent value="personaldetails">
        <PersonalDetails />
      </TabsContent>
      <TabsContent value="documents">
        <Documents />
      </TabsContent>
      <TabsContent value="changepassword">
        <ChangePassword />
      </TabsContent>
      <TabsContent value="merchantnotifications">
        <MerchantNotifications />
      </TabsContent>
    </Tabs>
    </div >
  );
};

export default MerchantAccount;
