import React, { useState, useEffect } from 'react';
import { getMerchantPersonalAndBusinessDetails, getMerchantDocuments } from 'Apis/employeeapi';
import { motion } from "framer-motion"
import { Building2, WalletCards, Landmark } from 'lucide-react';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import EditPersoanlDetails from './EditPersonalDetails';
import EditBusinessDetails from './EditBusinessDetails';
import MerchantDocuments from './MerchantDocuments';

const MerchantPersoanlAndBusinessDetails = (props) => {

    const [merchantData, setMerchantData] = useState("");
    const [businessDetails, setbusinessData] = useState(null);
    const [documentsData, setdocumentsData] = useState(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [merchantId, setMerchantId] = useState(props.merchantId);

    const getData = async () => {
        setTableLoading(true)
        const fetchMerchantData = await getMerchantPersonalAndBusinessDetails(merchantId);
        setMerchantData(fetchMerchantData.personaldetails);
        setbusinessData(fetchMerchantData.businessdetails);
        setdocumentsData(fetchMerchantData.documents);
        setTableLoading(false)

    };

    const refreshData = () => {
        getData(merchantId);
    }

    useEffect(() => {
        getData();

    }, [merchantId]);

    return (
        <div>
            <div className="">

                <div className='mt-6'>


                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card>
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-lg font-medium">
                                    Personal Details
                                </CardTitle>
                                <EditPersoanlDetails refreshData={getData} personaldetails={merchantData} />
                            </CardHeader>
                            <CardContent>
                                <div className="grid grid-cols-1 sm:grid-cols-5">


                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold'>Merchant Id:</h5>
                                        <h5 className='text-md font-bold'> {merchantData.merchant_gid}</h5>
                                    </div>

                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold' >Name:</h5>
                                        <h5 className='text-md font-bold'>{merchantData.name}</h5>
                                    </div>

                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold'>Email:</h5>
                                        <h5 className='text-md font-bold'>{merchantData.email}</h5>
                                    </div>

                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold'>Mobile:</h5>
                                        <h5 className='text-md font-bold'>{merchantData.mobile_no}</h5>
                                    </div>

                                    <div className='mt-3'>
                                        <h5 className='text-xs font-bold'>Transaction Limit:</h5>
                                        <h5 className='text-md font-bold'>{merchantData.transaction_limit}</h5>
                                    </div>
                                </div>
                            </CardContent>
                        </Card>
                    </motion.div>
                </div>
                {businessDetails !== null &&
                    <div className='mt-6'>
                        <motion.div
                            initial={{ scale: 0 }}
                            animate={{ rotate: 0, scale: 1 }}
                            transition={{
                                type: "spring",
                                stiffness: 260,
                                damping: 20
                            }}
                        >
                            <Card>
                                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                    <CardTitle className="text-lg font-medium">
                                        Business Details
                                    </CardTitle>
                                    <EditBusinessDetails refreshData={getData} businessdetails={businessDetails} />
                                </CardHeader>
                                <CardContent>
                                    <div className='grid grid-cols-3'>


                                        <div>
                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Business Type:</h5>
                                                <h5 className='text-md font-bold'> {businessDetails.business_type_id.type_name}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold' >Business Category:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.business_category_id.category_name}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>WebApp/Url:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.webapp_url}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Bank Name:</h5>
                                                <h5 className='text-md font-bold'> {businessDetails.bank_name}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold' >Account Number:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.bank_acc_no}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Ifsc Code :</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.bank_ifsc_code}</h5>
                                            </div>
                                        </div>

                                        <div>
                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Business Name:</h5>
                                                <h5 className='text-md font-bold'> {businessDetails.business_name}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold' >Company PAN Number:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.comp_pan_number}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Company GST No:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.comp_gst}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Authorized Signatory PAN No:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.mer_pan_number}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Authorized Signatory Aadhar No:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.mer_aadhar_number}</h5>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Authorized Signatory Name:</h5>
                                                <h5 className='text-md font-bold'> {businessDetails.mer_name}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold' >Address:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.address}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>Pincode:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.pincode}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>City:</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.city}</h5>
                                            </div>

                                            <div className='mt-3'>
                                                <h5 className='text-xs font-bold'>State</h5>
                                                <h5 className='text-md font-bold'>{businessDetails.state.state_name}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </motion.div>


                    </div>}

                <div className='my-4'>
                    {documentsData !== null && <MerchantDocuments className="" documents={documentsData} />}
                </div>



            </div>
        </div>
    );
};

export default MerchantPersoanlAndBusinessDetails;