import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ResellerLogout = () => {
    let navigate = useNavigate();
    localStorage.removeItem("reseller");

    useEffect(() => {
        navigate("/reseller/login");
    }, []);

    return <div></div>;
};

export default ResellerLogout;