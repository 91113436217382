import React from "react";

export const EmployeeLoginRequest = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/admin_login`,
        {
            method: "POST",
            headers: { "content-Type": "application/json" },
            body: JSON.stringify({
                email: data.email,
                password: data.password,
            }),
        }
    );

    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const saveEmployeeLoginActivity = async () => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/employee_login_activity_save`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            }
        }
    );

    const returnResponse = await formResponse.json();
    return returnResponse;
};


export const GenerateOtpRequest = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/generate_otp`,
        {
            method: "POST",
            headers: { "content-Type": "application/json" },
            body: JSON.stringify({
                email: data.email
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const VerifyOtpRequest = async (email, otp) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/verify_otp`,
        {
            method: "POST",
            headers: { "content-Type": "application/json" },
            body: JSON.stringify({
                email: email,
                otp: otp
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getEmployeeDashboardData = async (dates, userId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/employee_dashboard_data`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                userId: userId
            }),
        }
    );

    const returnResponse = await formResponse.json();

    return returnResponse;
};

export const getMerchantList = async (dates, userId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_merchants`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            }
        }
    );

    const returnResponse = await formResponse.json();

    return returnResponse;
};

export const getMerchantTotalNumberofTransactions = async (dates, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_total_number_of_transactions`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantTotalAmountofTransactions = async (dates, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_total_amount_of_transactions`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantsWithSearch = async (skip, limit, search) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_merchants_with_search`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                search: search,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const updateMerchantStatus = async (merchantId, status) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/update_merchant_status`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                status: status
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantPersonalAndBusinessDetails = async (merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_merchant_personal_business_details`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantLoginActivity = async (merchantId, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_merchant_login_activity`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const updateMerchantPassword = async (merchantId, data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/update_merchant_password`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                password: data.password
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getWebhook = async (merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_merchant_webhook`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const setWebhook = async (merchantId, data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/set_merchant_webhook`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                webhook_url: data.webhook_url,
                is_active: data.is_active,
                secret_key: data.secret_key,
                payment_failed: data.payment_failed,
                payment_captured: data.payment_captured,
                transfer_processed: data.transfer_processed,
                refund_processed: data.refund_processed,
                refund_created: data.refund_created,
                refund_speed_changed: data.refund_speed_changed,
                order_paid: data.order_paid,
                dispute_created: data.dispute_created,
                dispute_won: data.dispute_won,
                dispute_lost: data.dispute_lost,
                dispute_closed: data.dispute_closed,
                settlement_processed: data.settlement_processed,
                invoice_paid: data.invoice_paid,
                invoice_partially_paid: data.invoice_partially_paid,
                invoice_expired: data.invoice_expired,
                paylink_paid: data.paylink_paid,
                paylink_partially_paid: data.paylink_partially_paid,
                paylink_expired: data.paylink_expired,
                paylink_cancelled: data.paylink_cancelled,
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantApiKeys = async (merchantId, data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_merchant_api_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const generateMerchantApiKeys = async (merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/generate_merchant_api_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getStates = async () => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_states`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            }
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getBusinessCategories = async () => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_business_category`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            }
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getBusinessTypes = async () => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_business_type`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            }
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getBusinessExpenditures = async () => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_business_expenditure`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            }
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const updateMerchantPersonalDetails = async (merchantId, data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/update_merchant_personal_details`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                name: data.name,
                email: data.email,
                mobile_no: data.mobile_no,
                transaction_limit: data.transaction_limit
            }),
        }
    );

    const returnResponse = await formResponse.json();

    return returnResponse;
};

export const UpdateMerchantBusiness = async (merchantId, data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/update_merchant_business_details`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                business_type: data.business_type,
                monthly_expenditure: data.monthly_expenditure,
                business_category: data.business_category,
                webapp_url: data.webapp_url,
                bank_name: data.bank_name,
                bank_acc_no: data.bank_acc_no,
                bank_ifsc_code: data.bank_ifsc_code,
                business_logo: data.business_logo,
                company_name: data.company_name,
                pin_number: data.pincode,
                comp_pan_number: data.company_pan,
                comp_gst: data.company_gst,
                mer_pan_number: data.auth_sign_pan_no,
                mer_aadhar_number: data.auth_sign_aadhar_no,
                mer_name: data.auth_sign_name,
                company_address: data.company_address,
                pincode: data.pincode,
                city: data.city,
                state: data.state,
                country: data.country,
            }),
        }
    );

    const returnResponse = await formResponse.json();

    return returnResponse;
};

export const getMerchantDocuments = async (merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_merchant_documents`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId
            }),
        }
    )
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantTransactions = async (skip, limit, search, dates, merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_transactions`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit,
                search: search,
                startDate: dates.from,
                endDate: dates.to,
                merchantId: merchantId
            }),
        }
    )
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const exportTransactions = async (dates, merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/export/export_payments_employee`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                merchantId: merchantId
            }),
        }
    );
    return formResponse;
};

export const getEmployeeDetails = async () => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_employee_details`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            }
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const updateEmployeeDetails = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/update_employee_details`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                username: data.username,
                first_name: data.first_name,
                last_name: data.last_name,
                designation: data.designation,
                mobile: data.mobile_no,
                email: data.official_email
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const updateEmployeePassword = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/update_employee_password`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                password: data.password
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getEmployeeLoginActivity = async (skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_employee_login_activity`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantNotifications = async (merchantId, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_notifications`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};


export const sendNotificationsToMerchant = async (values) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/send_notifications`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify(values),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const sendNotificationsToUploadDocument = async (document, merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/send_notification_to_upload_document`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                document: document,
                merchantId: merchantId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const addMerchantChargeDetailRequest = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/add_merchant_charge_detail`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                dc_visa: data.dc_visa,
                dc_master: data.dc_master,
                dc_rupay: data.dc_rupay,
                cc_visa: data.cc_visa,
                cc_master: data.cc_master,
                cc_rupay: data.cc_rupay,
                amex: data.amex,
                upi: data.upi,
                wallet: data.wallet,
                dap: data.dap,
                qrcode: data.qrcode,
                net_sbi: data.net_sbi,
                net_hdfc: data.net_hdfc,
                net_axis: data.net_axis,
                net_icici: data.net_icici,
                net_yes_kotak: data.net_yes_kotak,
                net_others: data.net_others,
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantChargeDetails = async (merchantId, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/list_merchant_charge_detail`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const editMerchantChargeDetailRequest = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/edit_merchant_charge_detail`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                dc_visa: data.dc_visa,
                dc_master: data.dc_master,
                dc_rupay: data.dc_rupay,
                cc_visa: data.cc_visa,
                cc_master: data.cc_master,
                cc_rupay: data.cc_rupay,
                amex: data.amex,
                upi: data.upi,
                wallet: data.wallet,
                dap: data.dap,
                qrcode: data.qrcode,
                net_sbi: data.net_sbi,
                net_hdfc: data.net_hdfc,
                net_axis: data.net_axis,
                net_icici: data.net_icici,
                net_yes_kotak: data.net_yes_kotak,
                net_others: data.net_others,
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const listMerchantVendorBanks = async () => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/list_vendor_banks`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            }
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const addMerchantGatewayRoute = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/add_merchant_gateway_route`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                cc_card: data.cc_card,
                dc_card: data.dc_card,
                net: data.net,
                upi: data.upi,
                qrcode: data.qrcode,
                wallet: data.wallet,
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const editMerchantGatewayRoute = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/edit_merchant_gateway_route`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                cc_card: data.cc_card,
                dc_card: data.dc_card,
                net: data.net,
                upi: data.upi,
                qrcode: data.qrcode,
                wallet: data.wallet,
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantGatewayRoutes = async (merchantId, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/list_merchant_gateway_routes`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getAtomKeys = async (merchantId, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/list_atom_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const deleteAtomKeys = async (merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/delete_atom_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const addAtomKeys = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/add_atom_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                userid: data.userid,
                hash_request_key: data.hash_request_key,
                hash_response_key: data.hash_response_key,
                aes_request_key: data.aes_request_key,
                aes_request_salt_iv_key: data.aes_request_salt_iv_key,
                aes_response_key: data.aes_response_key,
                aes_response_salt_iv_key: data.aes_response_salt_iv_key,
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getCashfreeKeys = async (merchantId, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/list_cashfree_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const deleteCashfreeKeys = async (merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/delete_cashfree_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const addCashfreeKeys = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/add_cashfree_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                app_id: data.app_id,
                secret_key: data.secret_key
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getTimePayKeys = async (merchantId, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/list_timepay_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const deleteTimePayKeys = async (merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/delete_timepay_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const addTimePayKeys = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/add_timepay_keys`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                vpa: data.vpa,
                terminal_id: data.terminal_id,
                submerchant_id: data.submerchant_id,
                merchant_id: data.merchant_id,
                checksum_key: data.checksum_key,
                encryption_key: data.encryption_key,
                header_key: data.header_key,
                mcc: data.mcc,
                ext_transaction_id: data.ext_transaction_id
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const addVendorCharges = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/add_vendor_charges`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                vendor_id: data.vendor_id,
                upi_charges: data.upi_charges
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const editVendorCharges = async (id, data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/edit_vendor_charges`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                id: id,
                upi_charges: data.upi_charges
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const deleteVendorCharges = async (id) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/delete_vendor_charges`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                id: id
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const listVendorCharges = async (skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/list_vendor_charges`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};


export const getRncDocsMerchantStatus = async (merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_merchant_rnc_documents_status`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchant_id: merchantId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const listMerchantWithCompany = async (skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_merchant_with_company`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const updateMerchantRncStatus = async (id, status) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/update_merchant_rnc_status`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                id: id,
                status: status
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const updateMerchantDocumentStatus = async (id, status) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/update_merchant_document_status`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                id: id,
                status: status
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};


export const updateMerchantDocumentVerificationStatus = async (id, status) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/update_merchant_document_verification_status`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                id: id,
                status: status
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const listCdrModes = async (skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/list_cdr_modes`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            }
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const storeCdr = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/add_cdr`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify(data),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const importCdr = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/import_cdr`,
        {
            method: "POST",
            headers: {
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: data,
        }
    )
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const downloadSampleCdr = async () => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/export/download_sample_cdr`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
        }
    );
    return formResponse;
};

export const editCdr = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/edit_cdr`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify(data),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const listCdr = async (skip, limit, search, dates) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_cdr`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit,
                search: search,
                startDate: dates.from,
                endDate: dates.to,
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const setAppVariables = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/set_app_variables`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify(data),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const setBrandLogo = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/set_brand_logo`,
        {
            method: "POST",
            headers: {
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: data,
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getAppVariables = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_app_variables`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};


export const getTransactionInfoViaId = async (id) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_transaction_info`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                id: id
            }),
        }
    );
    const returnResponse = await formResponse.json();

    return returnResponse;
};

export const getTimepayWebhooks = async (skip, limit, search, dates) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_timepay_responses`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit,
                startDate: dates.from,
                endDate: dates.to,
                search: search
            }),
        }
    )
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const sendWebhook = async (id) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/send_webhook`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                id: id
            }),
        }
    )
    const returnResponse = await formResponse.json();
    return returnResponse;
};


export const addPayoutChargeDetailRequest = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/add_payout_charges`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                imps: data.imps,
                neft: data.neft,
                rtgs: data.rtgs,
                upi: data.upi,
                gst: data.gst,
                flat: data.flat,
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayoutChargeDetails = async (merchantId, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_payout_charges`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const editPayoutChargeDetailRequest = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/edit_payout_charges`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                imps: data.imps,
                neft: data.neft,
                rtgs: data.rtgs,
                upi: data.upi,
                gst: data.gst,
                flat: data.flat,
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayoutVendorBankList = async (merchantId, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/list_payout_vendor_banks`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const addPayoutRoute = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/add_payout_route`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                imps: data.imps,
                neft: data.neft,
                rtgs: data.rtgs,
                upi: data.upi
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayoutRoute = async (merchantId, skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_payout_route`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const editPayoutRoute = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/edit_payout_route`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                imps: data.imps,
                neft: data.neft,
                rtgs: data.rtgs,
                upi: data.upi
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayoutTransactionReport = async (merchantId, skip, limit, dates) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_payout_payments`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit,
                startDate: dates.from,
                endDate: dates.to
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayoutStatementReport = async (merchantId, skip, limit, dates, status) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_payout_statements`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit,
                startDate: dates.from,
                endDate: dates.to,
                type: status
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantsWalletBalance = async (skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_all_merchant_wallet_balance`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantBalanceSummary = async (merchantId, skip, limit, dates) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/list_fund`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: merchantId,
                skip: skip,
                limit: limit,
                startDate: dates.from,
                endDate: dates.to,
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const addFund = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/add_fund`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                merchantId: data.merchantId,
                fund_added: data.fund_added,
                remark: data.remark,
                is_debit: data.is_debit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const internalFundTransfer = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/internal_fund_transfer`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                senderMerchantId: data.senderMerchantId,
                amount: data.amount,
                beneficiaryMerchantId: data.beneficiaryMerchantId,
                remarks: data.remarks
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const exportMerchantPayoutTransactions = async (dates, merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/export_payout_transactions`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                merchantId: merchantId
            }),
        }
    );

    return formResponse;
};

export const exportMerchantPayoutStatement = async (dates, type, merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/export_payout_statement`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                type: type,
                merchantId: merchantId
            }),
        }
    );

    return formResponse;
};


export const createReseller = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/create_reseller`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                name: data.name,
                email: data.email,
                mobile: data.mobile,
                password: data.password
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const allocateMerchantToReseller = async (reseller_id, merchant_id) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/allocate_merchant_to_reseller`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                reseller_id: reseller_id,
                merchant_id: merchant_id
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getResellers = async (skip, limit) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_resellers`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getMerchantAllocatedToReseller = async (reseller_id) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/employee/get_allocated_merchants`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("employee")).token,
            },
            body: JSON.stringify({
                reseller_id: reseller_id
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};







