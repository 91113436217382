import React, { useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import moment from "moment";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { editTestPaylink } from "Apis/merchantapis";
import { Switch } from "@/components/ui/switch";

const EditTestPaylink = (prop) => {
    const [open, setOpen] = useState(false);

    const [paylink, setPaylink] = useState(prop.paylink);

    const [elementId, setelementId] = useState(paylink._id);
    const [emailChecked, setEmailChecked] = useState(paylink.email_paylink);
    const [partialPaymentChecked, setPartialPaymentChecked] = useState(
        paylink.paylink_partial
    );
    const [autoReminderChecked, setAutoReminder] = useState(
        paylink.paylink_auto_reminder
    );

    const expiryDate = moment(paylink.paylink_expiry).format("YYYY-MM-DD");

    const initialValues = {
        paylink_for: paylink.paylink_for,
        paylink_amount: paylink.paylink_amount,
        paylink_customer_email: paylink.paylink_customer_email,
        email_paylink: false,
        paylink_customer_mobile: paylink.paylink_customer_mobile,
        paylink_receipt: paylink.paylink_receipt,
        paylink_expiry: expiryDate !== "Invalid date" ? expiryDate : "",
        paylink_notes: paylink.paylink_notes,
        paylink_partial: false,
        paylink_auto_reminder: false,
    };

    const validationSchema = Yup.object().shape({
        paylink_for: Yup.string().required("Paylink For is required"),
        paylink_amount: Yup.number().required("Paylink Amount is required"),
    });

    const onSubmit = async (values) => {
        // Handle form submission

        values.email_paylink = emailChecked;
        values.paylink_partial = partialPaymentChecked;
        values.paylink_auto_reminder = autoReminderChecked;

        setOpen(false);
        setPaylink(values);

        const sendRequest = await editTestPaylink(elementId, values);
        prop.getPaylinksFunction();
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Edit</Button>
            </DialogTrigger>
            <DialogContent
                className={
                    "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                }
            >
                <DialogHeader>
                    <DialogTitle>Edit Paylink</DialogTitle>
                    <DialogDescription>
                        You can create your own payment link and share with clients and get
                        payments easily.
                    </DialogDescription>
                </DialogHeader>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                    }) => (
                        <Form>
                            <div className="grid grid-cols-1 md:grid-cols-3 pt-3 pb-16 px-3 gap-2 gap-y-4 ">
                                <div>
                                    <Label htmlFor="paylink_for">Paylink For:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="paylink_for"
                                        name="paylink_for"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.paylink_for}
                                    />
                                    <ErrorMessage
                                        name="paylink_for"
                                        className="text-red-500 text-xs  my-2"
                                        component="div"
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="paylink_amount">Paylink Amount:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="paylink_amount"
                                        name="paylink_amount"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.paylink_amount}
                                    />
                                    <ErrorMessage
                                        name="paylink_amount"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>

                                <div>
                                    <Label htmlFor="paylink_customer_email">
                                        Customer Email:
                                    </Label>
                                    <Input
                                        className="mt-3"
                                        type="email"
                                        id="paylink_customer_email"
                                        name="paylink_customer_email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.paylink_customer_email}
                                    />
                                    {/* Add validation error display */}
                                </div>

                                <div>
                                    <Label htmlFor="paylink_customer_mobile">
                                        Customer Mobile:
                                    </Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="paylink_customer_mobile"
                                        name="paylink_customer_mobile"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.paylink_customer_mobile}
                                    />
                                    {/* Add validation error display */}
                                </div>

                                <div>
                                    <Label htmlFor="paylink_receipt"> Receipt No:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="paylink_receipt"
                                        name="paylink_receipt"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.paylink_receipt}
                                    />
                                    {/* Add validation error display */}
                                </div>
                                <div>
                                    <Label htmlFor="paylink_expiry"> Expiry Date:</Label>
                                    <Input
                                        className="mt-3"
                                        type="date"
                                        id="paylink_expiry"
                                        name="paylink_expiry"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={
                                            values.paylink_expiry
                                        }
                                    />
                                </div>

                                <div>
                                    <Label htmlFor="paylink_notes"> Notes:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="paylink_notes"
                                        name="paylink_notes"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.paylink_notes}
                                    />
                                    {/* Add validation error display */}
                                </div>
                            </div>

                            <hr />
                            <div className="grid grid-cols-1 md:grid-cols-3 pt-3 pb-16 px-3 gap-2 gap-y-4">
                                <div>
                                    <div className="flex items-center space-x-2">
                                        <Label htmlFor="email_paylink">Send Email:</Label>
                                        <Switch
                                            checked={emailChecked}
                                            onCheckedChange={setEmailChecked}
                                        />
                                    </div>

                                    <div className="flex items-center space-x-2"></div>
                                    {/* Add validation error display */}
                                </div>
                                <div>
                                    <div className="flex items-center space-x-2">
                                        <Label htmlFor="partial_payment "> Partial Payment:</Label>
                                        <Switch
                                            checked={partialPaymentChecked}
                                            onCheckedChange={setPartialPaymentChecked}
                                        />
                                    </div>
                                </div>

                                <div>
                                    <div className="flex items-center space-x-2">
                                        <Label htmlFor="auto_reminder">Auto Reminder:</Label>
                                        <Switch
                                            checked={autoReminderChecked}
                                            onCheckedChange={setAutoReminder}
                                        />
                                    </div>
                                    {/* Add validation error display */}
                                </div>
                            </div>
                            <div></div>

                            <DialogFooter>
                                <Button type="submit">Submit</Button>
                            </DialogFooter>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default EditTestPaylink;
