import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { Input } from "@/components/ui/input";
import { getResellers } from "Apis/employeeapi";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import TableLoading from "LoadingComponents/TableLoading";
import Breadcrumb from "LoadingComponents/Breadcrumb";
import { User2Icon, } from "lucide-react";
import AddReseller from "./AddReseller";
import ViewAndAddMerchants from "./ViewAndAddMerchants";

const ResellerHome = () => {
    const BreadcrumbArray = [{ name: 'Reseller', icon: <User2Icon /> }]
    const [open, setOpen] = useState(false);

    const [paymentIdQ, setpaymentIdQ] = useState("");

    const [viewTable, setViewTable] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [transactions, setTransactions] = useState([]);

    const getData = async (skip, limit) => {
        setTableLoading(true)

        const fetchTransactions = await getResellers(
            skip,
            limit
        );
        setTransactions(fetchTransactions.data);
        setCount(fetchTransactions.count);
        setTableLoading(false)

    };

    const fetchdata = (limit, skip) => {
        console.log(limit, skip);
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit);
    };

    const refreshTable = () => {
        getData(skip, limit);
    }


    useEffect(() => {
        getData(skip, limit)
    }, [])




    return (
        <div className="rounded-md border mt-4">
            <Breadcrumb data={BreadcrumbArray} />


            <div className="grid grid-cols-1 md:grid-cols-5  pb-2 px-2 gap-8">
                <AddReseller refreshFunction={refreshTable} />
            </div>

            <div>
                <Table>
                    <TableCaption>A list of your recent payments.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead >Sno.</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead>Mobile</TableHead>
                            <TableHead >Created At</TableHead>

                            <TableHead>Action</TableHead>
                        </TableRow>
                    </TableHeader>

                    {tableLoading === false ?
                        <TableBody>
                            {transactions.length > 0 &&
                                transactions.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.mobile}</TableCell>
                                        <TableCell>{item.email}</TableCell>



                                        <TableCell>
                                            {moment(item.created_at).format("YYYY-MM-DD")}
                                        </TableCell>
                                        <TableCell>
                                            <ViewAndAddMerchants resellerId={item._id} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                        : <TableLoading count={11} />

                    }
                </Table>

                <div className="flex justify-center my-3">
                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div></div>



        </div>
    );
};

export default ResellerHome;
