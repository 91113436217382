import React, { useState, useEffect } from "react";
import { useTheme } from 'next-themes'
import MerchantActivation from './MerchantActivation';
import Modal from 'react-modal';
import { getMerchantPersonalDetails } from "Apis/merchantapis";
import { Button } from "@/components/ui/button";



const MerchantActivationModal = (props) => {
    const { theme, setTheme } = useTheme()
    const [modalBackgroundColor, setModalBackgroundColor] = useState('red')
    const [merchantShowModal, setMerchantShowModal] = useState(false);

    const getData = async () => {
        const data = await getMerchantPersonalDetails();

        if (props.testMode === true) {
            setMerchantShowModal(false);
        }
        else {
            if (data.data.company_details_form && data.data.business_docs_details && data.data.documents_upload && data.data.business_bank_details_form) {
                setMerchantShowModal(false);
            }
            else {
                setMerchantShowModal(true);
            }
        }

    }


    useEffect(() => {
        if (theme === 'light') {
            setModalBackgroundColor('white');
        }
        else {
            setModalBackgroundColor('black');
        }
    }, [theme])

    useEffect(() => {
        getData()
    })




    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            height: '800px',
            marginRight: '-50%',
            marginTop: '6%',
            backgroundColor: modalBackgroundColor,
            transform: 'translate(-50%, -50%)',
        },
    };
    return (
        <div>
            {merchantShowModal === true && <Modal
                isOpen={true}
                ariaHideApp={false}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div>
                    <Button  className="text-xs" onClick={()=>{props.modeFunction(true)}}>Try Test Mode</Button>
                    
                    <MerchantActivation className="" refreshFunction={getData} />
                </div>

            </Modal>}
        </div>
    );
};

export default MerchantActivationModal;