import React from 'react';
import { useOutletContext } from "react-router-dom";
import MerchantPayoutPayments from './MerchantPayoutPayments';
import MerchantTestPayoutPayments from './MerchantTestPayoutPayments';

const PayoutTransactionHome = () => {
    const testMode = useOutletContext();

    return (
        <div>
            {testMode ? <MerchantTestPayoutPayments /> : <MerchantPayoutPayments />}
        </div>
    );
};

export default PayoutTransactionHome;