// store.js
import { configureStore } from '@reduxjs/toolkit';
import testModeReducer from '../Slice/TestModeSlice';

const store = configureStore({
    reducer: {
        testMode: testModeReducer,
    },
});

export default store;
