import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { Link } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast"
import { Switch } from "@/components/ui/switch";
import { Button } from "@/components/ui/button";

import { Input } from "@/components/ui/input";
import { getMerchantsWithSearch, updateMerchantStatus } from "Apis/employeeapi";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import TableLoading from "LoadingComponents/TableLoading";
import Breadcrumb from "LoadingComponents/Breadcrumb";
import { BadgeInfo, Users } from "lucide-react";

const MerchantRefund = () => {
    const BreadcrumbArray = [{ name: 'Merchant', icon: <Users /> }, { name: 'Details', icon: <BadgeInfo /> }]
    const { toast } = useToast()
    const [merchantSearch, setMerchantSearch] = useState("");

    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [merchants, setMerchants] = useState([]);

    const getData = async (skip, limit, dates, paymentId) => {
        setTableLoading(true)
        const searchObject = merchantSearch;
        const fetchMerchants = await getMerchantsWithSearch(
            skip,
            limit,
            searchObject
        );
        setMerchants(fetchMerchants.data);
        setCount(fetchMerchants.count);
        setTableLoading(false)

    };

    const fetchdata = (limit, skip) => {
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, merchantSearch);
    };

    useEffect(() => {
        getData(skip, limit, merchantSearch);

    }, [merchantSearch]);

    const onchangeSwitch = async (merchantId, status) => {
        const updateStatus = await updateMerchantStatus(merchantId, status);
        if (updateStatus.success === true) {
            toast({
                title: "Success",
                description: updateStatus.message
            })
            getData(skip, limit, merchantSearch);
        }
        else {
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong. Try Again"
            })
        }

    }

    return (
        <div className="rounded-md border mt-4">
            <Breadcrumb data={BreadcrumbArray} />
            <div className="grid grid-cols-1 md:grid-cols-3 pt-3 pb-8 px-3 gap-8">

                <div>
                    <Input
                        type="text"
                        placeholder="Search"
                        onChange={(e) => setMerchantSearch(e.target.value)}
                    />
                </div>

            </div>



            <div>
                <Table>
                    <TableCaption>A list of your merchants.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Sno.</TableHead>
                            <TableHead>Merchant Id</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead>Mobile</TableHead>
                            <TableHead>Created Date</TableHead>
                            <TableHead>Last Login</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Action</TableHead>
                        </TableRow>
                    </TableHeader>

                    {tableLoading === false ? (
                        <TableBody>
                            {merchants.length > 0 &&
                                merchants.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>{item.merchant_gid}</TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.email}</TableCell>
                                        <TableCell>{item.mobile_no}</TableCell>
                                        <TableCell>
                                            {moment(item.created_date).format("YYYY-MM-DD")}
                                        </TableCell>
                                        <TableCell>{moment(item.last_seen_at).format("YYYY-MM-DD")}</TableCell>
                                        <TableCell> <Switch
                                            checked={item.merchant_status}
                                            onCheckedChange={(changedStatus) => { onchangeSwitch(item._id, changedStatus) }}
                                        /></TableCell>
                                        <TableCell><Link to={`/merchant/merchants/${item._id}`} ><Button>Details</Button></Link></TableCell>

                                    </TableRow>
                                ))}
                        </TableBody>) : <TableLoading count={5} />}
                </Table>
                <div className="flex justify-center my-3">
                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div>
            </div>



        </div >
    );
};

export default MerchantRefund;
