import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { getFeedbacks } from "Apis/merchantapis";
import PaginatedItems from "Pagination/Pagination";
import AddFeedback from "./AddFeedback";
import moment from "moment";
import StarRatingComponent from "react-star-rating-component";

const Feedback = () => {
  const [feedbacks, setFeedbacks] = useState([]);

  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate: number) => {
    setRating(rate);

    // other logic
  };
  

  const getData = async () => {
    const fetchData = await getFeedbacks();
    setFeedbacks(fetchData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="mt-6 px-6">
      <div className="rounded-md border">
        <div className="px-3 mt-3">
          <AddFeedback reloadFunction={getData} />
        </div>

        <div className="px-3"></div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Sno.</TableHead>
              <TableHead>Subject</TableHead>
              <TableHead>Rating</TableHead>
              <TableHead>Description</TableHead>
              <TableHead> Date </TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {feedbacks.length > 0 &&
              feedbacks.map((item, index) => (
                <TableRow key={item._id}>
                  <TableCell className="font-medium">{index + 1}</TableCell>
                  <TableCell>{item.feed_subject.option_value}</TableCell>
                  <TableCell>
                    <StarRatingComponent
                      name="rating"
                      value={parseInt(item.feed_rating)}
                      starCount={5}
                      editing={false}
                    />
                  </TableCell>
                  <TableCell>{item.feedback}</TableCell>
                  <TableCell>
                    {moment(item.created_date).format("YYYY-MM-DD")}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default Feedback;
