import logo from "./logo.svg";
import "./App.css";
import Testing from "Testing";
import RootLayout from "Layout";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import MerchantMiddleware from "Merchant/Auth/Middleware/MerchantMiddleware";
import MerchantLayout from "Merchant/Layout/MerchantLayout";
import DashboardHome from "Merchant/Pages/Dashboard/DashboardHome";
import MerchantTransactions from "Merchant/Pages/Transactions/MerchantTransactions";
import Paylinks from "Merchant/Pages/Paylinks/Paylinks";
import Settlements from "Merchant/Pages/Settlement/Settlements";
import Settings from "Merchant/Pages/Settings/Settings";
import Feedback from "Merchant/Pages/Feedback/Feedback";
import Invoices from "Merchant/Pages/Invoices/Invoices";
import Login from "Merchant/Auth/Login";
import SignUp from "Merchant/Auth/Signup";
import Logout from "Merchant/Auth/Logout";
import { Toaster } from "@/components/ui/toaster";
import AddInvoice from "Merchant/Pages/Invoices/Invoices/AddInvoice";
import MerchantAccount from "Merchant/Pages/Account/MerchantAccount";
import MerchantActivation from "Merchant/Activation/MerchantActivation";
import ItemTable from "Test";
import ApiDocumentation from "Merchant/Pages/ApiDocumentation/ApiDocumentation";
import Beneficiaries from "Merchant/Pages/Payout/Beneficiaries/Beneficiaries";
import PayoutTransactionHome from "Merchant/Pages/Payout/TransactionReport/PayoutTransactionHome";
import PayoutSettings from "Merchant/Pages/Payout/Payout Settings/PayoutSettings";
import PayoutDashboard from "Merchant/Pages/Payout/Dashboard/DashboardHome";
import PayoutStatementHome from "Merchant/Pages/Payout/PayoutStatement/PayoutStatementHome";
import EmployeeRoutes from "Routes/EmployeeRoutes";
import ResellerRoutes from "Routes/ResellerRoutes";
import Home from "Home";

function App() {
  return (
    <BrowserRouter>
      <Toaster />
      <Routes>

        {/* Admin routes */}
        <Route element={<RootLayout />}>
          <Route path="/signup" element={<SignUp />} />
          <Route path="/Login" element={<Login />} />
          <Route path="/" element={<Home />} />
          <Route path="/overview_api_documentation" element={<ApiDocumentation />} />
        </Route>

        <Route element={<MerchantMiddleware children={undefined} />}>



          <Route element={<MerchantLayout />}>

            <Route path="/dashboard" element={<DashboardHome />} />
            <Route path="/transactions" element={<MerchantTransactions />} />
            <Route path="/paylinks" element={<Paylinks />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path="/add_invoice" element={<AddInvoice />} />
            <Route path="/settlements" element={<Settlements />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/api_documentation" element={<ApiDocumentation />} />
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/merchant_account" element={<MerchantAccount />} />
            <Route path="/logout" element={<Logout />} />

            <Route path="/payout_dashboard" element={<PayoutDashboard />} />
            <Route path="/beneficiaries" element={<Beneficiaries />} />
            <Route path="/payout_payment_report" element={<PayoutTransactionHome />} />
            <Route path="/payout_statement_report" element={<PayoutStatementHome />} />
            <Route path="/payout_settings" element={<PayoutSettings />} />


            <Route path="/test" element={<Testing />} />

          </Route>


        </Route>
      </Routes>
      <EmployeeRoutes />
      <ResellerRoutes/>
    </BrowserRouter>
  );
}

export default App;
