import React, { useState, useEffect } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { addFeedback, getFeedbackSubjects } from "Apis/merchantapis";
import { Switch } from "@/components/ui/switch";
import { Textarea } from "@/components/ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  SelectLabel,
  SelectGroup,
} from "@/components/ui/select";
import StarRatingComponent from "react-star-rating-component";

const AddFeedback = (prop) => {
  const [subjects, setSubjects] = useState([]);

  const [selectedSubject, setSelectedSubject] = useState("");
  const [star, setStar] = useState("");

  const [open, setOpen] = useState(false);

  const getData = async () => {
    const fetchData = await getFeedbackSubjects();
    setSubjects(fetchData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const initialValues = {
    feed_subject: selectedSubject,
    feed_rating: star,
    feedback: "",
  };

  const validationSchema = Yup.object().shape({
    feed_subject: Yup.string().required("Subject  is required"),
    feed_rating: Yup.number().required("Rating is required"),
  });

  const OnClickStar = (nextValue, prevValue, name) => {
    setStar(nextValue);
  };

  const onSubmit = async (values) => {
    // Handle form submission

    setOpen(false);

    const sendRequest = await addFeedback(values);
    prop.reloadFunction();
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Add Feedback</Button>
      </DialogTrigger>
      <DialogContent
        className={
          "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
        }
      >
        <DialogHeader>
          <DialogTitle>Add Feedback</DialogTitle>
          <DialogDescription>
            You can send us your Feedbacks .
          </DialogDescription>
        </DialogHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 pt-3 pb-16 px-3 gap-12 gap-y-4 ">
                <div>
                  <Label htmlFor="paylink_for">Subject:</Label>

                  <div className="mt-3">
                    <Select
                      onValueChange={(value) => {
                        setSelectedSubject(value);
                      }}
                      defaultValue=""
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a Subject" />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectGroup>
                          {subjects.length > 0 &&
                            subjects.map((item, index) => (
                              <SelectItem value={item._id} key={item._id}>
                                {item.option_value}
                              </SelectItem>
                            ))}
                        </SelectGroup>
                      </SelectContent>
                    </Select>
                  </div>

                  <ErrorMessage
                    name="feed_subject"
                    className="text-red-500 text-xs  my-2"
                    component="div"
                  />
                </div>
                <div>
                  <Label htmlFor="paylink_amount">Rating:</Label>

                  <div>
                    <StarRatingComponent
                      className=" mt-2 text-3xl"
                      name="rating"
                      value={parseInt(star)}
                      starCount={5}
                      editing={true}
                      onStarClick={(next, prev, name) => {
                        OnClickStar(next, prev, name);
                      }}
                    />
                  </div>

                  <ErrorMessage
                    name="feed_rating"
                    className="text-red-500 text-xs my-2"
                    component="div"
                  />
                </div>

                <div className="col-span-2">
                  <Label htmlFor="paylink_customer_email">Feedback:</Label>
                  <Textarea
                    className="mt-3"
                    type="text"
                    id="feedback"
                    name="feedback"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.feedback}
                  />
                  {/* Add validation error display */}
                </div>
              </div>

              <div></div>

              <DialogFooter>
                <Button type="submit">Submit</Button>
              </DialogFooter>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddFeedback;
