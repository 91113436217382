import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { addDays, format } from "date-fns";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Calendar } from "@/components/ui/calendar";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { listCdrModes, editCdr } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";

export default function EditChargeback(props) {
    const { toast } = useToast();
    const [cdrModes, setCdrModes] = useState();
    const [cdrData, setcdrData] = useState(null);
    const [open, setOpen] = useState(false);

    const [date, setDate] = useState();

    const getData = async () => {
        const fetchMerchants = await listCdrModes();
        setCdrModes(fetchMerchants.data)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setcdrData(props.cdr)
    }, [props])


    let initialChargeDetailsValues;

    if (cdrData === null) {
        initialChargeDetailsValues =
        {
            id: "",
            cdr_id: '',
            cdr_desc: '',
            transaction_gid: '',
            transaction_date: '',
            adjustment_trans_id: '',
            total_amount: '',
            remarks: ''
        };
    }
    else {
        initialChargeDetailsValues =
        {
            id: cdrData._id,
            cdr_id: cdrData.cdr_id._id,
            cdr_desc: cdrData.cdr_desc,
            transaction_gid: cdrData.transaction_gid,
            transaction_date: new Date(cdrData.transaction_date),
            adjustment_trans_id: cdrData.adjustment_trans_id,
            total_amount: cdrData.total_amount.$numberDecimal,
            remarks: cdrData.remarks
        };
    }





    const chargeDetailSchema = Yup.object().shape({
        cdr_id: Yup.string().required('This Field is required'),
        transaction_gid: Yup.string().required('This Field is required'),
        total_amount: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
    });


    const submitChargeDetailsForm = async (values) => {
        console.log(values)
        const sendData = await editCdr(values)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        setOpen(false)
        props.refreshFunction();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button className="w-32" variant="outline">Edit</Button>
            </DialogTrigger>
            <DialogContent className={
                "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Chargeback Form</DialogTitle>
                    <DialogDescription>
                        Fill the Form & Click submmit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        enableReinitialize
                        initialValues={initialChargeDetailsValues}
                        validationSchema={chargeDetailSchema}
                        onSubmit={submitChargeDetailsForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values, setFieldValue } = formik;

                            return (
                                <div className=" mt-1  p-5 ">
                                    <Form>



                                        <div className="grid grid-cols-3 gap-4 my-6">
                                            <div>
                                                <Label htmlFor="cdr_id">Mode:</Label>
                                                <div className="mt-3">
                                                    <Select

                                                        onValueChange={(field) => {
                                                            setFieldValue('cdr_id', field)
                                                        }}
                                                        defaultValue={values.cdr_id}
                                                    >
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Select a Mode" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                <SelectLabel>Modes</SelectLabel>
                                                                <SelectItem value="">Select Mode</SelectItem>
                                                                {cdrModes.map((mode) => (
                                                                    <SelectItem value={mode._id} key={mode._id}>{mode.option_value}</SelectItem>
                                                                ))}

                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </div>

                                                {errors.cdr_id && touched.cdr_id && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.cdr_id}
                                                    </span>
                                                )}
                                            </div>

                                            <div>
                                                <Label htmlFor="cdr_desc"> Description:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="cdr_desc"
                                                    name="cdr_desc"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.cdr_desc}
                                                />
                                                {errors.cdr_desc && touched.cdr_desc && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.cdr_desc}
                                                    </span>
                                                )}
                                            </div>

                                            <div>
                                                <Label htmlFor="transaction_gid"> Transaction Gid:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="transaction_gid"
                                                    name="transaction_gid"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.transaction_gid}
                                                />
                                                {errors.transaction_gid && touched.transaction_gid && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.transaction_gid}
                                                    </span>
                                                )}
                                            </div>

                                            <div>
                                                <Label htmlFor="adjustment_trans_id">Adjustment Id:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="adjustment_trans_id"
                                                    name="adjustment_trans_id"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.adjustment_trans_id}
                                                />
                                                {errors.adjustment_trans_id && touched.adjustment_trans_id && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.adjustment_trans_id}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="">
                                                <Label htmlFor="transaction_date">Transaction Date:</Label>
                                                <div className="mt-3">
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button
                                                                variant={"outline"}
                                                                className={cn(
                                                                    "w-full justify-start text-left font-normal",
                                                                    !date && "text-muted-foreground"
                                                                )}
                                                            >
                                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                                {values.transaction_date ? format(values.transaction_date, "PPP") : <span>Pick a date</span>}
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0" align="start">
                                                            <Calendar
                                                                mode="single"
                                                                selected={values.transaction_date}
                                                                onSelect={(field) => { setFieldValue('transaction_date', field) }}
                                                                initialFocus
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                    {errors.transaction_date && touched.transaction_date && (
                                                        <span className="text-xs text-red-400">
                                                            {errors.transaction_date}
                                                        </span>
                                                    )}
                                                </div>

                                            </div>

                                            <div>
                                                <Label htmlFor="total_amount"> Total Amount:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="total_amount"
                                                    name="total_amount"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.total_amount}
                                                />
                                                {errors.total_amount && touched.total_amount && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.total_amount}
                                                    </span>
                                                )}
                                            </div>

                                            <div>
                                                <Label htmlFor="remarks"> Remarks:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="remarks"
                                                    name="remarks"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.remarks}
                                                />
                                                {errors.remarks && touched.remarks && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.remarks}
                                                    </span>
                                                )}
                                            </div>

                                        </div>


                                        <div className='flex justify-center'>
                                            <Button
                                                type="submit"
                                                className="btn mt-5 w-96  font-medium  "
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                </div>

            </DialogContent>
        </Dialog>
    )
}
