import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { getMerchantList, editMerchantChargeDetailRequest } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";

export default function EditMerchantChargeDetail(props) {
    const { toast } = useToast();
    const [merchants, setMerchants] = useState();
    const [chargeDetails, setChargeDetails] = useState(null);
    const [merchantId, setMerchantId] = useState();
    const [open, setOpen] = useState(false);

 

    const getData = async () => {
        const fetchMerchants = await getMerchantList();
        setMerchants(fetchMerchants.data)
    }

    useEffect(() => {
        setChargeDetails(props.chargeDetails)
    }, [props])


    let initialChargeDetailsValues;
    if (chargeDetails !== null) {
        initialChargeDetailsValues =
        {
            merchantId: chargeDetails.merchant_id ? chargeDetails.merchant_id._id : '',
            dc_visa: chargeDetails.dc_visa.$numberDecimal,
            dc_master: chargeDetails.dc_master.$numberDecimal,
            dc_rupay: chargeDetails.dc_rupay.$numberDecimal,
            cc_visa: chargeDetails.cc_visa.$numberDecimal,
            cc_master: chargeDetails.cc_master.$numberDecimal,
            cc_rupay: chargeDetails.cc_rupay.$numberDecimal,
            amex: chargeDetails.amex.$numberDecimal,
            upi: chargeDetails.upi.$numberDecimal,
            wallet: chargeDetails.wallet.$numberDecimal,
            dap: chargeDetails.dap.$numberDecimal,
            qrcode: chargeDetails.qrcode.$numberDecimal,
            net_sbi: chargeDetails.net_sbi.$numberDecimal,
            net_hdfc: chargeDetails.net_hdfc.$numberDecimal,
            net_axis: chargeDetails.net_axis.$numberDecimal,
            net_icici: chargeDetails.net_icici.$numberDecimal,
            net_yes_kotak: chargeDetails.net_yes_kotak.$numberDecimal,
            net_others: chargeDetails.net_others.$numberDecimal,
        }
            ;
    }
    else {
        initialChargeDetailsValues =
        {
            merchantId:  '',
            dc_visa: '',
            dc_master: '',
            dc_rupay: '',
            cc_visa: '',
            cc_master:'',
            cc_rupay:'',
            amex: '',
            upi: '',
            wallet: '',
            dap: '',
            qrcode: '',
            net_sbi:'',
            net_hdfc: '',
            net_axis: '',
            net_icici:'',
            net_yes_kotak: '',
            net_others: '',
        }
    }



    const chargeDetailSchema = Yup.object().shape({
        dc_visa: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        dc_master: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        dc_rupay: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        cc_visa: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        cc_master: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        cc_rupay: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        amex: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        upi: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        wallet: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        dap: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        qrcode: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        net_sbi: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        net_hdfc: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        net_axis: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        net_icici: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        net_yes_kotak: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        net_others: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
    });

    const fieldArray1 = [
        "dc_visa",
        "dc_master",
        "dc_rupay",
        "cc_visa",
        "cc_master",
        "cc_rupay",
        "amex",
        "upi"
    ];

    const fieldArray2 = [
        "net_sbi",
        "net_hdfc",
        "net_axis",
        "net_icici",
        "net_yes_kotak",
        "net_others",
    ];

    const fieldArray3 = [
        "wallet",
        "dap",
        "qrcode"
    ];

    function transformString(inputString) {
        // Split the input string by underscores
        const parts = inputString.split('_');

        // Capitalize the first letter of each part and join them with a space
        const transformedString = parts.map(part => {
            return part.charAt(0).toUpperCase() + part.slice(1);
        }).join(' ');

        return transformedString;
    }

    const submitChargeDetailsForm = async (values) => {
        const sendData = await editMerchantChargeDetailRequest(values)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        setOpen(false)
        props.refreshFunction();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Edit</Button>
            </DialogTrigger>
            <DialogContent className={
                "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Merchant Charges Form</DialogTitle>
                    <DialogDescription>
                        Fill the Form & Click submmit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        enableReinitialize
                        initialValues={initialChargeDetailsValues}
                        validationSchema={chargeDetailSchema}
                        onSubmit={submitChargeDetailsForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values, setFieldValue } = formik;

                            return (
                                <div className=" mt-1  p-5 ">
                                    <Form>
                                        <div className="grid grid-cols-3 gap-4">


                                            {fieldArray1.map((fieldName, index) => (
                                                <div key={index}>
                                                    <Label htmlFor={fieldName}>{transformString(fieldName)}:</Label>
                                                    <Input
                                                        className="mt-3"
                                                        type="text"
                                                        id={fieldName}
                                                        name={fieldName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values[fieldName]}
                                                    />
                                                    {errors[fieldName] && touched[fieldName] && (
                                                        <span className="text-xs text-red-400">
                                                            {errors[fieldName]}
                                                        </span>
                                                    )}
                                                </div>
                                            ))}




                                        </div>



                                        <div className="grid grid-cols-3 gap-4 my-6">
                                            {fieldArray2.map((fieldName, index) => (
                                                <div key={index}>
                                                    <Label htmlFor={fieldName}>{transformString(fieldName)}:</Label>
                                                    <Input
                                                        className="mt-3"
                                                        type="text"
                                                        id={fieldName}
                                                        name={fieldName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values[fieldName]}
                                                    />
                                                    {errors[fieldName] && touched[fieldName] && (
                                                        <span className="text-xs text-red-400">
                                                            {errors[fieldName]}
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                        </div>

                                        <div className="grid grid-cols-3 gap-4 my-6">
                                            {fieldArray3.map((fieldName, index) => (
                                                <div key={index}>
                                                    <Label htmlFor={fieldName}>{transformString(fieldName)}:</Label>
                                                    <Input
                                                        className="mt-3"
                                                        type="text"
                                                        id={fieldName}
                                                        name={fieldName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values[fieldName]}
                                                    />
                                                    {errors[fieldName] && touched[fieldName] && (
                                                        <span className="text-xs text-red-400">
                                                            {errors[fieldName]}
                                                        </span>
                                                    )}
                                                </div>
                                            ))}
                                        </div>
                                        <div className='flex justify-center'>
                                            <Button
                                                type="submit"
                                                className="btn mt-5 w-96  font-medium  "
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                </div>

            </DialogContent>
        </Dialog>
    )
}
