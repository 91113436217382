import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import download from 'downloadjs';
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { listCdr, listCdrModes, exportTransactions } from "Apis/employeeapi";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import { io } from "socket.io-client";
import TableLoading from "LoadingComponents/TableLoading";
import Breadcrumb from "LoadingComponents/Breadcrumb";
import { Edit, IndianRupee, SquareStack } from "lucide-react";
import EmployeeCard from "ReusableComponents/EmployeeCard";
import AddChargeback from "./AddChargeback";
import EditChargeback from "./EditChargeback";

const Transactions = () => {
    const BreadcrumbArray = [{ name: 'Chargeback', icon: <IndianRupee /> }]
    const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
    const socket = io(backendUrl);
    const [open, setOpen] = useState(false);
    const [percent, setPercent] = useState('');
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });

    const [paymentIdQ, setpaymentIdQ] = useState("");
    const [statusQ, setstatusQ] = useState("");
    const [cdrModes, setCdrModes] = useState("");
    const [selectedCdrMode, setSelectedCdrMode] = useState("");

    const [viewTable, setViewTable] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [transactions, setTransactions] = useState([]);

    const getData = async (skip, limit, dates, paymentId, status) => {
        setTableLoading(true)
        const dateConverter = (date) => {
            const currentDate = new Date(date);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            const dateString = `${year}-${month}-${day}`;
            return dateString;
        };

        if (dates !== undefined) {
            const fromDateString = dateConverter(dates.from);
            const toDateString = dateConverter(dates.to);

            const dateRangeObject = {
                from: fromDateString,
                to: toDateString,
            };



            const searchObject = {
                transaction_gid: paymentId,
                cdr_id: selectedCdrMode
            };

            const fetchTransactions = await listCdr(
                skip,
                limit,
                searchObject,
                dateRangeObject
            );
            setTransactions(fetchTransactions.data);
            setCount(fetchTransactions.count);
            setTableLoading(false)
        }
    };

    const fetchdata = (limit, skip) => {
        console.log(limit, skip);
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, date, paymentIdQ, statusQ);
    };

    const refreshTable = () => {
        getData(skip, limit, date, paymentIdQ, statusQ);
        console.log('refersh')
    }

    const onClickView = () => {
        if (viewTable === true) {
            setViewTable(false)
        }
        else {
            setViewTable(true)
        }
    }



    useEffect(() => {
        const timer = setInterval(() => {
            const userId = JSON.parse(localStorage.getItem("employee")).userId;
            socket.on(userId, function (msg) {
                console.log(msg);
                setPercent(msg);

            });
        }, 200);

        return () => {
            clearInterval(timer);
        };
    }, []);


    const onClickDownload = async () => {
        console.log('download clicked')

        setPercent('Fetching Data')

        const exportCall = await exportTransactions(
            date, selectedCdrMode
        );

        setPercent('Downloading File')
        const blob = await exportCall.blob();

        download(blob, 'data.csv', 'text/csv');

        setOpen(false)
        setPercent('')
    }

    const fetchCdrModes = async () => {
        const fetchList = await listCdrModes();
        setCdrModes(fetchList.data)
    }

    useEffect(() => {
        if (viewTable === true) {
            getData(skip, limit, date, paymentIdQ, statusQ);
        }
    }, [date, paymentIdQ, statusQ, viewTable, selectedCdrMode]);

    useEffect(() => {
        fetchCdrModes();
    }, []);


    return (
        <div className="rounded-md border mt-4">
            <Breadcrumb data={BreadcrumbArray} />
            <div className="grid grid-cols-1 md:grid-cols-5 pt-3 pb-6 px-3 gap-8">
                <div className="">

                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                id="date"
                                variant={"outline"}
                                className={cn(
                                    "w-full justify-start text-left font-normal",
                                    !date && "text-muted-foreground"
                                )}
                            >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {date?.from ? (
                                    date.to ? (
                                        <>
                                            {format(date.from, "LLL dd, y")} -{" "}
                                            {format(date.to, "LLL dd, y")}
                                        </>
                                    ) : (
                                        format(date.from, "LLL dd, y")
                                    )
                                ) : (
                                    <span>Pick a date</span>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                initialFocus
                                mode="range"
                                defaultMonth={date?.from}
                                selected={date}
                                onSelect={setDate}
                                numberOfMonths={2}
                            />
                        </PopoverContent>
                    </Popover>
                </div>
                <div>
                    <div>
                        <Select
                            onValueChange={(field) => {
                                setSelectedCdrMode(field)
                            }}
                            defaultValue={""}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select a Status" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Merchants</SelectLabel>
                                    <SelectItem value="">All</SelectItem>
                                    {cdrModes.length > 0 && cdrModes.map((mode) => (
                                        <SelectItem key={mode._id} value={mode._id}>{mode.option_value}</SelectItem>
                                    ))}

                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div>
                    <Input
                        type="text"
                        placeholder="Transaction gid"
                        onChange={(e) => setpaymentIdQ(e.target.value)}
                    />
                </div>
                <div>
                    <Button className="mx-2" onClick={() => { onClickView() }}>View</Button>
                    {/* <Button className="mx-2" disabled={percent !== ''} variant="outline" onClick={() => { onClickDownload() }}>{percent !== '' ? percent : 'Download'}</Button> */}
                </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-5  pb-2 px-2 gap-8">
                <AddChargeback refreshFunction={refreshTable} />
            </div>

            {viewTable === true && <div>
                <Table>
                    <TableCaption>A list of your recent payments.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead >Sno.</TableHead>
                            <TableHead>Transaction type</TableHead>
                            <TableHead>Transaction Gid</TableHead>
                            <TableHead>Adjustment Id</TableHead>
                            <TableHead >Amount</TableHead>
                            <TableHead>User</TableHead>
                            <TableHead>Created At</TableHead>
                            <TableHead>Action</TableHead>
                        </TableRow>
                    </TableHeader>

                    {tableLoading === false ?
                        <TableBody>
                            {transactions.length > 0 &&
                                transactions.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>{item.cdr_id.option_value ? item.cdr_id.option_value : ''}</TableCell>
                                        <TableCell>{item.transaction_gid}</TableCell>
                                        <TableCell>{item.adjustment_trans_id}</TableCell>
                                        <TableCell>{item.total_amount.$numberDecimal ? item.total_amount.$numberDecimal : ''}</TableCell>

                                        <TableCell>
                                            <EmployeeCard employee={item.created_user} />
                                        </TableCell>
                                        <TableCell>
                                            {moment(item.created_date).format("YYYY-MM-DD")}
                                        </TableCell>
                                        <TableCell>
                                            <EditChargeback cdr={item} refreshFunction={refreshTable} />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                        : <TableLoading count={11} />

                    }
                </Table>

                <div className="flex justify-center my-3">
                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div></div>}


            <Dialog open={open} onOpenChange={setOpen} disableOutsidePointerEvents >

                <DialogContent
                    className={
                        "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                    }
                >
                    <DialogHeader>
                        <DialogTitle>Exporting Transactions</DialogTitle>
                        <DialogDescription>

                        </DialogDescription>
                    </DialogHeader>
                    <h5 className="text-lg font-bold">{percent} %</h5>
                    <Progress value={percent} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Transactions;
