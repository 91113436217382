import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { CalendarIcon } from "@radix-ui/react-icons";
import {
    Dialog,
    DialogClose,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { addDays, format } from "date-fns";
import AddBeneficiary from "./AddBeneficiary";
import AddTestBalance from "./AddTestBalance";
import BeneficiaryInfo from "ReusableComponents/ViewBeneficiaries";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import DeleteBeneficiaries from "./DeleteBeneficiaries";
import TransferMoney from "./TransferMoney";
import TestTransferMoney from "./TestTransferMoney";
import { Input } from "@/components/ui/input";
import { getBeneficiaries, getPayoutWalletBalance } from "Apis/merchantapis";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import { get } from "react-hook-form";
import { Badge } from "@/components/ui/badge"

const Beneficiaries = () => {
    const url = `${process.env.REACT_APP_PAYMENT_URL}`;
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });

    const testMode = useOutletContext();

    const [search, setSearch] = useState("");

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [walletBalance, setWalletBalance] = useState('');
    const [paylinks, setpaylinks] = useState([]);

    const getData = async (skip, limit, search) => {

        const fetchbalance = await getPayoutWalletBalance();
        setWalletBalance(fetchbalance.result.balance)
        console.log(search)
        const fetchData = await getBeneficiaries(
            skip,
            limit,
            search
        );

        setpaylinks(fetchData.data);
        setCount(fetchData.count);

    };

    const reloadBeneficiaries = () => {
        getData(skip, limit, search);
    };

    console.log(search)

    const fetchdata = (limit, skip) => {
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, search);
    };

    useEffect(() => {
        getData(skip, limit, search);
    }, [search]);

    return (
        <div className="rounded-md border">
            <div className="grid grid-cols-1 md:grid-cols-3 pt-3 pb-8 px-3 gap-8">
                <div>
                    <Input
                        type="text"
                        placeholder="Search .."
                        onChange={(e) => setSearch(e.target.value)}
                    />
                </div>
            </div>

            <div className="flex gap-4 px-3">
                <AddBeneficiary getBeneficiariesFunction={reloadBeneficiaries} />
                {testMode && < AddTestBalance />}

                {!testMode &&  <Badge variant="outline" className="">Balance : {walletBalance}</Badge>}  

            </div>
            <Table>
                <TableCaption>A list of your recent payments.</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead>Sno.</TableHead>
                        <TableHead>Beneficiary Id</TableHead>
                        <TableHead>Name</TableHead>
                        <TableHead>Bank A/C</TableHead>
                        <TableHead>Phone</TableHead>
                        <TableHead>IFSC</TableHead>
                        <TableHead>Created At</TableHead>
                        <TableHead>Action</TableHead>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {paylinks.length > 0 &&
                        paylinks.map((item, index) => (
                            <TableRow key={item._id}>
                                <TableCell className="font-medium">{index + 1}</TableCell>

                                <TableCell>
                                    <Dialog>
                                        <DialogTrigger asChild>
                                            <Button variant="link">{item.beneficiary_gid}</Button>
                                        </DialogTrigger>
                                        <DialogContent className="lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar">
                                            <   BeneficiaryInfo beneficiary={item} />
                                        </DialogContent>
                                    </Dialog></TableCell>
                                <TableCell>{item.beneficiary_name}</TableCell>
                                <TableCell>{item.beneficiary_bank_ac}</TableCell>
                                <TableCell>{item.beneficiary_phone}</TableCell>
                                <TableCell>{item.beneficiary_ifsc}</TableCell>
                                <TableCell>
                                    {moment(item.created_date).format("YYYY-MM-DD")}
                                </TableCell>
                                <TableCell className="flex gap-2">
                                    <DeleteBeneficiaries id={item._id} ben_gid={item.beneficiary_gid} getReloadFunction={reloadBeneficiaries} />
                                    {testMode ? <TestTransferMoney beneficiary={item} /> : <TransferMoney beneficiary={item} getReloadFunction={reloadBeneficiaries} />}

                                </TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            <div className="flex justify-center my-3">
                <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
            </div>
        </div>
    );
};

export default Beneficiaries;
