// Import necessary dependencies
import React, { useEffect, useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { addTestBalance } from "Apis/merchantapis";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"

// Create the AddBeneficiary component
const AddTestBalance = (props) => {
    const [open, setOpen] = useState(false);
    const [states, setStates] = useState(false);



    // Initial values for the form
    const initialValues = {
        amount: ""
    };

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        amount: Yup.string().required("Amount is required")
    });

    // Function to handle form submission
    const onSubmit = async (values) => {
        // Handle form submission
        setOpen(false);

        // Replace with your actual API function for adding beneficiaries
        const sendRequest = await addTestBalance(values);

        // Do any additional actions after adding beneficiary, if needed
        // For example, you might want to fetch updated beneficiary list.
        props.getBeneficiariesFunction();
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Add Test Balance</Button>
            </DialogTrigger>
            <DialogContent
                className={
                    "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                }
            >
                <DialogHeader>
                    <DialogTitle>Add Test Balance</DialogTitle>
                    <DialogDescription>
                        Enter Amount below.
                    </DialogDescription>
                </DialogHeader>
                {/* Formik form for adding beneficiaries */}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <Form>
                            <div className="grid grid-cols-1 md:grid-cols-3 pt-3 pb-16 px-3 gap-2 gap-y-4">
                                <div>
                                    <Label htmlFor="beneficiary_name">Amount:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="amount"
                                        name="amount"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.amount}
                                    />
                                    <ErrorMessage
                                        name="amount"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>

                            </div>

                            <DialogFooter>
                                <Button type="submit">Submit</Button>
                            </DialogFooter>
                        </Form >


                    )}
                </Formik >
            </DialogContent >
        </Dialog >
    );
};

export default AddTestBalance;
