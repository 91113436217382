import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { EmployeeLoginRequest, GenerateOtpRequest, VerifyOtpRequest, saveEmployeeLoginActivity } from "../../Apis/employeeapi";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import Otp from "@/components/ui/otp";

export default function EmployeeLogin() {
    let navigate = useNavigate();
    const { toast } = useToast();
    const [open, setOpen] = React.useState(false);
    const [loginRequestData, setloginRequestData] = useState('');
    const [loginEmail, setLoginEmail] = useState('');
    const [enteredOtp, setenteredOtp] = useState('');
    const [loading, setLoading] = useState(false);



    
    //user Login Info 


    const otpvalidationSchema = Yup.object().shape({
        otp: Yup.string()
            .min(6, "Otp must be at least 6 digits")
            .max(6, "Otp cannot be greater than 6 digits")
            .required("Otp is required"),
    });

    // Define validation schema using Yup
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email("Invalid email address")
            .required("Email is required"),
        password: Yup.string()
            .min(6, "Password must be at least 6 characters")
            .required("Password is required"),
    });

    // Handle form submission
    const handleSubmit = async (values, { setSubmitting }) => {
        setLoading(true)
        const returnData = await EmployeeLoginRequest(values);
        console.log(returnData);
        if (returnData.success === true) {
            const generateOtp = await GenerateOtpRequest(values)
            if (generateOtp.success === true) {
                setLoginEmail(values.email)
                setloginRequestData(returnData.data)
                setOpen(true)
                toast({
                    title: "Success",
                    description: generateOtp.message
                })
            }



        } else if (returnData.success === false) {
            console.log(returnData.message);
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description: returnData.message
            })
        }

        console.log("cookie:", localStorage.getItem("employee"));

        setLoading(false)
    };

    const handleSubmitOtp = async (values) => {
        const verifyOtp = await VerifyOtpRequest(loginEmail, parseInt(values.otp));

        if (verifyOtp.success === true) {
            localStorage.setItem("employee", JSON.stringify(loginRequestData));
            saveEmployeeLoginActivity()
            navigate("/employee_dashboard");
        }
        else {
            setOpen(false);
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description: verifyOtp.error
            })
        }
    }

    return (
        <div className="flex justify-center">
            <div className="px-2 sm:w-3/4 md:w-3/4 lg:w-1/4">
                <h1 className="scroll-m-20 text-center text-5xl font-extrabold tracking-tight md:text-5xl  my-6">
                    Payripe
                </h1>
                <h1 className="scroll-m-20 text-center text-xl font-bold tracking-tight md:text-xl  my-6">
                    Employee Login
                </h1>
                <Card>
                    <CardHeader className="space-y-1">
                        <CardTitle className="text-2xl">Sign In</CardTitle>
                        <CardDescription>
                            Enter your email below to sign in to your account
                        </CardDescription>
                    </CardHeader>
                    <Formik
                        initialValues={{ email: "", password: "" }}
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                        }) => (
                            <Form>
                                <CardContent className="grid gap-4">
                                    <div className="grid gap-2">
                                        <Label htmlFor="email">Email</Label>
                                        <Input
                                            type="email"
                                            name="email"
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage
                                            name="email"
                                            className="text-red-500"
                                            component="div"
                                        />
                                    </div>
                                    <div className="grid gap-2">
                                        <Label htmlFor="password">Password</Label>
                                        <Input
                                            type="password"
                                            name="password"
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage
                                            name="password"
                                            className="text-red-500"
                                            component="div"
                                        />
                                    </div>
                                </CardContent>
                                <CardFooter>
                                    <Button className="w-full" disabled={loading} type="submit">
                                        {loading ? 'Sending Otp to Email' : "Login"}
                                    </Button>
                                </CardFooter>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </div>

            <Dialog open={open} onOpenChange={setOpen}>
                <DialogTrigger asChild>

                </DialogTrigger>
                <DialogContent className="sm:max-w-[425px]">
                    <DialogHeader>
                        <DialogTitle>Otp</DialogTitle>
                        <DialogDescription>
                            Please Enter 6 digit Otp
                        </DialogDescription>
                    </DialogHeader>
                    <Formik
                        initialValues={{ otp: "" }}
                        validationSchema={otpvalidationSchema
                        }
                        onSubmit={handleSubmitOtp}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            isSubmitting,
                            setFieldValue
                        }) => (
                            <Form>
                                <div className="">
                                    <div className="my-3">
                                        {/* <Input id="otp" name="otp" value={values.otp} onChange={handleChange}
                                            onBlur={handleBlur} className="my-2" /> */}


                                        <Otp length={6} otp={values.otp} onOtpChange={(field) => {
                                            console.log(field)
                                            setFieldValue('otp', field)
                                        }} />

                                    </div>
                                    <ErrorMessage
                                        name="otp"
                                        className="text-red-500 text-xs"
                                        component="div"
                                    />
                                </div>

                                <DialogFooter>
                                    <Button type="submit" >Submit</Button>
                                </DialogFooter>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </div>
    );
}
