import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { deleteVendorCharges } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";

export default function DeleteVendorCharges(props) {
    const { toast } = useToast();
    const [chargeDetails, setChargeDetails] = useState();
    const [open, setOpen] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);


    useEffect(() => {
        setChargeDetails(props.chargeDetails)
    }, [props])


    useEffect(() => {
        setSelectedVendor(props.selectedVendor);
    }, [props]);




    const submitDelete = async (values) => {
        const sendData = await deleteVendorCharges(chargeDetails._id)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        setOpen(false)
        props.refreshFunction();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Delete</Button>
            </DialogTrigger>
            <DialogContent className={
                "sm:max-w-[425px] overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Delete Vendor Charges</DialogTitle>
                    <DialogDescription>
                        Are you sure  ?
                    </DialogDescription>
                </DialogHeader>
                <div className="flex gap-6 justify-end">

                    <Button onClick={() => submitDelete()}>Yes</Button>
                    <Button variant="destructive" onClick={() => { setOpen(false) }}>No</Button>


                </div>

            </DialogContent>
        </Dialog>
    )
}
