import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { addDays, format } from "date-fns";
import { CalendarIcon } from "@radix-ui/react-icons";
import { Calendar } from "@/components/ui/calendar";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { listCdrModes, updateEmployeeDetails } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";

export default function EditEmployeeProfile(props) {
    const { toast } = useToast();
    const [cdrModes, setCdrModes] = useState();
    const [profileData, setProfileData] = useState(null);
    const [open, setOpen] = useState(false);



    const getData = async () => {
        const fetchMerchants = await listCdrModes();
        setCdrModes(fetchMerchants.data)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setProfileData(props.profile)
    }, [props])


    let initialChargeDetailsValues;

    if (profileData === null) {
        initialChargeDetailsValues =
        {
            username: "",
            first_name: '',
            last_name: '',
            designation: '',
            official_email: '',
            password: '',
            confirm_password: '',
            mobile_no: ''
        };
    }
    else {
        initialChargeDetailsValues =
        {
            username: profileData.employee_username,
            first_name: profileData.first_name,
            last_name: profileData.last_name,
            designation: profileData.designation,
            official_email: profileData.official_email,
            mobile_no: profileData.mobile_no
        };
    }





    const chargeDetailSchema = Yup.object().shape({
        username: Yup.string().required('This Field is required'),
        first_name: Yup.string().required('This Field is required'),
        last_name: Yup.string().required('This Field is required'),
        official_email: Yup.string().required('This Field is required'),
        mobile_no: Yup.string().required('This Field is required')

    });


    function transformString(inputString) {
        // Split the input string by underscores
        const parts = inputString.split('_');

        // Capitalize the first letter of each part and join them with a space
        const transformedString = parts.map(part => {
            return part.charAt(0).toUpperCase() + part.slice(1);
        }).join(' ');

        return transformedString;
    }

    const submitChargeDetailsForm = async (values) => {
        console.log(values)
        const sendData = await updateEmployeeDetails(values)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        setOpen(false)
        props.refreshFunction();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button className="w-16" variant="outline">Edit</Button>
            </DialogTrigger>
            <DialogContent className={
                "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Edit Profile</DialogTitle>
                    <DialogDescription>
                        Fill the Form & Click submmit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        enableReinitialize
                        initialValues={initialChargeDetailsValues}
                        validationSchema={chargeDetailSchema}
                        onSubmit={submitChargeDetailsForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values, setFieldValue } = formik;

                            return (
                                <div className=" mt-1  p-5 ">
                                    <Form>



                                        <div className="grid grid-cols-3 gap-4 my-6">

                                            {Object.keys(initialChargeDetailsValues).map((key) => (
                                                <div key={key}>
                                                    <Label htmlFor={key}>{transformString(key)}:</Label>
                                                    <Input
                                                        className="mt-3"
                                                        type="text"
                                                        id={key}
                                                        name={key}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values[key]}
                                                    />
                                                    {errors[key] && touched[key] && (
                                                        <span className="text-xs text-red-400">
                                                            {errors[key]}
                                                        </span>
                                                    )}
                                                </div>
                                            ))}



                                        </div>


                                        <div className='flex justify-center'>
                                            <Button
                                                type="submit"
                                                className="btn mt-5 w-96  font-medium  "
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                </div>

            </DialogContent>
        </Dialog>
    )
}
