import React from 'react';
import SettlementsTable from './SettlementsTable';

const Settlements = () => {
    return (
        <div className="mt-6 px-6">
        <SettlementsTable/>
    </div>
    );
};

export default Settlements;