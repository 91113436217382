import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { motion } from "framer-motion"
import { ArrowRightLeft, IndianRupeeIcon } from "lucide-react";
import CardLoading from "LoadingComponents/CardLoading";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { getTransactionInfoViaId } from "Apis/merchantapis";
import { Progress } from "@/components/ui/progress"
import DateTimeRangeInput from "ReusableComponents/DateTimeRangeInput";
import download from 'downloadjs';
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";

import { Input } from "@/components/ui/input";
import { getMerchantTestPayoutPayments } from "Apis/merchantapis";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import { Skeleton } from "@/components/ui/skeleton";
import TableLoading from "LoadingComponents/TableLoading";

const MerchantTestPayoutPayments = () => {
    const [open, setOpen] = useState(false);
    const [percent, setPercent] = useState('');
    const [transactionInfo, setTransactionInfo] = useState('');
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });

    const [paymentIdQ, setpaymentIdQ] = useState("");
    const [statusQ, setstatusQ] = useState("");

    const [viewTable, setViewTable] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [stats, setStats] = useState([]);

    const getDateRange = (dateRangeObject) => {
        console.log(dateRangeObject, 'parent');
        setDate(dateRangeObject)

    };

    const getData = async (skip, limit, dates, paymentId, status) => {
        setTableLoading(true)


        if (dates != undefined) {




            const searchObject = {
                transactiongid: paymentId,
                transaction_amount: "",
                transaction_contact: "",
                transaction_status: status,
            };

            const fetchTransactions = await getMerchantTestPayoutPayments(
                skip,
                limit,
                dates
            );
            setTransactions(fetchTransactions.data);
            setStats(fetchTransactions.totalStats);
            setCount(fetchTransactions.count);
            setTableLoading(false)
        }
    };

    const fetchdata = (limit, skip) => {
        console.log(limit, skip);
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, date, paymentIdQ, statusQ);
    };

    const onClickView = () => {
        if (viewTable === true) {
            setViewTable(false)
        }
        else {
            setViewTable(true)
        }
    }



    const onClickTransactionId = async (id) => {
        console.log(id)
        const fetchdata = await getTransactionInfoViaId(id);
        setTransactionInfo(fetchdata.data)
        console.log(fetchdata)

    }






    useEffect(() => {
        if (viewTable === true) {
            getData(skip, limit, date, paymentIdQ, statusQ);
        }
    }, [date, paymentIdQ, statusQ, viewTable]);

    return (
        <div className="rounded-md border">
            <div className="grid grid-cols-1 md:grid-cols-4 pt-3 pb-16 px-3 gap-8">
                <DateTimeRangeInput getFunction={getDateRange} />

                <div>
                    <Button className="mx-2" onClick={() => { onClickView() }}>View</Button>
                </div>
            </div>





            {viewTable === true && <div>
                {stats.length > 0 ? <div className="mx-3 my-3 grid grid-cols-3 gap-4 ">
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card>
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Total Amount
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold">{stats[0].totalAmount}</div>

                            </CardContent>
                        </Card>


                    </motion.div>

                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card className="h-full">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Total Charges
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold">{stats[0].totalVendorCharges}</div>

                            </CardContent>
                        </Card>
                    </motion.div>

                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card className="h-full">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Gst On Charges
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold">{stats[0].totalGst}</div>

                            </CardContent>
                        </Card>
                    </motion.div>  </div> : ""}
                <Table>
                    <TableCaption>A list of your recent payments.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead >Sno.</TableHead>
                            <TableHead>Reference Id</TableHead>
                            <TableHead>Transfer Id</TableHead>
                            <TableHead>Name</TableHead>
                            <TableHead >IFSC</TableHead>
                            <TableHead>A/c</TableHead>
                            <TableHead>Vpa</TableHead>
                            <TableHead>Phone</TableHead>
                            <TableHead>Email</TableHead>
                            <TableHead>Mode</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Amount</TableHead>
                            <TableHead>Charges</TableHead>
                            <TableHead>GST</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Add On</TableHead>
                        </TableRow>
                    </TableHeader>

                    {tableLoading === false ?
                        <TableBody>
                            {transactions.length > 0 &&
                                transactions.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>
                                            {item.reference_id}</TableCell>
                                        <TableCell>{item.transfer_id}</TableCell>
                                        <TableCell>{item.ben_name}</TableCell>
                                        <TableCell>{item.ben_ifsc}</TableCell>
                                        <TableCell>{item.ben_bank_acc}</TableCell>
                                        <TableCell>{item.ben_upi}</TableCell>
                                        <TableCell>{item.ben_phone}</TableCell>

                                        <TableCell>{item.ben_email}</TableCell>
                                        <TableCell>{item.transfer_mode}</TableCell>
                                        <TableCell>{item.status}</TableCell>
                                        <TableCell>{item.amount}</TableCell>
                                        <TableCell>{item.vendor_charges}</TableCell>
                                        <TableCell>{item.goods_service_tax}</TableCell>


                                        <TableCell>
                                            {item.transfer_desc}
                                        </TableCell>
                                        <TableCell>  {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                        : <TableLoading count={9} />

                    }
                </Table>

                <div className="flex justify-center my-3">
                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div></div>}
        </div>
    );
};

export default MerchantTestPayoutPayments;
