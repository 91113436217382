import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import moment from 'moment';
import { createReseller, getMerchantAllocatedToReseller, getMerchantList, allocateMerchantToReseller } from 'Apis/employeeapi';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { useToast } from "@/components/ui/use-toast";
import { cn } from "@/lib/utils";

export default function ViewAndAddMerchants(props) {
    const { toast } = useToast();
    const [open, setOpen] = useState(false);

    const [resellerId, setResellerId] = useState();
    const [merchants, setMerchants] = useState([]);
    const [merchantList, setMerchantList] = useState([]);


    useEffect(() => {
        setResellerId(props.resellerId);
    }, [props])


    const getData = async (resellerId) => {
        const fetchMerchants = await getMerchantAllocatedToReseller(
            resellerId
        );
        setMerchants(fetchMerchants.data);


        const fetchMerchantslist = await getMerchantList()
        setMerchantList(fetchMerchantslist.data);
    };








    const initialValues =
    {
        merchant: ""
    };


    const validationSchema = Yup.object().shape({
        merchant: Yup.string().required('Merchant is required')
    });



    const submitResellerForm = async (values) => {
        console.log(values)
        const sendData = await allocateMerchantToReseller(resellerId, values.merchant)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        getData(resellerId)
    }





    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button className="w-32" variant="outline" onClick={() => { getData(resellerId) }}>View</Button>
            </DialogTrigger>
            <DialogContent className={
                "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>View And Add Merchants </DialogTitle>
                    <DialogDescription>
                        Select And Add Merchants
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={submitResellerForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values, setFieldValue } = formik;

                            return (
                                <div className=" mt-1  p-2 ">
                                    <Form>
                                        <div className="grid grid-cols-3 gap-4 my-1">


                                            <div>

                                                <div className="">
                                                    <Select

                                                        onValueChange={(field) => {
                                                            setFieldValue('merchant', field)
                                                        }}
                                                        defaultValue={""}
                                                    >
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Select a Mode" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                <SelectLabel>Merchants</SelectLabel>
                                                                <SelectItem value="">Select Merchant</SelectItem>
                                                                {merchantList.length > 0 && merchantList.map((merchant) => (
                                                                    <SelectItem value={merchant._id} key={merchant._id}>{merchant.name}</SelectItem>
                                                                ))}

                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </div>

                                                {errors.merchant && touched.merchant && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.merchant}
                                                    </span>
                                                )}
                                            </div>



                                            <Button
                                                type="submit"
                                                className="btn  w-32  font-medium  "
                                            >
                                                Add
                                            </Button>

                                        </div>





                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                    <Table>
                        <TableCaption></TableCaption>
                        <TableHeader>
                            <TableRow>
                                <TableHead >Sno.</TableHead>
                                <TableHead>Merchant gid</TableHead>
                                <TableHead>Name</TableHead>
                                <TableHead>Email</TableHead>
                                <TableHead>Mobile</TableHead>

                            </TableRow>
                        </TableHeader>


                        <TableBody>
                            {merchants.length > 0 &&
                                merchants.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>{item.merchant_id.merchant_gid}</TableCell>
                                        <TableCell>{item.merchant_id.name}</TableCell>
                                        <TableCell>{item.merchant_id.email}</TableCell>
                                        <TableCell>{item.merchant_id.mobile_no}</TableCell>



                                    </TableRow>
                                ))}
                        </TableBody>




                    </Table>



                </div>

            </DialogContent>
        </Dialog>
    )
}
