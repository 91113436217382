import React, { useState, useEffect } from "react";
import { motion } from "framer-motion"
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { ArrowRightLeft, IndianRupeeIcon } from "lucide-react";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { listVendorCharges } from "Apis/employeeapi";
import CardLoading from "LoadingComponents/CardLoading";
import moment from "moment";
import AddVendorCharges from "./AddVendorCharges";
import EditVendorCharges from "./EditVendorCharges";
import DeleteVendorCharges from "./DeleteVendorCharges";

import PaginatedItems from "Pagination/Pagination";

const VendorCharges = () => {

    const [tableLoading, setTableLoading] = useState(false);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [chargeDetails, setChargeDetails] = useState(false);

    const getData = async (skip, limit) => {
        setTableLoading(true)
        const fetchData = await listVendorCharges("", skip, limit);
        setChargeDetails(fetchData.data);
        setCount(fetchData.count)
        setTableLoading(false)

    };

    const refreshData = () => {
        getData()
    }


    const fetchdata = (limit, skip) => {
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit);
    };

    useEffect(() => {
        getData(skip, limit);

    }, []);






    return (
        <div className=" mt-4">
            <div className='px-3 py-3'>
                <Card><CardContent>
                    <CardHeader>
                        <CardTitle>Vendor Charges</CardTitle>
                        <CardDescription>View and Edit Vendor Charges Details</CardDescription>
                    </CardHeader>
                    <div className="my-4">
                        <AddVendorCharges refreshFunction={refreshData} />
                    </div>

                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead >#</TableHead>
                                <TableHead >Vendor Name</TableHead>
                                <TableHead>Upi Charge</TableHead>
                                <TableHead >Date & Time</TableHead>
                                <TableHead >Action</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {chargeDetails.length > 0 && chargeDetails.map((detail, index) => (
                                <TableRow key={detail._id}>
                                    <TableCell >{index + 1}</TableCell>
                                    <TableCell className="font-medium">{detail.vendor_id.bank_name}</TableCell>
                                    <TableCell>{detail.upi_charges.$numberDecimal}</TableCell>
                                    <TableCell >  {moment(detail.created_date).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                    <TableCell > <div className="flex gap-2">
                                        <EditVendorCharges chargeDetails={detail} refreshFunction={refreshData} />
                                        <DeleteVendorCharges chargeDetails={detail} refreshFunction={refreshData} />

                                    </div>  </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className="flex justify-center my-3">

                        <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                    </div>
                </CardContent></Card>


            </div>



        </div>
    );
};

export default VendorCharges;
