import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import { motion } from "framer-motion"
import { ArrowRightLeft, IndianRupeeIcon } from "lucide-react";
import CardLoading from "LoadingComponents/CardLoading";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { getTransactionInfoViaId } from "Apis/merchantapis";
import { Progress } from "@/components/ui/progress"
import MerchantCard from "ReusableComponents/MerchantCard";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import DateTimeRangeInput from "ReusableComponents/DateTimeRangeInput";
import download from 'downloadjs';
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Input } from "@/components/ui/input";
import { getPayoutTransactions, getResellerMerchantList, exportPayoutTransactions } from "Apis/resellerapi";
import MerchantPayoutTransactionDialog from "ReusableComponents/MerchantPayoutTransactionDialog";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import { Skeleton } from "@/components/ui/skeleton";
import { io } from "socket.io-client";
import TableLoading from "LoadingComponents/TableLoading";

const ResellerPayoutTransactions = () => {
    const socket = io(`${process.env.REACT_APP_PAYMENT_URL}`);
    const [open, setOpen] = useState(false);
    const [percent, setPercent] = useState('');
    const [transactionInfo, setTransactionInfo] = useState('');
    const [merchants, setMerchants] = useState("");
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });

    const [paymentIdQ, setpaymentIdQ] = useState("");
    const [statusQ, setstatusQ] = useState("");
    const [selectedMerchant, setSelectedMerchant] = useState("");

    const [viewTable, setViewTable] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [stats, setStats] = useState([]);

    const getDateRange = (dateRangeObject) => {
        console.log(dateRangeObject, 'parent');
        setDate(dateRangeObject)

    };

    const getData = async (skip, limit, dates, merchantId) => {
        setTableLoading(true)

        if (dates != undefined) {



            const fetchTransactions = await getPayoutTransactions(
                skip,
                limit,
                dates,
                merchantId,
            );
            setTransactions(fetchTransactions.data);
            setStats(fetchTransactions.totalStats);
            setCount(fetchTransactions.count);
            setTableLoading(false)
        }
    };

    const fetchMerchantsList = async () => {
        const fetchList = await getResellerMerchantList();
        setMerchants(fetchList.data)
    }

    useEffect(() => {
        fetchMerchantsList();
    }, []);

    const fetchdata = (limit, skip) => {
        console.log(limit, skip);
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, date, paymentIdQ, statusQ);
    };

    const onClickView = () => {
        if (viewTable === true) {
            setViewTable(false)
        }
        else {
            setViewTable(true)
        }
    }



    const onClickTransactionId = async (id) => {
        console.log(id)
        const fetchdata = await getTransactionInfoViaId(id);
        setTransactionInfo(fetchdata.data)
        console.log(fetchdata)

    }



    // useEffect(() => {
    //     const timer = setInterval(() => {
    //         const userId = JSON.parse(localStorage.getItem("user")).userId;
    //         socket.on(userId, function (msg) {
    //             console.log(msg);
    //             setPercent(msg);

    //         });
    //     }, 200);

    //     return () => {
    //         clearInterval(timer);
    //     };
    // }, []);


    const onClickDownload = async () => {
        console.log('download clicked')

        setPercent('Fetching Data')

        const exportCall = await exportPayoutTransactions(
            date,
            selectedMerchant
        );

        setPercent('Downloading File')
        const blob = await exportCall.blob();

        download(blob, 'data.csv', 'text/csv');

        setOpen(false)
        setPercent('')
    }

    useEffect(() => {
        if (viewTable === true) {
            getData(skip, limit, date, selectedMerchant);
        }
    }, [date, limit, selectedMerchant, skip, viewTable]);

    return (
        <div className="rounded-md border mt-4">
            <div className="grid grid-cols-1 md:grid-cols-4 pt-3 pb-16 px-3 gap-8">
                <DateTimeRangeInput getFunction={getDateRange} />
                <div>
                    <div>
                        <Select
                            onValueChange={(field) => {
                                setSelectedMerchant(field)
                            }}
                            defaultValue={""}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select a Status" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Merchants</SelectLabel>
                                    <SelectItem value="">All</SelectItem>
                                    {merchants.length > 0 && merchants.map((merchant) => (
                                        <SelectItem key={merchant.merchant_id._id} value={merchant.merchant_id._id}>{merchant.merchant_id.name}</SelectItem>
                                    ))}

                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>

                <div>
                    <Button className="mx-2" onClick={() => { onClickView() }}>View</Button>
                    <Button className="mx-2" disabled={percent !== ''} variant="outline" onClick={() => { onClickDownload() }}>{percent !== '' ? percent : 'Download'}</Button>
                </div>
            </div>





            {viewTable === true && <div>

                <Table>
                    <TableCaption>A list of your recent payments.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead >Sno.</TableHead>
                            <TableHead>Reference Id</TableHead>
                            <TableHead>Transfer Id</TableHead>
                            <TableHead>Name</TableHead>

                            <TableHead>Mode</TableHead>
                            <TableHead>Status</TableHead>
                            <TableHead>Amount</TableHead>
                            <TableHead>Charges</TableHead>
                            <TableHead>GST</TableHead>
                            <TableHead>Merchant</TableHead>
                            <TableHead>Add On</TableHead>
                        </TableRow>
                    </TableHeader>

                    {tableLoading === false ?
                        <TableBody>
                            {transactions.length > 0 &&
                                transactions.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>

                                            <Dialog>
                                                <DialogTrigger >
                                                    <Button variant="link">{item.reference_id}</Button>
                                                </DialogTrigger>
                                                <DialogContent className={
                                                    "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                                                }>
                                                    <DialogHeader>
                                                        <DialogTitle>Payout Transactions Info</DialogTitle>
                                                        <DialogDescription>
                                                            All Details about this transaction
                                                        </DialogDescription>
                                                    </DialogHeader>
                                                    < MerchantPayoutTransactionDialog payoutTransactions={item} />
                                                    <DialogFooter>

                                                    </DialogFooter>
                                                </DialogContent>
                                            </Dialog>


                                        </TableCell>
                                        <TableCell>{item.transfer_id}</TableCell>
                                        <TableCell>{item.ben_name}</TableCell>

                                        <TableCell>{item.transfer_mode}</TableCell>
                                        <TableCell>{item.status}</TableCell>
                                        <TableCell>{item.amount}</TableCell>
                                        <TableCell>{item.vendor_charges}</TableCell>
                                        <TableCell>{item.goods_service_tax}</TableCell>


                                        <TableCell>
                                            < MerchantCard merchant={item.merchant_id} />
                                        </TableCell>
                                        <TableCell>  {moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                        : <TableLoading count={9} />

                    }
                </Table>

                <div className="flex justify-center my-3">
                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div></div>}


            <Dialog open={open} onOpenChange={setOpen} disableOutsidePointerEvents >

                <DialogContent
                    className={
                        "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                    }
                >
                    <DialogHeader>
                        <DialogTitle>Exporting Transactions</DialogTitle>
                        <DialogDescription>

                        </DialogDescription>
                    </DialogHeader>
                    <h5 className="text-lg font-bold">{percent} %</h5>
                    <Progress value={percent} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ResellerPayoutTransactions;
