import React, { useState, useEffect } from "react";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card"
import { Button } from "@/components/ui/button";
const EmployeeCard = (props) => {
    const [employee, setEmployee] = useState(null);


    useEffect(() => {
        setEmployee(props.employee);
    }, [props]);


    return (

        <div>
            {employee !== null ?
                (<HoverCard>
                    <HoverCardTrigger asChild>
                        <Button variant="link">{employee.employee_username}</Button>
                    </HoverCardTrigger>
                    <HoverCardContent className="w-80">
                        <div className="my-2">
                            <h5 className="text-lg font-bold ">Employee Details</h5>
                        </div>
                        <div className="my-2">
                            <h5 className="text-xs">Employee UserName</h5>
                            <h5 className="text-sm">{employee.employee_username}</h5>
                        </div>
                        <hr />
                        <div className="my-2">
                            <h5 className="text-xs">First Name</h5>
                            <h5 className="text-sm">{employee.first_name}</h5>
                        </div>
                        <hr />
                        <div className="my-2">
                            <h5 className="text-xs">Last Name</h5>
                            <h5 className="text-sm">{employee.last_name}</h5>
                        </div>
                        <hr />
                        <div className="my-2">
                            <h5 className="text-xs">Designation</h5>
                            <h5 className="text-sm">{employee.designation}</h5>
                        </div>
                        <hr />
                        <div className="my-2">
                            <h5 className="text-xs">Official Email</h5>
                            <h5 className="text-sm">{employee.official_email}</h5>
                        </div>
                    </HoverCardContent>
                </HoverCard>) : ''}
        </div>

    );
};

export default EmployeeCard;