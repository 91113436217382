
import ThemeToggle from "@/theme-toggle";
import IpAddress from "Employee/Layout/HeaderComponents/IpAdress";
import CurrentDateTime from "Employee/Layout/HeaderComponents/CurrentTimeDate";
import React, { useState, useEffect, useMemo } from "react";
import { getAppVariables } from "Apis/resellerapi";

export function ResellerHeader() {
  const url = `${process.env.REACT_APP_BACKEND_URL}`;
  const [appVariables, setAppVariables] = useState();

  const getData = async () => {
    const fetchData = await getAppVariables();
    setAppVariables(fetchData.data);
  };

  useEffect(() => {
    getData();
  }, []);

  // Memoize the data so that it's not recalculated on every render
  const memoizedData = useMemo(() => appVariables, [appVariables]);



  return (
    <div className="grid">
      <header className='sticky top-0 z-40 w-full border-b bg-background'>
        <div className=' flex h-16 items-center space-x-4 sm:justify-between sm:space-x-0'>
          {memoizedData !== undefined && memoizedData !== null ?
            <div className="ml-5">
              {memoizedData.logo !== '' ? <img width="150" height="120" src={`${url}/${memoizedData.logo}`} alt="No logo" /> : <h1>Upload Logo</h1>}
            </div> : <h1>Upload Logo</h1>
          }
          <div className='flex flex-1 items-center justify-end space-x-4'>
            <nav className='flex items-center space-x-1'>
              <CurrentDateTime />
              <IpAddress />
              <ThemeToggle />
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
}