import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { Button } from "@/components/ui/button"
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card"
import { getMerchantNotifications } from "Apis/employeeapi";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import { Eye, EyeOff } from "lucide-react";
import EmployeeCard from "ReusableComponents/EmployeeCard";
import MerchantCard from "ReusableComponents/MerchantCard";
import SendNotifications from "./SendNotifications";
import Breadcrumb from "LoadingComponents/Breadcrumb";
import { Vibrate,Users } from "lucide-react";

const MerchantNotifications = () => {

  const BreadcrumbArray = [ { name: 'Merchant', icon: <Users /> },{ name: 'Merchant Notifications', icon: <Vibrate /> }]

  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState("");
  const [notifications, setNotifications] = useState([]);

  const getData = async (skip, limit) => {
    const fetchData = await getMerchantNotifications("", skip, limit);

    setNotifications(fetchData.data);
    setCount(fetchData.count);
  };

  const refreshData = () => {
    getData(skip, limit);
  }


  const fetchdata = (limit, skip) => {
    setSkip(skip);
    setLimit(limit);
    getData(skip, limit);
  };

  useEffect(() => {
    getData(skip, limit);
  }, []);

  return (
    <div className=" mt-4 rounded-md border">
      <Breadcrumb data={BreadcrumbArray} />
      <div className="px-3 pt-3">
        <SendNotifications refreshFunction={refreshData} />
      </div>
      <Table>
        <TableCaption>Check your Notifications Here</TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Sno.</TableHead>
            <TableHead>Notification</TableHead>
            <TableHead> Type</TableHead>
            <TableHead> From</TableHead>
            <TableHead> To</TableHead>
            <TableHead> Seen</TableHead>
            <TableHead>Created At</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {notifications.length > 0 &&
            notifications.map((item, index) => (
              <TableRow key={item._id}>
                <TableCell className="font-medium">{index + 1}</TableCell>
                <TableCell>{item.message}</TableCell>
                <TableCell>{item.notify_type}</TableCell>
                <TableCell>
                  <EmployeeCard employee={item.notify_from} />
                </TableCell>
                <TableCell>
                  <MerchantCard merchant={item.notify_to} />
                </TableCell>
                <TableCell>{item.seen ? <Eye className="text-green-600" /> : <EyeOff className="text-red-600" />}</TableCell>
                <TableCell>
                  {moment(item.created_date).format("YYYY-MM-DD HH:mm:ss")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <div className="flex justify-center my-3">
        <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
      </div>
    </div>
  );
};

export default MerchantNotifications;
