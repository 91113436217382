import React from 'react';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { CodeBlock, dracula, anOldHope, a11yDark } from 'react-code-blocks';

const GetIntent = () => {
    return (
        <div>
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Transaction Get UPI Intent API
            </h1>
            <p className="leading-7 [&:not(:first-child)]:mt-3">
                This API is used to get UPI Intent
            </p>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    POST METHOD
                </h3>

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Url : https://pg.us.in/payment/gateway/v1/getIntent
                </h3>
            </div>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Parameters to be posted
                </h3>

                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[100px]">Parameter Name</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Data Type</TableHead>
                            <TableHead>Optional / Mandatory</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell className="font-medium">clientId</TableCell>
                            <TableCell>we would assign a unique 40-digit merchant key to you. This key is exclusive to your business/login account. If you have multiple login accounts, there will necessarily be one different clientId per login account BY us. clientId and api_key are the same.</TableCell>
                            <TableCell>varchar(40)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">clientSecret</TableCell>
                            <TableCell>Assigned by us. Max 40 digits.</TableCell>
                            <TableCell>varchar(40)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">emailId</TableCell>
                            <TableCell>Email Id of the account holder.</TableCell>
                            <TableCell>varchar(100)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">mobileNumber</TableCell>
                            <TableCell>Mobile number of the account holder.</TableCell>
                            <TableCell>varchar(10)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">amount</TableCell>
                            <TableCell>Value of funds being transferred.</TableCell>
                            <TableCell>decimal(10,2)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">txnCurr</TableCell>
                            <TableCell>Transfer currency. Static. Allowed values: INR.</TableCell>
                            <TableCell>varchar(150)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">username</TableCell>
                            <TableCell>Customer name.</TableCell>
                            <TableCell>varchar(150)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">signature</TableCell>
                            <TableCell>Compute a hash of all your parameters and pass that hash to us. Note: the Hash will be provided by us separately.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">udf1</TableCell>
                            <TableCell>Optional parameter for additional use.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">udf2</TableCell>
                            <TableCell>Optional parameter for additional use.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">udf3</TableCell>
                            <TableCell>Optional parameter for additional use.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">udf4</TableCell>
                            <TableCell>Optional parameter for additional use.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">udf5</TableCell>
                            <TableCell>Optional parameter for additional use.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Javascript Code for Hash Generation:
                </h3>

                <CodeBlock
                    text={`function getSignature( reqHashKey, clientKey, clientSecret, txnCurr,amount, emailId,mobileNumber ) {
                            const message =
                                clientKey + clientSecret + txnCurr + amount + emailId + mobileNumber;
                        
                            // to lowercase hexits
                            const signature = crypto
                                .createHmac("sha256", reqHashKey)
                                .update(message)
                                .digest("hex");
                        
                            return signature;
                        }`}
                    language={"javascript"}
                    showLineNumbers={false}
                    theme={anOldHope}
                />

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Please Note : don't desclose requestHashKey to any one or do not show in app or web.
                </h3>
            </div>


            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Request Parameter to be Post to Intent URL
                </h3>

                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Parameter Name</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Example</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>clientId</TableCell>
                            <TableCell>Client Id provided by us</TableCell>
                            <TableCell>abc23TestClientIdencrypt</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>clientSecret</TableCell>
                            <TableCell>Client Secret assigned by us</TableCell>
                            <TableCell>SDFGJSHFGKJHSERJJSNFKSGBJDKVSNSDKJFJGHSKJGHRSKJHKSFNGJSKGHSKJDHGKSURGHKSDGHKSJRHGSKJFHGKJS</TableCell>
                        </TableRow>

                    </TableBody>

                </Table>
            </div>


            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Response:
                </h3>

                <CodeBlock
                    text={`{
                        "intent": "upi://pay?ver=01&mode=04&am=100.00&mam=100.00&cu=INR&pa=.lucky@timecosmos&pn=LUCKY STREAK PRIVATE LIMITED&mc=6012&tr=Payripe289796852&tn=intentflow",
                        "txnId": "PPTbqTG9exjvHTMe4qVYW3vk",
                        "udf1": "optional param",
                        "udf2": "optional param",
                        "udf3": "optional param",
                        "udf4": "optional param",
                        "udf5": "optional param"
                      } `}
                    language={"text"}
                    showLineNumbers={false}
                    theme={anOldHope}
                />

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Please Note : don't desclose requestHashKey to any one or do not show in app or web.
                </h3>
            </div>


        </div>
    );
};

export default GetIntent;