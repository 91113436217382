import React, { useState, useEffect } from 'react';
import { getMerchantLoginActivity } from 'Apis/employeeapi';
import { motion } from "framer-motion"
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Card,
    CardContent
} from "@/components/ui/card"
import moment from "moment";
import PaginatedItems from "Pagination/Pagination";

const MerchantLoginActivity = (props) => {
    const [merchantLoginActivity, setMerchantLoginActivity] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [merchantId, setMerchantId] = useState(props.merchantId);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");

    const getData = async (merchantId, skip, limit) => {
        setTableLoading(true)
        const fetchMerchantData = await getMerchantLoginActivity(merchantId, skip, limit);
        setMerchantLoginActivity(fetchMerchantData.data);
        setCount(fetchMerchantData.count)
        setTableLoading(false)

    };

    console.log(merchantLoginActivity)

    const fetchdata = (limit, skip) => {
        setSkip(skip);
        setLimit(limit);
        getData(merchantId, skip, limit);
    };

    useEffect(() => {
        getData(merchantId, skip, limit);

    }, []);

    useEffect(() => {
        getData(merchantId, skip, limit);

    }, [merchantId]);
    return (
        <div>
            <Card><CardContent>
                <Table>
                    <TableHeader>
                        <TableRow>
                        <TableHead >#</TableHead>
                            <TableHead >Ip Address</TableHead>
                            <TableHead>Device</TableHead>
                            <TableHead>Operating System</TableHead>
                            <TableHead >Browser</TableHead>
                            <TableHead >Date & Time</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {merchantLoginActivity.length > 0 && merchantLoginActivity.map((activity,index) => (
                            <TableRow key={activity._id}>
                                <TableCell className="font-medium">{index + 1}</TableCell>
                                <TableCell >{activity.log_ipaddress}</TableCell>
                                <TableCell>{activity.log_device}</TableCell>
                                <TableCell>{activity.log_os}</TableCell>
                                <TableCell >{activity.log_browser}</TableCell>
                                <TableCell >  {moment(activity.log_time).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div className="flex justify-center my-3">

                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div>
            </CardContent></Card>


        </div>
    );
};

export default MerchantLoginActivity;