import React from "react";
import Webhooks from "./Webhooks";
import ApiKeys from "./ApiKeys";
import TestApiKeys from "./TestApiKeys";
import BusinessDetails from "./BusinessDetails";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useOutletContext } from "react-router-dom";

const Settings = () => {

  const testMode = useOutletContext();
  return (
    <div className="mt-6 px-6">
      <Tabs defaultValue="businessdetails" className="w-full">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="businessdetails">Business Details</TabsTrigger>
          {!testMode && <TabsTrigger value="webhook">Webhook</TabsTrigger>}

          <TabsTrigger value="apikeys">Api Keys</TabsTrigger>
        </TabsList>
        <TabsContent value="businessdetails">
          <BusinessDetails />
        </TabsContent>
        <TabsContent value="webhook">
          <Webhooks />
        </TabsContent>
        <TabsContent value="apikeys">
          {testMode ? <TestApiKeys /> : <ApiKeys />}


        </TabsContent>

      </Tabs>
    </div>
  );
};

export default Settings;
