import React from 'react';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { CodeBlock, anOldHope } from 'react-code-blocks';

const VerifyVAP = () => {
    return (
        <div className=''>
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                VerifyVAP (UPI ID) API
            </h1>
            <p className="leading-7 [&:not(:first-child)]:mt-3">
                This API is used to VerifyVAP (UPI ID)
            </p>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    POST METHOD
                </h3>

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Url : https://pg.payripe.in/payment/gateway/v1/verifyVpa
                </h3>
            </div>


            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Request Parameters
                </h3>

                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[100px]">Parameter Name</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Data Type</TableHead>
                            <TableHead>Optional / Mandatory</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell className="font-medium">clientId</TableCell>
                            <TableCell>Your client ID provided by us</TableCell>
                            <TableCell>varchar(40)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">encrypt</TableCell>
                            <TableCell>Encryption string for UPI ID and Order ID</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Response:
                </h3>

                <CodeBlock
                    text={`{
                        "secureData": "6F3FE5E6CB16AF77AE6076F6868B0B7139F52AFC9CE4BB6BFCB213C03397EAEDE625C874EA158953309
                        51BEE17A738DC1A507DC63537D229084581ACD510914244CD71E7AFC269224082CF80AC0F894243B338879EAA0B0B59B2D
                        1CF0BA9A81D8ADC33094F71A8D09E293F676FB1F391",
                        "clientId": "ryapay_live_WldFBYD8bpkHH52b",
                        "statusCode": 200
                      } `}
                    language={"text"}
                    showLineNumbers={false}
                    theme={anOldHope}
                />

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Note: Take secureData and Decrypt using the provided decryption method.
                </h3>
            </div>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Decrypted Response:
                </h3>

                <CodeBlock
                    text={`{
                        "upiId": "manojdaiya43-2@okicici",
                        "order_gid": "OrderIdsdfhjfhuief",
                        "customerName": "MANOJ KUMAR",
                        "status": "SUCCESS"
                      } `}
                    language={"text"}
                    showLineNumbers={false}
                    theme={anOldHope}
                />

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Note: If customerName is not found, you will get "0000".
                </h3>
            </div>
        </div>
    );
};

export default VerifyVAP;
