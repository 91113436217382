import React, { useState, useEffect } from "react";
import { Badge } from "@/components/ui/badge";

function IpAddress() {
  const [ipAddress, setIpAddress] = useState("");

  useEffect(() => {
    async function fetchIpAddress() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        setIpAddress(data.ip);
      } catch (error) {
        console.error("Error fetching IP address:", error);
      }
    }

    fetchIpAddress();
  }, []);

  return (
    <div>
      <Badge variant="">IP: {ipAddress}</Badge>
    </div>
  );
}

export default IpAddress;
