import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { listMerchantVendorBanks, addVendorCharges } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";

export default function AddVendorCharges(props) {
    const { toast } = useToast();
    const [banks, setBanks] = useState();
    const [open, setOpen] = useState(false);



    const getData = async () => {
        const fetchData = await listMerchantVendorBanks();
        setBanks(fetchData.data)
    }

    useEffect(() => {
        getData()
    }, [])


    const initialChargeDetailsValues =
    {
        vendor_id: "",
        upi_charges: ""
    }
        ;


    const chargeDetailSchema = Yup.object().shape({
        vendor_id: Yup.string().required('This Field is required')
        ,
        upi_charges: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        )
    });

    const fieldArray1 = [
        "upi_charges"
    ];



    function transformString(inputString) {
        // Split the input string by underscores
        const parts = inputString.split('_');

        // Capitalize the first letter of each part and join them with a space
        const transformedString = parts.map(part => {
            return part.charAt(0).toUpperCase() + part.slice(1);
        }).join(' ');

        return transformedString;
    }

    const submitChargeDetailsForm = async (values) => {

        const sendData = await addVendorCharges(values)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        setOpen(false)
        props.refreshFunction();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Add</Button>
            </DialogTrigger>
            <DialogContent className={
                "max-w[425px] overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Vendor Charges Form</DialogTitle>
                    <DialogDescription>
                        Fill the Form & Click submmit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        initialValues={initialChargeDetailsValues}
                        validationSchema={chargeDetailSchema}
                        onSubmit={submitChargeDetailsForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values, setFieldValue } = formik;

                            return (
                                <div className=" mt-1  p-5 ">
                                    <Form>
                                        <div className="grid grid-cols-1 gap-4">
                                            <div>
                                                <Label htmlFor="vendor_id">Merchant:</Label>
                                                <div className="mt-3">
                                                    <Select

                                                        onValueChange={(field) => {
                                                            setFieldValue('vendor_id', field)
                                                        }}
                                                        defaultValue={""}
                                                    >
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Select a Status" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                <SelectLabel>Vendors</SelectLabel>
                                                                <SelectItem value="">Select Vendor</SelectItem>
                                                                {banks.map((bank) => (
                                                                    <SelectItem value={bank._id} key={bank._id}>{bank.bank_name}</SelectItem>
                                                                ))}

                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </div>

                                                {errors.vendor_id && touched.vendor_id && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.vendor_id}
                                                    </span>
                                                )}
                                            </div>

                                            {fieldArray1.map((fieldName, index) => (
                                                <div key={index}>
                                                    <Label htmlFor={fieldName}>{transformString(fieldName)}:</Label>
                                                    <Input
                                                        className="mt-3"
                                                        type="text"
                                                        id={fieldName}
                                                        name={fieldName}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values[fieldName]}
                                                    />
                                                    {errors[fieldName] && touched[fieldName] && (
                                                        <span className="text-xs text-red-400">
                                                            {errors[fieldName]}
                                                        </span>
                                                    )}
                                                </div>
                                            ))}




                                        </div>



                                        
                                        
                                        <div className='flex justify-center'>
                                            <Button
                                                type="submit"
                                                className="btn mt-5 w-48  font-medium  "
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                </div>

            </DialogContent>
        </Dialog>
    )
}
