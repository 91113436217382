import React, { useState, useEffect } from 'react';
import { PDFDownloadLink, PDFViewer, Document, Page } from '@react-pdf/renderer';
import generatePdf from './TransactionPdf';
import { AvatarImage, AvatarFallback, Avatar } from "@/components/ui/avatar"
import { CardContent, Card } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
import { FileText, Image } from 'lucide-react';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const TranactionModal = (props) => {
    const [transactionInfo, setTransactioninfo] = useState(null);

    useEffect(() => {
        setTransactioninfo(props.transaction)
    }, [props])

    const generatePdf = () => {
        const documentDefinition = {
            content: [
                { text: 'Transaction Details', style: 'header' },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        body: [
                            [
                                { text: 'Transaction Gid', style: 'tableHeader' },
                                { text: 'Order Gid', style: 'tableHeader' },
                                { text: 'Vendor Transaction Id', style: 'tableHeader' },
                                { text: 'Bank Ref No', style: 'tableHeader' },
                            ],
                            [
                                transactionInfo.transaction_gid || '',
                                transactionInfo.order_gid || '',
                                transactionInfo.vendor_transaction_id || '',
                                transactionInfo.bank_ref_no || '',
                            ],
                        ],
                    },
                    layout: 'headerLineOnly',
                    margin: [0, 10],
                },
                { text: 'User Info', style: 'header', margin: [0, 20] },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        body: [
                            [
                                { text: 'Username', style: 'tableHeader' },
                                { text: 'Email', style: 'tableHeader' },
                                { text: 'Contact', style: 'tableHeader' },
                            ],
                            [
                                transactionInfo.transaction_username || '',
                                transactionInfo.transaction_email || '',
                                transactionInfo.transaction_contact || '',
                            ],
                        ],
                    },
                    layout: 'headerLineOnly',
                    margin: [0, 10],
                },
                { text: 'Transaction Info', style: 'header', margin: [0, 20] },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        body: [
                            [
                                { text: 'Amount', style: 'tableHeader' },
                                { text: 'Status', style: 'tableHeader' },
                                { text: 'Type', style: 'tableHeader' },
                                { text: 'Mode', style: 'tableHeader' },
                                { text: 'Notes', style: 'tableHeader' },
                                { text: 'Description', style: 'tableHeader' },
                                { text: 'Adjustment', style: 'tableHeader' },
                            ],
                            [
                                transactionInfo.transaction_amount || '',
                                transactionInfo.transaction_status || '',
                                transactionInfo.transaction_type || '',
                                transactionInfo.transaction_mode || '',
                                transactionInfo.transaction_notes || '',
                                transactionInfo.transaction_description || '',
                                transactionInfo.adjustment_done || '',
                            ],
                        ],
                    },
                    layout: 'headerLineOnly',
                    margin: [0, 10],
                },
                { text: 'Charges Info', style: 'header', margin: [0, 20] },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        body: [
                            [
                                { text: 'Merchant Charge', style: 'tableHeader' },
                                { text: 'Merchant Charge Amount', style: 'tableHeader' },
                                { text: 'Gst', style: 'tableHeader' },
                                { text: 'Gst Amount', style: 'tableHeader' },
                                { text: 'Total Charges', style: 'tableHeader' },
                                { text: 'Net Settlement', style: 'tableHeader' },
                                { text: 'Transaction Amount', style: 'tableHeader' },
                            ],
                            [
                                transactionInfo.merchant_charge || '',
                                transactionInfo.merchant_charge_amount || '',
                                transactionInfo.gst || '',
                                transactionInfo.gst_amount || '',
                                transactionInfo.total_charges || '',
                                transactionInfo.net_settlement || '',
                                transactionInfo.transaction_amount || '',
                            ],
                        ],
                    },
                    layout: 'headerLineOnly',
                    margin: [0, 10],
                },
                { text: 'Merchant Info', style: 'header', margin: [0, 20] },
                {
                    style: 'tableExample',
                    table: {
                        headerRows: 1,
                        body: [
                            [
                                { text: 'Merchant Name', style: 'tableHeader' },
                            ],
                            [
                                transactionInfo.merchant_name || '',
                            ],
                        ],
                    },
                    layout: 'headerLineOnly',
                    margin: [0, 10],
                },
            ],
            styles: {
                header: {
                    fontSize: 18,
                    bold: true,
                },
                tableHeader: {
                    bold: true,
                    fillColor: '#f2f2f2',
                },
                tableExample: {
                    margin: [0, 5, 0, 15],
                },
            }
        };

        return documentDefinition;
    }


    const takeScreenshot = () => {
        const input = document.getElementById('divToScreenshot');

        html2canvas(input, { scale: 6 })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                saveAs(imgData, `${transactionInfo.transaction_gid}.png`);
            });
    }



    const downloadPdf = () => {
        const pdfDoc = generatePdf();
        pdfMake.createPdf(pdfDoc).download(`${transactionInfo.transaction_gid}.pdf`);
    };

    return (
        transactionInfo !== null &&
        <div>
            <div className="flex gap-2">
                <Button title="Download Pdf " onClick={downloadPdf}><FileText /></Button>
                <Button title="Take Screenshot " onClick={() => { takeScreenshot() }}><Image /></Button>
            </div>



            <div id="divToScreenshot" className='px-8'>
                <div >
                    <div className="grid grid-cols-8 gap-1 py-4">
                        <div className="my-2 col-span-2">
                            <h5 className="text-xs">Transaction Gid</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.transaction_gid}</h5>
                        </div>

                        <div className="my-2 col-span-2">
                            <h5 className="text-xs">Order Gid</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.order_gid}</h5>
                        </div>

                        <div className="my-2 col-span-3">
                            <h5 className="text-xs">Vendor Transaction Id</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.vendor_transaction_id}</h5>
                        </div>

                        <div className="my-2 col-span-1">
                            <h5 className="text-xs">Bank Ref No</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.bank_ref_no}</h5>
                        </div>
                    </div>
                </div>

                <hr />

                <div>
                    <h5>User Info</h5>
                    <div className="grid grid-cols-4 gap-1 py-4">
                        <div className="my-2">
                            <h5 className="text-xs"> Username</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.transaction_username}</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs"> Email</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.transaction_email}</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Contact</h5>
                            <h5 className="text-sm font-medium break-words" >{transactionInfo.transaction_contact}</h5>
                        </div>
                    </div>
                </div>

                <hr />
                <div>
                    <h5>Transaction Info</h5>
                    <div className="grid grid-cols-4 gap-1 py-4">
                        <div className="my-2">
                            <h5 className="text-xs">Amount</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.transaction_amount} ₹</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Status</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.transaction_status}</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Type</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.transaction_type}</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Mode</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.transaction_mode}</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Notes</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.transaction_notes}</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Description</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.transaction_description}</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Adjustment</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.adjustment_done}</h5>
                        </div>


                    </div>
                </div>
                <hr />
                <div>
                    <h5>Charges Info</h5>
                    <div className="grid grid-cols-4 gap-1 py-4">
                        <div className="my-2">
                            <h5 className="text-xs">Merchant Charge</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.merchant_charge} % </h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Merchant Charge Amount</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.merchant_charge_amount} ₹</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Gst</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.gst} %</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Gst Amount</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.gst_amount} ₹</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Total Charges</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.total_charges} ₹</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Net Settlement</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.net_settlement} ₹</h5>
                        </div>

                        <div className="my-2">
                            <h5 className="text-xs">Transaction Amount</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.transaction_amount} ₹</h5>
                        </div>


                    </div>
                </div>
                <hr />
                <div>
                    <h5>Merchant Info</h5>
                    <div className="grid grid-cols-4 gap-1 py-4">
                        <div className="my-2">
                            <h5 className="text-xs">Merchant Name</h5>
                            <h5 className="text-sm font-medium break-words">{transactionInfo.merchant_name}  </h5>
                        </div>




                    </div>
                </div>
            </div>
        </div>



    );
};

export default TranactionModal;