import React from "react";
import { Skeleton } from "@/components/ui/skeleton";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"

const CardLoading = (props) => {
    const { count } = props;

    // Create an array of numbers from 1 to count
    const rows = Array.from({ length: count }, (_, index) => index + 1);
    return (
        <>
            {rows.map(row => (


                <Card>
                    <Skeleton className="w-full h-[100px] " />
                </Card>




            ))}
        </>
    );
};

export default CardLoading;
