import React from 'react';
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TableCell,
} from "@/components/ui/table"
import { CodeBlock, dracula, anOldHope, a11yDark } from 'react-code-blocks';

const TransactionStatus = () => {
    return (
        <div>
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Transaction Status Check API
            </h1>
            <p className="leading-7 [&:not(:first-child)]:mt-3">
                This API is used to check the status of a transaction.
            </p>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    POST METHOD
                </h3>

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Url : https://pg.payripe.in/payment/gateway/v1/getTxnStatus
                </h3>
            </div>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    JSON Request:
                </h3>

                <CodeBlock
                    text={`{
                        "bankId": "",
                        "clientId": "Nexa_live_ROlEfIkRuKDaBJEF",
                        "signature": "c13719ea3ecf5462ca9d99b35cc2a4e52fac0bd7fdb90cbaae60d9a4c2d67a00",
                        "mobileNumber": "",
                        "emailId": "",
                        "order_gid": "TestingVPA2",
                        "txnId": ""
                    }`}
                    language={"json"}
                    showLineNumbers={false}
                    theme={anOldHope}
                />
            </div>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Response:
                </h3>

                <CodeBlock
                    text={`{
                        "data": [
                            {
                                "bankId": "328216519500",
                                "vpa": "manojdaiya43-2@okicici",
                                "transactionId": "PPTDMXn1U0gTG4hvvCy8txoI",
                                "txnDate": "09-10-2023 07:21:36",
                                "status": "failed",
                                "desc": "Transaction Failed"
                            }
                        ],
                        "statusCode": 200
                    }`}
                    language={"json"}
                    showLineNumbers={false}
                    theme={anOldHope}
                />

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Note: On success of this API, you will get statusCode = 200, else 400.
                </h3>
            </div>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Signature:
                </h3>

                <CodeBlock
                    text={`const signature = encode(requestHashKey, clientId, clientSecret, txnId, bankId, emailId, mobileNumber);`}
                    language={"javascript"}
                    showLineNumbers={false}
                    theme={anOldHope}
                />

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Note: If any parameter is not passed, use a blank string ("").
                </h3>
            </div>
        </div>
    );
};

export default TransactionStatus;
