import React, { useState, useEffect } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useTheme } from 'next-themes'
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { getDashboardGraphData } from 'Apis/merchantapis';

const data = [
    {
        name: 'Page A',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: 'Page B',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: 'Page C',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: 'Page D',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: 'Page E',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: 'Page F',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: 'Page G',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
];

const BarChartComponent = () => {

    const [data, setData] = useState("");

    const { theme, setTheme } = useTheme()
    const [amountColor, setAmountColor] = useState("");
    const [countColor, setCountColor] = useState("");

    useEffect(() => {
        if (theme === 'light') {
            setAmountColor('#000000');
            setCountColor('#adfa1d');
        }
        else {
            setAmountColor('#FFFFFF');
            setCountColor('#adfa1d');
        }
    }, [theme])


    const getData = async () => {
        //userdata
        const DataResponse = await getDashboardGraphData();
        setData(DataResponse.document)
    }

    useEffect(() => {
        getData()
    }, [])


    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">

                    <Card className="h-full">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                {payload[0].payload.name}
                            </CardTitle>

                        </CardHeader>
                        <CardContent>
                            <h5>Total Number of Transactions : {payload[0].payload.count}</h5>

                        </CardContent>
                    </Card>
                </div>
            );
        }

        return null;
    };


    const CustomTooltipChart2 = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip">

                    <Card className="h-full">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                {payload[0].payload.name}
                            </CardTitle>

                        </CardHeader>
                        <CardContent>
                            <h5>Amount of Transactions : {payload[0].payload.totalAmount}</h5>

                        </CardContent>
                    </Card>
                </div>
            );
        }

        return null;
    };


    return (

        <div>
            <Card className="h-full">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                        Number of Transactions for Last 12 Months
                    </CardTitle>

                </CardHeader>
                <CardContent>
                    <div className="container" style={{ width: "100%", height: "400px" }}>
                        <BarChart width={1000} height={400} data={data}>

                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltip />} />
                            <Legend />

                            <Bar dataKey="count" fill={countColor} radius={[4, 4, 0, 0]} />
                        </BarChart>
                    </div>
                </CardContent>
            </Card>


            <Card className="h-full my-8">
                <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                    <CardTitle className="text-sm font-medium">
                        Amount of Transactions for Last 12 Months
                    </CardTitle>

                </CardHeader>
                <CardContent>
                    <div className="container" style={{ width: "100%", height: "400px" }}>
                        <BarChart width={1000} height={400} margin={{
                            top: 0,
                            right: 0,
                            left: 15,
                            bottom: 0
                        }} data={data}>

                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip content={<CustomTooltipChart2 />} />
                            <Legend />

                            <Bar dataKey="totalAmount" fill={countColor} radius={[4, 4, 0, 0]} />
                        </BarChart>
                    </div>
                </CardContent>
            </Card>
        </div>


    );
};

export default BarChartComponent;
