export const ResellerLogin = async (data) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller_login`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
            },
            body: JSON.stringify({
                email: data.email,
                password: data.password
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};


export const getResellerMerchantList = async (dates, userId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/get_merchants`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            }
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getAppVariables = async (dates, userId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/get_app_variables`,
        {
            method: "GET",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            }
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayinDashboardData = async (dates, userId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/payin_dashboard_data`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                userId: userId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayinTransactions = async (skip, limit, search, dates, userId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/payin_transactions`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit,
                startDate: dates.from,
                endDate: dates.to,
                merchantId: userId,
                search: search
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayinTransactionInfo = async (id) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/get_payin_transaction_info_via_id`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            },
            body: JSON.stringify({
                id: id
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayoutDashboardData = async (dates, userId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/payout_dashboard_data`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                merchantId: userId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayoutTransactions = async (skip, limit, dates, userId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/payout_transactions`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit,
                startDate: dates.from,
                endDate: dates.to,
                merchantId: userId
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const getPayoutStatement = async (userId, skip, limit, dates, type) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/payout_statement`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            },
            body: JSON.stringify({
                skip: skip,
                limit: limit,
                startDate: dates.from,
                endDate: dates.to,
                merchantId: userId,
                type: type
            }),
        }
    );
    const returnResponse = await formResponse.json();
    return returnResponse;
};

export const exportPayoutTransactions = async (dates, merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/payout_export_transactions`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                merchantId: merchantId
            }),
        }
    );
    return formResponse;
};

export const exportPayoutStatement = async (dates, type, merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/payout_export_statement`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                type: type,
                merchantId: merchantId
            }),
        }
    );
    return formResponse;
};

export const exportPayinTransactions = async (dates, merchantId) => {
    const formResponse = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/reseller/payin_export_transactions`,
        {
            method: "POST",
            headers: {
                "content-Type": "application/json",
                authorization: JSON.parse(localStorage.getItem("reseller")).token,
            },
            body: JSON.stringify({
                startDate: dates.from,
                endDate: dates.to,
                merchantId: merchantId
            }),
        }
    );
    return formResponse;
};

