import React, { useState, useEffect } from "react";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card"
import { Button } from "@/components/ui/button";
const MerchantCard = (props) => {
    const [merchant, setMerchant] = useState(null);


    useEffect(() => {
        setMerchant(props.merchant);
    }, [props]);


    return (

        <div>
            {merchant !== null ?
                (<HoverCard>
                    <HoverCardTrigger asChild>
                        <Button variant="link">{merchant.name}</Button>
                    </HoverCardTrigger>
                    <HoverCardContent className="w-80">
                        <div className="my-2">
                            <h5 className="text-lg font-bold ">Merchant Details</h5>
                        </div>
                        <div className="my-2">
                            <h5 className="text-xs">Merchant Gid</h5>
                            <h5 className="text-sm">{merchant.merchant_gid}</h5>
                        </div>
                        <hr />
                        <div className="my-2">
                            <h5 className="text-xs">Merchant Name</h5>
                            <h5 className="text-sm">{merchant.name}</h5>
                        </div>
                        <hr />
                        <div className="my-2">
                            <h5 className="text-xs">Email</h5>
                            <h5 className="text-sm">{merchant.email}</h5>
                        </div>
                        <hr />
                        <div className="my-2">
                            <h5 className="text-xs">Mobile No.</h5>
                            <h5 className="text-sm">{merchant.mobile_no}</h5>
                        </div>
                    </HoverCardContent>
                </HoverCard>) : ''}
        </div>

    );
};

export default MerchantCard;