import React, { useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { setWebhook } from "Apis/merchantapis";
import { Switch } from "@/components/ui/switch";
import { set } from "date-fns";

const AddWebhook = (prop) => {
  const [isActive, setIsActive] = useState(true);
  const [paymentFailed, setPaymentFailed] = useState(false);
  const [paymentCaptured, setPaymentCaptured] = useState(false);
  const [transferProcessed, setTransferProcessed] = useState(false);
  const [refundProcessed, setRefundProcessed] = useState(false);
  const [refundCreated, setRefundCreated] = useState(false);
  const [refundSpeedChanged, setRefundSpeedChanged] = useState(false);
  const [orderPaid, setOrderPaid] = useState(false);
  const [disputeCreated, setDisputeCreated] = useState(false);
  const [disputeWon, setDisputeWon] = useState(false);
  const [disputeLost, setDisputeLost] = useState(false);
  const [disputeClosed, setDisputeClosed] = useState(false);
  const [settlementProcessed, setSettlementProcessed] = useState(false);
  const [invoicePaid, setInvoicePaid] = useState(false);
  const [invoicePartiallyPaid, setInvoicePartiallyPaid] = useState(false);
  const [invoiceExpired, setInvoiceExpired] = useState(false);
  const [paylinkPaid, setPaylinkPaid] = useState(false);
  const [paylinkPartiallyPaid, setPaylinkPartiallyPaid] = useState(false);
  const [paylinkExpired, setPaylinkExpired] = useState(false);
  const [paylinkCancelled, setPaylinkCancelled] = useState(false);

  const [open, setOpen] = useState(false);

  const initialValues = {
    webhook_url: "",
    is_active: true,
    secret_key: "",
    payment_failed: false,
    payment_captured: false,
    transfer_processed: false,
    refund_processed: false,
    refund_created: false,
    refund_speed_changed: false,
    order_paid: false,
    dispute_created: false,
    dispute_won: false,
    dispute_lost: false,
    dispute_closed: false,
    settlement_processed: false,
    invoice_paid: false,
    invoice_partially_paid: false,
    invoice_expired: false,
    paylink_paid: false,
    paylink_partially_paid: false,
    paylink_expired: false,
    paylink_cancelled: false,
  };

  const validationSchema = Yup.object().shape({
    webhook_url: Yup.string()
      .url("Invalid URL format")
      .required("webhookUrl For is required")
  });

  const onSubmit = async (values) => {
    // Handle form submission

    setOpen(false);

    values.payment_failed = paymentFailed;
    values.payment_captured = paymentCaptured;
    values.transfer_processed = transferProcessed;
    values.refund_processed = refundProcessed;
    values.refund_created = refundCreated;
    values.refund_speed_changed = refundSpeedChanged;
    values.order_paid = orderPaid;
    values.dispute_created = disputeCreated;
    values.dispute_won = disputeWon;
    values.dispute_lost = paymentFailed;
    values.dispute_closed = disputeClosed;
    values.settlement_processed = settlementProcessed;
    values.invoice_paid = invoicePaid;
    values.invoice_partially_paid = invoicePartiallyPaid;
    values.invoice_expired = invoiceExpired;
    values.paylink_paid = invoicePaid;
    values.paylink_partially_paid = paylinkPartiallyPaid;
    values.paylink_expired = paylinkExpired;
    values.paylink_cancelled = paylinkCancelled;

    console.log(values);
    // const sendRequest = await setWebhook(values);
    prop.refreshWebhooks(values);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Add Webhook</Button>
      </DialogTrigger>
      <DialogContent
        className={
          "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
        }
      >
        <DialogHeader>
          <DialogTitle>Add Webhook</DialogTitle>
          <DialogDescription>
            Create Webhook Url to get Api Responses
          </DialogDescription>
        </DialogHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <div className=" pt-3 pb-16 px-3 gap-2 gap-y-4 ">
                <div>
                  <Label htmlFor="">Webhook:</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="webhook_url"
                    name="webhook_url"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.webhook_url}
                  />
                  <ErrorMessage
                    name="webhook_url"
                    className="text-red-500 text-xs  my-2"
                    component="div"
                  />
                </div>

              </div>
              <div>

              </div>

              <DialogFooter>
                <Button type="submit">Submit</Button>
              </DialogFooter>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddWebhook;
