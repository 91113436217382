import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";

import { getTransactionInfoViaId } from "Apis/merchantapis";
import { addDays, format } from "date-fns";

import { getMerchantsWalletBalance, getMerchantList } from "Apis/employeeapi";
import PaginatedItems from "Pagination/Pagination";

import TableLoading from "LoadingComponents/TableLoading";
import MerchantCard from "ReusableComponents/MerchantCard";

const WalletBalance = () => {

    const [open, setOpen] = useState(false);
    const [percent, setPercent] = useState('');
    const [transactionInfo, setTransactionInfo] = useState('');
    const [merchants, setMerchants] = useState("");
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });

    const [paymentIdQ, setpaymentIdQ] = useState("");
    const [statusQ, setstatusQ] = useState("");
    const [selectedMerchant, setSelectedMerchant] = useState("");

    const [viewTable, setViewTable] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [transactions, setTransactions] = useState([]);
    const [stats, setStats] = useState([]);

    const getDateRange = (dateRangeObject) => {
        console.log(dateRangeObject, 'parent');
        setDate(dateRangeObject)

    };

    const getData = async (skip, limit, dates, merchantId) => {
        setTableLoading(true)

        if (dates != undefined) {



            const fetchTransactions = await getMerchantsWalletBalance(
                skip,
                limit
            );
            setTransactions(fetchTransactions.data);
            setStats(fetchTransactions.totalStats);
            setCount(fetchTransactions.count);
            setTableLoading(false)
        }
    };

    const fetchMerchantsList = async () => {
        const fetchList = await getMerchantList();
        setMerchants(fetchList.data)
    }

    useEffect(() => {
        fetchMerchantsList();
    }, []);

    const fetchdata = (limit, skip) => {
        console.log(limit, skip);
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, date, paymentIdQ, statusQ);
    };

    const onClickView = () => {
        if (viewTable === true) {
            setViewTable(false)
        }
        else {
            setViewTable(true)
        }
    }



    const onClickTransactionId = async (id) => {
        console.log(id)
        const fetchdata = await getTransactionInfoViaId(id);
        setTransactionInfo(fetchdata.data)
        console.log(fetchdata)

    }

    const onClickDownload = async () => {
        console.log('download clicked')
    }

    useEffect(() => {

        getData(skip, limit, date, selectedMerchant);

    }, [date, limit, selectedMerchant, skip, viewTable]);

    return (
        <div className="rounded-md border">


                <Table>
                    <TableCaption>A list of your recent payments.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead >Sno.</TableHead>
                            <TableHead>Merchant Name</TableHead>
                            <TableHead>Balance</TableHead>
                        </TableRow>
                    </TableHeader>

                    {tableLoading === false ?
                        <TableBody>
                            {transactions.length > 0 &&
                                transactions.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>
                                      <  MerchantCard merchant={item.merchant_id}/></TableCell>
                                        <TableCell>{item.balance}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                        : <TableLoading count={9} />

                    }
                </Table>

                <div className="flex justify-center my-3">
                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div>


            
        </div>
    );
};

export default WalletBalance;
