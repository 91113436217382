import React, { useState, useEffect } from 'react';
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch"
import { useDispatch } from 'react-redux';
import { getUser } from '../../../Redux/Slice/TestModeSlice';
const TestModeSwitch = (props) => {
    const [testMode, setTestMode] = useState(false);

    const dispatch = useDispatch();


    const onChangeSwitch = async (value) => {
        setTestMode(value)
        dispatch(getUser('false'));
        props.modeFunction(value)
    }

    useEffect(() => {
        setTestMode(props.testMode)
    }, [props])

    return (
        <div>
            <div className="flex items-center space-x-2">
                <Switch checked={testMode}
                    onCheckedChange={(value) => { onChangeSwitch(value) }} id="test-mode" />

            </div>
        </div>
    );
};

export default TestModeSwitch;