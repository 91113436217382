import React from 'react';
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { CodeBlock, dracula, anOldHope, a11yDark } from 'react-code-blocks';

const CollectApi = () => {
    return (
        <div>
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                InitiatePayment API
            </h1>
            <p className="leading-7 [&:not(:first-child)]:mt-3">
                This API is used to initiate a payment.
            </p>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    POST METHOD
                </h3>

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Url: https://pg.example.com/payment/gateway/v1/initiatePayment
                </h3>
            </div>


            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Parameters to be posted
                </h3>

                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableCell className="w-[100px]">Parameter Name</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Data Type</TableCell>
                            <TableCell>Optional / Mandatory</TableCell>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell className="font-medium">clientId</TableCell>
                            <TableCell>PAYRIPE would assign a unique 40-digit merchant key to you.</TableCell>
                            <TableCell>varchar(40)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">clientSecret</TableCell>
                            <TableCell>Assigned by PAYRIPE. Max 40 digits.</TableCell>
                            <TableCell>varchar(40)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">emailId</TableCell>
                            <TableCell>Email Id of the account holder.</TableCell>
                            <TableCell>varchar(100)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">mobileNumber</TableCell>
                            <TableCell>Mobile number of the account holder.</TableCell>
                            <TableCell>varchar(10)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">amount</TableCell>
                            <TableCell>Value of funds being transferred.</TableCell>
                            <TableCell>decimal(10,2)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">txnCurr</TableCell>
                            <TableCell>Transfer currency. Static. Allowed values: INR.</TableCell>
                            <TableCell>varchar(150)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">username</TableCell>
                            <TableCell>Customer name.</TableCell>
                            <TableCell>varchar(150)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">upiId</TableCell>
                            <TableCell>Customer UPI Id.</TableCell>
                            <TableCell>varchar(150)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">order_gid</TableCell>
                            <TableCell>Must pass the same order_gid/txnId that you pass for the verify VPA API.</TableCell>
                            <TableCell>varchar(100)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">signature</TableCell>
                            <TableCell>Compute a hash of all your parameters and pass that hash to PAYRIPE. Note: the Hash will be provided by PAYRIPE separately.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">udf1</TableCell>
                            <TableCell>Optional parameter for additional use.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">udf2</TableCell>
                            <TableCell>Optional parameter for additional use.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">udf3</TableCell>
                            <TableCell>Optional parameter for additional use.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">udf4</TableCell>
                            <TableCell>Optional parameter for additional use.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">udf5</TableCell>
                            <TableCell>Optional parameter for additional use.</TableCell>
                            <TableCell>varchar(255)</TableCell>
                            <TableCell>Optional</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </div>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Request Parameters
                </h3>

                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[100px]">Parameter Name</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Data Type</TableHead>
                            <TableHead>Optional / Mandatory</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell className="font-medium">clientId</TableCell>
                            <TableCell>Your client ID provided by us</TableCell>
                            <TableCell>varchar(40)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell className="font-medium">orderId</TableCell>
                            <TableCell>Unique identifier for the order</TableCell>
                            <TableCell>varchar(50)</TableCell>
                            <TableCell>Mandatory</TableCell>
                        </TableRow>
                        {/* Add more parameters as needed */}
                    </TableBody>
                </Table>
            </div>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Response:
                </h3>

                <CodeBlock
                    text={`{
                        "paymentUrl": "https://payment.example.com/...",
                        "transactionId": "TXN123456789",
                        "status": "PENDING",
                        "message": "Payment initiated successfully"
                      } `}
                    language={"text"}
                    showLineNumbers={false}
                    theme={anOldHope}
                />

                <h3 className="scroll-m-20 text-normal font-bold tracking-tight lg:text-normal">
                    After Successfully calling this this api the popup will send to customer upi application to pay and final response will come in webhook.
                </h3>

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Note: Check the "status" field for the current status of the payment.
                </h3>
            </div>
        </div>
    );
};

export default CollectApi;
