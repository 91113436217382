import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { getInvoices } from "Apis/merchantapis";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import { Link } from "react-router-dom";

const InvoicesTable = () => {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState("");
  const [invoices, setInvoices] = useState([]);

  const getData = async (skip, limit) => {
    const fetchData = await getInvoices(skip, limit);

    setInvoices(fetchData.data);
    setCount(fetchData.count);
  };

  const reloadItems = () => {
    getData(skip, limit);
  };

  const fetchdata = (limit, skip) => {
    console.log(limit, skip);
    setSkip(skip);
    setLimit(limit);
    getData(skip, limit);
  };

  useEffect(() => {
    getData(skip, limit);
  }, []);

  return (
    <div className="rounded-md border">
      <div className="px-3 pt-3">
        <Button variant="outline">
          <Link to="/add_invoice"> Add Invoice</Link>
        </Button>
      </div>
      <Table>
        <TableCaption></TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Sno.</TableHead>
            <TableHead>Invoice Id</TableHead>
            <TableHead>Receipt</TableHead>
            <TableHead>Amount</TableHead>
            <TableHead>Customer</TableHead>
            <TableHead>Invoice Paylink</TableHead>
            <TableHead>Status</TableHead>
            <TableHead>Created At</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {invoices.length > 0 &&
            invoices.map((item, index) => (
              <TableRow key={item._id}>
                <TableCell className="font-medium">{index + 1}</TableCell>
                <TableCell>{item.invoice_gid}</TableCell>
                <TableCell>{item.invoice_receiptno}</TableCell>
                <TableCell>₹ {item.invoice_amount}</TableCell>
                <TableCell>{item.invoice_gid}</TableCell>
                <TableCell>{item.invoice_paylink}</TableCell>
                <TableCell> {item.invoice_status}</TableCell>
                <TableCell>
                  {moment(item.created_date).format("YYYY-MM-DD")}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <div className="flex justify-center my-3">
        <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
      </div>
    </div>
  );
};

export default InvoicesTable;
