import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import { io } from "socket.io-client";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { getMerchantOrders, exportMerchantOrders } from "Apis/merchantapis";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import download from 'downloadjs';
import TableLoading from "LoadingComponents/TableLoading";

const MerchantOrder = () => {
  const socket = io(`${process.env.REACT_APP_PAYMENT_URL}`);
  const [date, setDate] = useState({
    from: new Date(new Date().getFullYear(), 0, 1),
    to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
  });

  const [orderIdQ, setorderIdQ] = useState("");



  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState("");
  const [transactions, setTransactions] = useState([]);

  const [viewTable, setViewTable] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [percent, setPercent] = useState('');

  const getData = async (skip, limit, dates, paymentId) => {
    const dateConverter = (date) => {
      const currentDate = new Date(date);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const dateString = `${year}-${month}-${day}`;
      return dateString;
    };
    console.log(dates);

    if (dates != undefined) {
      const fromDateString = dateConverter(dates.from);
      const toDateString = dateConverter(dates.to);

      const dateRangeObject = {
        from: fromDateString,
        to: toDateString,
      };

      const searchObject = {
        order_gid: paymentId,
      };

      const fetchTransactions = await getMerchantOrders(
        skip,
        limit,
        searchObject,
        dateRangeObject
      );
      setTransactions(fetchTransactions.data);
      setCount(fetchTransactions.count);
      setTableLoading(false)
    }
  };

  const fetchdata = (limit, skip) => {
    setSkip(skip);
    setLimit(limit);
    getData(skip, limit, date, orderIdQ);
  };

  useEffect(() => {
    if (viewTable === true) {
      getData(skip, limit, date, orderIdQ);
    }
  }, [date, orderIdQ, viewTable]);

  const onClickView = () => {
    if (viewTable === true) {
      setViewTable(false)
    }
    else {
      setViewTable(true)
    }
  }

  useEffect(() => {
    const timer = setInterval(() => {
      const userId = JSON.parse(localStorage.getItem("user")).userId;
      socket.on(userId, function (msg) {
        console.log(msg);
        setPercent(msg);

      });
    }, 200);

    return () => {
      clearInterval(timer);
    };
  }, []);

  const onClickDownload = async () => {
    console.log('download clicked')

    const exportCall = await exportMerchantOrders(
      date
    );

    setPercent('Downloading File')
    const blob = await exportCall.blob();

    download(blob, 'data.csv', 'text/csv');
    setPercent('')
  }

  return (
    <div className="rounded-md border">
      <div className="grid grid-cols-1 md:grid-cols-3 pt-3 pb-16 px-3 gap-8">
        <div className="">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                id="date"
                variant={"outline"}
                className={cn(
                  "w-[350px] justify-start text-left font-normal",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date?.from ? (
                  date.to ? (
                    <>
                      {format(date.from, "LLL dd, y")} -{" "}
                      {format(date.to, "LLL dd, y")}
                    </>
                  ) : (
                    format(date.from, "LLL dd, y")
                  )
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={date?.from}
                selected={date}
                onSelect={setDate}
                numberOfMonths={2}
              />
            </PopoverContent>
          </Popover>
        </div>
        <div>
          <Input
            type="text"
            placeholder="Order Id"
            onChange={(e) => setorderIdQ(e.target.value)}
          />
        </div>
        <div>
          <Button className="mx-2" onClick={() => { onClickView() }}>View</Button>
          <Button className="mx-2" disabled={percent !== ''} variant="outline" onClick={() => { onClickDownload() }}>{percent !== '' ? percent : 'Download'}</Button>
        </div>
      </div>


      {viewTable === true &&
        <div>
          <Table>
            <TableCaption>A list of your  orders.</TableCaption>
            <TableHeader>
              <TableRow>
                <TableHead className="w-[100px]">Sno</TableHead>
                <TableHead>Order Id</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Order Status</TableHead>
                <TableHead>Created At</TableHead>
              </TableRow>
            </TableHeader>

            {tableLoading === false ? (
              <TableBody>
                {transactions.length > 0  &&
                  transactions.map((item, index) => (
                    <TableRow key={item._id}>
                      <TableCell className="font-medium">{index + 1}</TableCell>
                      <TableCell>{item.orderGid}</TableCell>
                      <TableCell>{item.orderAmount}</TableCell>
                      <TableCell>{item.orderStatus}</TableCell>
                      <TableCell>
                        {moment(item.created_date).format("YYYY-MM-DD")}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>) : <TableLoading count={4} />}
          </Table>

          <div className="flex justify-center my-3">
            <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
          </div>
        </div>
      }
    </div>
  );
};

export default MerchantOrder;
