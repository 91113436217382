import React from 'react';
import GetIntent from './getIntent';
import VerifyVAP from './VerifyVpa';
import CollectApi from './CollectApi';
import TransactionStatus from './TransactionStatus';
import GetReport from './GetReport';
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion"

const ApiDocumentation = () => {
    return (
        <div className='mx-8'>
            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Overview
                </h3>

                <h5>
                    This document explains merchant integration with our payment gateway. The
                    functionality of this application is to process online payments via credit cards, debit
                    cards,net-banking and more. Our payment gateway will authenticate the application
                    with the reference of the authentication credentials provided by the merchant during registration in the
                    payment gateway portal. For example, in our payment gateway integration, the merchant has to be
                    registered to get the API keys.
                </h5>

                <h5>
                    The whole process is Carried out in a secured way by our portal
                </h5>
            </div>
            <Accordion type="transactionstatus" collapsible className="w-full">
                <AccordionItem value="transactionstatus">
                    <AccordionTrigger>Transaction Status</AccordionTrigger>
                    <AccordionContent>
                        <TransactionStatus />
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="getintent">
                    <AccordionTrigger>Get Upi Intent</AccordionTrigger>
                    <AccordionContent>
                        <GetIntent />
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="verifyvap">
                    <AccordionTrigger>Verify Vap</AccordionTrigger>
                    <AccordionContent>
                        <VerifyVAP />
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="collect">
                    <AccordionTrigger>Collect Api (Initiate Payment)</AccordionTrigger>
                    <AccordionContent>
                        <CollectApi />
                    </AccordionContent>
                </AccordionItem>

                <AccordionItem value="report">
                    <AccordionTrigger>Get Transaction Report</AccordionTrigger>
                    <AccordionContent>
                        <GetReport />
                    </AccordionContent>
                </AccordionItem>
            </Accordion>



        </div>
    );
};

export default ApiDocumentation;