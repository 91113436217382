import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {
    FormDescription,
    FormLabel,
} from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { getMerchantList, internalFundTransfer } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";

export default function InternalFundTransfer(props) {
    const { toast } = useToast();
    const [merchants, setMerchants] = useState([]);
    const [senderMerchantId, setSenderMerchantId] = useState();
    const [beneficiaryMerchantId, setBeneficiaryMerchantId] = useState();
    const [open, setOpen] = useState(false);

    const getData = async () => {
        const fetchMerchants = await getMerchantList();
        setMerchants(fetchMerchants.data);
    };

    useEffect(() => {
        console.log('running')
        getData();
    }, []);

    const initialValues = {
        senderMerchantId: "",
        beneficiaryMerchantId: "",
        amount: "",
        remarks: "",
    };

    const transferFundsSchema = Yup.object().shape({
        senderMerchantId: Yup.string().required('This Field is required'),
        beneficiaryMerchantId: Yup.string().required('This Field is required'),
        amount: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        ),
        remarks: Yup.string(),
    });

    const submitTransferFundsForm = async (values) => {
        // Implement your logic to handle form submission
        // Example: const sendData = await transferFunds(values);
        // Handle the response and display toast messages accordingly

        console.log(values)
        const sendData = await internalFundTransfer(values)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }


    };

    return (

        <div className="">
            <Formik
                initialValues={initialValues}
                validationSchema={transferFundsSchema}
                onSubmit={submitTransferFundsForm}
            >
                {(formik) => {
                    const {
                        errors,
                        touched,
                        isValid,
                        dirty,
                        handleChange,
                        handleBlur,
                        values,
                        setFieldValue,
                    } = formik;

                    return (
                        <div className="mt-1 p-5">
                            <Form>
                                <div className="grid grid-cols-2 gap-4">
                                    <div>
                                        <Label htmlFor="senderMerchantId">Sender Merchant:</Label>
                                        <div className="mt-3">
                                            <Select
                                                onValueChange={(field) => {
                                                    setFieldValue('senderMerchantId', field);
                                                }}
                                                defaultValue={""}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select a Merchant" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        <SelectLabel>Merchants</SelectLabel>
                                                        <SelectItem value="">Select Merchant</SelectItem>
                                                        {merchants.length > 0 && merchants.map((merchant) => (
                                                            <SelectItem
                                                                value={merchant._id}
                                                                key={merchant._id}
                                                            >
                                                                {merchant.name}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </div>
                                        {errors.senderMerchantId && touched.senderMerchantId && (
                                            <span className="text-xs text-red-400">
                                                {errors.senderMerchantId}
                                            </span>
                                        )}
                                    </div>

                                    <div>
                                        <Label htmlFor="beneficiaryMerchantId">Beneficiary Merchant:</Label>
                                        <div className="mt-3">
                                            <Select
                                                onValueChange={(field) => {
                                                    setFieldValue('beneficiaryMerchantId', field);
                                                }}
                                                defaultValue={""}
                                            >
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select a Merchant" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectGroup>
                                                        <SelectLabel>Merchants</SelectLabel>
                                                        <SelectItem value="">Select Merchant</SelectItem>
                                                        {merchants.length > 0 && merchants.map((merchant) => (
                                                            <SelectItem
                                                                value={merchant._id}
                                                                key={merchant._id}
                                                            >
                                                                {merchant.name}
                                                            </SelectItem>
                                                        ))}
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </div>
                                        {errors.beneficiaryMerchantId && touched.beneficiaryMerchantId && (
                                            <span className="text-xs text-red-400">
                                                {errors.beneficiaryMerchantId}
                                            </span>
                                        )}
                                    </div>

                                    <div>
                                        <Label htmlFor="amount">Amount:</Label>
                                        <Input
                                            className="mt-3"
                                            type="text"
                                            id="amount"
                                            name="amount"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.amount}
                                        />
                                        {errors.amount && touched.amount && (
                                            <span className="text-xs text-red-400">
                                                {errors.amount}
                                            </span>
                                        )}
                                    </div>

                                    <div>
                                        <Label htmlFor="remarks">Remarks (Optional):</Label>
                                        <Input
                                            className="mt-3"
                                            type="text"
                                            id="remarks"
                                            name="remarks"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.remarks}
                                        />
                                        {errors.remarks && touched.remarks && (
                                            <span className="text-xs text-red-400">
                                                {errors.remarks}
                                            </span>
                                        )}
                                    </div>
                                </div>

                                <div className='flex justify-center'>
                                    <Button
                                        type="submit"
                                        className="btn mt-5 w-96 font-medium"
                                    >
                                        Money Transfer
                                    </Button>
                                </div>
                            </Form>
                        </div>
                    );
                }}
            </Formik>
        </div>

    );
}
