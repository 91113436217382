import React from "react";
import ItemsTable from "./ItemsTable";

const Items = () => {
  return (
    <div>
      <ItemsTable />
    </div>
  );
};

export default Items;
