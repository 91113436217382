import React, { useState, useEffect } from "react";

import { motion } from "framer-motion"
import { ArrowRightLeft } from "lucide-react";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"

import DateTimeRangeInput from "ReusableComponents/DateTimeRangeInput";
import { addDays, format } from "date-fns";

import { getPayoutDashboardData, getResellerMerchantList } from "Apis/resellerapi";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";


const ResellerPayoutDashboard = () => {
    const [walletBalance, setWalletBalance] = useState('');
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });

    const [merchantList, setMerchantList] = useState([]);
    const [userId, setUserId] = useState("");

    const [dateStats, setDateStats] = useState([]);
    const [dateSuccessStats, setDateSuccessStats] = useState([]);
    const [dateFailedStats, setdateFailedStats] = useState([]);

    const [stats, setStats] = useState([]);
    const [successStats, setSuccessStats] = useState([]);
    const [failureStats, setFailureStats] = useState([]);

    const getDateRange = (dateRangeObject) => {
        console.log(dateRangeObject, 'parent');
        setDate(dateRangeObject)

    };



    const getDataByDate = async (dates, userId) => {
        if (dates !== undefined) {
            const fetchDateStats = await getPayoutDashboardData(dates, userId);
            console.log(fetchDateStats)

            if (fetchDateStats.result.length > 0) {
                setDateStats(fetchDateStats.result[0]);

                const successData = await fetchDateStats.result[0].statuses.filter(data => data.status === 'Success');

                const failureData = await fetchDateStats.result[0].statuses.filter(data => data.status === 'Failed');

                if (successData.length > 0) {
                    setDateSuccessStats(successData[0].totalTransactions)
                }
                else {
                    setDateSuccessStats(0)
                }

                if (failureData.length > 0) {
                    setdateFailedStats(failureData[0].totalTransactions)
                } else {
                    setdateFailedStats(0)
                }

            } else {
                setDateStats({ totalAmountAll: 0, totalTransactionsAll: 0 })
                setDateSuccessStats(0)
                setdateFailedStats(0)
            }

        }
    }




    useEffect(() => {
        getDataByDate(date, userId);
    }, [date, userId]);


    const getMerchantList = async () => {
        const fetchMerchant = await getResellerMerchantList();

        setMerchantList(fetchMerchant.data)
    }

    useEffect(() => {

        getMerchantList();

    }, []);
    return (
        <div className="rounded-md border mt-4">
            <div className=" pt-3 pb-16 px-3   gap-8">
                <div className="my-4 text-lg font-bold" >
                    <h5>Date Wise Stats</h5>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-5">
                    <div className="w-72">
                        <DateTimeRangeInput className getFunction={getDateRange} />
                    </div>
                    <div>
                        <Select
                            onValueChange={(field) => {
                                setUserId(field)
                            }}
                            defaultValue={""}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select a Status" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Merchants</SelectLabel>
                                    <SelectItem value="">All</SelectItem>
                                    {merchantList.map((merchant) => (
                                        <SelectItem key={merchant.merchant_id._id} value={merchant.merchant_id._id}>{merchant.merchant_id.name}</SelectItem>
                                    ))}

                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>



                {dateStats && <div className=" my-3 grid grid-cols-4 gap-4 ">
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card>
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Transfer Amount
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold"> ₹ {dateStats.totalAmountAll}</div>

                            </CardContent>
                        </Card>
                    </motion.div>

                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card className="h-full">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Total Transaction
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold">{dateStats.totalTransactionsAll}</div>

                            </CardContent>
                        </Card>
                    </motion.div>

                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card className="h-full">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                     Success Transaction
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold"> {dateSuccessStats}</div>

                            </CardContent>
                        </Card>
                    </motion.div>
                    <motion.div
                        initial={{ scale: 0 }}
                        animate={{ rotate: 0, scale: 1 }}
                        transition={{
                            type: "spring",
                            stiffness: 260,
                            damping: 20
                        }}
                    >
                        <Card className="h-full">
                            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                                <CardTitle className="text-sm font-medium">
                                    Failed Transaction
                                </CardTitle>
                                <ArrowRightLeft />
                            </CardHeader>
                            <CardContent>
                                <div className="text-2xl font-bold">{dateFailedStats}</div>

                            </CardContent>
                        </Card>
                    </motion.div>
                </div>}

            </div>



        </div>
    );
};

export default ResellerPayoutDashboard;
