import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { updateMerchantPersonalDetails } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";

export default function EditPersoanlDetails(props) {
    const { toast } = useToast();
    const [personalDetails, setPersonalDetails] = useState(props.personaldetails);
    const [merchantId, setMerchantId] = useState(props.personaldetails._id);
    const [open, setOpen] = useState(false);

   

    const setProps = () => {
        setPersonalDetails(props.personaldetails)
        setMerchantId(props.personaldetails._id)

    }

    useEffect(() => {
        setProps()
    }, [props])

    const initialPersonalDetailsValues = {
        name: personalDetails.name,
        email: personalDetails.email,
        mobile_no: personalDetails.mobile_no,
        transaction_limit: personalDetails.transaction_limit
    };


    const personalDetailSchema = Yup.object().shape({
        name: Yup.string().required(),
        email: Yup.string().email().required(),
        mobile_no: Yup.string()
            .matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits')
            .required('Mobile number is required'),
        transaction_limit: Yup.number()
            .required('Transaction limit is required'),
    });

    const submitPersonalDetailsForm = async (values) => {
       

        const update = await updateMerchantPersonalDetails(merchantId, values)
        if (update.success === true) {
            toast({
                title: "Success",
                description: update.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: update.message,
            });
        }
        setOpen(false)
        props.refreshData();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Edit</Button>
            </DialogTrigger>
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Edit Personal Details</DialogTitle>
                    <DialogDescription>
                        Make changes to your profile here. Click edit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        enableReinitialize
                        initialValues={initialPersonalDetailsValues}
                        validationSchema={personalDetailSchema}
                        onSubmit={submitPersonalDetailsForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values } = formik;

                            return (
                                <div className=" mt-1  p-5 ">
                                    <Form>
                                        <div>
                                            <Label htmlFor="">Name:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="name"
                                                name="name"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.name}
                                            />
                                            {errors.name && touched.name && (
                                                <span className="text-xs text-red-400">
                                                    {errors.name}
                                                </span>
                                            )}
                                        </div>

                                        <div>
                                            <Label htmlFor="">Email:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="email"
                                                name="email"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                            />
                                            {errors.email && touched.email && (
                                                <span className="text-xs text-red-400">
                                                    {errors.email}
                                                </span>
                                            )}
                                        </div>

                                        <div>
                                            <Label htmlFor="">Mobile:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="mobile_no"
                                                name="mobile_no"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.mobile_no}
                                            />
                                            {errors.mobile_no && touched.mobile_no && (
                                                <span className="text-xs text-red-400">
                                                    {errors.mobile_no}
                                                </span>
                                            )}
                                        </div>

                                        <div>
                                            <Label htmlFor="">Transaction Limit:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="transaction_limit"
                                                name="transaction_limit"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.transaction_limit}
                                            />
                                            {errors.transaction_limit && touched.transaction_limit && (
                                                <span className="text-xs text-red-400">
                                                    {errors.transaction_limit}
                                                </span>
                                            )}
                                        </div>


                                        <Button
                                            type="submit"
                                            className="btn mt-5 w-full  font-medium  "
                                        >
                                            Edit
                                        </Button>
                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                </div>

            </DialogContent>
        </Dialog>
    )
}
