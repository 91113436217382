import { ThemeProvider } from "@/theme-provider";
import { EmployeeHeader } from "./EmployeeHeader";
import { TailwindIndicator } from "@/tailwind-indicator";
import { Outlet } from "react-router-dom";
import EmployeeSidebar from "./EmployeeSidebar";
import React from "react";

export default function EmployeeLayout() {
  return (
    <div className="min-h-screen bg-background font-sans antialiased">
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
        <EmployeeHeader />
        <div className="flex flex-shrink-0">
          <aside className="h-screen w-60 sticky top-0 shrink-0" style={{}}>
            {" "}
            <EmployeeSidebar />{" "}
          </aside>

          <main className="w-full">
            <aside className="w-full sticky top-0" style={{ zIndex: "998" }}>
              {" "}
            </aside>
            <Outlet />
          </main>
        </div>
        <TailwindIndicator />
      </ThemeProvider>
    </div>
  );
}
