import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogHeader,
    DialogTitle
} from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress"
import { subDays } from "date-fns";
import { useToast } from "@/components/ui/use-toast";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { getTimepayWebhooks, sendWebhook } from "Apis/employeeapi";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import TableLoading from "LoadingComponents/TableLoading";
import Breadcrumb from "LoadingComponents/Breadcrumb";
import { Webhook, SquareStack } from "lucide-react";
import DateTimeRangeInput from "ReusableComponents/DateTimeRangeInput";
import ViewWebhookResponse from "ReusableComponents/ViewWebhookResponse";

const WebhookResponses = () => {
    const { toast } = useToast();
    const BreadcrumbArray = [{ name: 'Technical', icon: <SquareStack /> }, { name: 'Webhooks', icon: <Webhook /> }]
    const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
    const [open, setOpen] = useState(false);
    const [percent, setPercent] = useState('');

    const [date, setDate] = useState({
        from: subDays(new Date(), 1), // Set to yesterday
        to: new Date(), // Set to today
    });


    const [paymentIdQ, setpaymentIdQ] = useState("");

    const [viewTable, setViewTable] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [transactions, setTransactions] = useState([]);

    const getDateRange = (dateRangeObject) => {
        setDate(dateRangeObject)

    };

    const getData = async (skip, limit, dates, paymentId) => {
        setTableLoading(true)
        if (dates !== undefined) {
            const searchObject = {
                transactiongid: paymentId
            };

            const fetchTransactions = await getTimepayWebhooks(
                skip,
                limit,
                searchObject,
                dates
            );
            setTransactions(fetchTransactions.data);
            setCount(fetchTransactions.count);
            setTableLoading(false)
        }
    };

    const fetchdata = (limit, skip) => {
        console.log(limit, skip);
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, date, paymentIdQ);
    };

    const onClickView = () => {
        if (viewTable === true) {
            setViewTable(false)
        }
        else {
            setViewTable(true)
        }
    }

    const onClickSendWebhook = async (id) => {
        console.log(id)
        const sendWebhookRequest = await sendWebhook(id);
        if (sendWebhookRequest.success === true) {
            toast({
                title: "Success",
                description: sendWebhookRequest.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendWebhookRequest.message,
            });
        }
    }








    useEffect(() => {
        if (viewTable === true) {
            getData(skip, limit, date, paymentIdQ);
        }
    }, [date, paymentIdQ, viewTable]);




    return (
        <div className="rounded-md border mt-4">
            <Breadcrumb data={BreadcrumbArray} />
            <div className="grid grid-cols-1 md:grid-cols-5 pt-3 pb-16 px-3 gap-8">
                <DateTimeRangeInput getFunction={getDateRange} />

                <div>
                    <Input
                        type="text"
                        placeholder="Payment Id"
                        onChange={(e) => setpaymentIdQ(e.target.value)}
                    />
                </div>

                <div>
                    <Button className="mx-2" onClick={() => { onClickView() }}>View</Button>

                </div>
            </div>


            {viewTable === true && <div>
                <Table>
                    <TableCaption>A list of your recent payments.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead >Sno.</TableHead>
                            <TableHead>Payment Id</TableHead>
                            <TableHead>Type</TableHead>
                            <TableHead>Date</TableHead>
                            <TableHead >View</TableHead>
                            <TableHead>Action</TableHead>

                        </TableRow>
                    </TableHeader>

                    {tableLoading === false ?
                        <TableBody>
                            {transactions.length > 0 &&
                                transactions.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>
                                            {item.transaction_gid}</TableCell>
                                        <TableCell>{item.type}</TableCell>
                                        <TableCell>
                                            {moment(item.request_timestamp).format("YYYY-MM-DD HH:mm:ss")}
                                        </TableCell>
                                        <TableCell>
                                            < ViewWebhookResponse webhook={item} />
                                        </TableCell>
                                        <TableCell>
                                            <Button onClick={() => { onClickSendWebhook(item._id) }}>Send Webhook</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                        : <TableLoading count={11} />

                    }
                </Table>

                <div className="flex justify-center my-3">
                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div></div>}


            <Dialog open={open} onOpenChange={setOpen} disableOutsidePointerEvents >

                <DialogContent
                    className={
                        "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                    }
                >
                    <DialogHeader>
                        <DialogTitle>Exporting Transactions</DialogTitle>
                        <DialogDescription>

                        </DialogDescription>
                    </DialogHeader>
                    <h5 className="text-lg font-bold">{percent} %</h5>
                    <Progress value={percent} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default WebhookResponses;
