import React, { useState, useEffect } from "react";
import { motion } from "framer-motion"
import { addDays, format } from "date-fns";
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { ArrowRightLeft, IndianRupeeIcon } from "lucide-react";
import { getPayinDashboardData, getResellerMerchantList } from "Apis/resellerapi";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import CardLoading from "LoadingComponents/CardLoading";
import { useToast } from "@/components/ui/use-toast";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";



const ResellerPayinDashboard = () => {
    const { toast } = useToast();
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });
    const [successData, setSuccessData] = useState(false);
    const [failedData, setFailedData] = useState(false);
    const [refundedData, setRefundedData] = useState(false);

    const [merchantList, setMerchantList] = useState([]);
    const [userId, setUserId] = useState("");



    const [loading, setLoading] = useState(false);

    const getData = async (dates, userId) => {
        setLoading(true)
        const dateConverter = (date) => {
            const currentDate = new Date(date);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            const dateString = `${year}-${month}-${day}`;
            return dateString;
        };

        if (dates !== undefined) {
            const fromDateString = dateConverter(dates.from);
            const toDateString = dateConverter(dates.to);

            const dateRangeObject = {
                from: fromDateString,
                to: toDateString,
            };

            const fetchData = await getPayinDashboardData(
                dateRangeObject, userId
            );


            const successData = fetchData.data.filter((item) => { return item._id === 'success' })
            if (successData.length > 0) {
                setSuccessData(successData[0])
            }
            else {
                setSuccessData({ _id: 'success', count: 0, totalAmount: 0, convertedAmount: '₹0.00' })
            }

            const failedData = fetchData.data.filter((item) => { return item._id === 'failed' })
            if (failedData.length > 0) {
                setFailedData(failedData[0])
            }
            else {
                setFailedData({ _id: 'failed', count: 0, totalAmount: 0, convertedAmount: '₹0.00' })
            }

            const refundedData = fetchData.data.filter((item) => { return item._id === 'refunded' })
            if (refundedData.length > 0) {
                setRefundedData(refundedData[0])
            }
            else {
                setRefundedData({ _id: 'refunded', count: 0, totalAmount: 0, convertedAmount: '₹0.00' })
            }



            setLoading(false)
        }
    };



    const getMerchantList = async () => {
        const fetchMerchant = await getResellerMerchantList();

        setMerchantList(fetchMerchant.data)
    }

    useEffect(() => {

        getMerchantList();

    }, []);





    useEffect(() => {

        getData(date, userId);

    }, [date, userId]);
    return (
        <div className=" ">
            <div className="grid grid-cols-1 md:grid-cols-4 mt-4  pb-8 px-3 gap-8">
                <div className="">
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                id="date"
                                variant={"outline"}
                                className={cn(
                                    "w-full justify-start text-left font-normal",
                                    !date && "text-muted-foreground"
                                )}
                            >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {date?.from ? (
                                    date.to ? (
                                        <>
                                            {format(date.from, "LLL dd, y")} -{" "}
                                            {format(date.to, "LLL dd, y")}
                                        </>
                                    ) : (
                                        format(date.from, "LLL dd, y")
                                    )
                                ) : (
                                    <span>Pick a date</span>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                initialFocus
                                mode="range"
                                defaultMonth={date?.from}
                                selected={date}
                                onSelect={setDate}
                                numberOfMonths={2}
                            />
                        </PopoverContent>
                    </Popover>
                </div>

                <div>
                    <div>
                        <Select
                            onValueChange={(field) => {
                                setUserId(field)
                            }}
                            defaultValue={""}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select a Status" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Merchants</SelectLabel>
                                    <SelectItem value="">All</SelectItem>
                                    {merchantList.map((merchant) => (
                                        <SelectItem key={merchant.merchant_id._id} value={merchant.merchant_id._id}>{merchant.merchant_id.name}</SelectItem>
                                    ))}

                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>



            </div>

            {loading === false ? <div className="mx-3 my-3 grid grid-cols-3 gap-4 ">
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Total Number of Successfull Transaction
                            </CardTitle>
                            <ArrowRightLeft />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{successData.count}</div>

                        </CardContent>
                    </Card>


                </motion.div>

                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <Card className="h-full">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Total Number of Failed Transaction
                            </CardTitle>
                            <ArrowRightLeft />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{failedData.count}</div>

                        </CardContent>
                    </Card>
                </motion.div>

                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <Card className="h-full">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Total Number of Refunded Transaction
                            </CardTitle>
                            <ArrowRightLeft />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{refundedData.count}</div>

                        </CardContent>
                    </Card>
                </motion.div>  </div> : <div className="mx-3 my-3 grid grid-cols-3 gap-4 ">  <CardLoading count={3} /></div>}



            {loading === false ? <div className="mx-3 my-3 grid grid-cols-3 gap-4 ">
                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <Card>
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Total Amount of Successfull Transaction
                            </CardTitle>
                            <IndianRupeeIcon />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{successData.convertedAmount}</div>

                        </CardContent>
                    </Card>
                </motion.div>

                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <Card className="h-full">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Total Amount of Failed Transaction
                            </CardTitle>
                            <IndianRupeeIcon />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{failedData.convertedAmount}</div>

                        </CardContent>
                    </Card>
                </motion.div>

                <motion.div
                    initial={{ scale: 0 }}
                    animate={{ rotate: 0, scale: 1 }}
                    transition={{
                        type: "spring",
                        stiffness: 260,
                        damping: 20
                    }}
                >
                    <Card className="h-full">
                        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                            <CardTitle className="text-sm font-medium">
                                Total Amount of Refunded Transaction
                            </CardTitle>
                            <IndianRupeeIcon />
                        </CardHeader>
                        <CardContent>
                            <div className="text-2xl font-bold">{refundedData.convertedAmount}</div>

                        </CardContent>
                    </Card>
                </motion.div>
            </div> : <div className="mx-3 my-3 grid grid-cols-3 gap-4 ">  <CardLoading count={3} /></div>}



        </div>
    );
};

export default ResellerPayinDashboard;
