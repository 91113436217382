import React from 'react';
import PaylinksTable from './PaylinksTable';
import TestPaylinksTable from './TestPaylinksTable';
import { useOutletContext } from "react-router-dom";

const Paylinks = () => {
    const testMode = useOutletContext();
    return (
        <div className="mt-6 px-6">
              {testMode ? <TestPaylinksTable  /> : <PaylinksTable />}
        </div>
    );
};

export default Paylinks;