import React from 'react';
import InvoicesTable from './InvoicesTable';

const InvoicesModule = () => {
    return (
        <div>
            < InvoicesTable/>
        </div>
    );
};

export default InvoicesModule;