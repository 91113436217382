import React, { useState, useEffect } from 'react';
import { getEmployeeLoginActivity } from 'Apis/employeeapi';
import { motion } from "framer-motion"
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
    CardDescription
} from "@/components/ui/card"
import moment from "moment";
import PaginatedItems from "Pagination/Pagination";

const LoginActivity = (props) => {
    const [LoginActivity, setLoginActivity] = useState("");
    const [tableLoading, setTableLoading] = useState(false);
    const [merchantId, setMerchantId] = useState(props.merchantId);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");

    const getData = async (skip, limit) => {
        setTableLoading(true)
        const fetchData = await getEmployeeLoginActivity(skip, limit);
        setLoginActivity(fetchData.data);
        setCount(fetchData.count)
        setTableLoading(false)

    };


    const fetchdata = (limit, skip) => {
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit);
    };

    useEffect(() => {
        getData(skip, limit);

    }, []);


    return (
        <div className='px-3'>
            <Card><CardContent>
                <CardHeader>
                    <CardTitle>Your Login Activities</CardTitle>
                    <CardDescription>Keep Track of your login activities .</CardDescription>
                </CardHeader>
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead >#</TableHead>
                            <TableHead >Ip Address</TableHead>
                            <TableHead>Device</TableHead>
                            <TableHead>Operating System</TableHead>
                            <TableHead >Browser</TableHead>
                            <TableHead >Date & Time</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {LoginActivity.length > 0 && LoginActivity.map((activity, index) => (
                            <TableRow key={activity._id}>
                                <TableCell >{index + 1}</TableCell>
                                <TableCell className="font-medium">{activity.log_ipaddress}</TableCell>
                                <TableCell>{activity.log_device}</TableCell>
                                <TableCell>{activity.log_os}</TableCell>
                                <TableCell >{activity.log_browser}</TableCell>
                                <TableCell >  {moment(activity.log_time).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <div className="flex justify-center my-3">

                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div>
            </CardContent></Card>


        </div>
    );
};

export default LoginActivity;