import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { getMerchantList, sendNotificationsToMerchant } from 'Apis/employeeapi';
import { Textarea } from "@/components/ui/textarea"
import { useToast } from "@/components/ui/use-toast";

export default function SendNotifications(props) {
    const { toast } = useToast();
    const [merchants, setMerchants] = useState();
    const [vendorBanks, setVendorBanks] = useState();
    const [open, setOpen] = useState(false);



    const getData = async () => {
        const fetchMerchants = await getMerchantList();
        setMerchants(fetchMerchants.data)
    }

    useEffect(() => {
        getData()
    }, [])


    const initialChargeDetailsValues =
    {
        merchantId: "",
        category: "",
        notify_type: "",
        message: ""
    }
        ;


    const chargeDetailSchema = Yup.object().shape({
        merchantId: Yup.string().required('This Field is required')
        ,
        message: Yup.string().required('This Field is required')
    });


    const submitChargeDetailsForm = async (values) => {


        const sendData = await sendNotificationsToMerchant(values)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        setOpen(false)
        props.refreshFunction();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Add</Button>
            </DialogTrigger>
            <DialogContent className={
                "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Send Notification to Merchant</DialogTitle>
                    <DialogDescription>
                        Fill the Form & Click submmit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        initialValues={initialChargeDetailsValues}
                        validationSchema={chargeDetailSchema}
                        onSubmit={submitChargeDetailsForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values, setFieldValue } = formik;

                            return (
                                <div className=" mt-1  p-5 ">
                                    <Form>
                                        <div className="grid grid-cols-3 gap-4">
                                            <div>
                                                <Label htmlFor="merchantId">Merchant:</Label>
                                                <div className="mt-3">
                                                    <Select

                                                        onValueChange={(field) => {
                                                            setFieldValue('merchantId', field)
                                                        }}
                                                        defaultValue={""}
                                                    >
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Select a Status" />
                                                        </SelectTrigger>
                                                        <SelectContent className={
                                                            "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                        }>
                                                            <SelectGroup>
                                                                <SelectLabel>Merchants</SelectLabel>
                                                                <SelectItem value="">Select Merchant</SelectItem>
                                                                {merchants.map((merchant) => (
                                                                    <SelectItem value={merchant._id} key={merchant._id}>{merchant.name}</SelectItem>
                                                                ))}

                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </div>

                                                {errors.merchantId && touched.merchantId && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.merchantId}
                                                    </span>
                                                )}
                                            </div>


                                            <div>
                                                <Label htmlFor='category'>Category:</Label>
                                                <div className="mt-3">
                                                    <Select

                                                        onValueChange={(field) => {
                                                            setFieldValue('category', field)
                                                        }}
                                                        defaultValue={""}
                                                    >
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Select a Category" />
                                                        </SelectTrigger>
                                                        <SelectContent className={
                                                            "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                        }>
                                                            <SelectGroup>
                                                                <SelectLabel>Categories</SelectLabel>
                                                                <SelectItem value="">Select a Category</SelectItem>
                                                                <SelectItem value="notification" >Notification</SelectItem>
                                                                <SelectItem value="message" >Message</SelectItem>
                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </div>

                                                {errors.category && touched.category && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.category}
                                                    </span>
                                                )}
                                            </div>


                                            <div>
                                                <Label htmlFor='notify_type'>Type:</Label>
                                                <div className="mt-3">
                                                    <Input
                                                        className="mt-3"
                                                        type="text"
                                                        id='notify_type'
                                                        name='notify_type'
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                        value={values.notify_type}
                                                    />
                                                </div>

                                                {errors.notify_type && touched.notify_type && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.notify_type}
                                                    </span>
                                                )}
                                            </div>


                                            <div className='col-span-3'>
                                                <Label htmlFor='message'>Message:</Label>
                                                <div className="mt-3">
                                                    <Textarea
                                                        placeholder="message .. "
                                                        onChange={((e) => { setFieldValue('message', e.target.value) })}


                                                    />
                                                </div>

                                                {errors.message && touched.message && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.message}
                                                    </span>
                                                )}
                                            </div>





                                        </div>




                                        <div className='flex justify-center'>
                                            <Button
                                                type="submit"
                                                className="btn mt-5 w-96  font-medium  "
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                </div>

            </DialogContent>
        </Dialog>
    )
}
