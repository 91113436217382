import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { getItems } from "Apis/merchantapis";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import AddItem from "./AddItem";
import DeleteItem from "./DeleteItem";
import EditItem from "./EditItem";

const ItemsTable = () => {
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState("");
  const [items, setItems] = useState([]);

  const getData = async (skip, limit) => {
    const fetchData = await getItems(skip, limit);

    setItems(fetchData.data);
    setCount(fetchData.count);
  };

  const reloadItems = () => {
    getData(skip, limit);
  };

  const fetchdata = (limit, skip) => {
    console.log(limit, skip);
    setSkip(skip);
    setLimit(limit);
    getData(skip, limit);
  };

  useEffect(() => {
    getData(skip, limit);
  }, []);

  return (
    <div className="rounded-md border">
      <div className="px-3 pt-3">
        <AddItem getFunction={reloadItems} />
      </div>
      <Table>
        <TableCaption></TableCaption>
        <TableHeader>
          <TableRow>
            <TableHead>Sno.</TableHead>
            <TableHead>Item Id</TableHead>
            <TableHead>Item Name</TableHead>
            <TableHead>Amount</TableHead>
            <TableHead>Created At</TableHead>
            <TableHead>Action</TableHead>
          </TableRow>
        </TableHeader>

        <TableBody>
          {items.length > 0 &&
            items.map((item, index) => (
              <TableRow key={item._id}>
                <TableCell className="font-medium">{index + 1}</TableCell>
                <TableCell>{item.item_gid}</TableCell>
                <TableCell>{item.item_name}</TableCell>
                <TableCell>₹ {item.item_amount}</TableCell>
                <TableCell>
                  {moment(item.created_date).format("YYYY-MM-DD")}
                </TableCell>
                <TableCell className="flex gap-2">
                  <EditItem getFunction={reloadItems} item={item}  />
                  <DeleteItem
                    getFunction={reloadItems}
                    item_id={item._id}
                    item_name={item.item_name}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>

      <div className="flex justify-center my-3">
        <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
      </div>
    </div>
  );
};

export default ItemsTable;
