import React from "react";

export const SignUpRequest = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/sign_up`,
    {
      method: "POST",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        mobile: data.mobile,
        password: data.password,
      }),
    }
  );

  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const LoginRequest = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/login`,
    {
      method: "POST",
      headers: { "content-Type": "application/json" },
      body: JSON.stringify({
        email: data.email,
        password: data.password,
      }),
    }
  );

  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const saveMerchantLoginActivity = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/save_merchant_login_activity`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      }
    }
  );

  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getMerchantTransactions = async (skip, limit, search, dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_payments`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getMerchantTestTransactions = async (skip, limit, search, dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_test_payments`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const exportMerchantTransactions = async (dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/export/export_payments`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );


  return formResponse;
};

export const exportMerchantRefunds = async (dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/export/export_refunds`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );


  return formResponse;
};

export const exportMerchantOrders = async (dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/export/export_orders`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );


  return formResponse;
};

export const getMerchantRefunds = async (skip, limit, search, dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_refunds`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getMerchantOrders = async (skip, limit, search, dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_orders`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getMerchantTestOrders = async (skip, limit, search, dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_test_orders`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const addPaylink = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_paylink`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        paylink_for: data.paylink_for,
        paylink_amount: data.paylink_amount,
        paylink_customer_email: data.paylink_customer_email,
        email_paylink: data.email_paylink,
        paylink_customer_mobile: data.paylink_customer_mobile,
        mobile_paylink: data.mobile_paylink,
        paylink_receipt: data.paylink_receipt,
        paylink_expiry: data.paylink_expiry,
        paylink_link: data.paylink_link,
        paylink_notes: data.paylink_notes,
        paylink_partial: data.paylink_partial,
        paylink_partial_amount: data.paylink_partial_amount,
        paylink_status: data.paylink_status,
        paylink_auto_reminder: data.paylink_auto_reminder,
        paylink_type: data.paylink_type,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const editPaylink = async (id, data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/edit_paylink`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        id: id,
        paylink_for: data.paylink_for,
        paylink_amount: data.paylink_amount,
        paylink_customer_email: data.paylink_customer_email,
        email_paylink: data.email_paylink,
        paylink_customer_mobile: data.paylink_customer_mobile,
        mobile_paylink: data.mobile_paylink,
        paylink_receipt: data.paylink_receipt,
        paylink_expiry: data.paylink_expiry,
        paylink_link: data.paylink_link,
        paylink_notes: data.paylink_notes,
        paylink_partial: data.paylink_partial,
        paylink_partial_amount: data.paylink_partial_amount,
        paylink_status: data.paylink_status,
        paylink_auto_reminder: data.paylink_auto_reminder,
        paylink_type: data.paylink_type,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getPaylinks = async (skip, limit, search, dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_paylinks`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getSettlements = async (skip, limit, search, dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_settlements`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getWebhook = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_webhook`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const setWebhook = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/set_webhook`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        webhook_url: data.webhook_url,
        is_active: data.is_active,
        secret_key: data.secret_key,
        payment_failed: data.payment_failed,
        payment_captured: data.payment_captured,
        transfer_processed: data.transfer_processed,
        refund_processed: data.refund_processed,
        refund_created: data.refund_created,
        refund_speed_changed: data.refund_speed_changed,
        order_paid: data.order_paid,
        dispute_created: data.dispute_created,
        dispute_won: data.dispute_won,
        dispute_lost: data.dispute_lost,
        dispute_closed: data.dispute_closed,
        settlement_processed: data.settlement_processed,
        invoice_paid: data.invoice_paid,
        invoice_partially_paid: data.invoice_partially_paid,
        invoice_expired: data.invoice_expired,
        paylink_paid: data.paylink_paid,
        paylink_partially_paid: data.paylink_partially_paid,
        paylink_expired: data.paylink_expired,
        paylink_cancelled: data.paylink_cancelled,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const generateApikeys = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/generate_api_keys`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const generateTestApikeys = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/generate_test_api_keys`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getApiKeys = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_api_keys`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getTestApiKeys = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_test_api_keys`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getFeedbacks = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_feedback`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const addFeedback = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_feedback`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        feed_subject: data.feed_subject,
        feed_rating: data.feed_rating,
        feedback: data.feedback,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getFeedbackSubjects = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_feedback_subjects`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getItems = async (skip, limit) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_items`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getAllItems = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_all_items`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify(),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const addItem = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_item`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        item_name: data.item_name,
        item_amount: data.item_amount,
        item_description: data.item_description,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const deleteItem = async (item_id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/delete_item`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        item_id: item_id,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const editItem = async (item_id, data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/edit_item`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        item_id: item_id,
        item_name: data.item_name,
        item_amount: data.item_amount,
        item_description: data.item_description,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getCustomers = async (skip, limit) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_customers`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getAllCustomers = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_all_customers`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify(),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getStates = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_states`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify(),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getCustomerAddresses = async (customerId) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_customer_addresses`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        customer_id: customerId,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const addCustomer = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_customer`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        customer_name: data.customer_name,
        customer_email: data.customer_email,
        customer_phone: data.customer_phone,
        customer_gstno: data.customer_gstno,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const deleteCustomer = async (customer_id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/delete_customer`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        customer_id: customer_id,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const editCustomer = async (customer_id, data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/edit_customer`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        customer_id: customer_id,
        customer_name: data.customer_name,
        customer_email: data.customer_email,
        customer_phone: data.customer_phone,
        customer_gstno: data.customer_gstno,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const generateInvoiceNumber = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/generate_invoice_number`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      }
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getInvoices = async (skip, limit) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_invoices`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const addInvoiceDetails = async (data, items) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_invoice`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        invoice_receiptno: data.invoice_receiptno,
        merchant_company: data.merchant_company,
        merchant_gstno: data.merchant_gstno,
        merchant_panno: data.merchant_panno,
        invoice_amount: data.invoice_amount,
        invoice_description: data.invoice_description,
        invoice_billing_to: data.invoice_billing_to,
        customer_gstno: data.customer_gstno,
        customer_email: data.customer_email,
        customer_phone: data.customer_phone,
        invoice_billing_address: data.invoice_billing_address,
        invoice_shipping_address: data.invoice_shipping_address,
        invoice_subtotal: data.invoice_subtotal,
        invoice_tax_amount: data.invoice_tax_amount,
        invoice_issue_date: data.invoice_issue_date,
        invoice_notes: data.invoice_notes,
        invoice_terms_cond: data.invoice_terms_cond,
        items: items
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const addCustomerAddress = async (customerId, data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_customer_address`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        customer_id: customerId,
        address: data.address,
        pincode: data.pincode,
        city: data.city,
        state_id: data.state_id,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getMerchantPersonalDetails = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_merchant_personal_details`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify(),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const updateMerchantPersonalDetails = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/update_merchant_personal_details`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        name: data.name,
        email: data.email,
        mobile_no: data.mobile_no,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const updateMerchantPassword = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/update_merchant_password`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        password: data.password,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const addUpdateUserBusinessValidation = async (field, value) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_update_merchant_business_validation`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        field: field,
        value: value
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};


export const getMerchantNotifications = async (skip, limit) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_merchant_notifications`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const markNotificationAsRead = async (id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/mark_notification_as_read`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        id: id
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getUnseenMerchantNotifications = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_notification_unseen`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getMerchantDocuments = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/documents`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};



export const addUpdateUserBusiness = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_update_merchant_business`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        business_type: data.business_type,
        monthly_expenditure: data.monthly_expenditure,
        business_category: data.business_category,
        webapp_url: data.webapp_url,
        bank_name: data.bank_name,
        bank_acc_no: data.bank_acc_no,
        bank_ifsc_code: data.bank_ifsc_code,
        business_logo: data.business_logo,
        company_name: data.company_name,
        pin_number: data.pincode,
        comp_pan_number: data.company_pan,
        comp_gst: data.company_gst,
        mer_pan_number: data.auth_sign_pan_no,
        mer_aadhar_number: data.auth_sign_aadhar_no,
        mer_name: data.auth_sign_name,
        company_address: data.company_address,
        pincode: data.pincode,
        city: data.city,
        state: data.state,
        country: data.country,
        form: data.form
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const uploadDocuments = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/upload_merchant_documents`,
    {
      method: "POST",
      headers: {
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: data,
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getMerchantBusinessDetails = async () => {
  const formResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/merchant/get_merchant_business_details`, {
    method: "GET",
    headers: {
      authorization: JSON.parse(localStorage.getItem("user")).token,
    }
  });

  const returnResponse = await formResponse.json();

  return returnResponse;
};


export const getBusinessTypes = async () => {
  const formResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/merchant/get_business_types`, {
    method: "GET",
    headers: {
      authorization: JSON.parse(localStorage.getItem("user")).token,
    }
  });

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getBusinessCategories = async () => {
  const formResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/merchant/get_business_categories`, {
    method: "GET",
    headers: {
      authorization: JSON.parse(localStorage.getItem("user")).token,
    }
  });

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getBusinessExpenditures = async () => {
  const formResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/merchant/get_business_expenditure_types`, {
    method: "GET",
    headers: {
      authorization: JSON.parse(localStorage.getItem("user")).token,
    }
  });

  const returnResponse = await formResponse.json();

  return returnResponse;
};


export const getDashboardGraphData = async () => {
  const formResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/merchant/graph_dashboard_data`, {
    method: "GET",
    headers: {
      authorization: JSON.parse(localStorage.getItem("user")).token,
    }
  });

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getDashboardData = async (dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/dashboard_data`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        startDate: dates.from,
        endDate: dates.to
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getTestDashboardData = async (dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/dashboard_test_data`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        startDate: dates.from,
        endDate: dates.to
      }),
    }
  );
  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getAppVariables = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_app_variables`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};


export const getTransactionInfoViaId = async (id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_transaction_info`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        id: id
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getTestTransactionInfoViaId = async (id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_test_transaction_info`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        id: id
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getBeneficiaries = async (skip, limit, search) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_beneficiaries`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const addBeneficiaries = async (data, items) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_beneficiaries`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        beneficiary_name: data.beneficiary_name,
        beneficiary_phone: data.beneficiary_phone,
        beneficiary_email: data.beneficiary_email,
        beneficiary_upi: data.beneficiary_upi,
        beneficiary_ifsc: data.beneficiary_ifsc,
        beneficiary_bank_ac: data.beneficiary_bank_ac,
        address_line1: data.address_line1,
        address_line2: data.address_line2,
        city: data.city,
        state: data.state,
        pincode: data.pincode
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const deleteBeneficiaries = async (id) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/delete_beneficiaries`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        id: id
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getMerchantPayoutPayments = async (skip, limit, dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_payout_payments`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getMerchantTestPayoutPayments = async (skip, limit, dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_test_payout_payments`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getMerchantPayoutStatement = async (skip, limit, dates, type) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_payout_statement`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        startDate: dates.from,
        endDate: dates.to,
        type: type
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getMerchantTestPayoutStatement = async (skip, limit, dates, type) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_test_payout_statement`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        startDate: dates.from,
        endDate: dates.to,
        type: type
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const generatePayoutApiKeys = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/set_payout_apikeys`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      }
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getPayoutApiKeys = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_payout_apikeys`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      }
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const addPayoutWebhook = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/set_payout_webhook`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        webhook_url: data.webhook_url,
        notify_email: data.notify_email
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getPayoutWebhook = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_payout_webhook`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      }
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const addIpWhitelist = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_ip_whitelist`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        ip: data.ip
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getWhitelistedIp = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_whitelisted_ip`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      }
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const exportMerchantPayoutTransactions = async (dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/export_payout_transactions`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );

  return formResponse;
};

export const exportMerchantPayoutStatement = async (dates, type) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/export_payout_statement`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        startDate: dates.from,
        endDate: dates.to,
        type: type
      }),
    }
  );

  return formResponse;
};

export const makePayoutTransaction = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/make_payout_transaction`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        beneficiary_id: data.beneficiary_id,
        transfer_method: data.transfer_method,
        amount: data.amount,
        purpose: data.purpose,
        remarks: data.remarks,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const makeTestPayoutTransaction = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/make_test_payout_transaction`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        beneficiary_id: data.beneficiary_id,
        transfer_method: data.transfer_method,
        amount: data.amount,
        purpose: data.purpose,
        remarks: data.remarks,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const addTestBalance = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_test_balance`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        amount: data.amount
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const addTestPaylink = async (data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/add_test_paylink`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        paylink_for: data.paylink_for,
        paylink_amount: data.paylink_amount,
        paylink_customer_email: data.paylink_customer_email,
        email_paylink: data.email_paylink,
        paylink_customer_mobile: data.paylink_customer_mobile,
        mobile_paylink: data.mobile_paylink,
        paylink_receipt: data.paylink_receipt,
        paylink_expiry: data.paylink_expiry,
        paylink_link: data.paylink_link,
        paylink_notes: data.paylink_notes,
        paylink_partial: data.paylink_partial,
        paylink_partial_amount: data.paylink_partial_amount,
        paylink_status: data.paylink_status,
        paylink_auto_reminder: data.paylink_auto_reminder,
        paylink_type: data.paylink_type,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const editTestPaylink = async (id, data) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/edit_test_paylink`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        id: id,
        paylink_for: data.paylink_for,
        paylink_amount: data.paylink_amount,
        paylink_customer_email: data.paylink_customer_email,
        email_paylink: data.email_paylink,
        paylink_customer_mobile: data.paylink_customer_mobile,
        mobile_paylink: data.mobile_paylink,
        paylink_receipt: data.paylink_receipt,
        paylink_expiry: data.paylink_expiry,
        paylink_link: data.paylink_link,
        paylink_notes: data.paylink_notes,
        paylink_partial: data.paylink_partial,
        paylink_partial_amount: data.paylink_partial_amount,
        paylink_status: data.paylink_status,
        paylink_auto_reminder: data.paylink_auto_reminder,
        paylink_type: data.paylink_type,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getTestPaylinks = async (skip, limit, search, dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_test_paylinks`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        skip: skip,
        limit: limit,
        search: search,
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getPayoutLast10DaysDate = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/payout_last_10_days_stats`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getPayoutWalletBalance = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/get_balance`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getPayoutDashboardDataByDate = async (dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/payout_stats_by_date`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

export const getTestPayoutLast10DaysDate = async () => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/test_payout_last_10_days_stats`,
    {
      method: "GET",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
    }
  );

  const returnResponse = await formResponse.json();

  return returnResponse;
};

export const getTestPayoutDashboardDataByDate = async (dates) => {
  const formResponse = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/merchant/test_payout_stats_by_date`,
    {
      method: "POST",
      headers: {
        "content-Type": "application/json",
        authorization: JSON.parse(localStorage.getItem("user")).token,
      },
      body: JSON.stringify({
        startDate: dates.from,
        endDate: dates.to,
      }),
    }
  );
  const returnResponse = await formResponse.json();
  return returnResponse;
};

