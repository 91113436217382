import React, { useState, useEffect } from "react";
import { motion } from "framer-motion"
import { addDays, format } from "date-fns";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/components/ui/card"
import { ArrowRightLeft, IndianRupeeIcon, RocketIcon } from "lucide-react";
import BarChartComponent from "./Graph";
import { getDashboardData, getUnseenMerchantNotifications, markNotificationAsRead } from "Apis/merchantapis";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { CalendarIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import CardLoading from "LoadingComponents/CardLoading";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/components/ui/alert"
import { useToast } from "@/components/ui/use-toast";



const MerchantDashboard = () => {
  const { toast } = useToast();
  const [date, setDate] = useState({
    from: new Date(new Date().getFullYear(), 0, 1),
    to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
  });
  const [successData, setSuccessData] = useState(false);
  const [failedData, setFailedData] = useState(false);
  const [refundedData, setRefundedData] = useState(false);

  const [notifications, setNotifications] = useState([]);

  const [loading, setLoading] = useState(false);

  const getData = async (dates, userId) => {
    setLoading(true)
    const dateConverter = (date) => {
      const currentDate = new Date(date);
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const dateString = `${year}-${month}-${day}`;
      return dateString;
    };

    if (dates !== undefined) {
      const fromDateString = dateConverter(dates.from);
      const toDateString = dateConverter(dates.to);

      const dateRangeObject = {
        from: fromDateString,
        to: toDateString,
      };

      const fetchData = await getDashboardData(
        dateRangeObject
      );

      const successData = fetchData.data.filter((item) => { return item._id === 'success' })
      if (successData.length > 0) {
        setSuccessData(successData[0])
      }
      else {
        setSuccessData({ _id: 'success', count: 0, totalAmount: 0, convertedAmount: '₹0.00' })
      }

      const failedData = fetchData.data.filter((item) => { return item._id === 'failed' })
      if (failedData.length > 0) {
        setFailedData(failedData[0])
      }
      else {
        setFailedData({ _id: 'failed', count: 0, totalAmount: 0, convertedAmount: '₹0.00' })
      }

      const refundedData = fetchData.data.filter((item) => { return item._id === 'refunded' })
      if (refundedData.length > 0) {
        setRefundedData(refundedData[0])
      }
      else {
        setRefundedData({ _id: 'refunded', count: 0, totalAmount: 0, convertedAmount: '₹0.00' })
      }



      setLoading(false)
    }
  };


  const getUnseenNotification = async () => {
    const fetchNotifications = await getUnseenMerchantNotifications();
    setNotifications(fetchNotifications.data)
    console.log(fetchNotifications, "---------not------------")
  }

  useEffect(() => {

    getUnseenNotification();

  }, []);


  const onClickMarkAsRead = async (id) => {
    const markAsRead = await markNotificationAsRead(id);
    if (markAsRead.success === true) {
      toast({
        title: "Success",
        description: markAsRead.message,
      });
    } else {
      toast({
        variant: "destructive",
        title: "Error",
        description: markAsRead.message,
      });
    }
    getUnseenNotification()
  }


  useEffect(() => {

    getData(date);

  }, [date]);
  return (
    <div className=" ">
      <div className="mb-5">
        {notifications.length > 0 && notifications.map((notification) => (<Alert className="my-1" key={notification._id}>
          <RocketIcon className="h-4 w-4" />
          <AlertTitle>{notification.notify_type}</AlertTitle>
          <div className="flex justify-between ">
            <AlertDescription className="mt-2">
              {notification.message}
            </AlertDescription>
            <Button variant="outline" onClick={() => { onClickMarkAsRead(notification._id) }} className="text-xs">
              Mark as Read
            </Button>
          </div>

        </Alert>))}
      </div>


      <div className="grid grid-cols-1 md:grid-cols-4  pb-8 px-3 gap-8">
        <div className="">

          <Popover>
            <PopoverTrigger asChild>
              <Button
                id="date"
                variant={"outline"}
                className={cn(
                  "w-full justify-start text-left font-normal",
                  !date && "text-muted-foreground"
                )}
              >
                <CalendarIcon className="mr-2 h-4 w-4" />
                {date?.from ? (
                  date.to ? (
                    <>
                      {format(date.from, "LLL dd, y")} -{" "}
                      {format(date.to, "LLL dd, y")}
                    </>
                  ) : (
                    format(date.from, "LLL dd, y")
                  )
                ) : (
                  <span>Pick a date</span>
                )}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0" align="start">
              <Calendar
                initialFocus
                mode="range"
                defaultMonth={date?.from}
                selected={date}
                onSelect={setDate}
                numberOfMonths={2}
              />
            </PopoverContent>
          </Popover>
        </div>



      </div>

      {loading === false ? <div className="mx-3 my-3 grid grid-cols-3 gap-4 ">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Number of Successfull Transaction
              </CardTitle>
              <ArrowRightLeft />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{successData.count}</div>

            </CardContent>
          </Card>


        </motion.div>

        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
          <Card className="h-full">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Number of Failed Transaction
              </CardTitle>
              <ArrowRightLeft />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{failedData.count}</div>

            </CardContent>
          </Card>
        </motion.div>

        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
          <Card className="h-full">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Number of Refunded Transaction
              </CardTitle>
              <ArrowRightLeft />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{refundedData.count}</div>

            </CardContent>
          </Card>
        </motion.div>  </div> : <div className="mx-3 my-3 grid grid-cols-3 gap-4 ">  <CardLoading count={3} /></div>}



      {loading === false ? <div className="mx-3 my-3 grid grid-cols-3 gap-4 ">
        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
          <Card>
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Amount of Successfull Transaction
              </CardTitle>
              <IndianRupeeIcon />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{successData.convertedAmount}</div>

            </CardContent>
          </Card>
        </motion.div>

        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
          <Card className="h-full">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Amount of Failed Transaction
              </CardTitle>
              <IndianRupeeIcon />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{failedData.convertedAmount}</div>

            </CardContent>
          </Card>
        </motion.div>

        <motion.div
          initial={{ scale: 0 }}
          animate={{ rotate: 0, scale: 1 }}
          transition={{
            type: "spring",
            stiffness: 260,
            damping: 20
          }}
        >
          <Card className="h-full">
            <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
              <CardTitle className="text-sm font-medium">
                Total Amount of Refunded Transaction
              </CardTitle>
              <IndianRupeeIcon />
            </CardHeader>
            <CardContent>
              <div className="text-2xl font-bold">{refundedData.convertedAmount}</div>

            </CardContent>
          </Card>
        </motion.div>
      </div> : <div className="mx-3 my-3 grid grid-cols-3 gap-4 ">  <CardLoading count={3} /></div>}

      <div className="mx-3">
        < BarChartComponent />
      </div>

    </div>
  );
};

export default MerchantDashboard;
