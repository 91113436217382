import React, { useState, useEffect } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { setAppVariables, getAppVariables, setBrandLogo } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";
import Breadcrumb from 'LoadingComponents/Breadcrumb';
import { Settings } from 'lucide-react';
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/components/ui/alert"

const AppVariables = () => {
    const { toast } = useToast();

    const BreadcrumbArray = [{ name: 'Settings', icon: <Settings /> }]

    const [fetchedAppVariables, setFetchedAppVariables] = useState(null);



    const getData = async () => {
        const fetchVariables = await getAppVariables();
        setFetchedAppVariables(fetchVariables.data)
        console.log(fetchVariables.data, 'data')
    }

    useEffect(() => {
        getData()
    }, [])

    let brandLogoValue = {
        logo: '',
        mode: 'changeLogo'
    }

    const brandLogochema = Yup.object({
        logo: Yup.mixed().required('Logo is required')
            .test(
                'fileSize',
                'File size must be less than or equal to 1MB',
                (value) => value && value.size <= 1048576 // 1MB in bytes;

            )
            .test(
                'fileType',
                'Only PNG, JPG, and JPEG files are allowed',
                (value) => {
                    if (!value) return true;

                    const supportedFormats = ['image/png', 'image/jpeg', 'image/jpg'];
                    return supportedFormats.includes(value.type);
                }
            ),
    });

    const submitBrandLogoForm = async (values) => {
        // Handle form submission here
        console.log(values);

        let formData = new FormData();

        // Update the formData object
        formData.append("logo", values.logo);

        const send = await setBrandLogo(formData);

        if (send.success === true) {
            toast({
                title: "Success",
                description: send.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: send.message,
            });
        }
    };

    let initalAppVariables;

    if (fetchedAppVariables === null) {
        initalAppVariables = {
            brandname: '',
            merchant_prefix: '',
            transaction_prefix: '',
            invoice_prefix: '',
            paylink_prefix: '',
            apikey_prefix: '',
            order_prefix: '',
        }
    } else {
        initalAppVariables = {
            brandname: fetchedAppVariables.brandname,
            merchant_prefix: fetchedAppVariables.merchant_prefix,
            transaction_prefix: fetchedAppVariables.transaction_prefix,
            invoice_prefix: fetchedAppVariables.invoice_prefix,
            paylink_prefix: fetchedAppVariables.paylink_prefix,
            apikey_prefix: fetchedAppVariables.apikey_prefix,
            order_prefix: fetchedAppVariables.order_prefix

        }
    }



    const appvariableschema = Yup.object({
        brandname: Yup.string().required('Brandname is required'),
        merchant_prefix: Yup.string().required('Merchant Prefix is required'),
        transaction_prefix: Yup.string().required('Transaction Prefix is required'),
        invoice_prefix: Yup.string().required('Invoice Prefix is required'),
        paylink_prefix: Yup.string().required('Paylink Prefix is required'),
        apikey_prefix: Yup.string().required('Api key Prefix is required'),
        order_prefix: Yup.string().required('Api key Prefix is required')
    });

    const submitChargeDetailsForm = async (values) => {
        // Handle form submission here
        console.log(values);

        const send = await setAppVariables(values);

        if (send.success === true) {
            toast({
                title: "Success",
                description: send.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: send.message,
            });
        }
    };

    return (
        <div className='rounded-md border mt-4'>
            <Breadcrumb data={BreadcrumbArray} />
            <div className=' mx-3 my-1'>
                <Formik
                    enableReinitialize
                    initialValues={brandLogoValue}
                    validationSchema={brandLogochema}
                    onSubmit={submitBrandLogoForm}
                >
                    {(formik) => {
                        const { errors, touched, isValid, dirty, handleChange,
                            handleBlur, values, setFieldValue } = formik;

                        return (
                            <div className=" mt-1  p-5 ">
                                <div className=' my-3'>
                                    <h5 className='text-xl font-bold'>Brand Logo</h5>
                                </div>
                                <Form>
                                    <div className="grid grid-cols-3 gap-4 my-6">


                                        <div>
                                            <Label htmlFor="logo">Site Logo:</Label>
                                            <Input
                                                className="mt-3"
                                                type="file"
                                                id="logo"
                                                name="logo"
                                                onChange={(event) => {
                                                    console.log(event.target.files[0])
                                                    setFieldValue('logo', event.target.files[0]);
                                                }}
                                                onBlur={handleBlur}
                                            />
                                            {errors.logo && touched.logo && (
                                                <span className="text-xs text-red-400">
                                                    {errors.logo}
                                                </span>
                                            )}



                                        </div>
                                    </div>

                                    <div className='text-xs'>
                                        <h5>Please Refresh after uploading logo to experience the changes .</h5>
                                    </div>


                                    <div className='flex justify-start'>
                                        <Button
                                            type="submit"
                                            className="btn mt-5 w-42  font-medium  "
                                        >
                                            Submit
                                        </Button>
                                    </div>

                                </Form>
                            </div>
                        );
                    }}
                </Formik>
            </div>

            <div className='mx-3 my-1'>


                <Formik
                    enableReinitialize
                    initialValues={initalAppVariables}
                    validationSchema={appvariableschema}
                    onSubmit={submitChargeDetailsForm}
                >
                    {(formik) => {
                        const { errors, touched, isValid, dirty, handleChange,
                            handleBlur, values, setFieldValue } = formik;

                        return (
                            <div className=" mt-1  p-5 ">
                                <div className='my-3'>
                                    <h5 className='text-xl font-bold'>App Variables</h5>
                                </div>
                                <Form>
                                    <div className="grid grid-cols-3 gap-4 my-6">



                                        <div>
                                            <Label htmlFor="cdr_desc"> Brand Name:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="brandname"
                                                name="brandname"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.brandname}
                                            />
                                            {errors.brandname && touched.brandname && (
                                                <span className="text-xs text-red-400">
                                                    {errors.brandname}
                                                </span>
                                            )}
                                        </div>

                                        <div>
                                            <Label htmlFor="merchant_prefix">Merchant Prefix:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="merchant_prefix"
                                                name="merchant_prefix"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.merchant_prefix}
                                            />
                                            {errors.merchant_prefix && touched.merchant_prefix && (
                                                <span className="text-xs text-red-400">
                                                    {errors.merchant_prefix}
                                                </span>
                                            )}
                                        </div>

                                        <div>
                                            <Label htmlFor="transaction_prefix">Transaction Prefix:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="transaction_prefix"
                                                name="transaction_prefix"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.transaction_prefix}
                                            />
                                            {errors.transaction_prefix && touched.transaction_prefix && (
                                                <span className="text-xs text-red-400">
                                                    {errors.transaction_prefix}
                                                </span>
                                            )}
                                        </div>

                                        <div>
                                            <Label htmlFor="order_prefix">Order Prefix:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="order_prefix"
                                                name="order_prefix"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.order_prefix}
                                            />
                                            {errors.order_prefix && touched.order_prefix && (
                                                <span className="text-xs text-red-400">
                                                    {errors.order_prefix}
                                                </span>
                                            )}
                                        </div>

                                        <div>
                                            <Label htmlFor="invoice_prefix">Invoice Prefix:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="invoice_prefix"
                                                name="invoice_prefix"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.invoice_prefix}
                                            />
                                            {errors.invoice_prefix && touched.invoice_prefix && (
                                                <span className="text-xs text-red-400">
                                                    {errors.invoice_prefix}
                                                </span>
                                            )}
                                        </div>



                                        <div>
                                            <Label htmlFor="paylink_prefix">Paylink Prefix:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="paylink_prefix"
                                                name="paylink_prefix"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.paylink_prefix}
                                            />
                                            {errors.paylink_prefix && touched.paylink_prefix && (
                                                <span className="text-xs text-red-400">
                                                    {errors.paylink_prefix}
                                                </span>
                                            )}
                                        </div>

                                        <div>
                                            <Label htmlFor="apikey_prefix">ApiKey Prefix:</Label>
                                            <Input
                                                className="mt-3"
                                                type="text"
                                                id="apikey_prefix"
                                                name="apikey_prefix"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.apikey_prefix}
                                            />
                                            {errors.apikey_prefix && touched.apikey_prefix && (
                                                <span className="text-xs text-red-400">
                                                    {errors.apikey_prefix}
                                                </span>
                                            )}
                                        </div>



                                    </div>


                                    <div className='flex justify-start'>
                                        <Button
                                            type="submit"
                                            className="btn mt-5 w-42  font-medium  "
                                        >
                                            Submit
                                        </Button>
                                    </div>

                                </Form>
                            </div>
                        );
                    }}
                </Formik>
            </div>
        </div>
    );
};

export default AppVariables;
