
import ThemeToggle from "@/theme-toggle";
import IpAddress from "./HeaderComponents/IpAdress";
import CurrentDateTime from "./HeaderComponents/CurrentTimeDate";
import TestModeSwitch from "./HeaderComponents/TestModeSwitch";
import React, { useState, useEffect, useMemo } from "react";
import { getAppVariables } from "Apis/merchantapis";
import { Badge } from "@/components/ui/badge"

export function MerchantHeader(props) {
  const url = `${process.env.REACT_APP_BACKEND_URL}`;
  const [appVariables, setAppVariables] = useState();
  const [testMode, setTestMode] = useState(false);

  const getData = async () => {
    const fetchData = await getAppVariables();
    setAppVariables(fetchData.data);
  };

  useEffect(() => {
    getData();
  }, []);


  const getMode = (value) => {
    props.modeFunction(value)
    setTestMode(value)
  }

  useEffect(() => {
    setTestMode(props.testMode)
  }, [props])

  // Memoize the data so that it's not recalculated on every render
  const memoizedData = useMemo(() => appVariables, [appVariables]);

  return (
    <div className="grid">
      <header className='sticky top-0 z-40 w-full border-b bg-background'>
        <div className=' flex h-16 items-center space-x-4 sm:justify-between sm:space-x-0'>

          {memoizedData !== undefined && memoizedData !== null ?
            <div className="ml-5">
              {memoizedData.logo !== '' ? <img width="150" height="120" src={`${url}/${memoizedData.logo}`} alt="No logo" /> : <h1>Upload Logo</h1>}
            </div> : <h1>Upload Logo</h1>
          }



          <div className='flex flex-1 items-center justify-end space-x-4'>

            <nav className='flex items-center space-x-1 gap-2'>
              {
                testMode ?
                  <div className="ml-8">
                    <Badge variant="destructive">Test Mode is Enabled</Badge>

                  </div>

                  :
                  <div className="ml-8">
                    <Badge variant="outline">Live Mode is Enabled</Badge>

                  </div>
              }

              <TestModeSwitch testMode={testMode} modeFunction={getMode} />
              <CurrentDateTime />
              <IpAddress />
              <ThemeToggle />
            </nav>
          </div>
        </div>
      </header>
    </div>
  );
}