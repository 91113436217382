import React, { useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";

const AddIpwhitelist = (prop) => {

    const [open, setOpen] = useState(false);

    const initialValues = {
        ip: ""
    };

    const validationSchema = Yup.object().shape({
        ip: Yup.string()
            .required("Ip is required")
    });

    const onSubmit = async (values) => {
        // Handle form submission
        setOpen(false);

        console.log(values);
        // const sendRequest = await addIpWhitelist(values);
        prop.refreshWebhooks(values);
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Add Ip</Button>
            </DialogTrigger>
            <DialogContent
                className={
                    "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                }
            >
                <DialogHeader>
                    <DialogTitle>Add Ip</DialogTitle>
                    <DialogDescription>
                        Add an Ip to Whitelist
                    </DialogDescription>
                </DialogHeader>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                    }) => (
                        <Form>
                            <div className=" pt-3 pb-16 px-3 gap-2 gap-y-4 ">
                                <div>
                                    <Label htmlFor="">Ip:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="ip"
                                        name="ip"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.ip}
                                    />
                                    <ErrorMessage
                                        name="ip"
                                        className="text-red-500 text-xs  my-2"
                                        component="div"
                                    />
                                </div>
                            </div>
                            <div>

                            </div>

                            <DialogFooter>
                                <Button type="submit">Submit</Button>
                            </DialogFooter>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AddIpwhitelist;
