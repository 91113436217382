import React from "react";
import { Skeleton } from "@/components/ui/skeleton";
import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

const TableLoading = (props) => {
  const { count } = props;
  const totalRows = 1;

  // Create an array of numbers from 1 to count
  const columns = Array.from({ length: count }, (_, index) => index + 1);
  const rows = Array.from({ length: totalRows }, (_, index) => index + 1);
  return (
    <TableBody>
      {rows.map(row => (
        <TableRow>
          {columns.map(column => (
            <TableCell>
              <Skeleton className="w-[90px] h-[20px] rounded" />
            </TableCell>
          ))}


          <Skeleton className="w-[100px] h-[20px] rounded-full" />


        </TableRow>

      ))}
    </TableBody>
  );
};

export default TableLoading;
