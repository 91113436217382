import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { getAtomKeys, getCashfreeKeys, getTimePayKeys } from "Apis/employeeapi";
import moment from "moment";
import PaginatedItems from "Pagination/Pagination";
import DeleteMerchantVendorKeys from "./DeleteMerchantVendorKeys";
import { AlertCircle } from "lucide-react";
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/components/ui/alert"
import AddMerchantVendorKeys from "./AddMerchantVendorKeys";
import MerchantCard from "ReusableComponents/MerchantCard";
const VendorKeysAddListDelete = (props) => {

    const [tableLoading, setTableLoading] = useState(false);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [chargeDetails, setChargeDetails] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);



    const getData = async (skip, limit) => {
        setTableLoading(true)
        let fetchData;
        if (selectedVendor !== null) {
            if (selectedVendor.bank_name === 'Atom') {
                fetchData = await getAtomKeys("", skip, limit);
            }
            else if (selectedVendor.bank_name === 'CashFree') {
                fetchData = await getCashfreeKeys("", skip, limit);
            }
            else if (selectedVendor.bank_name === 'TimePay') {
                fetchData = await getTimePayKeys("", skip, limit);
            }
            else {
                fetchData.data = [];
                fetchData.count = 0;
            }
        }
        else {
            fetchData = { data: [], count: 0 };
        }


        setChargeDetails(fetchData.data);
        setCount(fetchData.count)
        setTableLoading(false)

    };

    const refreshData = () => {
        getData()
    }


    const fetchdata = (limit, skip) => {
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit);
    };

    const configuredVendors = ['Atom', 'CashFree', 'TimePay'];




    useEffect(() => {
        if (props.vendorDetails === null || props.vendorDetails === undefined) {
            setSelectedVendor(null);
        } else {
            const filtered = configuredVendors.filter((item) => {
                return item === props.vendorDetails.bank_name
            })
            if (filtered.length > 0) {
                setSelectedVendor(props.vendorDetails);
            }
            else {
                setSelectedVendor(null);
            }
        }

    }, [props]);


    let fieldArray = [];
    if (selectedVendor !== null) {

        if (selectedVendor.bank_name === 'Atom') {
            fieldArray = [
                "userid",
                "hash_request_key",
                "hash_response_key",
                "aes_request_key",
                "aes_request_salt_iv_key",
                "aes_response_key",
                "aes_response_salt_iv_key"
            ];
        }
        else if (selectedVendor.bank_name === 'CashFree') {
            fieldArray = [
                "app_id",
                "secret_key"
            ];
        }
        else if (selectedVendor.bank_name === 'TimePay') {
            fieldArray = [
                "vpa",
                "submerchant_id",
                "mcc"
            ];
        }
        else {

        }
    }


    function transformString(inputString) {
        // Split the input string by underscores
        const parts = inputString.split('_');

        // Capitalize the first letter of each part and join them with a space
        const transformedString = parts.map(part => {
            return part.charAt(0).toUpperCase() + part.slice(1);
        }).join(' ');

        return transformedString;
    }

    useEffect(() => {
        getData(skip, limit);
    }, [selectedVendor]);






    return (
        <div className="mt-4">
            {selectedVendor !== null ?
                <div className='px-3 py-3'>
                    <Card><CardContent>
                        <CardHeader>
                            <CardTitle>{selectedVendor.bank_name} Keys </CardTitle>
                            <CardDescription>View Your {selectedVendor.bank_name} Keys Here ..</CardDescription>
                        </CardHeader>
                        <AddMerchantVendorKeys refreshFunction={refreshData} selectedVendor={selectedVendor} />
                        <Table>
                            <TableHeader>
                                <TableRow>
                                    <TableHead >#</TableHead>
                                    <TableHead >Merchant</TableHead>
                                    {fieldArray.map((field, index) => (
                                        <TableHead key={index}>{transformString(field)}</TableHead>
                                    ))}


                                    <TableHead >Date & Time</TableHead>
                                    <TableHead >Action</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {chargeDetails.length > 0 && chargeDetails.map((detail, index) => (
                                    <TableRow key={detail._id}>
                                        <TableCell >{index + 1}</TableCell>
                                        <TableCell > <MerchantCard merchant={detail.merchant_id} /></TableCell>
                                        {fieldArray.map((field, index) => (
                                            <TableCell key={index}>{detail[field]}</TableCell>
                                        ))}
                                        <TableCell >  {moment(detail.created_date).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                        <TableCell > <DeleteMerchantVendorKeys keyDetails={detail} refreshFunction={refreshData} selectedVendor={selectedVendor} /></TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div className="flex justify-center my-3">

                            <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                        </div>
                    </CardContent></Card>


                </div> :
                <div className="w-96">
                    <Alert>
                        <AlertCircle className="h-4 w-4" />
                        <AlertTitle>Alert !</AlertTitle>
                        <AlertDescription>
                            Not Configured for this vendor
                        </AlertDescription>
                    </Alert>
                </div>

            }




        </div>
    );
};

export default VendorKeysAddListDelete;
