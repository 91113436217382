import React, { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import * as Yup from "yup";
import { useToast } from "@/components/ui/use-toast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Field, Formik, Form, ErrorMessage } from "formik";
import { getStates, addCustomerAddress } from "Apis/merchantapis";

const AddCustomerAddress = (props) => {
  const [open, setOpen] = useState(false);

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedCustomerId, setSelectedCustomerId] = useState("");

  const { toast } = useToast();

  const getData = async () => {
    const states = await getStates();

    setStates(states.data);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    setSelectedCustomerId(props.customerId);
    setOpen(props.open)
  }, [props]);

  const initialValues = {
    address: "",
    pincode: "",
    city: "",
    state_id: selectedState,
  };

  const validationSchema = Yup.object().shape({
    address: Yup.string().required("Address  is required"),
    pincode: Yup.string()
      .matches(/^\d{6}$/, "Pin code must be a 6-digit number")
      .required("Pin code is required"),
    city: Yup.string().required("City is required"),
    state_id: Yup.string().required("State  is required"),
  });

  const onSubmit = async (values) => {
    // Handle form submission
    setOpen(false);

    const sendRequest = await addCustomerAddress(selectedCustomerId, values);

    if (sendRequest.success == true) {
      toast({
        title: "Success",
        description: sendRequest.message,
      });
    } else {
      toast({
        variant: "destructive",
        title: "Error",
        description: sendRequest.message,
      });
    }
    props.getAddressFunction(selectedCustomerId);
  };
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="">Add New Address</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Add Customer Address</DialogTitle>
          <DialogDescription></DialogDescription>
        </DialogHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
            setFieldValue,
          }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-1 pt-3 pb-16 px-3 gap-2 gap-y-4 ">
                <div>
                  <Label htmlFor="address">Address:*</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="address"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                  />

                  <ErrorMessage
                    name="address"
                    className="text-red-500 text-xs  my-2"
                    component="div"
                  />
                </div>
                <div>
                  <Label htmlFor="pincode">Pin Code:*</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="pincode"
                    name="pincode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.pincode}
                  />
                  <ErrorMessage
                    name="pincode"
                    className="text-red-500 text-xs  my-2"
                    component="div"
                  />
                </div>
                <div>
                  <Label htmlFor="city">City:*</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="city"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                  />
                  <ErrorMessage
                    name="city"
                    className="text-red-500 text-xs  my-2"
                    component="div"
                  />
                </div>
                <div>
                  <Label htmlFor="state_id">State:*</Label>
                  <div className="mt-3">
                    <Select
                      onValueChange={(field) => {
                        setFieldValue("state_id", field);
                      }}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select State" />
                      </SelectTrigger>
                      <SelectContent
                        className={
                          "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                        }
                      >
                        {states.length > 0 &&
                          states.map((states) => (
                            <SelectItem key={states._id} value={states._id}>
                              {states.state_name}
                            </SelectItem>
                          ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <ErrorMessage
                    name="state_id"
                    className="text-red-500 text-xs  my-2"
                    component="div"
                  />
                </div>
              </div>

              <Button type="submit">Submit</Button>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddCustomerAddress;
