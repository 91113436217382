import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    CardDescription,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import { getPayoutRoute } from "Apis/employeeapi";
import moment from "moment";
import AddPayoutGateway from "./AddPayoutGateway";
import EditPayoutGateway from "./EditPayoutGateway";
import PaginatedItems from "Pagination/Pagination";
import MerchantCard from "ReusableComponents/MerchantCard";

const PayoutGateway = () => {

    const [tableLoading, setTableLoading] = useState(false);
    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [chargeDetails, setChargeDetails] = useState(false);

    const getData = async (skip, limit) => {
        setTableLoading(true)
        const fetchData = await getPayoutRoute("", skip, limit);
        setChargeDetails(fetchData.data);
        setCount(fetchData.count)
        setTableLoading(false)

    };

    const refreshData = () => {
        getData()
    }


    const fetchdata = (limit, skip) => {
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit);
    };

    useEffect(() => {
        getData(skip, limit);

    }, []);






    return (
        <div className="mt-4">
            <div className='px-3 py-3'>
                <Card><CardContent>
                    <CardHeader>
                        <CardTitle>Payout Gateway Routes</CardTitle>
                        <CardDescription>View and Edit Payout Gateway Routes</CardDescription>
                    </CardHeader>
                    <AddPayoutGateway refreshFunction={refreshData} />
                    <Table>
                        <TableHeader>
                            <TableRow>
                                <TableHead >#</TableHead>
                                <TableHead >Merchant </TableHead>
                                <TableHead >Date & Time</TableHead>
                                <TableHead >Action</TableHead>
                            </TableRow>
                        </TableHeader>
                        <TableBody>
                            {chargeDetails.length > 0 && chargeDetails.map((detail, index) => (
                                <TableRow key={detail._id}>
                                    <TableCell >{index + 1}</TableCell>
                                    <TableCell >  <MerchantCard merchant={detail.merchant_id} /></TableCell>
                                    <TableCell >  {moment(detail.created_date).format("YYYY-MM-DD HH:mm:ss")}</TableCell>
                                    <TableCell ><EditPayoutGateway merchantGateway={detail} refreshFunction={refreshData} /> </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    <div className="flex justify-center my-3">

                        <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                    </div>
                </CardContent></Card>


            </div>



        </div>
    );
};

export default PayoutGateway;
