import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

let initialState = {
    user: "",

}

const authSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        getUser: (state, action) => {
            state.user = action.payload

        }
    }
})

export const { getUser } = authSlice.actions;
export default authSlice.reducer;