import React, { useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { addCustomer } from "Apis/merchantapis";
import { useToast } from "@/components/ui/use-toast";

const AddCustomer = (prop) => {
    const { toast } = useToast();
  const [open, setOpen] = useState(false);

  const initialValues = {
    customer_name: "",
    customer_email: "",
    customer_phone: "",
    customer_gstno: "",
  };

  const validationSchema = Yup.object().shape({
    customer_name: Yup.string().required(" Name is required"),
    customer_email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    customer_phone: Yup.string()
      .matches(/^\d{10}$/, "Invalid Phone Number format")
      .required("Phone Number is required"),
    customer_gstno: Yup.string()
      .matches(
        /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/,
        "Invalid gst no format"
      )
      .required("Gst No is required"),
  });

  const onSubmit = async (values) => {
    // Handle form submission
    setOpen(false);
    console.log(values);
    const sendRequest = await addCustomer(values);
    if (sendRequest.success == true) {
      toast({
        title: "Success",
        description: sendRequest.message,
      });
    } else {
      toast({
        variant: "destructive",
        title: "Error",
        description: sendRequest.message,
      });
    }
    prop.getFunction();
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Add Customer</Button>
      </DialogTrigger>
      <DialogContent
        className={
          "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
        }
      >
        <DialogHeader>
          <DialogTitle>Add Customer</DialogTitle>
          <DialogDescription>You can add Customers here ..</DialogDescription>
        </DialogHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-2 pt-3 pb-16 px-3 gap-2 gap-y-4 ">
                <div>
                  <Label htmlFor="customer_name">Customer Name:</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="customer_name"
                    name="customer_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.customer_name}
                  />
                  <ErrorMessage
                    name="customer_name"
                    className="text-red-500 text-xs  my-2"
                    component="div"
                  />
                </div>
                <div>
                  <Label htmlFor="customer_email">Customer Email:</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="customer_email"
                    name="customer_email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.customer_email}
                  />
                  <ErrorMessage
                    name="customer_email"
                    className="text-red-500 text-xs my-2"
                    component="div"
                  />
                </div>

                <div>
                  <Label htmlFor="customer_phone">Customer Phone:</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="customer_phone"
                    name="customer_phone"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.customer_phone}
                  />
                  <ErrorMessage
                    name="customer_phone"
                    className="text-red-500 text-xs my-2"
                    component="div"
                  />
                </div>

                <div>
                  <Label htmlFor="customer_gstno">Customer Gst No:</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="customer_gstno"
                    name="customer_gstno"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.customer_gstno}
                  />
                  <ErrorMessage
                    name="customer_gstno"
                    className="text-red-500 text-xs my-2"
                    component="div"
                  />
                </div>
              </div>

              <div></div>

              <DialogFooter>
                <Button type="submit">Submit</Button>
              </DialogFooter>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddCustomer;
