import React from 'react';
import {
    Table,
    TableBody,
    TableHeader,
    TableHead,
    TableRow,
    TableCell,
} from "@/components/ui/table"
import { RocketIcon } from "@radix-ui/react-icons"

import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/components/ui/alert"

const GetReport = () => {
    return (
        <div>
            <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-3xl">
                Get Transaction Report
            </h1>
            <p className="leading-7 [&:not(:first-child)]:mt-3">
                This API is used to get transaction reports within dates .
            </p>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    GET METHOD
                </h3>

                <h3 className="scroll-m-20 text-2xl font-bold tracking-tight lg:text-xl">
                    Url : /payment/gateway/v1/get_report
                </h3>
            </div>

            <div className='my-12'>
                <h3 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-xl">
                    Request Parameter to be Post to Intent URL
                </h3>

                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead>Parameter Name</TableHead>
                            <TableHead>Description</TableHead>
                            <TableHead>Example</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        <TableRow>
                            <TableCell>email</TableCell>
                            <TableCell>merchant login email</TableCell>
                            <TableCell>xyz@gmail.com</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>password</TableCell>
                            <TableCell>merchant login password</TableCell>
                            <TableCell>password</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>startDate</TableCell>
                            <TableCell>Start Date Range</TableCell>
                            <TableCell>2023-11-20</TableCell>
                        </TableRow>

                        <TableRow>
                            <TableCell>endDate</TableCell>
                            <TableCell>End Date Range</TableCell>
                            <TableCell>2023-11-21</TableCell>
                        </TableRow>

                    </TableBody>

                </Table>
            </div>


            <div className='my-12'>
                <h3 className="scroll-m-20 text-normal font-bold tracking-tight lg:text-normal">
                    On Success Report Will be Downloaded .
                </h3>

                <br />

                <Alert>
                    <RocketIcon className="h-4 w-4" />
                    <AlertTitle>Note!</AlertTitle>
                    <AlertDescription>
                        If you are using postman please click send and download
                    </AlertDescription>
                </Alert>
            </div>




        </div>
    );
};

export default GetReport;
