// Import necessary dependencies
import React, { useEffect, useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { CardTitle, CardHeader, CardContent, CardFooter, Card } from "@/components/ui/card"
import { Input } from "@/components/ui/input";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { useToast } from "@/components/ui/use-toast";
import { Label } from "@/components/ui/label";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"
import { IndianRupee } from "lucide-react";
import { makePayoutTransaction } from "Apis/merchantapis";

// Create the QuickTransfer component
const TransferMoney = (props) => {
    const { toast } = useToast();
    const [open, setOpen] = useState(false);
    const [beneficiary, setBeneficiary] = useState(false);

    console.log(props)
    // Initial values for the form
    const initialValues = {
        beneficiary_id: beneficiary ? beneficiary.beneficiary_gid : "",
        transfer_method: "",
        amount: "",
        purpose: "",
        remarks: "",
    };

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        beneficiary_id: Yup.string().required("Beneficiary ID is required"),
        transfer_method: Yup.string().required("Transfer method is required"),
        amount: Yup.number().required("Amount is required").positive("Amount must be positive"),
        purpose: Yup.string().required("Purpose is required"),
        remarks: Yup.string(),
    });

    // Function to handle form submission
    const onSubmit = async (values) => {
        // Handle form submission
        setOpen(false);
        console.log(values)

        // Replace with your actual API function for quick transfers
        const sendRequest = await makePayoutTransaction(values);

        if (sendRequest.success === true) {
            toast({
                title: "Success",
                description: sendRequest.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendRequest.message,
            });
        }

        props.getReloadFunction();
        // Do any additional actions after the quick transfer, if needed
        // For example, you might want to display a success message.
    };

    useEffect(() => {
        setBeneficiary(props.beneficiary)

    }, [])

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline"><IndianRupee /></Button>
            </DialogTrigger>
            <DialogContent
                className={
                    "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                }
            >
                <DialogHeader>
                    <DialogTitle>Quick Transfer</DialogTitle>
                    <DialogDescription>
                        Enter the details for a quick transfer below.
                    </DialogDescription>
                </DialogHeader>
                {/* Formik form for quick transfers */}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <Form>
                            <div className="grid grid-cols-1 md:grid-cols-2 pt-3 pb-16 px-3 gap-2 gap-y-4">
                                <div>
                                    <Card className="m-4">
                                        <CardHeader>
                                            <CardTitle>Beneficiary Details</CardTitle>
                                        </CardHeader>
                                        <CardContent>
                                            <div className="space-y-4">
                                                <div className="grid gap-0.5 text-xs">
                                                    <Label htmlFor="email">Name</Label>
                                                    <div className="font-bold my-2 text-lg ">{beneficiary.beneficiary_name}</div>
                                                </div>
                                                <div className="grid gap-0.5 text-xs">
                                                    <Label htmlFor="email">Email</Label>
                                                    <div className="font-bold my-2 text-lg">{beneficiary.beneficiary_email}</div>
                                                </div>
                                                <div className="grid gap-0.5 text-xs">
                                                    <Label htmlFor="phone">Phone</Label>
                                                    <div className="font-bold my-2 text-lg">{beneficiary.beneficiary_phone}</div>
                                                </div>
                                                <div className="grid gap-0.5 text-xs">
                                                    <Label htmlFor="upi">UPI ID</Label>
                                                    <div className="font-bold my-2 text-lg">{beneficiary.beneficiary_upi}</div>
                                                </div>
                                            </div>
                                        </CardContent>

                                    </Card>
                                </div>
                                <div className="grid grid-cols-1 md:grid-cols-1 pt-3 pb-2 px-3 gap-2 gap-y-4">
                                    <div>
                                        <Label htmlFor="beneficiary_id">Beneficiary ID:</Label>
                                        <Input
                                            className="mt-3"
                                            type="text"
                                            id="beneficiary_id"
                                            name="beneficiary_id"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.beneficiary_id}
                                            readOnly={true}
                                        />
                                        <ErrorMessage
                                            name="beneficiary_id"
                                            className="text-red-500 text-xs my-2"
                                            component="div"
                                        />
                                    </div>
                                    <div>
                                        <Label htmlFor="transfer_method" >Transfer Method:</Label>
                                        <div className="mt-3">
                                            <Select name="transfer_method"
                                                id="transfer_method"
                                                defaultValue={values.transfer_method}
                                                onValueChange={(field) => {
                                                    setFieldValue('transfer_method', field)
                                                }}>
                                                <SelectTrigger className="w-full ">
                                                    <SelectValue />
                                                </SelectTrigger>
                                                <SelectContent className={
                                                    "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                }>
                                                    <SelectGroup>
                                                        <SelectItem value="IMPS">IMPS</SelectItem>
                                                        <SelectItem value="NEFT">NEFT</SelectItem>
                                                        <SelectItem value="RTGS">RTGS</SelectItem>
                                                        <SelectItem value="UPI">UPI</SelectItem>
                                                    </SelectGroup>
                                                </SelectContent>
                                            </Select>
                                        </div>

                                        <ErrorMessage
                                            name="transfer_method"
                                            className="text-red-500 text-xs my-2"
                                            component="div"
                                        />
                                    </div>
                                    <div>
                                        <Label htmlFor="amount">Amount:</Label>
                                        <Input
                                            className="mt-3"
                                            type="number"
                                            id="amount"
                                            name="amount"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.amount}
                                        />
                                        <ErrorMessage
                                            name="amount"
                                            className="text-red-500 text-xs my-2"
                                            component="div"
                                        />
                                    </div>
                                    <div>
                                        <Label htmlFor="purpose">Purpose:</Label>
                                        <Input
                                            className="mt-3"
                                            type="text"
                                            id="purpose"
                                            name="purpose"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.purpose}
                                        />
                                        <ErrorMessage
                                            name="purpose"
                                            className="text-red-500 text-xs my-2"
                                            component="div"
                                        />
                                    </div>
                                    <div>
                                        <Label htmlFor="remarks">Remarks:</Label>
                                        <Input
                                            className="mt-3"
                                            type="text"
                                            id="remarks"
                                            name="remarks"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.remarks}
                                        />
                                        <ErrorMessage
                                            name="remarks"
                                            className="text-red-500 text-xs my-2"
                                            component="div"
                                        />
                                    </div>
                                </div>
                            </div>


                            <DialogFooter>
                                <Button type="submit">Submit</Button>
                            </DialogFooter>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default TransferMoney;
