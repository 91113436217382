import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, format } from "date-fns";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { getTestPaylinks } from "Apis/merchantapis";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import AddTestPaylink from "./AddTestPaylink";
import EditTestPaylink from "./EditTestPaylink";

const TestPaylinksTable = () => {
    const url = `${process.env.REACT_APP_PAYMENT_URL}`;
    const [date, setDate] = useState({
        from: new Date(new Date().getFullYear(), 0, 1),
        to: addDays(new Date(new Date().getFullYear(), 0, 1), 365),
    });

    const [statusQ, setstatusQ] = useState("");

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [paylinks, setpaylinks] = useState([]);

    const getData = async (skip, limit, dates, status) => {
        const dateConverter = (date) => {
            const currentDate = new Date(date);
            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, "0");
            const day = String(currentDate.getDate()).padStart(2, "0");
            const dateString = `${year}-${month}-${day}`;
            return dateString;
        };

        if (dates != undefined) {
            const fromDateString = dateConverter(dates.from);
            const toDateString = dateConverter(dates.to);

            const dateRangeObject = {
                from: fromDateString,
                to: toDateString,
            };

            const searchObject = {
                paylink_status: status,
            };

            const fetchData = await getTestPaylinks(
                skip,
                limit,
                searchObject,
                dateRangeObject
            );

            setpaylinks(fetchData.data);
            setCount(fetchData.count);
        }
    };

    const reloadPaylinks = () => {
        getData(skip, limit, date, statusQ);
    };

    const fetchdata = (limit, skip) => {
        console.log(limit, skip);
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, date, statusQ);
    };

    useEffect(() => {
        getData(skip, limit, date, statusQ);
    }, [date, statusQ]);

    return (
        <div className="rounded-md border">
            <div className="grid grid-cols-1 md:grid-cols-3 pt-3 pb-8 px-3 gap-8">
                <div className="">
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button
                                id="date"
                                variant={"outline"}
                                className={cn(
                                    "w-[350px] justify-start text-left font-normal",
                                    !date && "text-muted-foreground"
                                )}
                            >
                                <CalendarIcon className="mr-2 h-4 w-4" />
                                {date?.from ? (
                                    date.to ? (
                                        <>
                                            {format(date.from, "LLL dd, y")} -{" "}
                                            {format(date.to, "LLL dd, y")}
                                        </>
                                    ) : (
                                        format(date.from, "LLL dd, y")
                                    )
                                ) : (
                                    <span>Pick a date</span>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-auto p-0" align="start">
                            <Calendar
                                initialFocus
                                mode="range"
                                defaultMonth={date?.from}
                                selected={date}
                                onSelect={setDate}
                                numberOfMonths={2}
                            />
                        </PopoverContent>
                    </Popover>
                </div>

                <div>
                    <Select
                        onValueChange={(field) => {
                            setstatusQ(field);
                        }}
                        defaultValue={""}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Select a Status" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Status</SelectLabel>
                                <SelectItem value="">All</SelectItem>
                                <SelectItem value="issued">Issued</SelectItem>
                                <SelectItem value="paid">Paid</SelectItem>
                                <SelectItem value="failed">Failed</SelectItem>
                                <SelectItem value="expired">Expired</SelectItem>
                                <SelectItem value="cancelled">Cancelled</SelectItem>
                                <SelectItem value="partially_paid">Partially Paid</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
            </div>

            <div className="px-3">
                <AddTestPaylink getPaylinksFunction={reloadPaylinks} />
            </div>
            <Table>
                <TableCaption>A list of your recent payments.</TableCaption>
                <TableHeader>
                    <TableRow>
                        <TableHead>Sno.</TableHead>
                        <TableHead>Paylink Id</TableHead>
                        <TableHead>Receipt</TableHead>
                        <TableHead>Amount</TableHead>
                        <TableHead>Customer Email</TableHead>
                        <TableHead>Customer Mobile</TableHead>
                        <TableHead>Paylink</TableHead>
                        <TableHead>Status</TableHead>
                        <TableHead>Created At</TableHead>
                        <TableHead>Created By</TableHead>
                        <TableHead>Action</TableHead>
                    </TableRow>
                </TableHeader>

                <TableBody>
                    {paylinks.length > 0 &&
                        paylinks.map((item, index) => (
                            <TableRow key={item._id}>
                                <TableCell className="font-medium">{index + 1}</TableCell>
                                <TableCell>{item.paylink_gid}</TableCell>
                                <TableCell>{item.paylink_receipt}</TableCell>
                                <TableCell>{item.paylink_amount}</TableCell>
                                <TableCell>{item.paylink_customer_email}</TableCell>
                                <TableCell>{item.paylink_customer_mobile}</TableCell>
                                <TableCell> <a className="text-blue-500" href={`${url}${item.paylink_link}`} target="_blank" rel="noreferrer">{`${url}${item.paylink_link}`}</a>  </TableCell>

                                <TableCell>{item.paylink_status}</TableCell>
                                <TableCell>
                                    {moment(item.created_date).format("YYYY-MM-DD")}
                                </TableCell>
                                <TableCell>{item.created_merchant.name}</TableCell>
                                <TableCell><EditTestPaylink paylink={item} getPaylinksFunction={reloadPaylinks} /></TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>

            <div className="flex justify-center my-3">
                <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
            </div>
        </div>
    );
};

export default TestPaylinksTable;
