// Import necessary dependencies
import React, { useEffect, useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { addBeneficiaries, getStates } from "Apis/merchantapis";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select"

// Create the AddBeneficiary component
const AddBeneficiary = (props) => {
    const [open, setOpen] = useState(false);
    const [states, setStates] = useState(false);

    const getData = async () => {
        const StateResponse = await getStates();

        setStates(StateResponse.data);
    }

    useEffect(() => {
        getData()
    }, [])


    // Initial values for the form
    const initialValues = {
        beneficiary_name: "",
        beneficiary_phone: "",
        beneficiary_email: "",
        beneficiary_upi: "",
        beneficiary_ifsc: "",
        beneficiary_bank_ac: "",
        address_line1: "",
        address_line2: "",
        city: "",
        state: "",
    };

    // Validation schema using Yup
    const validationSchema = Yup.object().shape({
        beneficiary_name: Yup.string().required("Beneficiary Name is required"),
        beneficiary_phone: Yup.string()
            .required("Phone number is required")
            .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
        beneficiary_email: Yup.string().email('Invalid email address'),
        beneficiary_upi: Yup.string().required("Upi Id is required"),
        beneficiary_ifsc: Yup.string().required("IFSC code is required"),
        beneficiary_bank_ac: Yup.string().required("Bank account number is required"),
        address_line1: Yup.string().required("Address Line 1 is required"),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
    });

    // Function to handle form submission
    const onSubmit = async (values) => {
        // Handle form submission
        setOpen(false);

        // Replace with your actual API function for adding beneficiaries
        const sendRequest = await addBeneficiaries(values);

        // Do any additional actions after adding beneficiary, if needed
        // For example, you might want to fetch updated beneficiary list.
        props.getBeneficiariesFunction();
    };

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Add Beneficiary</Button>
            </DialogTrigger>
            <DialogContent
                className={
                    "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                }
            >
                <DialogHeader>
                    <DialogTitle>Add Beneficiary</DialogTitle>
                    <DialogDescription>
                        Enter the details of the beneficiary below.
                    </DialogDescription>
                </DialogHeader>
                {/* Formik form for adding beneficiaries */}
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    onSubmit={onSubmit}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <Form>
                            <div className="grid grid-cols-1 md:grid-cols-3 pt-3 pb-16 px-3 gap-2 gap-y-4">
                                <div>
                                    <Label htmlFor="beneficiary_name">Beneficiary Name:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="beneficiary_name"
                                        name="beneficiary_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.beneficiary_name}
                                    />
                                    <ErrorMessage
                                        name="beneficiary_name"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="beneficiary_phone">Phone Number:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="beneficiary_phone"
                                        name="beneficiary_phone"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.beneficiary_phone}
                                    />
                                    <ErrorMessage
                                        name="beneficiary_phone"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>
                                <div>
                                    <Label htmlFor="beneficiary_email">Email:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="beneficiary_email"
                                        name="beneficiary_email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.beneficiary_email}
                                    />
                                    <ErrorMessage
                                        name="beneficiary_email"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>

                                <div>
                                    <Label htmlFor="beneficiary_upi">UPI:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="beneficiary_upi"
                                        name="beneficiary_upi"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.beneficiary_upi}
                                    />
                                    <ErrorMessage
                                        name="beneficiary_upi"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>

                                <div>
                                    <Label htmlFor="beneficiary_ifsc">IFSC Code:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="beneficiary_ifsc"
                                        name="beneficiary_ifsc"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.beneficiary_ifsc}
                                    />
                                    <ErrorMessage
                                        name="beneficiary_ifsc"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>

                                <div>
                                    <Label htmlFor="beneficiary_bank_ac">Bank Account Number:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="beneficiary_bank_ac"
                                        name="beneficiary_bank_ac"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.beneficiary_bank_ac}
                                    />
                                    <ErrorMessage
                                        name="beneficiary_bank_ac"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>

                                <div>
                                    <Label htmlFor="address_line1">Address Line 1:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="address_line1"
                                        name="address_line1"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.address_line1}
                                    />
                                    <ErrorMessage
                                        name="address_line1"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>

                                <div>
                                    <Label htmlFor="address_line2">Address Line 2:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="address_line2"
                                        name="address_line2"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.address_line2}
                                    />
                                    <ErrorMessage
                                        name="address_line2"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>

                                <div>
                                    <Label htmlFor="city">City:</Label>
                                    <Input
                                        className="mt-3"
                                        type="text"
                                        id="city"
                                        name="city"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.city}
                                    />
                                    <ErrorMessage
                                        name="city"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>

                                <div>
                                    <Label htmlFor="state">State:</Label>
                                    <Select name="state"
                                        id="state"
                                        defaultValue={values.state}
                                        onValueChange={(field) => {
                                            setFieldValue('state', field)
                                        }}>
                                        <SelectTrigger className="w-full ">
                                            <SelectValue />
                                        </SelectTrigger>
                                        <SelectContent className={
                                            "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                        }>
                                            <SelectGroup>
                                                {states.map((value) => (

                                                    <SelectItem key={value._id} value={value._id}>{value.state_name}</SelectItem>
                                                ))}

                                            </SelectGroup>
                                        </SelectContent>
                                    </Select>
                                    <ErrorMessage
                                        name="state"
                                        className="text-red-500 text-xs my-2"
                                        component="div"
                                    />
                                </div>
                            </div>

                            <DialogFooter>
                                <Button type="submit">Submit</Button>
                            </DialogFooter>
                        </Form >


                    )}
                </Formik >
            </DialogContent >
        </Dialog >
    );
};

export default AddBeneficiary;
