import React from "react";
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "@/components/ui/tabs"

import MerchantNumberofTransactions from "./MerchantNumberofTransactions";
import MerchantAmountofTransactions from "./MerchantAmountofTransaction";
import Breadcrumb from "LoadingComponents/Breadcrumb";
import { Users, IndianRupee } from "lucide-react";


const EmployeeDashboard = () => {

    const BreadcrumbArray = [{ name: 'Merchant',icon:<Users/> }, { name: 'Transactions Info',icon:<IndianRupee/> }]


    return (
        <div className="rounded-md border mt-4">
            <Breadcrumb data={BreadcrumbArray} />

            <Tabs defaultValue="count" className="w-full px-2 py-2">
                <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="count">Number of Transactions</TabsTrigger>
                    <TabsTrigger value="amount">Total Transactions Amount</TabsTrigger>
                </TabsList>
                <TabsContent value="count">
                    <MerchantNumberofTransactions />
                </TabsContent>
                <TabsContent value="amount">
                    <MerchantAmountofTransactions />
                </TabsContent>
            </Tabs>







        </div>
    );
};

export default EmployeeDashboard;
