import React, { useState, useEffect } from 'react';
import { AvatarImage, AvatarFallback, Avatar } from "@/components/ui/avatar"
import { CardContent, Card } from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Button } from "@/components/ui/button"

const BeneficiaryInfo = (props) => {

    const [beneficiaryInfo, setBeneficiaryinfo] = useState(null);

    useEffect(() => {
        setBeneficiaryinfo(props.beneficiary)
    }, [props])

    return (
        beneficiaryInfo !== null &&
        <div>

            <div>
                <div className="grid grid-cols-8 gap-1 py-4">
                    <div className="my-2 col-span-2">
                        <h5 className="text-xs">Beneficiary Gid</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.beneficiary_gid}</h5>
                    </div>

                    <div className="my-2 col-span-2">
                        <h5 className="text-xs">Beneficiary Name</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.beneficiary_name}</h5>
                    </div>

                    <div className="my-2 col-span-2">
                        <h5 className="text-xs">Beneficiary Phone</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.beneficiary_phone}</h5>
                    </div>

                    <div className="my-2 col-span-2">
                        <h5 className="text-xs">Beneficiary Email</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.beneficiary_email}</h5>
                    </div>
                </div>
            </div>

            <hr />

            <div>
                <h5>Address Info</h5>
                <div className="grid grid-cols-4 gap-1 py-4">
                    <div className="my-2">
                        <h5 className="text-xs">Address Line 1</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.address_line1}</h5>
                    </div>

                    <div className="my-2">
                        <h5 className="text-xs">Address Line 2</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.address_line2}</h5>
                    </div>

                    <div className="my-2">
                        <h5 className="text-xs">City</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.city}</h5>
                    </div>

                    <div className="my-2">
                        <h5 className="text-xs">State</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.state.state_name}</h5>
                    </div>
                </div>
            </div>

            <hr />

            <div>
                <h5>Bank & Upi Info</h5>
                <div className="grid grid-cols-4 gap-1 py-4">
                    <div className="my-2">
                        <h5 className="text-xs">IFSC</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.beneficiary_ifsc}</h5>
                    </div>

                    <div className="my-2">
                        <h5 className="text-xs">Bank Account</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.beneficiary_bank_ac}</h5>
                    </div>

                    <div className="my-2">
                        <h5 className="text-xs">Upi</h5>
                        <h5 className="text-sm font-medium break-words">{beneficiaryInfo.beneficiary_upi}</h5>
                    </div>
                </div>
            </div>

            {/* Add more sections as needed for other information */}
        </div>
    );
};

export default BeneficiaryInfo;
