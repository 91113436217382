import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import moment from "moment";
import { Button } from "@/components/ui/button"
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion"
import { Badge } from "@/components/ui/badge"

const ViewWebhookResponse = (prop) => {
    const [response, setResponse] = useState(null);

    useEffect(() => {
        setResponse(prop.webhook)
    }, [prop])

    console.log(response)

    return (
        <Dialog>
            <DialogTrigger asChild>
                <Button variant="outline">View</Button>
            </DialogTrigger>
            <DialogContent className={
                "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Webhook Responses</DialogTitle>
                    <DialogDescription>
                        View Transaction Request and responses
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    {response !== null &&
                        <Accordion type="single" collapsible className="w-full">
                            {response.request !== undefined &&
                                <AccordionItem value="item-1">
                                    <AccordionTrigger>Request {moment(response.request_timestamp).format("YYYY-MM-DD HH:mm:ss")}</AccordionTrigger>
                                    <AccordionContent>
                                        <pre>{JSON.stringify(JSON.parse(response.request), null, 4)}</pre>
                                    </AccordionContent>
                                </AccordionItem>
                            }
                            {response.response !== undefined &&
                                <AccordionItem value="item-2">
                                    <AccordionTrigger>Response {moment(response.response_timestamp).format("YYYY-MM-DD HH:mm:ss")}</AccordionTrigger>
                                    <AccordionContent>
                                        <pre>{JSON.stringify(JSON.parse(response.response), null, 4)}</pre>
                                    </AccordionContent>
                                </AccordionItem>
                            }
                            {response.webhook !== undefined &&
                                <AccordionItem value="item-3">
                                    <AccordionTrigger>Webhook Response  {moment(response.webhook_timestamp).format("YYYY-MM-DD HH:mm:ss")}</AccordionTrigger>
                                    <AccordionContent>
                                        <pre>{JSON.stringify(JSON.parse(response.webhook), null, 4)}</pre>
                                    </AccordionContent>
                                </AccordionItem>
                            }

                        </Accordion>}

                </div>

            </DialogContent>
        </Dialog>
    );
};

export default ViewWebhookResponse;