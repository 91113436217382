import React from 'react';
import RootLayout from 'Layout';
import { Toaster } from "@/components/ui/toaster";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import EmployeeLogin from 'Employee/Auth/EmployeeLogin';
import EmployeeMiddleware from 'Employee/Auth/Middleware/EmployeeMiddleware';
import EmployeeLayout from 'Employee/Layout/EmployeeLayout';
import EmployeeDashboard from 'Employee/Pages/Dashboard';
import MerchantsTransactions from 'Employee/Pages/Merchant/MerchantTransactions/MerchantsTransactions';
import MerchantTable from 'Employee/Pages/Merchant/MerchantDetails/MerchantTable';
import MerchantDetailsTabs from 'Employee/Pages/Merchant/MerchantDetails/MerchantDetailsTabs';
import Transactions from 'Employee/Pages/Technical/Transactions/Transactions';
import ProfilePage from 'Employee/Pages/Profile/ProfilePage';
import MerchantNotifications from 'Employee/Pages/Merchant/MerchantNotifications/Notifications';
import MerchantSettings from 'Employee/Pages/Merchant/MerchantChargeSettings/MerchantSettings';
import MerchantDocuments from 'Employee/Pages/RiskCompliance/MerchantDocuments/MerchantDocuments';
import ApproveDocuments from 'Employee/Pages/RiskCompliance/MerchantDocuments/ApproveDocuments';
import Chargeback from 'Employee/Pages/ChargeBack/Chargeback';
import AppVariables from 'Employee/Pages/AppVariables/AppVariables';
import WebhookResponses from 'Employee/Pages/Technical/Webhooks/WebhookResponses';
import AdminPayoutSettings from 'Employee/Pages/Payout/PayoutSettings/AdminPayoutSettings';
import AdminPayoutReports from 'Employee/Pages/Payout/Reports/Reports';
import MerchantBalance from 'Employee/Pages/Payout/Balance/MerchantBalance';
import FundsHome from 'Employee/Pages/Payout/Fund/FundHome';
import ResellerHome from 'Employee/Pages/Reseller/ResellerHome';
import EmployeeLogout from 'Employee/Auth/EmployeeLogout';

const EmployeeRoutes = () => {
    return (

        <Routes>
            {/* Admin routes */}
            <Route element={<RootLayout />}>
                <Route path="/manage/login" element={<EmployeeLogin />} />
            </Route>

            <Route element={<EmployeeMiddleware children={undefined} />}>



                <Route element={<EmployeeLayout />}>

                    <Route path="/employee_dashboard" element={<EmployeeDashboard />} />

                    <Route path="/chargeback" element={<Chargeback />} />

                    <Route path="/reseller" element={<ResellerHome />} />

                    <Route path="/merchant/merchant_transactions" element={<MerchantsTransactions />} />
                    <Route path="/merchant/merchants" element={<MerchantTable />} />
                    <Route path="/merchant/merchants/:id" element={<MerchantDetailsTabs />} />
                    <Route path="/merchant/notifications" element={<MerchantNotifications />} />
                    <Route path="/merchant/charge_details" element={<MerchantSettings />} />

                    <Route path="/technical/transactions" element={<Transactions />} />
                    <Route path="/technical/webhook_responses" element={<WebhookResponses />} />

                    <Route path="/payout/settings" element={<AdminPayoutSettings />} />
                    <Route path="/payout/reports" element={<AdminPayoutReports />} />
                    <Route path="/payout/balance" element={<MerchantBalance />} />
                    <Route path="/payout/funds" element={<FundsHome />} />


                    <Route path="/riskcompliance/documentverification" element={<MerchantDocuments />} />
                    <Route path="/riskcompliance/documentverification/:id" element={<ApproveDocuments />} />


                    <Route path="/app_variables" element={<AppVariables />} />
                    <Route path="/profile" element={<ProfilePage />} />


                    <Route path="/employee_logout" element={<EmployeeLogout />} />


                </Route>
            </Route>
        </Routes>

    );
};

export default EmployeeRoutes;