import React from "react";
import CustomersTable from "./CustomersTable";

const Customers = () => {
  return (
    <div>
      <CustomersTable />
    </div>
  );
};

export default Customers;
