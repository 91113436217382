import React, { useState, useEffect, useRef } from "react";
import { Button } from "@/components/ui/button";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import {
    Carousel,
    CarouselContent,
    CarouselItem,
    CarouselNext,
    CarouselPrevious,
} from "@/components/ui/carousel"
import Autoplay from "embla-carousel-autoplay"
import Spline from "@splinetool/react-spline";
import { AspectRatio } from "@/components/ui/aspect-ratio"
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { SignUpRequest } from "../../Apis/merchantapis"; // Assuming you have a SignupRequest API function

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast"
import TextTransition, { presets } from 'react-text-transition';

const TEXTS = ['Customizable Payment Gateway Solution For Your Business', 'Easy-To-Use APIs That Require Minimal Setup', 'Simple And Easy-To-Use Dashboard', 'Fast And Simple Onboarding Process', 'Improved Success Rates', 'A Secure And Reliable Platform', '24x7 Assistance And Technical Support'];

export default function SignUp() {
    let navigate = useNavigate();
    const { toast } = useToast();

    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            2000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);

    // Define validation schema using Yup
    const validationSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email("Invalid email address").required("Email is required"),
        mobile: Yup.string().required("Mobile is required"),
        password: Yup.string().min(6, "Password must be at least 6 characters").required("Password is required"),
        confirmPassword: Yup.string().oneOf([Yup.ref("password"), null], "Passwords must match").required("Confirm Password is required"),
    });

    // Handle form submission
    const handleSubmit = async (values, { setSubmitting }) => {
        const returnData = await SignUpRequest(values); // Adjust the API call according to your backend
        console.log(returnData);
        if (returnData.success === true) {
            toast({
                variant: "outline",
                title: "Success",
                description: returnData.message
            })
            navigate("/login");
        } else if (returnData.success === false) {
            console.log(returnData.message);
            toast({
                variant: "destructive",
                title: "Uh oh! Something went wrong.",
                description: returnData.message,
            });
        }
    };

    return (
        <div className="grid grid-cols-1 sm:grid-cols-2">
            <div className="mx-12 mt-1">
               
               
            <AspectRatio ratio={11 / 10} className="mt-16">
            <Spline scene="https://prod.spline.design/wVJn4WYwHsqm857P/scene.splinecode" />
        </AspectRatio>

            </div>
            <div className="flex justify-center">
                <div className="px-2 mt-2 w-2/4">
                    <h1 className="scroll-m-20 text-center text-xl font-bold tracking-tight md:text-xl  my-6">
                        Merchant Signup
                    </h1>
                    <Card>
                        <CardHeader className="space-y-1">
                            <CardTitle className="text-2xl">Sign Up</CardTitle>
                            <CardDescription>
                                Enter your details below to create an account
                            </CardDescription>
                        </CardHeader>
                        <Formik
                            initialValues={{
                                name: "",
                                email: "",
                                mobile: "",
                                password: "",
                                confirmPassword: "",
                            }}
                            validationSchema={validationSchema}
                            onSubmit={handleSubmit}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                isSubmitting,
                            }) => (
                                <Form>
                                    <CardContent className="grid gap-4">
                                        <div className="grid gap-2">
                                            <Label htmlFor="name">Name</Label>
                                            <Input
                                                type="text"
                                                name="name"
                                                value={values.name}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage
                                                name="name"
                                                className="text-red-500"
                                                component="div"
                                            />
                                        </div>
                                        <div className="grid gap-2">
                                            <Label htmlFor="email">Email</Label>
                                            <Input
                                                type="email"
                                                name="email"
                                                value={values.email}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage
                                                name="email"
                                                className="text-red-500"
                                                component="div"
                                            />
                                        </div>
                                        <div className="grid gap-2">
                                            <Label htmlFor="mobile">Mobile</Label>
                                            <Input
                                                type="text"
                                                name="mobile"
                                                value={values.mobile}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage
                                                name="mobile"
                                                className="text-red-500"
                                                component="div"
                                            />
                                        </div>
                                        <div className="grid gap-2">
                                            <Label htmlFor="password">Password</Label>
                                            <Input
                                                type="password"
                                                name="password"
                                                value={values.password}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage
                                                name="password"
                                                className="text-red-500"
                                                component="div"
                                            />
                                        </div>
                                        <div className="grid gap-2">
                                            <Label htmlFor="confirmPassword">Confirm Password</Label>
                                            <Input
                                                type="password"
                                                name="confirmPassword"
                                                value={values.confirmPassword}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                            />
                                            <ErrorMessage
                                                name="confirmPassword"
                                                className="text-red-500"
                                                component="div"
                                            />
                                        </div>
                                    </CardContent>
                                    <CardFooter>
                                        <Button className="w-full" type="submit">
                                            Sign Up
                                        </Button>
                                    </CardFooter>
                                </Form>
                            )}
                        </Formik>
                    </Card>
                </div>
            </div>
        </div>
    );
}
