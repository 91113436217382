import React, { useState, useEffect } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { deleteBeneficiaries } from "Apis/merchantapis";
import { useToast } from "@/components/ui/use-toast";
import { Trash2 } from "lucide-react";
const DeleteBeneficiaries = (prop) => {
    const { toast } = useToast();
    const [open, setOpen] = useState(false);
    const [id, setId] = useState(false);
    const [benGid, setBenGid] = useState(false);

    const onSubmit = async () => {
        // Handle form submission
        setOpen(false);
        const sendRequest = await deleteBeneficiaries(id);
        if (sendRequest.success === true) {
            toast({
                title: "Success",
                description: sendRequest.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendRequest.message,
            });
        }
        prop.getReloadFunction();
    };

    const CloseDialog = () => {
        setOpen(false);
    };

    useEffect(() => {
        setId(prop.id);
        setBenGid(prop.ben_gid);
    }, [prop]);

    return (
        <Dialog open={open} onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline"><Trash2 /></Button>
            </DialogTrigger>
            <DialogContent className={" overflow-y-scroll w-[400px] hide-scrollbar"}>
                <DialogHeader>
                    <DialogTitle>Are you Sure ?</DialogTitle>
                    <DialogDescription>{benGid} will be deleted .</DialogDescription>
                </DialogHeader>
                <DialogFooter>
                    <Button
                        variant="destructive"
                        onClick={() => {
                            onSubmit();
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => {
                            CloseDialog();
                        }}
                    >
                        No
                    </Button>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
};

export default DeleteBeneficiaries;
