import React, { useState, useEffect } from "react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog";
import { Progress } from "@/components/ui/progress"
import TranactionModal from "ReusableComponents/TranactionModal";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card"
import download from 'downloadjs';
import { CalendarIcon } from "@radix-ui/react-icons";
import { addDays, subDays, format } from "date-fns";
import { getTransactionInfoViaId } from "Apis/employeeapi";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover";
import { Input } from "@/components/ui/input";
import { getMerchantTransactions, getMerchantList, exportTransactions } from "Apis/employeeapi";
import PaginatedItems from "Pagination/Pagination";
import moment from "moment";
import { io } from "socket.io-client";
import TableLoading from "LoadingComponents/TableLoading";
import Breadcrumb from "LoadingComponents/Breadcrumb";
import { IndianRupee, SquareStack } from "lucide-react";
import DateTimeRangeInput from "ReusableComponents/DateTimeRangeInput";

const Transactions = () => {
    const BreadcrumbArray = [{ name: 'Technical', icon: <SquareStack /> }, { name: 'Transactions', icon: <IndianRupee /> }]
    const backendUrl = `${process.env.REACT_APP_BACKEND_URL}`;
    const socket = io(backendUrl);
    const [open, setOpen] = useState(false);
    const [percent, setPercent] = useState('');

    const [date, setDate] = useState({
        from: subDays(new Date(), 1), // Set to yesterday
        to: new Date(), // Set to today
    });


    const [paymentIdQ, setpaymentIdQ] = useState("");
    const [statusQ, setstatusQ] = useState("");
    const [merchants, setMerchants] = useState("");
    const [selectedMerchant, setSelectedMerchant] = useState("");
    const [transactionInfo, setTransactionInfo] = useState('');

    const [viewTable, setViewTable] = useState(false);
    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");
    const [transactions, setTransactions] = useState([]);

    const getDateRange = (dateRangeObject) => {
        console.log(dateRangeObject, 'parent');
        setDate(dateRangeObject)

    };

    const getData = async (skip, limit, dates, paymentId, status) => {
        setTableLoading(true)
        if (dates !== undefined) {
            const searchObject = {
                transactiongid: paymentId,
                transaction_amount: "",
                transaction_contact: "",
                transaction_status: status,
            };

            const fetchTransactions = await getMerchantTransactions(
                skip,
                limit,
                searchObject,
                dates,
                selectedMerchant
            );
            setTransactions(fetchTransactions.data);
            setCount(fetchTransactions.count);
            setTableLoading(false)
        }
    };

    const fetchdata = (limit, skip) => {
        console.log(limit, skip);
        setSkip(skip);
        setLimit(limit);
        getData(skip, limit, date, paymentIdQ, statusQ);
    };

    const onClickView = () => {
        if (viewTable === true) {
            setViewTable(false)
        }
        else {
            setViewTable(true)
        }
    }

    const onClickTransactionId = async (id) => {
        console.log(id)
        const fetchdata = await getTransactionInfoViaId(id);
        setTransactionInfo(fetchdata.data)
    }



    useEffect(() => {
        const timer = setInterval(() => {
            const userId = JSON.parse(localStorage.getItem("employee")).userId;
            socket.on(userId, function (msg) {
                console.log(msg);
                setPercent(msg);

            });
        }, 200);

        return () => {
            clearInterval(timer);
        };
    }, []);


    const onClickDownload = async () => {
        console.log('download clicked')
        setPercent('Fetching Data')
        if (date !== undefined) {
            const exportCall = await exportTransactions(
                date, selectedMerchant
            );

            setPercent('Downloading File')
            const blob = await exportCall.blob();

            download(blob, 'data.csv', 'text/csv');

            setOpen(false)
            setPercent('')
        }
    }

    const fetchMerchantsList = async () => {
        const fetchList = await getMerchantList();
        setMerchants(fetchList.data)
    }

    useEffect(() => {
        if (viewTable === true) {
            getData(skip, limit, date, paymentIdQ, statusQ);
        }
    }, [date, paymentIdQ, statusQ, viewTable, selectedMerchant]);

    useEffect(() => {
        fetchMerchantsList();
    }, []);


    return (
        <div className="rounded-md border mt-4">
            <Breadcrumb data={BreadcrumbArray} />
            <div className="grid grid-cols-1 md:grid-cols-5 pt-3 pb-16 px-3 gap-8">
                <DateTimeRangeInput getFunction={getDateRange} />
                <div>
                    <div>
                        <Select
                            onValueChange={(field) => {
                                setSelectedMerchant(field)
                            }}
                            defaultValue={""}
                        >
                            <SelectTrigger>
                                <SelectValue placeholder="Select a Status" />
                            </SelectTrigger>
                            <SelectContent>
                                <SelectGroup>
                                    <SelectLabel>Merchants</SelectLabel>
                                    <SelectItem value="">All</SelectItem>
                                    {merchants.length > 0 && merchants.map((merchant) => (
                                        <SelectItem key={merchant._id} value={merchant._id}>{merchant.name}</SelectItem>
                                    ))}

                                </SelectGroup>
                            </SelectContent>
                        </Select>
                    </div>
                </div>
                <div>
                    <Input
                        type="text"
                        placeholder="Payment Id"
                        onChange={(e) => setpaymentIdQ(e.target.value)}
                    />
                </div>
                <div>
                    <Select
                        onValueChange={(field) => {
                            setstatusQ(field);
                        }}
                        defaultValue={""}
                    >
                        <SelectTrigger>
                            <SelectValue placeholder="Select a Status" />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectLabel>Status</SelectLabel>
                                <SelectItem value="">All</SelectItem>
                                <SelectItem value="success">Success</SelectItem>
                                <SelectItem value="captured">Captured</SelectItem>
                                <SelectItem value="failed">Failed</SelectItem>
                                <SelectItem value="refunded">Refunded</SelectItem>
                                <SelectItem value="authorized">Authorized</SelectItem>
                                <SelectItem value="cancelled">Cancelled</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>
                <div>
                    <Button className="mx-2" onClick={() => { onClickView() }}>View</Button>
                    <Button className="mx-2" disabled={percent !== ''} variant="outline" onClick={() => { onClickDownload() }}>{percent !== '' ? percent : 'Download'}</Button>
                </div>
            </div>


            {viewTable === true && <div className="w-auto">
                <Table>
                    <TableCaption>A list of your recent payments.</TableCaption>
                    <TableHeader>
                        <TableRow>
                            <TableHead >Sno.</TableHead>
                            <TableHead>Payment Id</TableHead>
                            <TableHead>Merchant Gid</TableHead>
                            <TableHead>Vpa</TableHead>
                            <TableHead>Utr</TableHead>
                            <TableHead >Amount</TableHead>
                            <TableHead>Created At</TableHead>
                            <TableHead>Status</TableHead>
                        </TableRow>
                    </TableHeader>

                    {tableLoading === false ?
                        <TableBody>
                            {transactions.length > 0 &&
                                transactions.map((item, index) => (
                                    <TableRow key={item._id}>
                                        <TableCell className="font-medium">{index + 1}</TableCell>
                                        <TableCell>
                                            <Dialog>
                                                <DialogTrigger asChild>
                                                    <Button onClick={() => { onClickTransactionId(item._id) }} variant="link">{item.transaction_gid}</Button>
                                                </DialogTrigger>
                                                <DialogContent className={
                                                    "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                                                }>
                                                    <DialogHeader>
                                                        <DialogTitle>Transaction Info</DialogTitle>
                                                        <DialogDescription>
                                                            All the transaction info at one place
                                                        </DialogDescription>
                                                    </DialogHeader>
                                                    <TranactionModal transaction={transactionInfo} />


                                                </DialogContent>
                                            </Dialog></TableCell>
                                        <TableCell>
                                            <HoverCard>
                                                <HoverCardTrigger asChild>
                                                    <Button variant="link">{item.created_merchant.merchant_gid}</Button>
                                                </HoverCardTrigger>
                                                <HoverCardContent className="w-80">
                                                    <div className="my-2">
                                                        <h5 className="text-lg font-bold ">Merchant Details</h5>
                                                    </div>
                                                    <div className="my-2">
                                                        <h5 className="text-xs">Merchant Gid</h5>
                                                        <h5 className="text-sm">{item.created_merchant.merchant_gid}</h5>
                                                    </div>
                                                    <hr />
                                                    <div className="my-2">
                                                        <h5 className="text-xs">Merchant Name</h5>
                                                        <h5 className="text-sm">{item.created_merchant.name}</h5>
                                                    </div>
                                                    <hr />
                                                    <div className="my-2">
                                                        <h5 className="text-xs">Email</h5>
                                                        <h5 className="text-sm">{item.created_merchant.email}</h5>
                                                    </div>
                                                    <hr />
                                                    <div className="my-2">
                                                        <h5 className="text-xs">Mobile No.</h5>
                                                        <h5 className="text-sm">{item.created_merchant.mobile_no}</h5>
                                                    </div>
                                                </HoverCardContent>
                                            </HoverCard></TableCell>
                                        <TableCell>{item.udf1}</TableCell>
                                        <TableCell>{item.bank_ref_no}</TableCell>
                                        <TableCell>{item.transaction_amount}</TableCell>
                                        
                                        
                                        <TableCell>
                                            {moment(item.transaction_date).format("YYYY-MM-DD HH:mm:ss")}
                                        </TableCell>
                                        <TableCell>{item.transaction_status}</TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>

                        : <TableLoading count={11} />

                    }
                </Table>

                <div className="flex justify-center my-3">
                    <PaginatedItems pg={fetchdata} itemsPerPage={limit} length={count} />
                </div></div>}


            <Dialog open={open} onOpenChange={setOpen} disableOutsidePointerEvents >

                <DialogContent
                    className={
                        "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
                    }
                >
                    <DialogHeader>
                        <DialogTitle>Exporting Transactions</DialogTitle>
                        <DialogDescription>

                        </DialogDescription>
                    </DialogHeader>
                    <h5 className="text-lg font-bold">{percent} %</h5>
                    <Progress value={percent} />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Transactions;
