import React, { useState, useEffect } from 'react';
import { getEmployeeDetails } from 'Apis/employeeapi';
import { motion } from 'framer-motion';
import {
    Card,
    CardContent,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import EditEmployeeProfile from './EditProfile';
import UpdateEmpPassword from './UpdateEmpPassword';
const Profile = () => {


    const [profileDetails, setprofileDetails] = useState("");
    const [tableLoading, setTableLoading] = useState(false);

    const [skip, setSkip] = useState(0);
    const [limit, setLimit] = useState(10);
    const [count, setCount] = useState("");

    const getData = async () => {
        setTableLoading(true)
        const fetchProfile = await getEmployeeDetails();
        setprofileDetails(fetchProfile.data);
        setTableLoading(false)
        console.log(fetchProfile)
    };



    // const fetchdata = (limit, skip) => {
    //     setSkip(skip);
    //     setLimit(limit);
    //     getData(merchantId, skip, limit);
    // };

    useEffect(() => {
        getData();

    }, []);
    return (

        <div className="grid grid-cols-1 md:grid-cols-1 pt-3 pb-8 px-3 gap-8">

            <motion.div
                initial={{ scale: 0 }}
                animate={{ rotate: 0, scale: 1 }}
                transition={{
                    type: "spring",
                    stiffness: 260,
                    damping: 20
                }}
            >
                <Card>
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
                        <CardTitle className="text-lg font-medium">
                            Profile details
                        </CardTitle>
                        <div className='flex gap-2'>
                        <EditEmployeeProfile profile={profileDetails} refreshFunction={getData} />
                        <UpdateEmpPassword/>
                        </div>
                        
                    </CardHeader>
                    <CardContent>
                        <div className="grid grid-cols-1 sm:grid-cols-6">


                            <div className='mt-3'>
                                <h5 className='text-xs font-bold'>UserName:</h5>
                                <h5 className='text-md font-bold'> {profileDetails.employee_username}</h5>
                            </div>

                            <div className='mt-3'>
                                <h5 className='text-xs font-bold' >First Name:</h5>
                                <h5 className='text-md font-bold'>{profileDetails.first_name}</h5>
                            </div>

                            <div className='mt-3'>
                                <h5 className='text-xs font-bold'>Last Name:</h5>
                                <h5 className='text-md font-bold'>{profileDetails.last_name}</h5>
                            </div>

                            <div className='mt-3'>
                                <h5 className='text-xs font-bold'>Designation:</h5>
                                <h5 className='text-md font-bold'>{profileDetails.designation}</h5>
                            </div>

                            <div className='mt-3'>
                                <h5 className='text-xs font-bold'>Official Email:</h5>
                                <h5 className='text-md font-bold'>{profileDetails.official_email}</h5>
                            </div>

                            <div className='mt-3'>
                                <h5 className='text-xs font-bold'>Mobile:</h5>
                                <h5 className='text-md font-bold'>{profileDetails.mobile_no}</h5>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </motion.div>
        </div>

    );
};

export default Profile;