import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import {

    FormDescription,
    FormLabel,
} from "@/components/ui/form"
import { Switch } from "@/components/ui/switch"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { getMerchantList, addFund } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";

export default function AddFund(props) {
    const { toast } = useToast();
    const [merchants, setMerchants] = useState();
    const [merchantId, setMerchantId] = useState();
    const [open, setOpen] = useState(false);



    const getData = async () => {
        const fetchMerchants = await getMerchantList();
        setMerchants(fetchMerchants.data)
    }

    useEffect(() => {
        getData()
    }, [])


    const initialChargeDetailsValues =
    {
        merchantId: "",
        fund_added: "",
        is_debit: false,
        remark: ""
    }
        ;


    const chargeDetailSchema = Yup.object().shape({
        merchantId: Yup.string().required('This Field is required'),
        fund_added: Yup.string().required('This Field is required').test('two-decimal-places', 'Must have exactly two decimal places like 1.00', (value) =>
            /^\d+\.\d{2}$/.test(value)
        )
    });




    const submitChargeDetailsForm = async (values) => {
        const sendData = await addFund(values)
        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        setOpen(false)
        props.refreshFunction();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Add</Button>
            </DialogTrigger>
            <DialogContent className={
                " overflow-y-scroll w-3/4 hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Merchant Charges Form</DialogTitle>
                    <DialogDescription>
                        Fill the Form & Click submmit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        initialValues={initialChargeDetailsValues}
                        validationSchema={chargeDetailSchema}
                        onSubmit={submitChargeDetailsForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values, setFieldValue } = formik;

                            return (
                                <div className=" mt-1  p-5 ">
                                    <Form>
                                        <div className="grid grid-cols-1 gap-4">
                                            <div>
                                                <Label htmlFor="merchantId">Merchant:</Label>
                                                <div className="mt-3">
                                                    <Select

                                                        onValueChange={(field) => {
                                                            setFieldValue('merchantId', field)
                                                        }}
                                                        defaultValue={""}
                                                    >
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Select a Status" />
                                                        </SelectTrigger>
                                                        <SelectContent>
                                                            <SelectGroup>
                                                                <SelectLabel>Merchants</SelectLabel>
                                                                <SelectItem value="">Select Merchant</SelectItem>
                                                                {merchants.map((merchant) => (
                                                                    <SelectItem value={merchant._id} key={merchant._id}>{merchant.name}</SelectItem>
                                                                ))}

                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </div>

                                                {errors.merchantId && touched.merchantId && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.merchantId}
                                                    </span>
                                                )}
                                            </div>


                                            <div >
                                                <Label htmlFor="fund_added">Amount:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="fund_added"
                                                    name="fund_added"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.fund_added}
                                                />
                                                {errors.fund_added && touched.fund_added && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.fund_added}
                                                    </span>
                                                )}
                                            </div>

                                            <div className="flex flex-row items-center justify-between rounded-lg border p-3 shadow-sm">
                                                <div className="space-y-0.5">
                                                    <div className='text-normal font-bold'>Debit</div>
                                                    <div className='text-xs font-normal'>
                                                        Amount Will Be Debited
                                                    </div>
                                                </div>
                                                <div>
                                                    <Switch
                                                        checked={values.is_debit}
                                                        onCheckedChange={(value) => { console.log(value); setFieldValue('is_debit', value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div >
                                                <Label htmlFor="remark">Remark:</Label>
                                                <Input
                                                    className="mt-3"
                                                    type="text"
                                                    id="remark"
                                                    name="remark"
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    value={values.remark}
                                                />
                                                {errors.remark && touched.remark && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.remark}
                                                    </span>
                                                )}
                                            </div>










                                        </div>
                                        <div className='flex justify-center'>
                                            <Button
                                                type="submit"
                                                className="btn mt-5 w-96  font-medium  "
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                </div>

            </DialogContent >
        </Dialog >
    )
}
