import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { getMerchantList, addAtomKeys, addCashfreeKeys, addTimePayKeys } from 'Apis/employeeapi';
import { useToast } from "@/components/ui/use-toast";

export default function AddMerchantVendorKeys(props) {
    const { toast } = useToast();
    const [merchants, setMerchants] = useState();
    const [open, setOpen] = useState(false);
    const [selectedVendor, setSelectedVendor] = useState(null);




    const getData = async () => {
        const fetchMerchants = await getMerchantList();
        setMerchants(fetchMerchants.data)
    }

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        setSelectedVendor(props.selectedVendor);
    }, [props]);

    let fieldArray1 = [];
    if (selectedVendor !== null) {
        if (selectedVendor.bank_name === 'Atom') {
            fieldArray1 = [
                "userid",
                "hash_request_key",
                "hash_response_key",
                "aes_request_key",
                "aes_request_salt_iv_key",
                "aes_response_key",
                "aes_response_salt_iv_key"
            ];
        } else if (selectedVendor.bank_name === 'CashFree') {
            fieldArray1 = [
                "app_id",
                "secret_key",
            ];
        }
        else if (selectedVendor.bank_name === 'TimePay') {
            fieldArray1 = [
                "vpa",
                "submerchant_id",
                "mcc",
            ];
        }
        else {
            fieldArray1 = []
        }

    }


    const initialChargeDetailsValues = {
        merchantId: "", // Static key
    };

    let chargeDetailSchema;
    if (fieldArray1.length > 0) {
        for (const field of fieldArray1) {
            initialChargeDetailsValues[field] = "";
        }

        chargeDetailSchema = Yup.object().shape({
            merchantId: Yup.string().required('This Field is required'),
            ...fieldArray1.reduce((schema, field) => {
                schema[field] = Yup.string().required('This Field is required');
                return schema;
            }, {})
        });
    }







    function transformString(inputString) {
        // Split the input string by underscores
        const parts = inputString.split('_');

        // Capitalize the first letter of each part and join them with a space
        const transformedString = parts.map(part => {
            return part.charAt(0).toUpperCase() + part.slice(1);
        }).join(' ');

        return transformedString;
    }

    const submitChargeDetailsForm = async (values) => {



        let sendData;
        if (selectedVendor.bank_name === 'Atom') {
            sendData = await addAtomKeys(values)
        } else if (selectedVendor.bank_name === 'CashFree') {
            sendData = await addCashfreeKeys(values)
        }
        else if (selectedVendor.bank_name === 'TimePay') {
            console.log(values)
            sendData = await addTimePayKeys(values)
        }
        else {
            sendData = { success: false, message: 'no message' }
        }


        if (sendData.success === true) {
            toast({
                title: "Success",
                description: sendData.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendData.message,
            });
        }
        setOpen(false)
        props.refreshFunction();
    }

    return (
        <Dialog open={open}
            onOpenChange={setOpen}>
            <DialogTrigger asChild>
                <Button variant="outline">Add</Button>
            </DialogTrigger>
            <DialogContent className={
                "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
            }>
                <DialogHeader>
                    <DialogTitle>Vendor Configuration Form</DialogTitle>
                    <DialogDescription>
                        Fill the Form & Click submmit when you're done.
                    </DialogDescription>
                </DialogHeader>
                <div className="">
                    <Formik
                        initialValues={initialChargeDetailsValues}
                        validationSchema={chargeDetailSchema}
                        onSubmit={submitChargeDetailsForm}
                    >
                        {(formik) => {
                            const { errors, touched, isValid, dirty, handleChange,
                                handleBlur, values, setFieldValue } = formik;

                            return (
                                <div className=" mt-1  p-5 ">
                                    <Form>
                                        <div className="grid grid-cols-3 gap-4">
                                            <div>
                                                <Label htmlFor="merchantId">Merchant:</Label>
                                                <div className="mt-3">
                                                    <Select

                                                        onValueChange={(field) => {
                                                            setFieldValue('merchantId', field)
                                                        }}
                                                        defaultValue={""}
                                                    >
                                                        <SelectTrigger>
                                                            <SelectValue placeholder="Select a Status" />
                                                        </SelectTrigger>
                                                        <SelectContent className={
                                                            "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                                                        }>
                                                            <SelectGroup>
                                                                <SelectLabel>Merchants</SelectLabel>
                                                                <SelectItem value="">Select Merchant</SelectItem>
                                                                {merchants.map((merchant) => (
                                                                    <SelectItem value={merchant._id} key={merchant._id}>{merchant.name}</SelectItem>
                                                                ))}

                                                            </SelectGroup>
                                                        </SelectContent>
                                                    </Select>
                                                </div>

                                                {errors.merchantId && touched.merchantId && (
                                                    <span className="text-xs text-red-400">
                                                        {errors.merchantId}
                                                    </span>
                                                )}
                                            </div>

                                            {fieldArray1.map((fieldName, index) => (
                                                <div key={index}>
                                                    <Label htmlFor={fieldName}>{transformString(fieldName)}:</Label>
                                                    <div className="mt-3">
                                                        <Input
                                                            className="mt-3"
                                                            type="text"
                                                            id={fieldName}
                                                            name={fieldName}
                                                            onChange={handleChange}
                                                            onBlur={handleBlur}
                                                            value={values[fieldName]}
                                                        />
                                                    </div>

                                                    {errors[fieldName] && touched[fieldName] && (
                                                        <span className="text-xs text-red-400">
                                                            {errors[fieldName]}
                                                        </span>
                                                    )}
                                                </div>
                                            ))}




                                        </div>




                                        <div className='flex justify-center'>
                                            <Button
                                                type="submit"
                                                className="btn mt-5 w-96  font-medium  "
                                            >
                                                Submit
                                            </Button>
                                        </div>

                                    </Form>
                                </div>
                            );
                        }}
                    </Formik>




                </div>

            </DialogContent>
        </Dialog>
    )
}
