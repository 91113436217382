import React, { useState, useEffect } from "react";

import { listMerchantVendorBanks } from "Apis/employeeapi";
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "@/components/ui/select";
import VendorKeysAddListDelete from "./VendorModules/Atom/VendorKeysAddListDelete";


const VendorConfiguration = () => {
    const [vendorBanks, setVendorBanks] = useState([]);
    const [selectedVendor, setSelectedVendor] = useState(null);

    const getData = async () => {
        const getBanks = await listMerchantVendorBanks();
        setVendorBanks(getBanks.data)
    }



    const onChangeVendor = (field) => {
        const filterSelected = vendorBanks.filter((vendor) => {
            return vendor._id === field
        })
        setSelectedVendor(filterSelected[0])
    }


    useEffect(() => {
        getData();
    }, [])

    return (
        <div className="rounded border-1 mt-1">
            <div className="w-96">
                <Select
                    onValueChange={(field) => {
                        onChangeVendor(field)
                    }}
                    defaultValue={""}
                >
                    <SelectTrigger>
                        <SelectValue placeholder="Select a Vendor" />
                    </SelectTrigger>
                    <SelectContent className={
                        "lg:max-w-screen-lg overflow-y-scroll h-48 hide-scrollbar"
                    }>
                        <SelectGroup>
                            <SelectLabel>Vendor Banks</SelectLabel>
                            <SelectItem value="">Select Vendor Banks</SelectItem>
                            {vendorBanks.length > 0 && vendorBanks.map((vendor) => (
                                <SelectItem value={vendor._id} key={vendor._id}>{vendor.bank_name}</SelectItem>
                            ))}

                        </SelectGroup>
                    </SelectContent>
                </Select>
            </div>

            <VendorKeysAddListDelete vendorDetails={selectedVendor} />



        </div>
    );
};

export default VendorConfiguration;