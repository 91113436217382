import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { getRncDocsMerchantStatus, updateMerchantRncStatus, updateMerchantDocumentStatus, sendNotificationsToUploadDocument } from 'Apis/employeeapi';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import Breadcrumb from 'LoadingComponents/Breadcrumb';
import { ShieldAlert, File } from "lucide-react";
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Switch } from "@/components/ui/switch";
import { AlertCircle } from "lucide-react";
import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "@/components/ui/table"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "@/components/ui/alert"
import { useToast } from "@/components/ui/use-toast";
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "@/components/ui/dialog"

const ApproveDocuments = () => {
    const { toast } = useToast();
    const BreadcrumbArray = [{ name: 'RiskCompliance', icon: <ShieldAlert /> }, { name: 'Document Verification', icon: <File /> }]
    const { id } = useParams();

    const [backendUrl, setBackendUrl] = useState("");

    const [merchantRnc, setMerchantRnc] = useState([]);
    const [merchantDocs, setMerchantDocs] = useState([]);

    const getData = async () => {

        const fetchMerchants = await getRncDocsMerchantStatus(id);
        setMerchantRnc(fetchMerchants.rnc);
        setMerchantDocs(fetchMerchants.documents);
    };

    useEffect(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}`;
        setBackendUrl(url)
    }, [])


    useEffect(() => {
        getData();
    }, []);


    const onchangeSwitch = async (id, status) => {
        const updateStatus = await updateMerchantRncStatus(id, status);
        getData();
    }

    const onchangeDocSwitch = async (id, status) => {
        const updateStatus = await updateMerchantDocumentStatus(id, status);
        getData();
    }


    const sendNotifcation = async (document, merchantId) => {
        const sendNotifcationRequest = await sendNotificationsToUploadDocument(document, merchantId);
        if (sendNotifcationRequest.success === true) {
            toast({
                title: "Success",
                description: sendNotifcationRequest.message,
            });
        } else {
            toast({
                variant: "destructive",
                title: "Error",
                description: sendNotifcationRequest.message,
            });
        }
    }


    return (
        <div>
            <Breadcrumb data={BreadcrumbArray} />
            <div className='py-2'>
                <Link to="/riskcompliance/documentverification">   <Button>Back</Button></Link>
            </div>


            {merchantRnc.length > 0 ? merchantRnc.map((item) => (
                <div className="grid grid-cols-3 gap-8  my-2" key={item._id}>
                    <div>
                        <Label className="mt-2" htmlFor={item.field_name}>{item.field_label}:</Label>
                    </div>

                    <div className="">
                        <Input
                            className=""
                            readOnly
                            type="text"
                            id={item.field_name}
                            name={item.field_name}
                            value={item.field_value}
                        />
                    </div>
                    <div>
                        <Switch
                            checked={item.field_verified}
                            onCheckedChange={(changedStatus) => { onchangeSwitch(item._id, changedStatus) }}
                        />
                    </div>
                </div>
            )) :
                <Alert>
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Alert !</AlertTitle>
                    <AlertDescription>
                        No Business Information Found .
                    </AlertDescription>
                </Alert>}



            {merchantDocs.length > 0 ?
                <Card className="my-16">
                    <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2 ">
                        <CardTitle className="text-lg font-medium">
                            Merchant Documents
                        </CardTitle>

                    </CardHeader>
                    <CardContent>
                        <Table>
                            <TableCaption>A list of merchant uploaded Documents.</TableCaption>
                            <TableHeader>
                                <TableRow>
                                    <TableHead className="">File Name</TableHead>
                                    <TableHead>File</TableHead>
                                    <TableHead>Approve</TableHead>
                                    <TableHead>Send Notication to Upload Again</TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {
                                    merchantDocs.length > 0 && merchantDocs.map((item) => (
                                        <TableRow key={item._id}>
                                            <TableCell >{item.doc_label}</TableCell>
                                            <TableCell>    <Dialog>
                                                <DialogTrigger ><Button variant="outline">Open</Button></DialogTrigger>
                                                <DialogContent className="max-w-full">
                                                    <DialogHeader>
                                                        <DialogTitle></DialogTitle>
                                                        <DialogDescription>
                                                            <div className='flex justify-center'>
                                                                <img
                                                                    src={`${backendUrl}/${item.file_name}`}
                                                                    alt="png"
                                                                    width="600px"
                                                                    height="200px"

                                                                />
                                                            </div>

                                                        </DialogDescription>
                                                    </DialogHeader>
                                                </DialogContent>
                                            </Dialog></TableCell>

                                            <TableCell > <Switch
                                                checked={item.doc_verified}
                                                onCheckedChange={(changedStatus) => { onchangeDocSwitch(item._id, changedStatus) }}
                                            /></TableCell>

                                            <TableCell > <Button variant="outline" onClick={(() => { sendNotifcation(item.doc_label, item.merchant_id) })}>Send</Button></TableCell>
                                        </TableRow>
                                    ))


                                }


                            </TableBody>
                        </Table>
                    </CardContent>
                </Card> :
                <Alert className="mt-2">
                    <AlertCircle className="h-4 w-4" />
                    <AlertTitle>Alert !</AlertTitle>
                    <AlertDescription>
                        No Documents Found .
                    </AlertDescription>
                </Alert>}





        </div>
    );
};

export default ApproveDocuments;