import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useToast } from "@/components/ui/use-toast"

const MerchantMiddleware = ({ children }) => {
  const { toast } = useToast()
  let navigate = useNavigate();
  const [jwt, setJwt] = useState(localStorage.getItem("user"));

  useEffect(() => {
    setTimeout(async () => {
      toast({
        variant: "destructive",
        title: "Session Expired",
        description: "Please Login Again . Thank You!",
      });
      navigate('/logout');
    }, 3600000);
  }, [])




  return jwt ? <Outlet /> : <Navigate to="/login" />

};

export default MerchantMiddleware;
