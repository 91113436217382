import React, { useState, useEffect } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { cn } from "@/lib/utils";
import ItemCart from "./ItemCart";
import { Textarea } from "@/components/ui/textarea"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { useNavigate } from "react-router-dom";
import { addCustomer } from "Apis/merchantapis";
import { useToast } from "@/components/ui/use-toast";
import { Calendar as CalendarIcon } from "lucide-react";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import {
  getAllCustomers,
  getCustomerAddresses, getMerchantBusinessDetails, generateInvoiceNumber, addInvoiceDetails
} from "Apis/merchantapis";
import { format, getUnixTime } from "date-fns";
import AddCustomerAddress from "./AddCustomerAddress";

const AddInvoice = (prop) => {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(new Date());
  const [customers, setCustomers] = useState([]);
  const [merchantBusinessDetails, setMerchantBusinessDetails] = useState([]);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [itemsCart, setItemsCart] = useState([]);
  const [customerAddresses, setcustomerAddresses] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState({});

  const [invoiceSubtotal, setinvoiceSubtotal] = useState('');
  const [invoiceTax, setinvoiceTax] = useState('');
  const [invoiceTotal, setinvoiceTotal] = useState('');

  const [addCustomerAddressDialog, setAddCustomerAddressDialog] =
    useState(false);

  const [selectedBillingAdress, setSelectedBillingAdress] = useState("");

  const [selectedShippingAdress, setSelectedShippingAdress] = useState("");

  const currentDate = new Date();
  const formattedDate = format(currentDate, "yyyyMMddHHmm");

  const getData = async () => {
    const fetchData = await getAllCustomers();
    setCustomers(fetchData.data);

    const fetchBusiness = await getMerchantBusinessDetails()
    setMerchantBusinessDetails(fetchBusiness.data)

    const fetchNewInvoiceNumber = await generateInvoiceNumber()
    setInvoiceNumber(fetchNewInvoiceNumber.data.new_invoice_number)
  };

  const getCustomerAddressData = async (customerId) => {
    const fetchAdresses = await getCustomerAddresses(customerId);

    setcustomerAddresses(fetchAdresses.data);
  };

  const onChangeCustomer = (customerId) => {
    setSelectedCustomerId(customerId);
    const filteredData = customers.filter((item) => item._id === customerId);
    setSelectedCustomer(filteredData[0]);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getCustomerAddressData(selectedCustomerId);
  }, [selectedCustomerId]);


  const calculateInvoice = (values) => {
    const sum = itemsCart.reduce((accumulator, currentObject) => {
      return accumulator + currentObject.total_amount;
    }, 0);

    const gst = (sum * 18) / 100;
    const total = sum + gst;
    setinvoiceSubtotal(sum)
    setinvoiceTax(gst)
    setinvoiceTotal(total)
  }

  //getting values from itemscart component
  const getItemsCart = (values) => {
    setItemsCart(values)
    calculateInvoice(values)
  }

  const initialValues = {
    invoice_receiptno: invoiceNumber,
    merchant_company: merchantBusinessDetails.business_name,
    merchant_gstno: merchantBusinessDetails.comp_gst,
    merchant_panno: merchantBusinessDetails.comp_pan_number,
    invoice_issue_date: date,
    invoice_amount: invoiceTotal,
    invoice_billing_to: selectedCustomerId,
    customer_gstno: selectedCustomer.customer_gstno,
    customer_email: selectedCustomer.customer_email,
    customer_phone: selectedCustomer.customer_phone,
    invoice_billing_address: selectedBillingAdress,
    invoice_shipping_address: selectedShippingAdress,
    invoice_subtotal: invoiceSubtotal,
    invoice_tax_amount: invoiceTax,
    invoice_description: "",
    invoice_terms_cond: "",
  };

  const validationSchema = Yup.object().shape({
    invoice_receiptno: Yup.string().required("Receipt Number is required"),
    merchant_company: Yup.string().required("Company Name  is required"),
    merchant_gstno: Yup.string()
      .required("Gst Number  is required")
      .matches(
        /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/,
        "Invalid gst no format"
      )
      .required("Gst No is required"),
    merchant_panno: Yup.string()
      .required("Pan Number  is required")
      .matches(/[A-Z]{5}[0-9]{4}[A-Z]{1}/, "Invalid Pan Number  format")
      .required("Gst No is required"),
    invoice_amount: Yup.number().moreThan(0, 'Amount must be greater than 0').required("Amount is required"),
    invoice_billing_to: Yup.string().required("Customer  is required"),
    customer_gstno: Yup.string()
      .matches(
        /^(\d{2}[A-Z]{5}\d{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1})$/,
        "Invalid gst no format"
      )
      .required("Gst No is required"),
    customer_email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    customer_phone: Yup.string()
      .matches(/^\d{10}$/, "Invalid Phone Number format")
      .required("Phone Number is required"),
    invoice_billing_address: Yup.string().required(
      "Billing Address  is required"
    ),
    invoice_shipping_address: Yup.string().required('Shipping adress is required'),
    invoice_subtotal: Yup.number().moreThan(0, 'Subtotal must be greater than 0').required("Subtotal  is required"),
    invoice_tax_amount: Yup.number().moreThan(0, 'Tax must be greater than 0').required("Tax amount  is required"),
  });

  const navigate = useNavigate();
  const onSubmit = async (values, { resetForm }) => {
    // Handle form submission

    console.log(values, 'form Submitted');

    const sendRequest = await addInvoiceDetails(values,itemsCart);

    if (sendRequest.success === true) {
      toast({
        title: "Success",
        description: sendRequest.message,
      });
    } else {
      toast({
        variant: "destructive",
        title: "Error",
        description: sendRequest.message,
      });
    }
    resetForm();
    navigate("/invoices");
  };



  return (
    <div className="my-6 mx-3">
      <div >
        <h1 className="text-lg font-bold">Add Invoice</h1>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form>
            <div className="grid grid-cols-1 md:grid-cols-3 pt-3 pb-8 px-3 gap-2 gap-y-4 ">
              <div className="col-span-3">
                <h1 className="text-lg font-bold">Company Details :</h1>
              </div>
              <div>
                <Label htmlFor="invoice_receiptno">Invoice No:*</Label>
                <Input
                  className="mt-3"
                  type="text"
                  id="invoice_receiptno"
                  name="invoice_receiptno"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.invoice_receiptno}
                />
                <div></div>
                <ErrorMessage
                  name="invoice_receiptno"
                  className="text-red-500 text-xs  my-2"
                  component="div"
                />
              </div>
              <div>
                <Label htmlFor="merchant_company">Company Name:*</Label>
                <Input
                  className="mt-3"
                  type="text"
                  readOnly
                  id="merchant_company"
                  name="merchant_company"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.merchant_company}
                />
                <ErrorMessage
                  name="merchant_company"
                  className="text-red-500 text-xs  my-2"
                  component="div"
                />
              </div>
              <div>
                <Label htmlFor="merchant_gstno">GSTIN:*</Label>
                <Input
                  className="mt-3"
                  type="text"
                  readOnly
                  id="merchant_gstno"
                  name="merchant_gstno"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.merchant_gstno}
                />
                <ErrorMessage
                  name="merchant_gstno"
                  className="text-red-500 text-xs  my-2"
                  component="div"
                />
              </div>
              <div>
                <Label htmlFor="merchant_panno">Pan No:*</Label>
                <Input
                  className="mt-3"
                  type="text"
                  readOnly
                  id="merchant_panno"
                  name="merchant_panno"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.merchant_panno}
                />
                <ErrorMessage
                  name="merchant_panno"
                  className="text-red-500 text-xs  my-2"
                  component="div"
                />
              </div>

              <div>
                <Label htmlFor="invoice_issue_date">Invoice Date:*</Label>
                <div className="mt-3">
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        variant={"outline"}
                        className={cn(
                          "w-full justify-start text-left font-normal",
                          !date && "text-muted-foreground"
                        )}
                      >
                        <CalendarIcon className="mr-2 h-4 w-4" />
                        {date ? format(date, "LLL dd, y") : <span>Pick a date</span>}
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent className="w-auto p-0">
                      <Calendar
                        mode="single"
                        selected={date}
                        onSelect={setDate}
                        initialFocus
                      />
                    </PopoverContent>
                  </Popover>
                </div>

                <ErrorMessage
                  name="invoice_issue_date"
                  className="text-red-500 text-xs  my-2"
                  component="div"
                />
              </div>

              <div className="col-span-3">
                <hr />
                <h1 className="text-lg font-bold mt-2">Customer Details :</h1>
              </div>
              <div>
                <Label htmlFor="customer_name">Customer Name:</Label>
                <div className="mt-3">
                  <Select
                    onValueChange={(field) => {
                      onChangeCustomer(field);
                    }}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Customer" />
                    </SelectTrigger>
                    <SelectContent>
                      {customers.length > 0 &&
                        customers.map((customer) => (
                          <SelectItem key={customer._id} value={customer._id}>
                            {customer.customer_name}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
                <ErrorMessage
                  name="customer_name"
                  className="text-red-500 text-xs  my-2"
                  component="div"
                />
              </div>

              <div>
                <Label htmlFor="customer_gstno">Customer Gst No:</Label>
                <Input
                  className="mt-3"
                  type="text"
                  id="customer_gstno"
                  name="customer_gstno"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.customer_gstno}
                />
                <ErrorMessage
                  name="customer_gstno"
                  className="text-red-500 text-xs my-2"
                  component="div"
                />
              </div>

              <div>
                <Label htmlFor="customer_email">Customer Email:</Label>
                <Input
                  className="mt-3"
                  type="text"
                  id="customer_email"
                  name="customer_email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.customer_email}
                />
                <ErrorMessage
                  name="customer_email"
                  className="text-red-500 text-xs my-2"
                  component="div"
                />
              </div>

              <div>
                <Label htmlFor="customer_phone">Customer Phone:</Label>
                <Input
                  className="mt-3"
                  type="text"
                  id="customer_phone"
                  name="customer_phone"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.customer_phone}
                />
                <ErrorMessage
                  name="customer_phone"
                  className="text-red-500 text-xs my-2"
                  component="div"
                />
              </div>

              <div>
                <Label htmlFor="invoice_billing_address">
                  Billing Address:
                </Label>
                <div className="mt-3">
                  <Select
                    onValueChange={(field) => {
                      setSelectedBillingAdress(field);
                    }}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select Address" />
                    </SelectTrigger>
                    <SelectContent>
                      {customerAddresses.length > 0 &&
                        customerAddresses.map((address) => (
                          <SelectItem key={address._id} value={address._id}>
                            {address.address} {address.pincode} {address.city}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
                <ErrorMessage
                  name="invoice_billing_address"
                  className="text-red-500 text-xs my-2"
                  component="div"
                />
              </div>

              <div>
                <Label htmlFor="invoice_shipping_address">
                  Shipping Address:
                </Label>
                <div className="mt-3">
                  <Select
                    onValueChange={(field) => {
                      setSelectedShippingAdress(field);
                    }}
                  >
                    <SelectTrigger className="w-full">
                      <SelectValue placeholder="Select Address" />
                    </SelectTrigger>
                    <SelectContent>
                      {customerAddresses.length > 0 &&
                        customerAddresses.map((address) => (
                          <SelectItem key={address._id} value={address._id}>
                            {address.address} {address.pincode} {address.city}
                          </SelectItem>
                        ))}
                    </SelectContent>
                  </Select>
                </div>
                <ErrorMessage
                  name="invoice_shipping_address"
                  className="text-red-500 text-xs my-2"
                  component="div"
                />
              </div>

              {selectedCustomerId != null && (
                <div>
                  <AddCustomerAddress
                    getAddressFunction={getCustomerAddressData}
                    customerId={selectedCustomerId}
                    open={addCustomerAddressDialog}
                  />
                </div>
              )}
            </div>

            <hr />
            <div className="mt-2">
              <ItemCart sendCart={getItemsCart} />
            </div>


            <hr />

            <div className="grid grid-cols-3 gap-4 my-2">
              <div>
                <Label htmlFor="invoice_subtotal">
                  Subtotal:
                </Label>
                <div>
                  <Input
                    className="mt-3"
                    type="text"
                    id="invoice_subtotal"
                    name="invoice_subtotal"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.invoice_subtotal}
                  />
                </div>
                <ErrorMessage
                  name="invoice_subtotal"
                  className="text-red-500 text-xs  my-2"
                  component="div"
                />
              </div>

              <div>
                <Label htmlFor="invoice_tax_amount">
                  Tax:
                </Label>
                <div>
                  <Input
                    className="mt-3"
                    type="text"
                    id="invoice_tax_amount"
                    name="invoice_tax_amount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.invoice_tax_amount}
                  />
                  <ErrorMessage
                    name="invoice_tax_amount"
                    className="text-red-500 text-xs  my-2"
                    component="div"
                  />
                </div>
              </div>

              <div>
                <Label htmlFor="invoice_amount">
                  Total Amount:
                </Label>
                <div>
                  <Input
                    className="mt-3"
                    type="text"
                    id="invoice_amount"
                    name="invoice_amount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.invoice_amount}
                  />
                  <ErrorMessage
                    name="invoice_amount"
                    className="text-red-500 text-xs  my-2"
                    component="div"
                  />
                </div>
              </div>
            </div>

            <div>
              <Label htmlFor="invoice_terms_cond">
                Terms & Condition:
              </Label>
              <div>
                <Textarea
                  className="mt-3"
                  id="invoice_terms_cond"
                  name="invoice_terms_cond"
                  onChange={(e) => { setFieldValue('invoice_terms_cond', e.target.value) }}
                  onBlur={handleBlur}
                  value={values.invoice_terms_cond}
                />

              </div>
            </div>

            <div>
              <Label htmlFor="invoice_description">
                Description:
              </Label>
              <div>
                <Textarea
                  className="mt-3"
                  id="invoice_description"
                  name="invoice_description"
                  onChange={(e) => { setFieldValue('invoice_description', e.target.value) }}
                  onBlur={handleBlur}
                  value={values.invoice_description}
                />

              </div>
            </div>

            <div>
              {Object.keys(errors).length > 0 && (
                <div>
                  <h3>Validation Errors:</h3>
                  <ul>
                    {Object.keys(errors).map((fieldName, index) => (
                      <li key={index}>{errors[fieldName]}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div className="mx-4 my-4">
              <Button type="submit">Submit</Button>
            </div>

          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddInvoice;
