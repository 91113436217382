import React, { useState } from "react";
import { Field, Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { addItem } from "Apis/merchantapis";
import { useToast } from "@/components/ui/use-toast";

const AddItem = (prop) => {
  const { toast } = useToast();
  const [open, setOpen] = useState(false);

  const initialValues = {
    item_name: "",
    item_amount: "",
    item_description: "",
  };

  const validationSchema = Yup.object().shape({
    item_name: Yup.string().required("Item Name is required"),
    item_amount: Yup.number().required(" Amount is required"),
  });

  const onSubmit = async (values) => {
    // Handle form submission
    setOpen(false);
    const sendRequest = await addItem(values);
    if (sendRequest.success == true) {
      toast({
        title: "Success",
        description: sendRequest.message,
      });
    } else {
      toast({
        variant: "destructive",
        title: "Error",
        description: sendRequest.message,
      });
    }
    prop.getFunction();
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">Add Item</Button>
      </DialogTrigger>
      <DialogContent
        className={
          "lg:max-w-screen-lg overflow-y-scroll max-h-screen hide-scrollbar"
        }
      >
        <DialogHeader>
          <DialogTitle>Add Item</DialogTitle>
          <DialogDescription>You can create Items here ..</DialogDescription>
        </DialogHeader>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => (
            <Form>
              <div className="grid grid-cols-1 md:grid-cols-3 pt-3 pb-16 px-3 gap-2 gap-y-4 ">
                <div>
                  <Label htmlFor="item_name">Item Name:</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="item_name"
                    name="item_name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.item_name}
                  />
                  <ErrorMessage
                    name="item_name"
                    className="text-red-500 text-xs  my-2"
                    component="div"
                  />
                </div>
                <div>
                  <Label htmlFor="item_amount">Item Amount:</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="item_amount"
                    name="item_amount"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.item_amount}
                  />
                  <ErrorMessage
                    name="item_amount"
                    className="text-red-500 text-xs my-2"
                    component="div"
                  />
                </div>

                <div>
                  <Label htmlFor="item_description">Item Description:</Label>
                  <Input
                    className="mt-3"
                    type="text"
                    id="item_description"
                    name="item_description"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.item_description}
                  />
                  {/* Add validation error display */}
                </div>
              </div>

              <div></div>

              <DialogFooter>
                <Button type="submit">Submit</Button>
              </DialogFooter>
            </Form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

export default AddItem;
