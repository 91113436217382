import React from 'react';
import { useOutletContext } from "react-router-dom";
import TestDashboard from './TestDashboard';
import Dashboard from './Dashboard';

const PayoutDashboard = () => {
    const testMode = useOutletContext();

    return (
        <div>
            {testMode ? <TestDashboard /> : <Dashboard />}
        </div>
    );
};

export default PayoutDashboard;