import { ThemeProvider } from "@/theme-provider";
import { MerchantHeader } from "./MerchantHeader";
import { TailwindIndicator } from "@/tailwind-indicator";
import { Outlet } from "react-router-dom";
import MerchantSidebar from "./MerchantSidebar";
import React, { useState } from "react";
import MerchantActivationModal from "Merchant/Activation/MerchantActivationModal";
import { getMerchantOrders } from "Apis/merchantapis";

export default function MerchantLayout() {

  const [testMode, setTestMode] = useState(false);

  const getMode = (value) => {
    setTestMode(value)
  }


  return (
    <div className="min-h-screen bg-background font-sans antialiased">
      <ThemeProvider attribute="class" defaultTheme="system" enableSystem>
        <MerchantHeader modeFunction={getMode} testMode={testMode} />
        <div className="flex flex-shrink-0">
          <aside className="h-screen w-60 sticky top-0 shrink-0" style={{}}>
            {" "}
            <MerchantSidebar testMode={testMode} />{" "}
          </aside>

          <main className="w-full mt-3">
            <aside className="w-full sticky top-0" style={{ zIndex: "998" }}>
              {" "}
            </aside>

            <MerchantActivationModal modeFunction={getMode} testMode={testMode} />
            <Outlet context={testMode} />
          </main>
        </div>
        <TailwindIndicator />
      </ThemeProvider >
    </div >
  );
}
